import { ContentBlock, ContentBlockType } from "../../models";

export const CardDeckLayout = new ContentBlock({
    title: "Card deck",
    content: "Card deck",
    content_block_type: ContentBlockType["Card Deck"],
    // theme: {
    //     ...defaultGridTheme,
    //     display: "grid",
    //     gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    //     gridGap: ["1em", "1.1em", "1.2em", "1.3em"],
    //     columnCount: 4,
    //     childCount: 4,
    //     consistentChildProps: true,
    //     widthBreakpoint1: "100%",
    //     widthBreakpoint2: "100%",
    //     widthBreakpoint3: "100%",
    //     widthBreakpoint4: "100%",
    // } as ThemeObject,
    contentBlocks: [],
});
