import React, { ReactNode, useMemo } from "react";
import { ThemeObject } from "../ThemeObjects";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ModalContainer } from "@/modules/shared";

type Props = ContentBlockEvents & {
    isOpen: boolean;
    theme: typeof defaultModalTheme;
    title?: string;
    children: ReactNode;
    contentBlockId: string;
    closeOverride?: (e: React.MouseEvent) => void;
};

export const ModalTheme = ({
    isOpen,
    theme,
    title,
    children,
    onClick,
    onHover,
    isInEditorContext,
    contentBlockId,
    closeOverride,
}: Props) => {
    function close(e) {
        if ("function" === typeof closeOverride) {
            closeOverride(e);
        } else {
            SapienInertia.visit(
                sapienRoute("sim-content.modal-content-block.close"),
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: [
                        "modalComponent",
                        "savedContentBlock",
                        "modalPageProps",
                        "othersSelections",
                        "reflectionJournalEntries",
                    ],
                },
            );
        }
    }

    const maxWidth = useMemo(() => {
        const { modalSize } = theme;
        if (modalSize === "sm") return "36rem";

        if (modalSize === "md") return "48rem";

        if (modalSize === "lg") return "60rem";
        if (modalSize === "xl") return "72em";

        return "100vw";
    }, [theme?.modalSize]);

    const minHeight = useMemo(() => {
        const { modalSize } = theme;
        if (modalSize === "full") {
            return "100vh";
        }
        return "auto";
    }, [theme?.modalSize]);

    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={isInEditorContext ? () => {} : close}
            styles={{
                maxWidth,
                minHeight,
                backgroundColor: theme.backgroundColor,
                width: "100%",
                padding: 0,
            }}
            modalId={contentBlockId}
            isInEditorContext={isInEditorContext}
            wrapperSelector="#content-block-modal-wrapper"
        >
            {(isOpen || isInEditorContext) && (
                <div
                    className={`flex min-h-[100%] items-center justify-center`}
                    id={contentBlockId}
                    data-testid={`modal-${contentBlockId}`}
                >
                    <div
                        className={`relative flex w-full flex-col justify-between px-4 ${
                            isInEditorContext
                                ? "overflow-y-visible"
                                : "overflow-hidden"
                        } ${theme.modalSize === "full" ? "" : "rounded-md"}`}
                        onClick={onClick}
                        onMouseOver={onHover}
                        data-content-block-id={contentBlockId}
                        style={{
                            minHeight,
                            maxWidth,
                            backgroundColor: theme.backgroundColor,
                        }}
                    >
                        <div className={"flex w-full justify-between p-4"}>
                            {!!title && title}
                            {!title && <span />}
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    close(e);
                                }}
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>

                        <div>{children}</div>
                        <div className="flex justify-end py-4">
                            <button
                                onClick={close}
                                type="button"
                                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
};

export const defaultModalTheme: Partial<ThemeObject> = {
    modalSize: "lg",
    backgroundColor: "rgba(255,255,255,1)",
};
