import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReflectionJournalEntry } from "@/models";

type ReflectionJournalEntrySliceState = {
    reflectionJournalEntries: { [index: string]: ReflectionJournalEntry };
};

const initialState: ReflectionJournalEntrySliceState = {
    reflectionJournalEntries: {},
};

const reflectionJournalEntrySlice = createSlice({
    name: "reflectionJournalEntrySlice",
    initialState,
    reducers: {
        gotReflectionJournalEntries(
            state,
            { payload }: PayloadAction<ReflectionJournalEntry[]>
        ) {
            state.reflectionJournalEntries = payload.reduce(
                (carry, reflectionJournalEntry) => ({
                    ...carry,
                    [reflectionJournalEntry.id]: reflectionJournalEntry,
                }),
                {}
            );
        },
    },
});

export const reflectionJournalEntryReducer =
    reflectionJournalEntrySlice.reducer;

// export type RootState = ReturnType<typeof reflectionJournalEntryReducer>;

export const { gotReflectionJournalEntries } =
    reflectionJournalEntrySlice.actions;
