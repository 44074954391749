import { Extension } from "@tiptap/react";

export interface LineHeightOptions {
    types: string[];
    heights: string[];
    defaultHeight: string;
}

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        lineHeight: {
            /**
             * Set the line height attribute
             */
            setLineHeight: (height: string) => ReturnType;
            /**
             * Unset the text align attribute
             */
            unsetLineHeight: () => ReturnType;
        };
    }
}

export const lineHeightOptions = [
    "normal",
    ".75" /* 12px */,
    "1" /* 16px */,
    "1.25" /* 20px */,
    "1.5" /* 24px */,
    "1.75" /* 28px */,
    "2" /* 32px */,
    "2.25" /* 36px */,
    "2.5", /* 40px */,
    "2.75", /* 44px */,
    "3", /* 48px */,
    "3.25", /* 52px */,
    "3.5", /* 56px */,
    "3.75", /* 60px */,
];
export const LineHeight = Extension.create<LineHeightOptions>({
    name: "lineHeight",

    addOptions() {
        return {
            types: ["heading", "paragraph", "textStyle", "typography"],
            heights: lineHeightOptions,
            defaultHeight: "100%",
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: this.options.defaultHeight,
                        parseHTML: (element) =>
                            element.style.lineHeight ||
                            this.options.defaultHeight,
                        renderHTML: (attributes) => {
                            if (
                                attributes.lineHeight ===
                                this.options.defaultHeight
                            ) {
                                return {};
                            }

                            return {
                                style: `line-height: ${attributes.lineHeight} !important`,
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setLineHeight:
                (height: string) =>
                ({ commands }) => {
                    if (!this.options.heights.includes(height)) {
                        return false;
                    }

                    return this.options.types.every((type) =>
                        commands.updateAttributes(type, { lineHeight: height })
                    );
                },

            unsetLineHeight:
                () =>
                ({ commands }) => {
                    return this.options.types.every((type) =>
                        commands.resetAttributes(type, "lineHeight")
                    );
                },
        };
    },
});
