import React from "react";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { VictoryLabel, VictoryLine } from "victory";
import { AreaOrLineSeriesTheme } from "./SharedChartTypes";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";

type StyledChartContainerProps = ContentBlockEvents & {
    theme: Partial<AreaOrLineSeriesTheme>;
    contentBlockId?: string;
    formattedChartData: FormattedChartValue[];
};

export const LineSeries: React.FC<StyledChartContainerProps> = ({
    theme,
    formattedChartData,
    contentBlockId,
    onClick,
    onHover,
}) => {
    return (
        <VictoryLine
            domain={[1, 4]}
            data-content-block-id={contentBlockId}
            // label={formattedChartData?.label && ""}
            data={formattedChartData}
            interpolation={theme?.interpolation || undefined}
            style={{
                data: {
                    stroke: theme?.stroke,
                    strokeWidth: theme?.strokeWidth,
                },
            }}
            labelComponent={
                <VictoryLabel
                    dx={theme.labelProps?.x && ""}
                    dy={theme.labelProps?.y && ""}
                    renderInPortal
                />
            }
        />
    );
};


