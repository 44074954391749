import React, { ReactNode } from "react";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { ThemeObject } from "../../ThemeObjects";
import { StyledUniversalDiv } from "../Universal";
import { ChartContentBlock } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { LineOrAreaChart, PieOrDonutChart } from "@/modules/shared/charts";
import { ColumnChart } from "@/modules/shared/charts/ColumnChart";
import { ChartType } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";
import { cn } from "@/util";

type StyledChartContainerProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
    contentBlocks: ChartContentBlock[];
    formattedChartValues: {
        [index: string]: FormattedChartValue[];
    };
};
function getChartType(formattedChartValues: {
    [index: string]: FormattedChartValue[];
}): ChartType {
    const chartTypes = Object.values(formattedChartValues)
        .filter((values) => values?.length > 0)
        .reduce((carry: ChartType[], values) => {
            return [...carry, ...values.map((value) => value.chartType)];
        }, []);

    if (chartTypes.some((type) => type === "pie" || type === "doughnut")) {
        return chartTypes.every((type) => type === "doughnut")
            ? "doughnut"
            : "pie";
    }

    if (chartTypes.every((type) => type === "bar")) return "bar";

    //multi-mode charts should render as area or line
    return chartTypes.some((type) => type === "area") ? "area" : "line";
}

function Chart({
    formattedChartValues,
    theme,
}: {
    formattedChartValues: { [index: string]: FormattedChartValue[] };
    theme: typeof defaultChartContainerTheme;
}) {
    switch (getChartType(formattedChartValues)) {
        case "pie":
        case "doughnut":
            return (
                <PieOrDonutChart
                    theme={theme}
                    formattedChartValues={formattedChartValues}
                />
            );
        case "bar":
            return (
                <ColumnChart
                    theme={theme}
                    formattedChartValues={formattedChartValues}
                />
            );
        case "area":
        case "line":
            return (
                <LineOrAreaChart
                    formattedChartValues={formattedChartValues}
                    theme={theme}
                />
            );
    }
}

export const ChartContainer: React.FC<StyledChartContainerProps> = ({
    theme,
    children,
    contentBlockId,
    contentBlocks,
    onClick,
    onHover,
    formattedChartValues,
}) => {
    return (
        <StyledUniversalDiv
            data-content-block-id={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
            {...theme}
            width={theme.width || "100%"}
            border={"none"}
            className={cn("relative", theme.tailwindClasses)}
        >
            <Chart theme={theme} formattedChartValues={formattedChartValues} />
        </StyledUniversalDiv>
    );
};

type ChartContainerTheme = Partial<ThemeObject> & {
    maximumValue?: number;
};
export const defaultChartContainerTheme: ChartContainerTheme = {
    pt: 0,
    pr: 0,
    pb: 0,
    pl: 0,
    mt: 0,
    mr: 0,
    mb: 0,
    ml: 0,
    maximumValue: 0,
    minimumValue: null,
    color: "#000000",
    backgroundColor: "transparent",
    borderColor: "transparent",
    borderRadius: 0,
    gridColor: "#0000003a",
    axisColor: "#000000",
    toolTipBackground: "#ffffffe6",
    borderWidth: 0,
    width: "100%",
    abbreviateYAxis: true,
    abbreviateXAxis: true,
} as const;
