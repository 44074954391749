import { useCallback } from "react";
import { ColorGroup, Color } from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state/AppStore";
import {
    SetColorGroup,
    SetColor,
    ColorsQueried,
} from "@/redux-state/colorSlice";

// /**
//  *
//  * @param rgb Should be a number between 0 and 1
//  * @returns string
//  */
// function opacityToHex(rgb: number): string {
//     const hex = Math.round(Number(rgb * 255)).toString(16);
//     if (hex.length < 2) {
//         return "0" + hex;
//     }
//     return hex;
// }

// function convertColorMapToHexColor(
//     theme: string | ColorMap,
//     colors: { [index: string]: Color }
// ) {
//     if ("string" === typeof theme) return theme;
//     if (
//         "object" === typeof theme &&
//         "color_id" in theme &&
//         "opacity" in theme &&
//         colors &&
//         colors[theme["color_id"]]
//     ) {
//         return colors[theme["color_id"]].css_color + opacityToHex(theme["opacity"]);
//         // if(!colors[blockCopy.theme[prop]['opacity']) {} else {}
//         //blockCopy.theme[prop] = colors[blockCopy.theme[prop]["color_id"]];
//     }
// }

export function useColorStore() {
    const dispatch = useAppDispatch();

    const colorGroups = useAppSelector((state) => {
        return Object.values(state.colorStore.colorGroups);
    });
    const colorGroup = useAppSelector((state) => state.colorStore.colorGroup);

    const color = useAppSelector((state) => state.colorStore.color);

    const selectedColors = useAppSelector((state) => {
        return state.colorStore.colorGroup
            ? Object.values(state.colorStore.colors).filter(
                  (color) =>
                      color.color_group_id === state.colorStore.colorGroup.id
              )
            : [];
    });

    const _setColorGroup = useCallback(
        (colorGroup: ColorGroup) => dispatch(SetColorGroup(colorGroup)),
        [dispatch]
    );

    const _setColor = useCallback(
        (color: Color) => dispatch(SetColor(color)),
        [dispatch]
    );

    const _setColorGroups = useCallback(
        (colorGroups: ColorGroup[]) => dispatch(ColorsQueried(colorGroups)),
        [dispatch]
    );

    // const _convertColorMapToHexColor = useCallback(
    //     (theme: ColorMap | string) => {
    //         return useAppSelector((state) => {
    //             return convertColorMapToHexColor(theme, state.colorStore.colors)
    //         });
    //     },
    //     [dispatch]
    // );

    return {
        colorGroups,
        colorGroup,
        color,
        selectedColors,
        setColor: _setColor,
        setColorGroup: _setColorGroup,
        // convertColorMapToHexColor: _convertColorMapToHexColor,
        setColorGroups: _setColorGroups,
    };
}
