import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { VariableValue } from "@/models/VariableValue";
//import { defaultSimulationTheme, StyledQuestionProps } from "../styles";

type VariableValueSliceState = {
    variableValues: { [index: string]: VariableValue };
    variableValuesByContentBlockId: { [index: string]: VariableValue[] };
};

const initialState: VariableValueSliceState = {
    variableValues: {},
    variableValuesByContentBlockId: {},
};

const variableValueSlice = createSlice({
    name: "ContentBlockStore",
    initialState,
    reducers: {
        gotVariableValues(state, { payload }: PayloadAction<VariableValue[]>) {
            state.variableValuesByContentBlockId = payload.reduce(
                (carry, variableValue) => {
                    if (!carry[variableValue.content_block_id])
                        carry[variableValue.content_block_id] = [];
                    return {
                        ...carry,
                        [variableValue.content_block_id]: [
                            ...carry[variableValue.content_block_id],
                            variableValue,
                        ],
                    };
                },
                {}
            );

            state.variableValues = payload.reduce(
                (
                    carry: { [index: string]: VariableValue },
                    variableValue: VariableValue
                ) => {
                    return { ...carry, [variableValue.id]: variableValue };
                },
                {}
            );
        },
    },
});

export const variableValueSliceReducer = variableValueSlice.reducer;
export const { gotVariableValues } = variableValueSlice.actions;
