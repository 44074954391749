import { ContentBlockType } from "../../models";
import { defaultLinkTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const LinkConfig: ContentBlockConfig = {
    typeKey: "Link",
    type: ContentBlockType.Link,
    defaultTheme: defaultLinkTheme,
    availableBehaviors: [
        "MoveToRound",
        "NavigateToNextPage",
        "NavigateToPage",
        "NavigateToPreviousPage",
        "ExternalLink",
        "DisplayIfRoundIsComplete",
        "DisplayIfParticipantHasSubmittedOnCurrentPage",
        "DisplayIfTeammateHasSubmittedOnCurrentPage",
        "DisplayIfRoundHasSubmissionFromTeam",
    ],
    addableChildBlocks: [ContentBlockType.Icon],
};
