import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Round, RoundShape } from "../models";
import { mapById } from "./util";

type RoundSliceState = {
    rounds: { [index: string]: RoundShape };
    appState: {
        editedRound: RoundShape | undefined;
    };
};

const initialState: RoundSliceState = {
    rounds: {},
    appState: {
        editedRound: undefined,
    },
};

const roundDataSlice = createSlice({
    name: "RoundStore",
    initialState,
    reducers: {
        roundsQueried(state, { payload }: PayloadAction<RoundShape[]>) {
            state.rounds = mapById(payload);
        },
        setEditedRound(state, { payload }: PayloadAction<Round>) {
            state.appState.editedRound = payload;
        },
    },
});

const roundStoreReducer = roundDataSlice.reducer;

const { roundsQueried, setEditedRound } = roundDataSlice.actions;
export { setEditedRound, roundsQueried };

export { roundStoreReducer };
