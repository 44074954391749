import {
    Prompt,
    PromptType,
    Selection,
    Option,
    SelectionShape,
} from "../models";
import { groupBy } from "lodash";
import {
    FormContentBlockDefaultValue,
    FormContentBlockDefaultValuesObject,
} from "@/styles";
import { z } from "zod";

export const getQuestionData = (
    prompt: Prompt,
    options: Option[],
    selections: Selection[]
): FormContentBlockDefaultValue => {
    let hasSelection = selections !== undefined && selections.length > 0;

    switch (prompt.prompt_type) {
        case PromptType["Multiple Choice"]:
            return hasSelection
                ? selections
                      .filter((s) => s.is_selected)
                      .map((s) => s.option_id)[0]
                : null;
        case PromptType["Multiple Select"]:
            return hasSelection
                ? selections
                      .filter((s) => s.is_selected)
                      .map((s) => s.option_id)
                : [];
        case PromptType["Numerical Input"]:
            return hasSelection
                ? selections[0].numerical_value.toString()
                : (prompt.min || 0).toString();
        case PromptType["Numerical Slider"]:
            return hasSelection
                ? selections[0].numerical_value.toString()
                : (prompt.min || 0).toString();
        case PromptType["Rank Order"]:
        case PromptType["Drag and Drop"]:
            return hasSelection
                ? (selections.map((s) => ({
                      option_id: s.option_id,
                      numerical_value: s.numerical_value,
                      option_text: s.option_text,
                      is_selected: s.is_selected,
                  })) as SelectionShape[])
                : (options.map((o, i) => ({
                      option_id: o.id,
                      numerical_value: i,
                      option_text: o.content,
                      is_selected:
                          prompt.prompt_type === PromptType["Rank Order"],
                  })) as SelectionShape[]);
        case PromptType.Timeline:
            return hasSelection
                ? (selections.map((s) => ({
                      option_id: s.option_id,
                      numerical_value: s.numerical_value,
                      option_text: s.option_text,
                      is_selected: s.is_selected,
                      drag_and_drop_prompt_container_id:
                          s.drag_and_drop_prompt_container_id,
                  })) as SelectionShape[] &
                      { option_drag_and_drop_prompt_container_id: string }[])
                : (options.map((o, i) => ({
                      option_id: o.id,
                      numerical_value: i,
                      option_text: o.content,
                      is_selected: false,
                      drag_and_drop_prompt_container_id:
                          o.drag_and_drop_prompt_container_id,
                  })) as SelectionShape[] &
                      { option_drag_and_drop_prompt_container_id: string }[]);
        case PromptType["Dropdown List"]:
            return hasSelection
                ? selections
                      .filter((s) => s.is_selected)
                      .map((s) => s.option_text)[0]
                : "";
        case PromptType["Toggle Switch"]:
            return hasSelection
                ? selections[0].option_text
                : !!options?.length
                ? options[0].content
                : "";
        case PromptType["Short Text"]:
            return hasSelection ? selections[0].string_value : "";
        case PromptType["Long Text"]:
            return hasSelection ? selections[0].string_value : "";
        default:
            return "";
    }
};

// used in usePromptStore for participant layer
export const getSelectionData = (
    prompts: Prompt[],
    selections: Selection[],
    formObject: FormContentBlockDefaultValuesObject,
    simulationId: string
) => {
    let groupedSelections = groupBy(selections, "prompt_id");
    let newSelections: Selection[] = [];
    prompts.forEach((p) => {
        let formData = formObject[p.id];
        let promptSelections: Selection[] = [];
        //let updatedSelection: Selection;
        let firstSelection =
            groupedSelections[p.id] && groupedSelections[p.id].length > 0
                ? groupedSelections[p.id][0]
                : new Selection({
                      prompt_id: p.id,
                      option_id:
                          p.options && p.options.length ? p.options[0].id : "",
                      prompt_text: p.content,
                      option_text:
                          p.options && p.options.length
                              ? p.options[0].content
                              : "",
                      is_selected: true,
                      string_value:
                          p.options && p.options.length
                              ? p.options[0].content
                              : "",
                      simulation_id: simulationId,
                  });
        switch (p.prompt_type) {
            case PromptType["Multiple Choice"]:
            case PromptType["Dropdown List"]:
                // case PromptType["Toggle Switch"]: --- TODO: fix toggle
                // let mcSel = {
                //     ...firstSelection,
                //     option_id: p.options.filter(
                //         (o) => o.content === formData
                //     )[0].id,
                //     option_text: formData,
                //     string_value: formData,
                // } as Selection;
                // promptSelections = [mcSel];
                // break;
                promptSelections = groupedSelections[p.id]
                    ? groupedSelections[p.id].map(
                          (s) =>
                              ({
                                  ...s,
                                  is_selected: (formData as string[])?.includes(
                                      s.option_id
                                  ),
                              } as Selection)
                      )
                    : p.options.map(
                          (option) =>
                              new Selection({
                                  prompt_id: p.id,
                                  option_id: option.id,
                                  is_selected:
                                      (formData as string) == option.id,
                                  prompt_text: p.content,
                                  option_text: option.content,
                                  string_value: option.content,
                                  simulation_id: simulationId,
                              })
                      );
                break;
            case PromptType["Multiple Select"]:
                promptSelections = groupedSelections[p.id]
                    ? groupedSelections[p.id].map(
                          (s) =>
                              ({
                                  ...s,
                                  is_selected: (formData as string[])?.includes(
                                      s.option_id
                                  ),
                              } as Selection)
                      )
                    : p.options.map(
                          (option) =>
                              new Selection({
                                  prompt_id: p.id,
                                  option_id: option.id,
                                  is_selected: (formData as string[]).includes(
                                      option.id
                                  ),
                                  prompt_text: p.content,
                                  option_text: option.content,
                                  string_value: option.content,
                                  simulation_id: simulationId,
                              })
                      );
                break;
            case PromptType["Numerical Input"]:
                let numSel = {
                    ...firstSelection,
                    numerical_value: Number(formData),
                } as Selection;
                promptSelections = [numSel];
                break;
            case PromptType["Numerical Slider"]:
                let slideSel = {
                    ...firstSelection,
                    numerical_value: Number(formData),
                } as Selection;
                promptSelections = [slideSel];
                break;
            case PromptType["Rank Order"]:
            case PromptType["Drag and Drop"]: // TODO
                promptSelections = groupedSelections[p.id]
                    ? groupedSelections[p.id]
                          .filter(
                              (selection) =>
                                  !!(formData as SelectionShape[]).find(
                                      (d: SelectionShape) =>
                                          d.option_id === selection.option_id
                                  )
                          )
                          .map(
                              (s) =>
                                  ({
                                      ...s,
                                      // rank_position:
                                      numerical_value: (
                                          formData as SelectionShape[]
                                      ).filter(
                                          (d: SelectionShape) =>
                                              d.option_id === s.option_id
                                      )[0].numerical_value,
                                      is_selected: (
                                          formData as SelectionShape[]
                                      ).filter(
                                          (d: SelectionShape) =>
                                              d.option_id === s.option_id
                                      )[0].is_selected,
                                  } as Selection)
                          )
                    : p.options.map(
                          (option) =>
                              new Selection({
                                  prompt_id: p.id,
                                  option_id: option.id,
                                  // rank_position:
                                  numerical_value: (
                                      formData as SelectionShape[]
                                  ).filter(
                                      (d: SelectionShape) =>
                                          d.option_id === option.id
                                  )[0].numerical_value,
                                  prompt_text: p.content,
                                  option_text: option.content,
                                  is_selected: (
                                      formData as SelectionShape[]
                                  ).filter(
                                      (d: SelectionShape) =>
                                          d.option_id === option.id
                                  )[0].is_selected,
                                  string_value: option.content,
                                  simulation_id: simulationId,
                              })
                      );
                break;
            case PromptType.Timeline:
                promptSelections = groupedSelections[p.id]
                    ? groupedSelections[p.id].map((selection) => {
                          const selectionShape = (
                              formData as SelectionShape[]
                          ).find(
                              (d: SelectionShape) =>
                                  d.option_id === selection.option_id
                          );

                          return {
                              ...selection,
                              numerical_value: selectionShape.numerical_value,
                              is_selected: selectionShape.is_selected,
                              drag_and_drop_prompt_container_id:
                                  selectionShape.drag_and_drop_prompt_container_id,
                          } as Selection;
                      })
                    : p.options.map((option) => {
                          const selectionShape = (
                              formData as SelectionShape[]
                          ).find(
                              (d: SelectionShape) => d.option_id === option.id
                          );

                          return new Selection({
                              prompt_id: p.id,
                              option_id: option.id,
                              // rank_position:
                              numerical_value: selectionShape.numerical_value,
                              prompt_text: p.content,
                              option_text: option.content,
                              is_selected: selectionShape.is_selected,
                              string_value: option.content,
                              simulation_id: simulationId,
                              drag_and_drop_prompt_container_id:
                                  selectionShape.drag_and_drop_prompt_container_id,
                          });
                      });
                break;
            case PromptType["Short Text"]:
            case PromptType["Long Text"]:
                let textSel = {
                    ...firstSelection,
                    string_value: formData,
                } as Selection;
                promptSelections = [textSel];
                break;
            default:
                break;
        }
        newSelections = [...newSelections, ...promptSelections];
    });
    return newSelections;
};

const genericRequiredMessage = "Field is required";

const getPromptValidationSchema = (prompt: Prompt): z.ZodTypeAny => {
    switch (prompt.prompt_type) {
        case PromptType["Multiple Choice"]:
            return prompt.is_required
                ? z.string({
                      required_error: genericRequiredMessage,
                      invalid_type_error: genericRequiredMessage,
                  })
                : z.string().optional();
        case PromptType["Dropdown List"]:
            return prompt.is_required
                ? z
                      .string({
                          required_error: genericRequiredMessage,
                          invalid_type_error: genericRequiredMessage,
                      })
                      .min(1, genericRequiredMessage)
                : z.string().optional();
        case PromptType["Short Text"]:
        case PromptType["Long Text"]:
            return prompt.is_required
                ? z.string().min(1, genericRequiredMessage)
                : z.string().optional();
        case PromptType["Multiple Select"]:
            return prompt.is_required
                ? z.string().array()
                : z.string().array().optional();
        case PromptType["Numerical Input"]:
            let isRange = typeof prompt.min === "number" && !!prompt.max;
            if (prompt.is_required) {
                return isRange
                    ? z
                          .string()
                          .transform((val) => Number(val))
                          .refine((val) => val >= prompt.min, {
                              message: `Number must be greater than or equal to ${prompt.min}`,
                          })
                          .refine((val) => val <= prompt.max, {
                              message: `Number must be less than or equal to ${prompt.max}`,
                          })
                    : z.string();
            } else {
                return isRange
                    ? z
                          .string()
                          .transform((val) => Number(val))
                          .refine((val) => val >= prompt.min, {
                              message: `Number must be greater than or equal to ${prompt.min}`,
                          })
                          .refine((val) => val <= prompt.max, {
                              message: `Number must be less than or equal to ${prompt.max}`,
                          })
                          .optional()
                    : z.string().optional();
            }
        case PromptType["Numerical Slider"]:
            return prompt.is_required
                ? z
                      .string()
                      .transform((val) => Number(val))
                      .refine((val) => val >= prompt.min, {
                          message: `Number must be greater than or equal to ${prompt.min}`,
                      })
                      .refine((val) => val <= prompt.max, {
                          message: `Number must be less than or equal to ${prompt.max}`,
                      })
                : z
                      .string()
                      .transform((val) => Number(val))
                      .refine((val) => val >= prompt.min, {
                          message: `Number must be greater than or equal to ${prompt.min}`,
                      })
                      .refine((val) => val <= prompt.max, {
                          message: `Number must be less than or equal to ${prompt.max}`,
                      })
                      .optional();
        case PromptType["Rank Order"]:
            return z.any().refine((selections: SelectionShape[]) => true, {
                message: `Testing...`,
            });
        case PromptType["Toggle Switch"]: // to do...
        case PromptType["Drag and Drop"]:
        case PromptType.Timeline:
        default:
            return z.any().optional();
    }
};

export const getFormValidationSchema = (prompts: Prompt[]) => {
    let schemaObject = prompts.reduce((validationSchema, prompt) => {
        return {
            ...validationSchema,
            ...{ [prompt.id]: getPromptValidationSchema(prompt) },
        };
    }, {});
    return z.object<any>(schemaObject).passthrough();
};
