import React, { FC, useState } from "react";
import styled from "styled-components";
import { ThemeObject } from "../../ThemeObjects";
import { StyledUniversalDiv } from "../Universal";
import { accordionContext } from "./accordionContext";

interface StyledAccordionsProps {
    theme: AccordionTheme;
    title?: string;
    isEditable?: boolean;
    children?: JSX.Element | JSX.Element[];
    setActiveItemIndices?: (indices: number[]) => void;
    activeAccordionKeys: string[];
    contentBlockId: string;
    isInDesignContext: boolean;
    isSelected: boolean;
}

export type AccordionVariant = "default" | "detached" | "ghost" | "small";
type AccordionTheme = Partial<ThemeObject> & {
    type: AccordionVariant;
};
export const ghostLikeVariants = ["ghost", "small"];
const Provider = accordionContext.Provider;

const AccordionWrapper = styled(StyledUniversalDiv)<{
    variant: AccordionVariant;
}>`
    border: ${({ variant }) =>
        !ghostLikeVariants.includes(variant) ? "1px solid #E5E7EB" : "none"};
    border-radius: ${({ variant }) =>
        !ghostLikeVariants.includes(variant) ? "16px" : "0"};
    > * {
        border-radius: ${({ variant }) =>
            !ghostLikeVariants.includes(variant) ? "16px" : "0"};
        border-bottom: 1px solid #e5e7eb;
        /* borderBottom={"1px solid #E5E7EB"}; */
        /* border-top: ${({ variant }) =>
            !ghostLikeVariants.includes(variant)
                ? "1px solid #E5E7EB"
                : "none"}; */
    }
`;

export const StyledAccordions: FC<StyledAccordionsProps> = ({
    theme,
    children,
    contentBlockId,
    isInDesignContext,
    isSelected,
    title,
}) => {
    const [activeAccordionKeys, setActiveAccordionKeys] = useState([]);

    const context = {
        activeAccordionKeys,
        hoverColor: theme.hoverColor || null,
        hoverBackgroundColor: theme.hoverBackgroundColor || null,
        setActiveItemIndices: (item) => {
            const newKeys = activeAccordionKeys.includes(item)
                ? activeAccordionKeys.filter(
                      (accordionKey) => accordionKey !== item
                  )
                : [...activeAccordionKeys, item];

            setActiveAccordionKeys(newKeys);
        },
        variant: theme.type,
    };

    return (
        <Provider value={context}>
            <AccordionWrapper
                className="accordion"
                data-content-block-id={contentBlockId}
                data-content-block-type={title || "Accordion"}
                width={"100%"}
                borderRadius="16px"
                overflow={isSelected ? "visible" : "hidden"}
                variant={theme.type || "default"}
                color={theme.color}
                backgroundColor={theme.backgroundColor}
            >
                {children}
            </AccordionWrapper>
        </Provider>
    );
};

export const defaultAccordionsTheme: AccordionTheme = {
    py: 2,
    px: 2,
    my: 0,
    mx: 0,
    color: "rgba(0,0,0,1)",
    backgroundColor: "rgba(255,255,255,0)",
    hoverColor: "rgba(0,0,0,1)",
    hoverBackgroundColor: "rgba(255,255,255,0)",
    type: "default",
};
