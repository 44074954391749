import { ColorScheme } from "@/models";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { ThemeObject } from "../../ThemeObjects";

type Hero2Theme = Partial<ThemeObject> & {
    imagePlacement: "left" | "right";
    textPlacement: "left" | "right" | "center";
};

interface StyledHeroImageProps {
    theme: Partial<Hero2Theme>;
    children?: ReactNode;
    imageUrl: string;
    palette: ColorScheme;
    contentBlockId: string;
    title?:string
}

const containerStyles = {
    // background: "orange",
    justifyContent: [
        "space-around",
        "space-around",
        "space-between",
        "space-between",
    ],
    minHeight: "100vh",
    padding: ["20px 5px 20px 5px", "20px 5px 20px 5px", 0, 0],
};

const Hero2StyledComponent = styled.div<{
    palette: ColorScheme;
    imagePlacement: "left" | "right";
    textPlacement: "left" | "right" | "center";
}>`
    background: ${({ palette }) => palette.backgroundColor};
    width: 100%;
    position: relative;
    > div {
        width: 100%;
        display: flex;
        align-items: center !important;
        ${variant({
            prop: "imagePlacement",
            variants: {
                left: {
                    flexDirection: [
                        "column",
                        "column",
                        "row-reverse",
                        "row-reverse",
                    ],
                    ...containerStyles,
                },
                right: {
                    flexDirection: ["column", "column", "row", "row"],
                    ...containerStyles,
                },
            },
        })};
    }
    * {
        text-align: ${({ textPlacement }) => textPlacement};
    }
`;

export const StyledHero2: FC<StyledHeroImageProps> = ({
    theme,
    children,
    imageUrl,
    palette,
    contentBlockId,
    title
}) => {
    return (
        <Hero2StyledComponent
            imagePlacement={theme.imagePlacement}
            textPlacement={theme.textPlacement}
            palette={palette}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Hero2"}
        >
            {children}
        </Hero2StyledComponent>
    );
};

export const defaultHero2Theme: Partial<ThemeObject> = {
    // color: "#000000",
    // backgroundColor: "#ffffff",
    // borderColor: "#000000",
    // borderWidth: 0,
    // borderRadius: 0,
    // borderStyle: "solid",
    // pt: 3,
    // pb: 3,
    // px: 0,
    // mt: 0,
    // mb: 0,
    // mx: 0,
    // width: ["100%"],
    // height: ["75vh"],
};
