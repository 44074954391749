import { defaultAreaChartTheme } from "@/styles/themes/charts/themes";
import React from "react";
import {
    VictoryLineProps,
    VictoryLine,
    VictoryAreaProps,
    VictoryArea,
    VictoryLabelStyleObject,
    VictoryStyleObject,
} from "victory";
import { FormattedChartValue } from "./SharedTypes";

function themeToAreaOrLineSeries(theme: typeof defaultAreaChartTheme): {
    data: VictoryStyleObject;
    labels: VictoryLabelStyleObject;
} {
    return {
        data: {
            stroke: theme?.stroke,
            strokeWidth: theme?.strokeWidth,
            fill: theme?.fill,
        },
        labels: {
            fill: theme?.stroke || "black",
            fontSize: 10,
            textAnchor: "left",
            marginBottom: 2,
        },
    };
}

//
type SapienChartSeriesProps = {
    seriesTheme?: typeof defaultAreaChartTheme;
} & (VictoryLineProps | VictoryAreaProps);

export function LineSeries(
    props: Omit<VictoryLineProps, "data"> & {
        data: FormattedChartValue[];
    }
) {
    const seriesTheme = themeToAreaOrLineSeries(
        props.data[0]?.theme || defaultAreaChartTheme
    );

    console.log("AREA PROPS", props)
    return <VictoryLine {...props} style={seriesTheme} name={props.data[0].label}/>;
}

export function AreaSeries(props: VictoryAreaProps) {
    return <VictoryArea {...props}  name={props.data[0].label}/>;
}
