import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastProps } from "@/components/toasts/StyledToast";

type NotificationSliceState = {
    notification: ToastProps | null;
};

const initialState: NotificationSliceState = {
    notification: null,
};

const notificationSlice = createSlice({
    name: "NotificationState",
    initialState,
    reducers: {
        setNotification(state, { payload }: PayloadAction<ToastProps | null>) {
            state.notification = payload;

        },
    },
});

export const notificationStoreReducer = notificationSlice.reducer;

export const { setNotification } = notificationSlice.actions;
