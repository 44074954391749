import React, { ReactNode,  useEffect } from "react";
import { ThemeObject } from "@/styles/ThemeObjects";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { SapienInertia, } from "@/inertia-utils/hooks";
import { ModalTheme } from "../ModalTheme";

type Props = ContentBlockEvents & {
    isOpen: boolean;
    theme: typeof defaultInboxMessageTheme;
    title?: string;
    children: ReactNode;
    contentBlockId: string;
    isRead: boolean;
    closeOverride?: (e: React.MouseEvent) => void;

};

export const InboxMessage = ({
    isOpen,
    theme,
    title,
    children,
    contentBlockId,
    onHover,
    onClick,
    isInEditorContext,
    isRead,
    closeOverride
}: Props) => {

    useEffect(() => {
        if (isOpen && !isRead) {
            SapienInertia.post(
                "read-notifications.store",
                {
                    content_block_id: contentBlockId,
                },
                {},
                {
                    only: ["unreadMessageIds"],
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        }
    }, [isRead, isOpen]);

    return (
        <ModalTheme
            isOpen={isOpen}
            theme={theme}
            contentBlockId={contentBlockId}
            isInEditorContext={isInEditorContext}
            onClick={onClick}
            onHover={onHover}
            closeOverride={closeOverride}
        >
            <div className="flex flex-col">
                <div className="flex items-center py-4 border-b space-x-4">
                    {!!theme?.fromAvatarUrl ? (
                        <img
                            className="inline-block h-10 w-10 rounded-full"
                            src={theme.fromAvatarUrl}
                            alt=""
                        />
                    ) : (
                        <svg
                            className="h-10 w-10 text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    )}
                    {theme?.from && (
                        <div>
                            From:{" "}
                            <span className="text-bold underline">
                                {theme?.from}
                            </span>
                        </div>
                    )}
                </div>
                <div className="text-lg text-bold flex py-4 border-b">
                    {title}
                </div>
                <div className="text-lg text-bold flex py-4 border-b">
                    {children}
                </div>
            </div>
        </ModalTheme>
    );
};

export const defaultInboxMessageTheme: Partial<ThemeObject> = {
    modalSize: "sm",
    backgroundColor: "rgba(255,255,255,1)",
    fromAvatarUrl: "",
    from: "",
};
