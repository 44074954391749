import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    FormContentBlockDefaultValuesObject,
    FormValidationSchema,
} from "@/styles";
import { Prompt, Selection, ContentBlock, SelectionShape } from "@/models";

type PromptState = {
    teamId: string;
    simulationId: string;
    prompts: {
        [promptId: string]: Prompt;
    };
    formContentBlocks: { [formContentBlockId: string]: ContentBlock };
    promptsByForm: {
        [formContentBlockId: string]: Prompt[];
    };
    formData: {
        [formContentBlockId: string]: FormContentBlockDefaultValuesObject;
    };
    selectionsByForm: {
        [formContentBlockId: string]: Selection[];
    };
    controlledFormFieldDataMap: {
        [formContentBlockId: string]: {
            [promptId: string]: SelectionShape[];
        };
    };
    formValidationSchemaMap: {
        [formContentBlockId: string]: FormValidationSchema;
    };
    isSubmitting: boolean;
    isInvalid: boolean;
};

const initialState: PromptState = {
    teamId: "",
    simulationId: "",
    prompts: {},
    formContentBlocks: {},
    promptsByForm: {},
    formData: {},
    selectionsByForm: {},
    controlledFormFieldDataMap: {},
    formValidationSchemaMap: {},
    isSubmitting: false,
    isInvalid: false,
};

const promptsSlice = createSlice({
    name: "SimulationThemeStore",
    initialState,
    reducers: {
        gotIdsForSavingSelections(
            state,
            { payload }: PayloadAction<{ teamId: string; simulationId: string }>
        ) {
            state.teamId = payload.teamId;
            state.simulationId = payload.simulationId;
        },
        gotPrompts(
            state,
            { payload }: PayloadAction<{ [index: string]: Prompt }>
        ) {
            state.prompts = payload;
        },
        gotFormContentBlocks(
            state,
            { payload }: PayloadAction<{ [index: string]: ContentBlock }>
        ) {
            state.formContentBlocks = payload;
        },
        gotPromptsByForm(
            state,
            { payload }: PayloadAction<{ [index: string]: Prompt[] }>
        ) {
            state.promptsByForm = payload;
        },
        gotFormData(
            state,
            {
                payload,
            }: PayloadAction<{
                [index: string]: FormContentBlockDefaultValuesObject;
            }>
        ) {
            state.formData = payload;
        },
        gotSelectionsByForm(
            state,
            { payload }: PayloadAction<{ [index: string]: Selection[] }>
        ) {
            state.selectionsByForm = payload;
        },
        gotControlledFormFieldDataMap(
            state,
            {
                payload,
            }: PayloadAction<{
                [formContentBlockId: string]: {
                    [promptId: string]: SelectionShape[];
                };
            }>
        ) {
            state.controlledFormFieldDataMap = payload;
        },
        gotFormValidationSchemaMap(
            state,
            {
                payload,
            }: PayloadAction<{
                [index: string]: FormValidationSchema;
            }>
        ) {
            state.formValidationSchemaMap = payload;
        },
        formSubmitting(state) {
            state.isSubmitting = true;
        },
        formDoneSubmitting(state) {
            state.isSubmitting = false;
        },
        setFormInvalid(state) {
            state.isInvalid = true;
        },
        setFormValid(state) {
            state.isInvalid = false;
        },
    },
});

export const promptSliceReducer = promptsSlice.reducer;

// export type RootState = ReturnType<typeof promptSliceReducer>;

const {
    gotIdsForSavingSelections,
    gotPrompts,
    gotFormContentBlocks,
    gotPromptsByForm,
    gotFormData,
    gotSelectionsByForm,
    gotControlledFormFieldDataMap,
    gotFormValidationSchemaMap,
    formSubmitting,
    formDoneSubmitting,
    setFormValid,
    setFormInvalid
} = promptsSlice.actions;

export {
    gotIdsForSavingSelections,
    gotPrompts,
    gotFormContentBlocks,
    gotPromptsByForm,
    gotFormData,
    gotSelectionsByForm,
    gotControlledFormFieldDataMap,
    gotFormValidationSchemaMap,
    formSubmitting,
    formDoneSubmitting,
    setFormValid,
    setFormInvalid
};
