import React, { ReactNode, useMemo } from "react";
import { ThemeObject } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";

type StyledBottomDrawerProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
    isOpen: boolean;
};

const StyledBottomDrawer: React.FC<StyledBottomDrawerProps> = ({
    isOpen,
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    isInEditorContext,
}: StyledBottomDrawerProps) => {
    const bottomClass = useMemo(() => {
        if (isInEditorContext) {
            return "relative";
        } else {
            return isOpen ? "fixed bottom-0" : "fixed -bottom-24";
        }
    }, [isOpen, isInEditorContext]);

    return (
        <StyledUniversalDiv
            {...theme}
            data-content-block-id={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
            className={`p-2 z-100 lg:py-1 flex-col space-y-1 lg:space-y-0 lg:flex-row flex items-center justify-center border border-gray-200 border-t border-shadow-2xl right-0 ease-in-out duration-700 lg:h-16 transition-all w-full ${bottomClass}`}
        >
            {children}
        </StyledUniversalDiv>
    );
};
export const defaultBottomDrawerTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffffff",
};
export { StyledBottomDrawer };
