import React from "react";
import { ContentBlockShape } from "@/models";
import { useSaveModal } from "./useSaveModal";

type Props = { contentBlock: ContentBlockShape; depth: number };

export default function AddModalToExistingBlockButton({
    contentBlock,
    depth,
}: Props) {
    const mutation = useSaveModal();

    return (
        <button
            className="add-modal-button add-content-block-button absolute inset-0 flex h-full w-full items-center justify-center bg-transparent text-2xl text-white"
            aria-label={`Add Modal Content Block to ${contentBlock.id}`}
            onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                mutation.mutateAsync(contentBlock);
            }}
            style={{ zIndex: depth * 10 }}
        >
            {" "}
        </button>
    );
}
