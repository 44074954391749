import { ContentBlockShape, ContentBlockType } from "@/models";
import { defaultTableTheme } from "@/styles";

function buildTable(): ContentBlockShape {
    return {
        content_block_type: ContentBlockType.Table,
        content: null,
        theme: defaultTableTheme,
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Table Head"],
                content: null,
                contentBlocks: [buildTableRow()],
            },
            {
                content_block_type: ContentBlockType["Table Body"],
                content: null,
                contentBlocks: [buildTableRow()],
            },
        ],
    };
}

export function buildTableRow(
    parentContentBlock?: ContentBlockShape
): ContentBlockShape {
    let row: ContentBlockShape = {
        content_block_type: ContentBlockType["Table Row"],
        // theme: defaultTableTheme,
    };

    if (parentContentBlock)
        row = {
            ...row,
            ...{
                parent_content_block_id: parentContentBlock.id,
                round_id: parentContentBlock.round_id,
                weight: parentContentBlock.contentBlocks.length,
            },
        };
    return row;
}

// export function buildTD() {}
// export function buildTH() {}

export const TableLayout: ContentBlockShape = buildTable();
