import { ContentBlockType } from "../../models";
import { ConfigComponentType, defaultSectionTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { LayoutType } from "../LayoutType";

export const SectionConfig: ContentBlockConfig = {
    typeKey: "Section",
    type: ContentBlockType.Section,
    addableChildBlocks: [
        ContentBlockType.Text,
        ContentBlockType.Form,
        ContentBlockType.Image,
        ContentBlockType.Video,
        ContentBlockType.Tabs,
        ContentBlockType.Box,
        ContentBlockType["Rich Text"],
        ContentBlockType.Button,
        ContentBlockType.Link,
        ContentBlockType["Chart Container"],
        ContentBlockType.Leaderboard,
        ContentBlockType["Model Value Display"],
        ContentBlockType["Reflection journal"],
        ContentBlockType["Decision log"],
        ContentBlockType.Carousel,
        ContentBlockType.Section
    ],
    addableLayouts: [
        LayoutType.Accordion,
        LayoutType.Card,
        LayoutType["Card Deck"],
        LayoutType.Hero,
        LayoutType["Hero 2"],
        LayoutType["Hero 3"],
        LayoutType.Table,
        LayoutType.Modal,
        LayoutType["Data Display Card"],
    ],
    defaultTheme: defaultSectionTheme,
    description: "A major component of a page. Generally full-width.",
    variantOptions: [
        {
            label: "Alignment",
            themeObjectKey: "alignment",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                left: "left",
                center: "center",
                right: "right",
            },
        },
        {
            label: "Stacking",
            themeObjectKey: "stacking",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                column: "column",
                row: "row",
            },
        },
        {
            label: "Full Bleed",
            themeObjectKey: "fullBleed",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
        {
            label: "Backgound Position",
            themeObjectKey: "backgroundPosition",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                center: "center",
                top: "top",
                bottom: "bottom",
                left: "left",
                right: "right",
            },
        },
    ],
    availableBehaviors:[
        "DisplayIfRoundIsComplete",
        "DisplayIfParticipantHasSubmittedOnCurrentPage",
        "DisplayIfTeammateHasSubmittedOnCurrentPage",
        "DisplayIfRoundHasSubmissionFromTeam",
    ]
};
