export function addSearchParamsToUrl(
    href: string,
    searchParamsToAdd: { [index: string]: string }
) {
    const oldUrl = new URL(href);
    const searchParams = new URLSearchParams(oldUrl.search);
    Object.keys(searchParamsToAdd).forEach((key) => {
        searchParams.set(key, searchParamsToAdd[key]);
    });

    const url = new URL(href);
    url.search = searchParams.toString();

    return url;
}

export function removeSearchParamsFromUrl(
    href: string,
    searchParams: string[]
) {
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    searchParams.forEach((param) => {
        params.delete(param);
    });
    url.search = params.toString();

    return url;
}

export function queryStringToObject(urlString: string) {
    const params = new URLSearchParams(urlString);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
        // Convert numeric strings to numbers if needed
        queryParams[key] = !isNaN(Number(value)) ? Number(value) : value;
    }

    return queryParams;
}
