import React, { ReactNode, useContext, Fragment } from "react";
import { accordionContext } from "./accordionContext";

import { ThemeObject } from "../../ThemeObjects";
import { Transition } from "@headlessui/react";
import { StyledUniversalDiv } from "../Universal";

export function StyledAccordionPanel({
    children,
    theme,
    contentBlockId,
    parentContentBlockId,
    title,
}: {
    children: ReactNode;
    theme: Partial<ThemeObject>;
    contentBlockId: string;
    parentContentBlockId: string;
    title?: string;
}) {
    const accordionContextInternal = useContext(accordionContext);

    const isExpanded =
        accordionContextInternal?.activeAccordionKeys?.indexOf(
            parentContentBlockId
        ) !== -1;

    return (
        <Transition.Root show={isExpanded} as={Fragment}>
            <Transition.Child
                as={Fragment}
                enter="transition transition-[max-height] duration-200 ease-in"
                enterFrom="transformmax-h-[0px] opacity-0"
                enterTo="transform max-h-[9999px] opacity-100"
                leave="transition transition-[max-height] duration-400 ease-out"
                leaveFrom="transform max-h-[9999px] opacity-100"
                leaveTo="transform max-h-[0px] opacity-0"
            >
                <StyledUniversalDiv
                    pb={4}
                    px={theme.px as number}
                    py={theme.py as number}
                    bg={theme.backgroundColor || "rgba(0,0,0,0)"}
                    data-content-block-id={contentBlockId}
                    data-content-block-type={title || "Accordion Content"}
                    className="accordion-panel-wrapper relative z-[1000]"
                >
                    {children}
                </StyledUniversalDiv>
            </Transition.Child>
        </Transition.Root>
    );
}

export const defaultAccordionContentTheme: Partial<ThemeObject> = {
    py: 0,
    px: 0,
    my: 0,
    mx: 0,
    backgroundColor: "rgba(0,0,0,0)",
};
