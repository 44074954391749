import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { CheckIcon } from "lucide-react";

import React from "react";

type Props = {
    items: { label: string; value: string }[];
    selectedItem: string;
    label?: string | React.ReactNode;
    callback: (item: string) => void;
    width?: number;
};

export default function DropDownSelector({
    items,
    selectedItem,
    label,
    callback,
    width,
}: Props) {
    const selectedLabel =
        items.find((item) => item.value === selectedItem)?.label ||
        selectedItem;

    return (
        <Popover>
            <PopoverTrigger>
                <div
                    role="combobox"
                    aria-controls="radix-:r6f:"
                    aria-expanded="false"
                    aria-autocomplete="none"
                    dir="ltr"
                    data-state="closed"
                    className="border-px ring-offset-background placeholder:text-muted-foreground
                        focus:ring-ring flex h-7 w-28 items-center justify-between truncate
                        whitespace-nowrap rounded-md border border-slate-500 bg-transparent px-3 py-2
                        text-xs shadow-sm focus:outline-none focus:ring-1 disabled:cursor-not-allowed
                        disabled:opacity-50"
                    style={{ width }}
                >
                    {!!label && (
                        <span className="text-muted-foreground">{label}</span>
                    )}
                    <span className="pointer-events-none max-w-20 truncate">
                        {selectedLabel}
                    </span>
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 opacity-50"
                        aria-hidden="true"
                    >
                        <path
                            d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </div>
            </PopoverTrigger>
            <PopoverContent
                className="z-[9999] max-h-[10rem] overflow-y-scroll bg-white p-0 scrollbar
                    scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2"
                align="start"
            >
                <div>
                    <ul className="w-full list-none p-0">
                        {items.map((item) => (
                            <li
                                className="w-full list-none px-2"
                                key={item.value}
                            >
                                <div
                                    role="button"
                                    aria-selected={selectedItem === item.value}
                                    onClick={() => callback(item.value)}
                                    className="relative flex w-full select-none items-center justify-between rounded-sm px-2
                                        py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none
                                        data-[disabled=true]:opacity-50 hover:bg-gray-200 hover:text-black"
                                >
                                    {item.label}
                                    {selectedItem === item.value && (
                                        <CheckIcon className="text-accent absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </PopoverContent>
        </Popover>
    );
}
