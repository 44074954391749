import React, { FC, ReactNode } from "react";
import { StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { TextDisplayOrEdit, TextDisplay } from "@/modules/text-editor";
import { RichText } from "@/models";
import { ThemeObject } from "../ThemeObjects";
import { PencilIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";

type StyledRichTextProps = ContentBlockEvents & {
    children?: ReactNode;
    contentBlockId?: string;
    richText: RichText | string;
    theme: any;
};

const EditorPortal = styled.div<ThemeObject>`
    > div {
        width: auto !important;
        margin: 0 !important;
    }
    &:has(#text-editor) + div {
        display: none;
        /* visibility: hidden; */
    }
    z-index: 5000;
`;

export const StyledRichText: FC<StyledRichTextProps> = ({
    contentBlockId,
    onClick,
    onHover,
    richText,
    theme,
    isInEditorContext,
    isSelected,
}) => {
    if (!richText) {
        if (isInEditorContext) {
            return (
                <StyledUniversalDiv
                    data-content-block-id={contentBlockId}
                    {...theme}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    className="group/rt rich-text relative min-w-[10rem] cursor-pointer rounded-md border border-dashed border-gray-300 px-8 py-4"
                    data-content-block-type="Text"
                >
                    <EditorPortal
                        data-editor-portal-id={contentBlockId}
                    ></EditorPortal>
                    {!isSelected && (
                        <>
                            <div className="pointer-events-none absolute inset-0 z-99 flex cursor-pointer items-center justify-center rounded-md border border-dashed border-gray-300 bg-blue-600/5 opacity-0 shadow-md transition-all group-hover/rt:opacity-100">
                                <PencilIcon className="h-6 w-6 text-blue-800" />
                            </div>
                            <p className="bg-white-50 pointer-events-none cursor-pointer text-center text-gray-500">
                                Click to edit
                            </p>
                        </>
                    )}
                </StyledUniversalDiv>
            );
        }
        return <></>;
    }
    if (typeof richText === "string")
        richText = JSON.parse(richText) as RichText;

    if (isInEditorContext)
        return (
            <StyledUniversalDiv
                onClick={onClick}
                // onMouseOver={onHover}
                data-content-block-id={contentBlockId}
                {...theme}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                className="group/rt rich-text relative cursor-pointer"
                data-content-block-type="Text"
            >
                <EditorPortal
                    data-editor-portal-id={contentBlockId}
                ></EditorPortal>
                {!isSelected && (
                    <>
                        <div className="group/rt:opacity-100 pointer-events-none absolute inset-0 z-99 flex items-center justify-center rounded-md border border-dashed border-gray-300 bg-blue-600/5 opacity-0 shadow-md transition-all">
                            <PencilIcon className="h-6 w-6 text-blue-800" />
                        </div>
                        <TextDisplay
                            richText={richText}
                            theme={theme}
                            editedId={null}
                        />
                    </>
                )}
            </StyledUniversalDiv>
        );

    return (
        <StyledUniversalDiv
            data-content-block-id={contentBlockId}
            {...theme}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            className="rich-text group relative cursor-pointer"
            data-content-block-type="Text"
        >
            <TextDisplayOrEdit
                richText={richText}
                theme={theme}
                editedId={null}
            />
        </StyledUniversalDiv>
    );
};

export const defaultRichTextTheme: Partial<ThemeObject> = {
    backgroundColor: "#ffffff00",
    pt: 0,
    pb: 0,
    pl: 0,
    py: 0,
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
};
