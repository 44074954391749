import { ContentBlockType } from "@/models";
import { defaultAccordionsTheme } from "@/styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { AccordionsConfig } from "./AccordionsConfig";

export const DecisionLogConfig: ContentBlockConfig = {
    typeKey: "Decision log",
    type: ContentBlockType["Decision log"],
    defaultTheme: { ...defaultAccordionsTheme },
    variantOptions: AccordionsConfig.variantOptions,
};
