import { defaultCarouselTheme } from "@/styles/themes/carousel";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const CarouselConfig: ContentBlockConfig = {
    typeKey: "Carousel",
    type: ContentBlockType.Carousel,
    requiredChildBlocks: [],
    addableChildBlocks: [
        ContentBlockType.Section,
        ContentBlockType.Card,
        ContentBlockType.Image,
        ContentBlockType.Video,
        ContentBlockType.Text,
        ContentBlockType.Hero,
        ContentBlockType.Hero2,
        ContentBlockType.Hero3,
        ContentBlockType.Carousel,
    ],
    defaultTheme: defaultCarouselTheme,
    isCollectionType: true,
    // onClickAdd: (parent) => {},
};
