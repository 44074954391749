import { ContentBlockShape, PromptShape, PromptType, RichText } from "@/models";
import React, { useMemo, useState } from "react";
import LessonRichText from "../LessonRichText";
import { PlusIcon } from "lucide-react";
import { useSaveOption } from "../../LessonDesign/content-block-management/useSaveOption";
import { SliderMinAndMax } from "../../LessonDesign/QuestionForms";
import { Submission, useSelections } from "../../shared-state/useSelections";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
};

type OptionProps = Props & {
    selections: Submission[];
};

function MultipleChoice({ contentBlock, editable, selections }: OptionProps) {
    return (
        <div className="flex flex-col space-y-2">
            {contentBlock.prompt.options
                .sort((a, b) => a.weight - b.weight)
                .map((option, index) => (
                    <div className="flex items-center gap-x-3" key={option.id}>
                        <input
                            id={option.id}
                            name={contentBlock.prompt.id}
                            value={option.id}
                            defaultChecked={
                                !!selections?.find(
                                    (selection) =>
                                        selection.option_id === option.id &&
                                        selection.is_selected,
                                )
                            }
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                            htmlFor={editable ? "" : option.id}
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            <LessonRichText
                                editable={editable}
                                content={option.contentBlock.rich_text}
                                contentBlock={option.contentBlock}
                            />
                        </label>
                    </div>
                ))}{" "}
            {editable && <AddOption prompt={contentBlock.prompt} />}
        </div>
    );
}

function MultipleSelect({ contentBlock, editable, selections }: OptionProps) {
    return (
        <div className="flex flex-col space-y-2">
            {contentBlock.prompt.options
                .sort((a, b) => a.weight - b.weight)
                .map((option) => (
                    <div className="flex items-center gap-x-3" key={option.id}>
                        <input
                            defaultChecked={
                                !!selections?.find(
                                    (selection) =>
                                        selection.option_id === option.id &&
                                        selection.is_selected,
                                )
                            }
                            id={option.id}
                            name={option.id}
                            type="checkbox"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                            htmlFor={editable ? "" : option.id}
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            <LessonRichText
                                editable={editable}
                                content={option.contentBlock.rich_text}
                                contentBlock={option.contentBlock}
                            />
                        </label>
                    </div>
                ))}
            {editable && <AddOption prompt={contentBlock.prompt} />}
        </div>
    );
}
function Text({ contentBlock, editable, selections }: OptionProps) {
    <div className="flex flex-col space-y-2"></div>;
}

function Slider({ contentBlock, editable, selections }: OptionProps) {
    const min = contentBlock?.prompt?.min || 0;
    const max = contentBlock?.prompt?.max || 100;
    const option = contentBlock.prompt.options[0];

    const selection = selections.find((s) => s.option_id === option?.id);

    const [value, setValue] = useState(
        selection?.numerical_value || Math.floor((max - min) / 2),
    );
    const leftPercent = ((value - min) / (max - min)) * 100

    return (
        <>
            <SliderMinAndMax contentBlock={contentBlock} />
            <div className="flex flex-col space-y-2 py-6">
                <div
                    className="relative flex items-center justify-center gap-x-3"
                    key={option.id}
                >
                    {editable && (
                        <div
                            data-min-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                    <div className="max-w-2x relative w-full pt-8 text-sapien-blue">
                        <div
                            className="absolute -top-[2px] flex h-8 min-w-8 items-center justify-center rounded-full
                                rounded-bl-none border-2 border-sapien-blue bg-white px-2 shadow-lg
                                translate-x-[5px]"
                            style={{
                                left: `calc(${leftPercent}% + (${10 - leftPercent * 0.2}px))`,
                            }}
                        >
                            {value}
                        </div>
                        <input
                            onChange={(e) => setValue(parseInt(e.target.value))}
                            defaultValue={value}
                            id={option.id}
                            name={option.id}
                            max={max}
                            min={min}
                            type="range"
                            className="h-4 w-full border-gray-300 text-sapien-blue focus:ring-indigo-600 "
                        />
                    </div>
                    {editable && (
                        <div
                            data-max-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                </div>
            </div>
        </>
    );
}

function OptionDisplay(props: OptionProps) {
    switch (props.contentBlock.prompt.prompt_type) {
        case PromptType["Multiple Choice"]:
            return <MultipleChoice {...props} />;
        case PromptType["Multiple Select"]:
            return <MultipleSelect {...props} />;
        case PromptType["Numerical Slider"]:
            return <Slider {...props} />;
        default:
            return null;
    }
}

function AddOption({ prompt }: { prompt: PromptShape }) {
    const { mutate } = useSaveOption();

    return (
        <button
            className="flex items-center justify-center space-x-2 self-start rounded-md border
                border-sapien-blue px-4 py-2"
            onClick={() => {
                mutate(prompt);
            }}
        >
            <span>Add Option</span>
            <PlusIcon />
        </button>
    );
}

export default function LessonQuestion(props: Props) {
    if (!props?.contentBlock?.prompt) return null;

    const selections = useSelections(props.contentBlock.prompt.id);

    return (
        <div className="flex flex-col space-y-4">
            <LessonRichText {...props} />
            <OptionDisplay {...props} selections={selections} />
        </div>
    );
}
