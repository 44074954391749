import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";

export const EditorFormGroup = styled.div`
    margin: 0 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const EditorFormLabel = styled.label`
    display: inline-block;
    /* margin: 0 1rem 0.5rem 0; */
    margin: 0;
    font-size: 0.875rem;
    padding-right: 6px;
`;

export const EditorFormInputDiv = styled.div`
    margin: 0 0 0.25rem;
`;

export const EditorFormInputRadioCheckbox = styled.input`
    margin: 0 0.75rem 0 0.25rem;
`;

export const EditorFormInputRange = styled.input`
    margin: 0;
    width: 100%;
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background: purple;
        height: 10px;
        width: 20px;
        border-radius: 1em;
    }
`;

export const EditorFormToggleLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 52px;
    height: 26px;
    border-radius: 15px;
    background: #bebebe;
    /* cursor: pointer; */
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin: 3.5px;
        background: #ffffff;
        /* box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2); */
        transition: 0.2s;
    }
`;

export const EditorFormInputToggle = styled.input`
    margin: 0;
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 52px;
    height: 26px;
    &:checked + ${EditorFormToggleLabel} {
        background: #0374fe;
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 30px;
            transition: 0.2s;
        }
    }
`;

export const EditorFormToggleDiv = styled.div`
    position: relative;
`;

export const EditorFormToggleLeftLabel = styled.label`
    display: inline-block;
    font-size: 0.875rem;
    margin-right: 6px;
`;

export const EditorFormToggleRightLabel = styled.label`
    display: inline-block;
    font-size: 0.875rem;
    margin-left: 6px;
`;

const EditorFormSelect = styled.select`
    width: 100%;
    /* padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5; */
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1rem;
    box-sizing: border-box;
    /* border: 1px solid #ced4da; */
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    color: rgba(255, 255, 255, 0.75);
    background-color: rgba(255, 255, 255, 0.15);
    :focus {
        outline: none;
        box-shadow: none;
        border-color: rgba(255, 255, 255, 0.4);
        color: rgba(0, 0, 0, 0.75);
        background-color: rgba(255, 255, 255, 0.75);
    }
`;

export const EditorFormSelectSmall = styled(EditorFormSelect)<LayoutProps>`
    width: ${({width}) => width || "auto"};
    max-width: 250px;
    ${layout}
`;

export const EditorFormOption = styled.option`
    line-height: 1.5;
`;
