import { User, UserShape } from "@/models";
import { atom, useAtom } from "jotai";
import Pusher, { Channel } from "pusher-js";
interface SocketListenerCallback<T> {
    (param: T | undefined | any): void;
}

// Pusher.logToConsole = true;

//SETUP SOCKET INSTANCE HERE SO IT BEHAVES AS SINGLETON
export const socket = new Pusher("cce8c26c1aadf87a901c", {
    cluster: "us2",
});

type SocketBinding = {
    [index: string]: SocketListenerCallback<any>;
};

const boundFunctionAtom = atom<{[index:string]:string}>({})

export function useWebSockets() {
    const [boundFunctions, setBoundFunctions] = useAtom(boundFunctionAtom);

    function setupTeamSockets(
        team_id: string,
        bindingMap: Partial<SocketBinding>,
    ) {
        const channel = socket.subscribe(`team.${team_id}`);
        bindListenersToChannel(channel, bindingMap);
        return socket;
    }

    function setupPlayerSockets(
        user: User | UserShape,
        bindingMap: Partial<SocketBinding>,
    ) {
        const channel = socket.subscribe(`participant.${user.id}`);
        bindListenersToChannel(channel, bindingMap);
        return socket;
    }

    function setUpFacilitatorSockets(
        cohortId: string,
        bindingMap: Partial<SocketBinding>,
    ) {
        const channel = socket.subscribe(`cohort.${cohortId}`);
        bindListenersToChannel(channel, bindingMap);

        return socket;
    }

    function setupAdminSockets(
        user: UserShape,
        bindingMap: Partial<SocketBinding>,
    ) {
        const channel =
            socket.channel(`admin.${user.id}`) ||
            socket.subscribe(`admin.${user.id}`);

        bindListenersToChannel(channel, bindingMap);
    }

    function bindListenersToChannel(
        channel: Channel,
        bindingMap: Partial<SocketBinding>,
    ) {
        Object.keys(bindingMap)
            .filter((key) => !boundFunctions[key])
            .forEach((key) => {
                setBoundFunctions((prev) => ({...prev, [key]: key}))
                channel.bind(key, bindingMap[key]);
            });
    }

    // const boundFunctions = Object.keys(bindingMap).map((key) => {
    //     if (
    //         "channelName" in (bindingMap as any)[key as keyof typeof bindingMap]
    //     ) {
    //         console.log("subscribing to " + key);

    //         if (
    //             !socket.channel(
    //                 (bindingMap as any)[key as keyof typeof bindingMap]
    //                     .channelName
    //             )
    //         ) {
    //             socket.subscribe(
    //                 (bindingMap as any)[key as keyof typeof bindingMap]
    //                     .channelName
    //             );
    //         }

    //         const callback = (bindingMap as any)[key as keyof typeof bindingMap]
    //             .callback;
    //         const channel = socket.channel(
    //             (bindingMap as any)[key as keyof typeof bindingMap].channelName
    //         );

    //         return channel.bind(key, callback);
    //     } else {
    //         console.log("subscribing to " + key);

    //         return socket.bind(
    //             key as string,
    //             bindingMap[
    //                 key as keyof typeof bindingMap
    //             ] as SocketListenerCallback<any>
    //         );
    //     }
    // });

    return {
        boundFunctions,
        setupPlayerSockets,
        setupTeamSockets,
        setupAdminSockets,
        setUpFacilitatorSockets,
    };
}
