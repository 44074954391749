import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "../../../../../css/app.css";
import "../../lesson-design.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const rootElement = document.querySelector("#root") as Element;
if (!!rootElement && !rootElement?.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </React.StrictMode>,
    );
}
