import React from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormOption,
    EditorFormSelectSmall,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";

export const FormSelectEnumType = (props: {
    placeHolder?: string;
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    optionObject: { [index: string]: string | number };
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    displayProperty?: (property: string | number) => string;
    forceButtonStyle?: boolean;
    width?: string;
    inline?: boolean;
}) => {
    const {
        value,
        label,
        themeObjectKey,
        optionObject,
        handleChange,
        displayProperty,
        placeHolder,
        forceButtonStyle,
        width,
        inline,
    } = props;

    return (
        <EditorFormGroup style={!!inline ? { margin: 0 } : {}}>
            {!forceButtonStyle && Object.keys(optionObject)?.length > 6 ? (
                <>
                    <EditorFormLabel>{label}</EditorFormLabel>
                    <EditorFormSelectSmall
                        width={width}
                        maxWidth={width}
                        value={value}
                        onChange={(e) =>
                            handleChange(themeObjectKey, e.target.value)
                        }
                        style={
                            !!inline
                                ? { padding: "0.25rem 2rem 0.25rem 0.5rem", fontSize: "12px" }
                                : {}
                        }
                    >
                        {placeHolder && <option value="">{placeHolder}</option>}
                        {optionObject !== undefined &&
                            Object.keys(optionObject).map((key: string) => (
                                <EditorFormOption
                                    key={key}
                                    value={optionObject[key]}
                                    className="bg-[#29303e]"
                                >
                                    {displayProperty !== undefined
                                        ? displayProperty(key)
                                        : key}
                                </EditorFormOption>
                            ))}
                    </EditorFormSelectSmall>
                </>
            ) : (
                <div className="flex w-full flex-col space-y-2">
                    {!!label && <EditorFormLabel>{label}</EditorFormLabel>}
                    <div
                        className={`flex w-full flex-row gap-1 ${!inline && "flex-wrap"}`}
                    >
                        {Object.keys(optionObject).map((key: string) => (
                            <div
                                className={`${!inline && "w-[24%]"}`}
                                key={key}
                            >
                                <button
                                    className={`w-full rounded-sm px-2 ${!inline ? "py-2 text-sm" : "whitespace-nowrap py-1.5 text-xs"} capitalize text-white hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none focus:ring-opacity-50 ${
                                        value === optionObject[key]
                                            ? "border-gray-300/50 bg-[#3071F2]"
                                            : "border-gray-300/30 bg-white/10"
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChange(
                                            themeObjectKey,
                                            optionObject[key],
                                        );
                                    }}
                                    data-testid={`${themeObjectKey}-${key}`}
                                >
                                    {displayProperty !== undefined
                                        ? displayProperty(key)
                                        : key}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </EditorFormGroup>
    );
};
