import { BubbleMenu, Editor } from "@tiptap/react";
import React from "react";
import Menu from "./Menu";

type Props = {
    editor: Editor;
    showColorPicker?: boolean;
};

export function RichTextTooltipMenu({
    editor,
    showColorPicker,
}: Props) {
    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{
                duration: 100,
                placement: "bottom",
                maxWidth: 412,
            }}
            className="relative flex items-center justify-center bg-white"
        >
            <div
                className="absolute -top-2 h-0 w-0 border-x-8 border-b-[8px] border-x-transparent
                    border-b-black"
            ></div>
            <div className="absolute top-0 w-full border-b-4 border-black"></div>
            <Menu editor={editor} showColorPicker={showColorPicker} />
        </BubbleMenu>
    );

}
