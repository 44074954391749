import React from "react";
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/Accordion";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
type Props = {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
    children: React.ReactNode | React.ReactNode[];
};

export default function LessonAccordionItem({ contentBlock, children }: Props) {
    return (
        <AccordionItem
            value={contentBlock.id}
            data-content-block-id={contentBlock.id}
            className={`px-2 ${cn(
                contentBlock.theme?.tailwindClasses.filter(
                    (className) =>
                        className.includes("p-") ||
                        className.includes("px-") ||
                        className.includes("py-"),
                ),
            )}`}
        >
            <AccordionTrigger className="w-full">
                {contentBlock.content}
            </AccordionTrigger>
            <AccordionContent
                className={`pb-2 ${cn(contentBlock.theme?.tailwindClasses)}`}
                style={{ borderColor: contentBlock.theme?.backgroundColor }}
            >
                {children}
            </AccordionContent>
        </AccordionItem>
    );
}
