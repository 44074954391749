import { defaultChartContainerTheme } from "@/styles";
import {
    defaultAreaChartTheme,
    defaultLineSeriesTheme,
} from "@/styles/themes/charts/themes";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";

export const ChartConfig: ContentBlockConfig = {
    typeKey: "Chart Container",
    type: ContentBlockType["Chart Container"],
    defaultTheme: defaultChartContainerTheme,
    addableChildBlocks: [
        ContentBlockType["Line Series"],
        ContentBlockType["Area Series"],
    ],
    isCollectionType: true,
};

export const LineChartConfig: ContentBlockConfig = {
    typeKey: "Line Series",
    type: ContentBlockType["Line Series"],
    defaultTheme: defaultLineSeriesTheme,
};

export const AreaChartConfgi: ContentBlockConfig = {
    typeKey: "Area Series",
    type: ContentBlockType["Area Series"],
    defaultTheme: defaultAreaChartTheme,
};
