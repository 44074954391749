import React, { FC, ReactNode } from "react";
import {
    ThemeObject,
} from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import * as icons from "@heroicons/react/24/outline";

type StyledIconProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
};

// const keys = Object.keys(icons);

// function renderIcon(icon: keyof typeof keys) {
//     return icons[icon];
// }

const iconMap = Object.keys(icons).reduce((carry, key) => {
    return { ...carry, [key.toLowerCase()]: icons[key] };
}, {});

export const StyledIcon: FC<StyledIconProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
}) => {
    const Component = iconMap[theme.icon.toLowerCase()] as any;
    return (
        Component && (
            <>
                <Component
                    className={`h-${theme.size} w-${theme.size}`}
                    onClick={
                        "function" === typeof onClick ? onClick : (evt) => {}
                    }
                    onMouseMove={
                        "function" === typeof onHover
                            ? (event: React.MouseEvent) => {
                                  onHover(event);
                              }
                            : (_) => {}
                    }
                />
            </>
        )
    );
};

export const defaultIconTheme: Partial<ThemeObject> = {
    icon: "ChevronDoubleRightIcon",
    size: 6,
};
