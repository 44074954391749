import { createContext } from "react";

export const inboxContext = createContext<{
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    openedMessageId: string;
    setOpenedMessageId?: (messageId: string) => void;
}>({
    isOpen: false,
    openedMessageId: "",
});

