import { sapienAxios } from "@/inertia-utils/hooks";
import {
    ContentBlockShape,
    ContentBlockType,
    PromptShape,
    PromptType,
    SelectionShape,
} from "@/models";
import { useMutation } from "@tanstack/react-query";
import { atom, useAtom } from "jotai";
import { FormEvent, useEffect } from "react";
import { useScorm } from "../Student/scorm-export/useScormApi";

export type Submission = Omit<
    SelectionShape,
    "simulation_id" | "team_id" | "user_id"
> & {
    prompt?: PromptShape;
};

const selectionAtom = atom<{ [index: string]: Submission[] }>({});

function mapSelections(selections: Submission[]) {
    return selections.reduce((carry, selection) => {
        if (!carry[selection.prompt_id]) {
            carry = { ...carry, [selection.prompt_id]: [] };
        }

        return {
            ...carry,
            [selection.prompt_id]: [...carry[selection.prompt_id], selection],
        };
    }, {});
}

export function useSetupSelections(selections: Submission[]) {
    const [_, setSelections] = useAtom(selectionAtom);
    useEffect(() => {
        const mapped = mapSelections(selections);
        setSelections(mapped);
    }, [selections?.length]);
}

export function useSelections(promptId: string) {
    const [selections] = useAtom(selectionAtom);
    return selections[promptId] || [];
}

export function selectionsFromEvent(
    e: FormEvent,
    contentBlock: ContentBlockShape,
) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const formEntries = Object.fromEntries(formData.entries()) as {
        [index: string]: string | number;
    };

    const prompt = contentBlock?.contentBlocks.find(
        (block) => block.content_block_type === ContentBlockType.Question,
    )?.prompt;

    const selections: Submission[] = prompt.options.map((option) => {
        let value: string | number = formEntries[option.id];

        if (prompt.prompt_type === PromptType["Multiple Choice"]) {
            value = Object.values(formEntries).find((v) => v === option.id);
        }

        return {
            prompt_id: prompt?.id,
            option_id: option.id,
            numerical_value: Number(value) || 0,
            string_value:
                typeof value === "string"
                    ? value
                    : value?.toString() || option.content,
            is_selected: !!value,
            prompt_text: prompt.content,
            option_text: option.content,
        };
    });

    return selections;
}

const scormQuestionTypes = {
    [PromptType["Multiple Choice"]]: "choice", // Multiple Choice
    [PromptType["Short Text"]]: "fill-in", // Fill in the Blank
    [PromptType["Long Text"]]: "long-fill-in", // Long Fill in the Blank (extended text)
    // "matching",     // Matching
    // "performance",  // Performance Task
    // "likert",       // Likert Scale (opinion scale)
    [PromptType["Numerical Input"]]: "numeric", // Numeric Response
    [PromptType["Numerical Slider"]]: "numeric", // Numeric Response
    [PromptType["Rank Order"]]: "sequencing", // Sequencing
};

export function useSubmitSelections(isInAdminContext: boolean) {
    const [_, setSelections] = useAtom(selectionAtom);
    const { scorm } = useScorm();

    return useMutation({
        mutationFn: async (selections: Submission[]) => {
            if (isInAdminContext) {
                return Promise.resolve({ selections });
            }
            const endpoint = !!scorm?.isAvailable()
                ? "e-learning.participant.api.submit"
                : "e-learning.participant.submit";
            const { data } = await sapienAxios.post<
                { selections: Submission[] },
                "e-learning.participant.submit"
            >(endpoint as "e-learning.participant.submit", { selections });

            return data;
        },
        onSuccess: ({ selections }) => {
            const selection = selections[0];
            scorm?.submit({
                questionId: selection.prompt_id,
                questionType: scormQuestionTypes[selection.prompt?.prompt_type] || "choice",
                learnerResponse: selections
                    .map((selection) => selection.option_text)
                    .join(", "),
            });

            setSelections(mapSelections(selections));
        },
    });
}
