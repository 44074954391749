import React, { useMemo, useState } from "react";
import {
    InterpolationPropType,
    VictoryAxis,
    VictoryChart,
    VictoryLine,
    VictoryScatter,
    VictoryVoronoiContainer,
} from "victory";
import {
    StyledUniversalDiv,
    ThemeObject,
    defaultChartContainerTheme,
} from "@/styles";
import { SharedChartProps } from "./SharedTypes";
import ResponsiveToolTip from "./ResponsiveToolTip";
import { abbrNum, getDomain, themeToAreaOrLineSeries } from "./chart-util";
import { AreaSeries } from "./LineSeries";
import { defaultAreaChartTheme } from "@/styles/themes/charts/themes";
import Legend from "./Legend";

function AxisLabel({ x, y, text }: { text?: string; x?: number; y?: number }) {
    return (
        <g fontSize={10}>
            <foreignObject
                x={x - 25}
                y={y - 15}
                width={100}
                height={20}
                style={{ pointerEvents: "none" }}
            >
                <div className="flex flex-wrap text-xs">{text}</div>
            </foreignObject>
        </g>
    );
}
export type AreaOrLineSeriesTheme = {
    interpolation?: InterpolationPropType;
    stroke?: string;
    strokeWidth?: number;
    // labelProps can be used to styled the VictoryLabel
    labelProps?: {
        x: number;
        y: number;
    };
} & Partial<ThemeObject>;

export type ChartAxisTheme = {
    tickValues?: number[];
    dependentAxis?: boolean;
    tickPrefix?: string;
    tickSuffix?: string;
} & Partial<ThemeObject>;

type LineOrAreaChartProps = SharedChartProps & {
    onClick?: (clickedId: string) => void;
    theme: typeof defaultChartContainerTheme;
    hideLegend?: boolean;
};

export function LineOrAreaChart({
    theme,
    formattedChartValues,
    hideLegend,
}: LineOrAreaChartProps) {
    const yDomain = useMemo(() => {
        let values = getDomain(formattedChartValues, "y");
        if (
            theme.hasOwnProperty("minimumValue") &&
            theme.minimumValue !== null
        ) {
            values[0] = theme.minimumValue;
        }
        if (theme.maximumValue) {
            values[1] = theme.maximumValue;
        }
        return values;
    }, [formattedChartValues, theme]);

    const height = useMemo(() => {
        if (theme?.height) {
            return Number(theme?.height);
        }
        if (yDomain?.length === 2) {
            const minHeight = Math.min(
                Math.abs(yDomain[1] - yDomain[0]) * 6,
                300,
            );
            return Math.max(minHeight, 200);
        }
        return 300;
    }, [yDomain, theme]);

    const [filteredSeriesIndices, setFilteredSeriesIndices] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const filteredChartValues = useMemo(() => {
        return Object.keys(formattedChartValues)
            .filter((_, i) => {
                return !filteredSeriesIndices.includes(i);
            })
            .reduce((acc, key, i) => {
                const series = formattedChartValues[key];

                if (hoveredIndex === i && series[0]?.theme) {
                    return {
                        ...acc,
                        [key]: series.map((series) => ({
                            ...series,
                            theme: { ...series.theme, strokeWidth: 5 },
                        })),
                    };
                }
                return { ...acc, [key]: series };
            }, {});
    }, [filteredSeriesIndices, formattedChartValues, hoveredIndex]);

    const xDomain = useMemo(() => {
        return getDomain(formattedChartValues, "x");
    }, [formattedChartValues]);

    //band aid for border theming; need to refactor
    let borderTheme = {};
    if (theme.borderColor !== "transparent")
        borderTheme = ["borderWidth", "borderColor", "borderRadius"]
            .filter((themeProp) => theme.hasOwnProperty(themeProp))
            .reduce((acc, themeProp) => {
                return { ...acc, [themeProp]: theme[themeProp] };
            }, {});

    return (
        <StyledUniversalDiv
            {...borderTheme}
            className={`flex flex-col lg:items-center`}
        >
            <VictoryChart
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiDimension="x"
                        labels={(labelStuff) => {
                            const { datum } = labelStuff;
                            return `${datum.label}: ${datum.displayText}`;
                        }}
                        labelComponent={
                            <ResponsiveToolTip
                                tooltipBackgroundColor={theme.toolTipBackground}
                            />
                        }
                        style={{
                            fontSize: 25,
                            fontFamily: "Open Sans",
                            fill: "black",
                        }}
                        height={300}
                    />
                }
                height={300}
                style={{
                    background: {
                        fill: theme.backgroundColor || "white",
                    },
                    parent: {
                        height: 5000,
                    },
                }}
                domainPadding={{ x: 0, y: 0 }}
            >
                <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) =>
                        theme.abbreviateYAxis !== false
                            ? abbrNum(tick)
                            : Number(tick).toString().length > 4
                              ? Number(tick).toLocaleString()
                              : tick
                    }
                    domain={yDomain}
                    style={{
                        tickLabels: {
                            fontFamily: "Open Sans",
                            fontSize: 9,
                            fill: theme.color || "black",
                            angle: -45,
                        },
                        grid: {
                            stroke: theme.gridColor || "rgba(0,0,0,0.1)",
                            strokeDasharray: "15",
                        },
                        axis: { stroke: theme.axisColor || "black" },
                    }}
                />
                <VictoryAxis
                    tickFormat={(tick) => abbrNum(tick)}
                    domain={xDomain}
                    tickValues={[""]}
                    style={{
                        tickLabels: {
                            fontFamily: "Open Sans",
                            fontSize: 0,
                            angle: 45,
                        },
                        axis: { stroke: theme.axisColor || "black" },
                    }}
                    label={theme?.xAxisLabel || "Time"}
                    axisLabelComponent={<AxisLabel />}
                />

                {Object.keys(filteredChartValues).map((key, idx) => {
                    const series = filteredChartValues[key];
                    if (!series?.length) return null;
                    const style = themeToAreaOrLineSeries(
                        series[0]?.theme || defaultAreaChartTheme,
                    );
                    if (series[0].chartType === "area") {
                        return (
                            <AreaSeries
                                key={key}
                                data={series}
                                style={style}
                                labelComponent={<></>}
                                x={idx.toString()}
                                height={height}
                                interpolation={series[0]?.theme?.interpolation}
                            />
                        );
                    }

                    return (
                        <VictoryLine
                            key={key}
                            data={series}
                            style={style}
                            labelComponent={<></>}
                            x={(idx - 1).toString()}
                            height={height}
                            interpolation={series[0]?.theme?.interpolation}
                        />
                    );
                })}
                {Object.keys(filteredChartValues).map((key, i) => {
                    const series = filteredChartValues[key];
                    if (!series?.length) return null;

                    return (
                        <VictoryScatter
                            style={{
                                data: { fill: series[0]?.theme?.stroke },
                            }}
                            size={hoveredIndex === i ? 5 : 3}
                            data={series}
                            labelComponent={<></>}
                            labels={({}) => null}
                            key={key}
                            x={(i - 1).toString()}
                            // interpolation={series[0]?.theme?.interpolation}
                        />
                    );
                })}
            </VictoryChart>
            {!hideLegend && (
                <Legend
                    formattedChartValues={formattedChartValues}
                    filteredIndices={filteredSeriesIndices}
                    setFilteredSeriesIndices={setFilteredSeriesIndices}
                    setHoveredIndex={setHoveredIndex}
                    hoveredIndex={hoveredIndex}
                    color={theme.color}
                    hoverBackgroundColor={theme.toolTipBackground}
                />
            )}
        </StyledUniversalDiv>
    );
}
