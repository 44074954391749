import { schema } from "normalizr";
import {
    ModelBlock,
    ModelVariable,
    Option,
    Prompt,
    Round,
    VariableRelationship,
} from "../../models";

export type StoredModelBlock = Omit<ModelBlock, "modelVariables"> & {
    modelVariables: string[];
};

export const modelVariableSchema = new schema.Entity<ModelVariable>(
    "modelVariables"
);

export const modelBlockSchema = new schema.Entity<StoredModelBlock>(
    "modelBlocks"
);

export const variableRelationshipSchema =
    new schema.Entity<VariableRelationship>("variableRelationships");

modelBlockSchema.define({
    modelVariables: [modelVariableSchema],
    modelBlocks: [modelBlockSchema],
});

export const modelBlocksSchema = new schema.Array<StoredModelBlock>(
    modelBlockSchema
);

const optionSchema = new schema.Entity<Option>("options");

export const promptSchema = new schema.Entity<Prompt>("prompts");

promptSchema.define({ options: [optionSchema] });

export const roundSchema = new schema.Entity<Round>("roundsWithPrompts");

roundSchema.define({
    prompts: [promptSchema],
});

const modelSchema = {
    modelBlockSchema,
    modelBlocksSchema,
    variableRelationshipSchema,
    roundSchema,
};

export type modelSchemaShape = typeof modelSchema;
