import { useEffect, useRef, useState } from "react";

export const useScrollToElement = <T extends HTMLElement>(shouldScroll: boolean) => {
    const ref = useRef<T>();
    const [shouldScrollTo, setShouldScrollTo] = useState(false);

    useEffect(() => {
        if (shouldScroll && !shouldScrollTo) {
            setShouldScrollTo(true);
        }
    }, [shouldScroll]);

    useEffect(() => {
        if (ref.current && shouldScrollTo) {
            ref.current.scrollIntoView({ behavior: "smooth" });
            setShouldScrollTo(false);
        }
    }, [shouldScrollTo]);

    return [ref, setShouldScrollTo] as const;
};
