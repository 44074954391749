import { ContentBlockType } from "../../models";
import { ConfigComponentType, defaultAccordionsTheme } from "../../styles";
import { buildAccordionItem } from "../content-block-layouts/AccordionLayout";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const AccordionsConfig: ContentBlockConfig = {
    typeKey: "Accordion",
    type: ContentBlockType.Accordion,
    requiredChildBlocks: [ContentBlockType.AccordionItem],
    addableChildBlocks: [ContentBlockType.AccordionItem],
    variantOptions: [
        {
            label: "Type",
            themeObjectKey: "type",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                default: "default",
                detached: "detached",
                ghost: "ghost",
                small: "small",
            },
        },
    ],
    defaultTheme: defaultAccordionsTheme,
    isCollectionType: true,
    onClickAdd: (parent) => {
        const blockToCreate = buildAccordionItem();
        blockToCreate.parent_content_block_id = parent.id;
        blockToCreate.round_id = parent.round_id;

        const lastChild =
            parent.contentBlocks &&
            parent.contentBlocks[parent.contentBlocks.length - 1];
        const weight = !!lastChild ? lastChild.weight + 1 : 0;

        blockToCreate.weight = weight;

        return blockToCreate;
    },
};

export const AccordionItemConfig: ContentBlockConfig = {
    typeKey: "AccordionItem",
    type: ContentBlockType.AccordionItem,
    requiredChildBlocks: [
        ContentBlockType.AccordionHeader,
        ContentBlockType.AccordionContent,
    ],
    addableChildBlocks: [
        ContentBlockType.AccordionHeader,
        ContentBlockType.AccordionContent,
    ],
    defaultTheme: defaultAccordionsTheme,
    isCollectionType: true,
};

export const AccordionHeaderConfig: ContentBlockConfig = {
    typeKey: "AccordionHeader",
    type: ContentBlockType.AccordionHeader,
    requiredChildBlocks: [ContentBlockType["Rich Text"]],
    addableChildBlocks: [ContentBlockType["Rich Text"]],
    defaultTheme: defaultAccordionsTheme,
    isCollectionType: true,
};

export const AccordionContentConfig: ContentBlockConfig = {
    typeKey: "AccordionContent",
    type: ContentBlockType.AccordionContent,
    requiredChildBlocks: [ContentBlockType.Box],
    addableChildBlocks: [ContentBlockType.Box],
    defaultTheme: defaultAccordionsTheme,
    isCollectionType: true,
};

// export function handleAccordionContentBlocks(accordionItem: ContentBlock) {
//     const header = accordionItem.contentBlocks.find(
//         (block) => block.content_block_type === ContentBlockType.AccordionHeader
//     );
//     const content = accordionItem.contentBlocks.find(
//         (block) =>
//             block.content_block_type === ContentBlockType.AccordionContent
//     );

//     if (!header || !content) return [];

//     return [header, content];
// }
