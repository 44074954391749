import React, { ReactNode } from "react";
import { ThemeObject, TextAlign, FlexDirection } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";
import { VariableValue } from "@/models";
import { useFormatVariableValue } from "@/hooks";

type StyledPageProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId: string;
    variableValue: VariableValue | null;
};

export const ModelDataDisplay = ({
    theme,
    contentBlockId,
    onClick,
    onHover,
    variableValue,
}: StyledPageProps) => {
    const formatVariableValue = useFormatVariableValue();

    return (
        <>
            <StyledUniversalDiv
                data-content-block-id={contentBlockId}
                onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                onMouseMove={
                    "function" === typeof onHover ? onHover : (evt) => {}
                }
                {...theme}
                display={"flex"}
                flexDirection={FlexDirection.column}
            >
                <div
                    className="truncate text-sm font-medium"
                    style={{
                        color: theme?.color || "#6a7281",
                        opacity: !!theme?.color ? "70%" : "100%",
                    }}
                >
                    {!!variableValue
                        ? variableValue.modelVariable?.label
                        : "Connect to Model Variable"}
                </div>
                <div
                    className="mt-1 text-3xl font-semibold tracking-tight "
                    style={{ color: theme?.color || "#111827" }}
                    data-testid={`variable-value-${contentBlockId}`}
                >
                    {!!variableValue
                        ? formatVariableValue(
                              variableValue.modelVariable.unit,
                              variableValue.numerical_value,
                              variableValue.modelVariable.is_integer,
                          )
                        : "0"}
                </div>
            </StyledUniversalDiv>
        </>
    );
};

export const defaultModelDataDisplayTheme: Partial<ThemeObject> = {
    textAlign: TextAlign.left,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#000000ff",
    backgroundColor: "#ffffff00",
    opacity: 1,
    pt: 5,
    pr: 3,
    pb: 5,
    pl: 3,
    mt: 4,
    mr: 4,
    mb: 4,
    ml: 4,
};
