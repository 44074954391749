import { VictoryStyleObject, VictoryLabelStyleObject } from "victory";
import { defaultAreaChartTheme } from "@/styles/themes/charts/themes";

export function abbrNum(
    number: number,
    decPlaces: number | [number, number, number, number] = 2
) {
    const isNegative = number < 0;

    number = Math.abs(number);
    let places = Array.isArray(decPlaces)
        ? decPlaces[decPlaces.length - 1]
        : decPlaces;

    if (number && number < 1000) {
        // console.log(places, decPlaces)
        return Math.round(number).toString();
    }

    let numberString = number.toString();
    // 2 decimal places => 100, 3 => 1000, etc
    places = Math.pow(10, places);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
        const places = Array.isArray(decPlaces)
            ? Math.pow(10, decPlaces[i])
            : Math.pow(10, decPlaces);

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            if (places === 0) {
                number = Math.round((number * places) / size);
            }
            number = Math.round((number * places) / size) / places;
            numberString = number.toString();
            // Handle special case where we round up to the next abbreviation
            if (number == 1000 && i < abbrev.length - 1) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            numberString = number.toString() + abbrev[i];

            // We are done... stop
            break;
        }
    }

    return isNegative ? `-${numberString}` : numberString;
}
export function getDomain(
    data: {
        [index: string]: { x: number, y:number }[];
    },
    domain: "x" | "y"
): [number, number] {
    const lowerBound =
        domain === "x"
            ? 0
            : Math.floor(
                  Math.min(
                      ...Object.values(data).map((value) => {
                          if (value?.length) {
                              return Math.min(
                                  ...value.map((datum) => datum[domain])
                              );
                          }
                          return 0;
                      })
                  ) * 0.9
              );


    const upperBound =
        domain === "x"
            ? Math.max(
                  ...Object.values(data)
                      .map((data) => data?.length)
              ) - 1
            : Math.ceil(
                  Math.max(
                      ...Object.values(data)
                          .filter((data) => data?.length)
                          .map((value) => {
                              if (value?.length) {
                                  return Math.min(
                                      ...value.map((datum) => datum[domain])
                                  );
                              }
                              return 0;
                          })
                  ) * 1.1
              );

    return [lowerBound, upperBound];
}

export function getXTicks(data: { [index: string]: { x: number }[] }) {
    const firstWithData = Object.values(data).find((value) => value?.length);
    if (!firstWithData) return [];
    return firstWithData
        .filter(({ x }) => x > 0)
        .map((value) => abbrNum(value.x));
}

export function themeToAreaOrLineSeries(theme: typeof defaultAreaChartTheme): {
    data: VictoryStyleObject;
    labels: VictoryLabelStyleObject;
} {
    return {
        data: {
            stroke: theme?.stroke,
            strokeWidth: theme?.strokeWidth,
            fill: theme?.fill,
        },
        labels: {
            fill: theme?.stroke || "black",
            fontSize: 10,
            textAnchor: "left",
            marginBottom: 2,
        },
    };
}

function detectColorFormat(
    color: string
): "RGB" | "RGBA" | "HEX" | "HEX_A" | "UNKNOWN" {
    if (/^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/.test(color)) return "HEX";
    if (/^#([a-fA-F0-9]{4}|[a-fA-F0-9]{8})$/.test(color)) return "HEX_A";
    if (/^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/.test(color)) return "RGB";
    if (/^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*([\d\.]+)\s*\)$/.test(color))
        return "RGBA";
    return "UNKNOWN";
}

function hexToRgbA(hex: string): string {
    let c: any;
    if (/^#([A-Fa-f0-9]{3,4})$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2], "F", "F"];
        } else if (c.length === 4) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2], c[3], c[3]];
        }
        hex = `#${c.join("")}`;
    }

    if (/^#([A-Fa-f0-9]{6,8})$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 6) {
            c = c.concat(["F", "F"]);
        }
        const rgb = [
            parseInt(c[0] + c[1], 16),
            parseInt(c[2] + c[3], 16),
            parseInt(c[4] + c[5], 16),
        ];
        const alpha = parseInt(c[6] + c[7], 16) / 255;
        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
    }
    throw new Error("Bad Hex");
}

export function getSteppedColors(color: string, steps: number): string[] {
    const format = detectColorFormat(color);
    let baseColor = color;

    if (format === "HEX" || format === "HEX_A") {
        baseColor = hexToRgbA(color);
    } else if (format === "RGB") {
        baseColor = color.replace("rgb", "rgba").replace(")", ", 1)");
    }
    const rgbaMatch = baseColor.match(
        /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)$/
    );
    if (!rgbaMatch) throw new Error("Invalid color format");

    const [, r, g, b, a] = rgbaMatch.map(Number);
    const stepDecrease = a / steps;

    return Array.from({ length: steps }).map((_, i) => {
        return `rgba(${r}, ${g}, ${b}, ${a - i * stepDecrease})`;
    });
}

export function reduceOpacity(color: string, opacity = 0.5): string {
    if (color.startsWith("#")) {
        // Convert hex to rgba
        const colorHexValues = color.slice(1, 7);
        let bigint = parseInt(colorHexValues, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.5)`;
    } else if (color.startsWith("rgb")) {
        // Extract numbers from string
        let nums = color.match(/(\d+(\.\d+)?)/g).map(Number);

        if (color.startsWith("rgba")) {
            // Already has opacity, reduce by half
            nums[3] *= 0.5;
            return `rgba(${nums[0]}, ${nums[1]}, ${nums[2]}, ${nums[3]})`;
        } else {
            // No opacity, set to 0.5
            return `rgba(${nums[0]}, ${nums[1]}, ${nums[2]},${opacity})`;
        }
    } else {
        return color;
    }
}

export const radian = Math.PI / 180;
export const getXOffsetMultiplayerByAngle = (angle: number) =>
    Math.cos(angle - 90 * radian);
export const getYOffsetMultiplayerByAngle = (angle: number) =>
    Math.sin(angle - 90 * radian);
export const getXOffset = (offset: number, angle: number) =>
    offset * getXOffsetMultiplayerByAngle(angle);
export const getYOffset = (offset: number, angle: number) =>
    offset * getYOffsetMultiplayerByAngle(angle);
export const getAverage = (array: number[]) =>
    array.reduce((acc, cur) => acc + cur, 0) / array.length;
