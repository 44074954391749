import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, PromptType } from "@/models";

export async function createQuestion({
    parentContentBlockId,
    promptType,
}: {
    parentContentBlockId: string;
    promptType: PromptType;
}) {

    const { data } = await sapienAxios.get<
        { contentBlock: ContentBlockShape, promptType: PromptType},
        "creator.design.questions.create"
    >("creator.design.questions.create", {
        parentContentBlockId,
        promptType,
    });

    return data;
}
