import { defaultBoxTheme } from "../../styles";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const TileConfig: ContentBlockConfig = {
    typeKey: "Tile",
    type: ContentBlockType.Tile,
    addableChildBlocks: [ContentBlockType.Image, ContentBlockType.Box],
    defaultTheme: defaultBoxTheme,
};
