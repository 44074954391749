import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { Node } from "@tiptap/core";
import {
    StyledTipTapTextTheme,
    typographyProps,
} from "@/styles";
import { TypographyTypeValues } from "@/styles/SimulationTheme";

type MarkOptions = {
    typographyType: TypographyTypeValues;
};

//for now, there's not a better way to type commands
declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        TextType: {
            /**
             * Comments will be added to the autocomplete.
             */
            setTypographyType: (typographyType: TypographyTypeValues) => ReturnType;
        };
    }
}

export const TextType = Node.create<MarkOptions>({
    name: "typography",
    content: "inline*",
    group: "block",
    defining: true,
    addOptions() {
        return {
            typographyType: "PARAGRAPH",
        };
    },
    parseHTML(this) {
        const tag =
            typographyProps[this.storage.typographyType]?.asTag || "typography";
        return [
            {
                tag,
            },
        ];
    },
    addStorage() {
        return {
            typographyType: "PARAGRAPH",
        };
    },
    renderHTML({ HTMLAttributes }) {
        const tag =
            typographyProps[this.storage.typographyType]?.asTag || "typography";
        return [tag, mergeAttributes(this.options, HTMLAttributes), 0];
    },
    addCommands(this) {
        return {
            setTypographyType:
                (typographyType) =>
                ({ commands }) => {
                    this.storage.typographyType = typographyType;

                    return commands.setNode(this.name, {
                        typographyType,
                    });
                },
        };
    },
    addAttributes(this) {
        return {
            typographyType: {
                default: "PARAGRAPH",
                rendered: true,
            },
            default: "PARAGRAPH",
        };
    },
    addNodeView(this) {
        return ReactNodeViewRenderer(StyledTipTapTextTheme);
    },
});
