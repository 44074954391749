import { atom, useAtom } from "jotai";
import { useContentBlockState } from "../useContentBlockState";
import { useMemo } from "react";

const contentBlockWithAddButtonClickedIdAtom = atom<string | false>(false);
export function useIsAddingContentBlock(contentBlockId: string = "") {
    const [contentBlockWithAddButtonClickedId, setIsAddingBlock] = useAtom(
        contentBlockWithAddButtonClickedIdAtom,
    );
    const { parentContentBlocks } = useContentBlockState();

    const isAddingContentBlock = useMemo(() => {

        return (
            contentBlockWithAddButtonClickedId === contentBlockId &&
            !!parentContentBlocks.find((block) => block.id === contentBlockId)
        );
    }, [contentBlockWithAddButtonClickedId, parentContentBlocks]);

    const isAddingAnyContentBlock = useMemo(() => {
        return (
            contentBlockWithAddButtonClickedId !== false &&
            !!parentContentBlocks.find((block) => block.id === contentBlockId)
        );
    }, [contentBlockWithAddButtonClickedId, parentContentBlocks]);

    return {
        isAddingContentBlock,
        setIsAddingBlock,
        isAddingAnyContentBlock,
    };
}
