import { ContentBlockShape, ContentBlockType } from "@/models";
import {
    defaultBottomDrawerTheme,
    defaultBoxTheme,
    defaultSubmissionCountTheme,
} from "@/styles";

export function buildBottomDrawer(): ContentBlockShape {
    return {
        content_block_type: ContentBlockType["Bottom Drawer"],
        theme: defaultBottomDrawerTheme,
        weight: 9999,
        behaviors: {
            participantState: {
                DisplayIfRoundIsCompleteAndFacilitatorAllows: true,
            },
        },
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Box,
                theme: {
                    ...defaultBoxTheme,
                    pt: 0,
                    pr: 0,
                    pb: 0,
                    pl: 0,
                    mt: 0,
                    mr: 0,
                    mb: 0,
                    ml: 0,
                },
                weight: 0,
                contentBlocks: [
                    {
                        content_block_type:
                            ContentBlockType["Submission Count"],
                        theme: defaultSubmissionCountTheme,
                        weight: 0,
                        content:
                            "players on your team are ready for the next team challenge",
                    },
                ],
            },
        ],
    };
}

export const BottomDrawerLayout = buildBottomDrawer();
