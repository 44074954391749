import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PromptShape, Selection, SelectionShape } from "@/models";

type OthersSelectionState = {
    othersSelectionsByContentBlockId: { [index: string]: SelectionShape[] };
    decisionLogSelections: (SelectionShape & {prompt: PromptShape})[];
};

const initialState: OthersSelectionState = {
    othersSelectionsByContentBlockId: {},
    decisionLogSelections: [],
};

const othersSelectionsSlice = createSlice({
    name: "OthersSelectionsState",
    initialState,
    reducers: {
        gotOthersSelections(state, { payload }: PayloadAction<Selection[]>) {
            state.othersSelectionsByContentBlockId = payload.reduce(
                (carry, selection) => {
                    if (!carry[selection.content_block_id])
                        carry[selection.content_block_id] = [];

                    return {
                        ...carry,
                        [selection.content_block_id]: [
                            ...carry[selection.content_block_id],
                            selection,
                        ],
                    };
                },
                {}
            );
        },
        gotDecisionLogSelections(
            state,
            { payload }: PayloadAction<(SelectionShape & {prompt: PromptShape})[]>
        ) {
            state.decisionLogSelections = payload;
        },
    },
});

export const othersSelectionsReducer = othersSelectionsSlice.reducer;

// export type RootState = ReturnType<typeof othersSelectionsReducer>;

export const { gotOthersSelections, gotDecisionLogSelections } =
    othersSelectionsSlice.actions;
