import React, { ReactNode, useContext } from "react";
import { ThemeObject } from "../../ThemeObjects";
import { StyledUniversalDiv } from "../Universal";
import { accordionContext } from "./accordionContext";
import styled from "styled-components";
import { AccordionVariant } from "./AccordionsTheme";

const AccordionItemStyledComponent = styled(StyledUniversalDiv)<{
    variant: AccordionVariant;
}>`
    margin-bottom: ${({ variant }) => (variant === "detached" ? "12px" : "0")};

`;

export function StyledAccordionItem({
    children,
    theme,
    contentBlockId,
    title
}: {
    children?: ReactNode;
    theme: Partial<ThemeObject>;
    isDisabled: boolean;
    contentBlockId: string;
    title?:string
}) {
    const accordionContextInternal = useContext(accordionContext);
    const variant = accordionContextInternal.variant;

    return (
        <AccordionItemStyledComponent
            px={theme.px as number}
            py={theme.py as number}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Accordion Item"}
            variant={variant}
        >
            {children}
        </AccordionItemStyledComponent>
    );
}

export const defaultAccordionItemTheme: Partial<ThemeObject> = {
    py: 0,
    px: 0,
    my: 0,
    mx: 0,
    background: "rgba(0,0,0,0)",
};
