import React from "react";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextType } from "@/modules/text-editor";

type Props = { content: JSONContent };

export default function ReflectionJournalEntryDisplay({ content }: Props) {
    const editor = useEditor({
        content,
        extensions: [StarterKit, TextType],
        editable: false,
    });

    return <EditorContent editor={editor} />;
}
