import { useMutation } from "@tanstack/react-query";
import { useAddContentBlockState } from "../useAddContentBlockState";
import {
    createContentBlock,
    CreateContentBlockBody,
} from "./createContentBlock";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useGotContentBlock, useSelectedSimulation } from "@/hooks";
import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";
import { generateContentBlockToSave } from "@/hooks/useConfigSelector/util";
import { ContentBlockMetadata } from "@/model-configs";

function buildContentBlockRequestBody(
    contentBlock: ContentBlockShape,
    contentBlockMetaData: ContentBlockMetadata,
    position: "before" | "after",
    weight: number,
): CreateContentBlockBody {
    const newBlock = generateContentBlockToSave(
        contentBlock,
        contentBlockMetaData.blockOrLayoutType,
        weight,
        contentBlockMetaData,
    );

    return {
        ...newBlock,
        parent_content_block_id: contentBlock.id,
        position,
    } as CreateContentBlockBody;
}

export function useCreateContentBlock(
    addableContentBlockMetaData: ContentBlockMetadata,
) {
    const { selectedSimulation } = useSelectedSimulation();
    const { reset } = useAddContentBlockState();
    const { gotContentBlock } = useGotContentBlock();
    const { setActiveContentBlock } = useSelectActiveContentBlock();

    return useMutation({
        mutationFn: async ({
            position,
            contentBlock,
            weight,
        }: {
            contentBlock: ContentBlockShape;
            position: "before" | "after";
            weight: number;
        }) => {
            const body = buildContentBlockRequestBody(
                contentBlock,
                addableContentBlockMetaData,
                position,
                weight,
            );
            reset();

            return createContentBlock({ body, selectedSimulation });
        },
        onSuccess: ({ contentBlock, activeContentBlockId }) => {
            gotContentBlock(contentBlock);
            setActiveContentBlock(activeContentBlockId);
        },
        onError: (error) => {
            console.log("error saving modal", error);
        },
    });
}
