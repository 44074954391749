import { defaultRichTextTheme } from "@/styles/themes/RichTextTheme";
import {
    ContentBlock,
    ContentBlockShape,
    ContentBlockType,
    RichText,
} from "../../models";
import {
    AccordionVariant,
    defaultAccordionContentTheme,
    defaultAccordionHeaderTheme,
    defaultAccordionItemTheme,
    defaultAccordionsTheme,
    defaultSectionTheme,
    ThemeObject,
    TypographyType,
} from "../../styles";
// const theme = {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     background: "rgba(0,0,0,0)",
// };
//TODO: Figure out how to work with background images
//
function buildWrappedAccordion(
    maintext = "Accordion Header",
    subtext = "Accordion Content"
) {
    return new ContentBlock({
        title: "Accordion Wrapper",
        content: "Accordion Wrapper",
        content_block_type: ContentBlockType.Section,
        theme: {
            ...defaultSectionTheme,
            ...{
                backgroundColor: "rgba(255,255,255,0)",
                alignment: "left",
            },
        },
        contentBlocks: [buildAccordion(maintext, subtext)],
    });
}

export function buildAccordion(
    maintext = "Accordion Header",
    subtext = "Accordion Content",
    type: AccordionVariant = "default",
    headerBlocks: ContentBlockShape[] = [],
    bodyBlocks: ContentBlockShape | undefined = undefined
) {
    return new ContentBlock({
        title: "Accordion",
        content: "Accordion",
        content_block_type: ContentBlockType.Accordion,
        theme: {
            ...defaultAccordionsTheme,
            ...{ type, consistentChildProps: true },
        } as ThemeObject,
        contentBlocks: [
            buildAccordionItem(
                maintext,
                subtext,
                type === "small" ? { px: 4, py: 4 } : { px: 6, py: 5 },
                headerBlocks,
                bodyBlocks
            ),
            buildAccordionItem(
                maintext,
                subtext,
                type === "small" ? { px: 4, py: 4 } : { px: 6, py: 5 },
                headerBlocks,
                bodyBlocks
            ),
            buildAccordionItem(
                maintext,
                subtext,
                type === "small" ? { px: 4, py: 4 } : { px: 6, py: 5 },
                headerBlocks,
                bodyBlocks
            ),
        ],
    }) as ContentBlockShape;
}

export const buildAccordionItem = (
    maintext = "Accordion Header",
    subtext = "Accordion Content",
    padding: { px: number; py: number } = { px: 6, py: 5 },
    headerBlocks: ContentBlockShape[] = [],
    bodyBlocks: ContentBlockShape | undefined = undefined,
    weight = 0
) => {
    const block = new ContentBlock({
        content_block_type: ContentBlockType.AccordionItem,
        theme: {
            ...defaultAccordionItemTheme,
        },
        weight,
        contentBlocks: [
            new ContentBlock({
                content_block_type: ContentBlockType.AccordionHeader,
                theme: defaultAccordionHeaderTheme,
                weight: 0,
                contentBlocks: headerBlocks?.length
                    ? headerBlocks
                    : [
                          {
                              content_block_type: ContentBlockType["Rich Text"],
                              theme: defaultRichTextTheme,
                              rich_text: {
                                  type: "doc",
                                  content: [
                                      {
                                          type: "paragraph",
                                          attrs: {
                                              textAlign: "left",
                                              typographyType: "SUBTITLE_ALT",
                                          },
                                          content: [
                                              { type: "text", text: maintext },
                                          ],
                                      },
                                  ],
                              } as RichText,
                          },
                      ],
            }),
            bodyBlocks ||
                new ContentBlock({
                    content: subtext,
                    content_block_type: ContentBlockType.AccordionContent,
                    theme: defaultAccordionContentTheme,
                    weight: 1,
                    contentBlocks: [
                        new ContentBlock({
                            content_block_type: ContentBlockType.Box,
                            theme: {
                                mx: 0,
                                my: 0,
                                color: "rgba(2,2,2,1)",
                                backgroundColor: "rgba(0,0,0,0)",
                                ...padding,
                            },
                            contentBlocks: [
                                new ContentBlock({
                                    content: subtext,
                                    weight: 0,
                                    content_block_type:
                                        ContentBlockType["Rich Text"],
                                    theme: defaultRichTextTheme,
                                    rich_text: {
                                        type: "doc",
                                        content: [
                                            {
                                                type: "paragraph",
                                                attrs: {
                                                    textAlign: "left",
                                                    typographyType:
                                                        "TEXT_STACKED_CARD",
                                                },
                                                content: [
                                                    {
                                                        type: "text",
                                                        text: subtext,
                                                    },
                                                ],
                                            },
                                        ],
                                    } as RichText,
                                }),
                            ],
                        }),
                    ],
                }),
        ],
    });

    return block;
};

export const AccordionLayout: ContentBlock = buildWrappedAccordion();
