import React, { ReactNode, useContext, useState } from "react";
import { ThemeObject } from "@/styles/ThemeObjects";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { inboxContext } from "./InboxContext";
import { BellIcon } from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";

type StyledSectionProps = ContentBlockEvents & {
    theme: typeof defaultInboxTheme;
    children?: ReactNode;
    contentBlockId: string;
    isOpen: boolean;
    unreadMessageIds: string[];
    hasActiveChild?: boolean;
};

const Provider = inboxContext.Provider;

const Inbox = ({
    theme,
    children,
    contentBlockId,
    onClick,
    position,
    isInEditorContext,
    unreadMessageIds,
    hasActiveChild,
}: StyledSectionProps & { position: "left" | "right" }) => {
    const { openedMessageId, isOpen, setIsOpen } = useContext(inboxContext);

    return (
        <>
            <Popover className={"flex"}>
                <Popover.Button
                    onClick={(e) => {
                        setIsOpen(!isOpen);
                        if ("function" === typeof onClick) {
                            onClick(e);
                        }
                    }}
                    className="group relative bg-gray-50 rounded-full outline-none"
                >
                    <BellIcon
                        className="w-6 h-6 transition-colors opacity-80 group-hover:opacity-100"
                        style={{ color: theme.color }}
                    />
                    {!!unreadMessageIds?.length && (
                        <span className="z-100 absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                            {unreadMessageIds.length}
                        </span>
                    )}
                </Popover.Button>
                <Transition
                    show={isOpen || !!openedMessageId}
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        static
                        data-content-block-id={contentBlockId}
                        className={`absolute inbox z-100 mt-8 max-w-sm transform px-4 @sm:px-0 @lg:max-w-3xl @lg:w-96 ${
                            position === "left" ? "left-0" : "right-2 w-full"
                        }`}
                    >
                        <div
                            className={`rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ${
                                isInEditorContext
                                    ? "overflow-visible"
                                    : "overflow-hidden"
                            }`}
                            style={{
                                backgroundColor:
                                    theme.backgroundColor || "#FFFFFF",
                                color: theme.color || "#000000ff",
                            }}
                        >
                            <div
                                className={`py-4 max-h-144 ${
                                    hasActiveChild
                                        ? "overflow-visible"
                                        : "overflow-y-auto"
                                }`}
                            >
                                {children}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
            {isOpen && (
                <div
                    className="fixed inset-"
                    onClick={(e) => {
                        setIsOpen(false);
                    }}
                ></div>
            )}
        </>
    );
};

export const InboxTheme = (props: StyledSectionProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openedMessageId, setOpenedMessageId] = useState("");

    const context = {
        isOpen,
        setIsOpen: (isOpen: boolean) => {
            setIsOpen(isOpen);
        },
        openedMessageId,
        setOpenedMessageId(id) {
            setOpenedMessageId(id);
        },
    };

    const sideBarNavWrappingElement = document.querySelector(
        "#sidebar-notification-icon"
    );
    const topBarElement = document.querySelector("#notification-icon");

    return (
        <Provider value={context}>
            {!!topBarElement &&
                createPortal(
                    <Inbox {...props} position={"right"} />,
                    topBarElement
                )}
            {!!sideBarNavWrappingElement &&
                createPortal(
                    <Inbox {...props} position={"left"} />,
                    sideBarNavWrappingElement
                )}
        </Provider>
    );
};

export const defaultInboxTheme: Partial<ThemeObject> = {
    backgroundColor: "#ffffffff",
    color: "#000000ff",
};
