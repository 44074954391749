import { useMutation } from "@tanstack/react-query";
import {
    useGotContentBlock,
    useModalQueryParams,
    useSelectedSimulation,
} from "@/hooks";
import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";
import {
    SaveBehaviorBlockRequestBody,
    saveBehaviorBlock,
} from "./saveBehaviorBlock";
import { useAddContentBlockState } from "../useAddContentBlockState";

export function useCreateBehaviorContentBlock() {
    const { gotContentBlock } = useGotContentBlock();
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    const { openModal } = useModalQueryParams();
    const { selectedSimulation } = useSelectedSimulation();
    const { reset } = useAddContentBlockState();

    return useMutation({
        mutationFn: (contentBlock: SaveBehaviorBlockRequestBody) => {
            return saveBehaviorBlock({ contentBlock, selectedSimulation });
        },
        onSettled: (data) => {
            gotContentBlock(data);
            setActiveContentBlock(data.id);
            reset()
            openModal({
                modalComponent: "BehaviorModal",
                behaviorContentBlockId: data.id,
            });
        },
    });
}
