import React from "react";
import { Editor } from "@tiptap/react";
import {
    FormSelectEnumType,
    FormSelectArrayIndex,
} from "@/styles/editor-components";
import { TypographyType } from "@/styles";
import {
    TypographyTypeValues,
    getTypographyStyle,
} from "@/styles/SimulationTheme";
import { lineHeightOptions } from "./LineHeight";

export const MenuBarCompact = ({
    editor,
    showColorPicker,
}: {
    editor: Editor;
    showColorPicker?: boolean;
}) => {
    if (!editor) {
        return null;
    }

    // const color = editor.getAttributes("textStyle")?.color || "black";
    const optionallySelectAll = (editor: Editor) => {
        const currentSelectionIsEmpty = editor.view.state.selection.empty;
        if (currentSelectionIsEmpty) {
            editor.chain().focus().selectAll().run();
        }
    };
    return (
        <div className="menubar flex h-[36px] max-h-[36px] flex-row overflow-hidden bg-black text-white">
            <div
                className="flex flex-row items-center overflow-auto scrollbar scrollbar-thumb-gray-500
                    scrollbar-thumb-rounded-full scrollbar-h-0.5"
            >
                <div className="flex flex-row items-center justify-between px-2">
                    <div className="flex flex-row gap-1">
                        <button
                            title={"Undo"}
                            onClick={() => editor.chain().focus().undo().run()}
                            className={`flex items-center justify-center rounded-sm border-gray-300/30 bg-white/10 px-4
                                py-2 text-center capitalize text-white/70 hover:border-white/20
                                hover:bg-[#3071F2] hover:text-white focus:outline-none focus:ring-opacity-50`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.41436 10.0001H16.0002C19.3139 10.0001 22.0002 12.6864 22.0002 16.0001C22.0002 19.3138 19.3139 22.0001 16.0002 22.0001H13.5002V20.0001H16.0002C18.2093 20.0001 20.0002 18.2092 20.0002 16.0001C20.0002 13.7909 18.2093 12.0001 16.0002 12.0001H5.41436L9.70726 16.293L8.29304 17.7072L1.58594 11.0001L8.29304 4.29297L9.70726 5.70718L5.41436 10.0001Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            title={"Redo"}
                            onClick={() => editor.chain().focus().redo().run()}
                            className={`flex items-center justify-center rounded-sm border-gray-300/30 bg-white/10 px-4
                                py-2 text-center capitalize text-white/70 hover:border-white/20
                                hover:bg-[#3071F2] hover:text-white focus:outline-none focus:ring-opacity-50`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.5858 10.0001L14.2929 5.70718L15.7071 4.29297L22.4142 11.0001L15.7071 17.7072L14.2929 16.293L18.5858 12.0001H8C5.79086 12.0001 4 13.7909 4 16.0001C4 18.2092 5.79086 20.0001 8 20.0001H10.5V22.0001H8C4.68629 22.0001 2 19.3138 2 16.0001C2 12.6864 4.68629 10.0001 8 10.0001H18.5858Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex flex-row items-center px-2">
                    <FormSelectEnumType
                        value={
                            editor.getAttributes("typography")?.typographyType
                                ? editor.getAttributes("typography")
                                      ?.typographyType
                                : "none"
                        }
                        label={""}
                        inline={true}
                        themeObjectKey={"typographyType"}
                        handleChange={(_, value) => {
                            optionallySelectAll(editor);
                            const typographyTypeKey = Object.keys(
                                TypographyType,
                            ).find((key) => TypographyType[key] === value);

                            const style = getTypographyStyle(value);

                            if (style?.fontSize) {
                                editor
                                    .chain()
                                    .focus()
                                    .setFontSize(style.fontSize[0])
                                    .run();
                            }

                            if (typographyTypeKey) {
                                editor
                                    .chain()
                                    .focus()
                                    .setTypographyType(
                                        TypographyType[typographyTypeKey],
                                    )
                                    .run();
                            }
                        }}
                        optionObject={
                            {
                                H1: "FIGMA_HEADING_1",
                                H2: "FIGMA_HEADING_2",
                                H3: "FIGMA_HEADING_3",
                                H6: "HEADING_6",
                                P: "FIGMA_PARAGRAPH",
                                "P Alt": "FIGMA_PARAGRAPH_ALT",
                                Subtitle: "FIGMA_SUBTITLE",
                                // "Subtitle Alt": TypographyType["Figma Subtitle Alt"],
                                "Card Text": "TEXT_STACKED_CARD",
                            } as {
                                [index: string]: TypographyTypeValues;
                            }
                        }
                        forceButtonStyle={true}
                    />
                </div>
                <div className="flex flex-row items-center justify-between px-2">
                    <div className="flex flex-row gap-1">
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor.chain().focus().toggleBold().run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive("bold")
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M15.8915 11.79C16.8615 11.12 17.5415 10.02 17.5415 9C17.5415 6.74 15.7915 5 13.5415 5H7.2915V19H14.3315C16.4215 19 18.0415 17.3 18.0415 15.21C18.0415 13.69 17.1815 12.39 15.8915 11.79ZM10.2915 7.5H13.2915C14.1215 7.5 14.7915 8.17 14.7915 9C14.7915 9.83 14.1215 10.5 13.2915 10.5H10.2915V7.5ZM13.7915 16.5H10.2915V13.5H13.7915C14.6215 13.5 15.2915 14.17 15.2915 15C15.2915 15.83 14.6215 16.5 13.7915 16.5Z"
                                    fill={"currentColor"}
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor.chain().focus().toggleItalic().run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive("italic")
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M10 5V8H12.21L8.79 16H6V19H14V16H11.79L15.21 8H18V5H10Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor.chain().focus().toggleUnderline().run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive("underline")
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12.333 17C15.643 17 18.333 14.31 18.333 11V3H15.833V11C15.833 12.93 14.263 14.5 12.333 14.5C10.403 14.5 8.83301 12.93 8.83301 11V3H6.33301V11C6.33301 14.31 9.02301 17 12.333 17ZM5.33301 19V21H19.333V19H5.33301Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor.chain().focus().toggleBulletList().run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive("bulletList")
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M4.9165 10.5C4.0865 10.5 3.4165 11.17 3.4165 12C3.4165 12.83 4.0865 13.5 4.9165 13.5C5.7465 13.5 6.4165 12.83 6.4165 12C6.4165 11.17 5.7465 10.5 4.9165 10.5ZM4.9165 4.5C4.0865 4.5 3.4165 5.17 3.4165 6C3.4165 6.83 4.0865 7.5 4.9165 7.5C5.7465 7.5 6.4165 6.83 6.4165 6C6.4165 5.17 5.7465 4.5 4.9165 4.5ZM4.9165 16.5C4.0865 16.5 3.4165 17.18 3.4165 18C3.4165 18.82 4.0965 19.5 4.9165 19.5C5.7365 19.5 6.4165 18.82 6.4165 18C6.4165 17.18 5.7465 16.5 4.9165 16.5ZM7.9165 19H21.9165V17H7.9165V19ZM7.9165 13H21.9165V11H7.9165V13ZM7.9165 5V7H21.9165V5H7.9165Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor
                                    .chain()
                                    .focus()
                                    .toggleOrderedList()
                                    .run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive("orderedList")
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M2.5 17H4.5V17.5H3.5V18.5H4.5V19H2.5V20H5.5V16H2.5V17ZM3.5 8H4.5V4H2.5V5H3.5V8ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 5V7H21.5V5H7.5ZM7.5 19H21.5V17H7.5V19ZM7.5 13H21.5V11H7.5V13Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between px-2">
                    <div className="flex flex-row gap-1">
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("left")
                                    .run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive({ textAlign: "left" })
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 25 24"
                                fill="none"
                            >
                                <path
                                    d="M15.6665 15H3.6665V17H15.6665V15ZM15.6665 7H3.6665V9H15.6665V7ZM3.6665 13H21.6665V11H3.6665V13ZM3.6665 21H21.6665V19H3.6665V21ZM3.6665 3V5H21.6665V3H3.6665Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);

                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("center")
                                    .run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive({ textAlign: "center" })
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M7 15V17H17V15H7ZM3 21H21V19H3V21ZM3 13H21V11H3V13ZM7 7V9H17V7H7ZM3 3V5H21V3H3Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("right")
                                    .run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive({ textAlign: "right" })
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 25 24"
                                fill="none"
                            >
                                <path
                                    d="M3.33301 21H21.333V19H3.33301V21ZM9.33301 17H21.333V15H9.33301V17ZM3.33301 13H21.333V11H3.33301V13ZM9.33301 9H21.333V7H9.33301V9ZM3.33301 3V5H21.333V3H3.33301Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                optionallySelectAll(editor);
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("justify")
                                    .run();
                            }}
                            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                            hover:border-white/20 hover:bg-[#3071F2] hover:text-white focus:outline-none
                            focus:ring-opacity-50 ${
                                editor.isActive({ textAlign: "justify" })
                                    ? "border-gray-300/50 bg-[#3071F2] text-white"
                                    : "border-gray-300/30 bg-white/10 text-white/70"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 25 24"
                                fill="none"
                            >
                                <path
                                    d="M3.6665 21H21.6665V19H3.6665V21ZM3.6665 17H21.6665V15H3.6665V17ZM3.6665 13H21.6665V11H3.6665V13ZM3.6665 9H21.6665V7H3.6665V9ZM3.6665 3V5H21.6665V3H3.6665Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className=" flex flex-row" title={"Font Family"}>
                    <FormSelectEnumType
                        value={
                            editor?.getAttributes("textStyle")?.fontFamily ||
                            "Open Sans"
                        }
                        label={""}
                        inline={true}
                        themeObjectKey={"fontFamily"}
                        handleChange={(_, value) => {
                            optionallySelectAll(editor);
                            editor.chain().focus().setFontFamily(value).run();
                        }}
                        optionObject={{
                            "Open Sans (default)": "Open Sans",
                            Inter: "Inter",
                            Arial: "Arial",
                            "Courier New": "Courier New",
                            Georgia: "Georgia",
                            Helvetica: "Helvetica",
                            Impact: "Impact",
                            "Times New Roman": "Times New Roman",
                        }}
                    />
                </div>
                <div className=" flex flex-row" title={"Line Height"}>
                    <FormSelectArrayIndex
                        value={
                            lineHeightOptions.includes(
                                editor.getAttributes("textStyle")?.lineHeight,
                            )
                                ? lineHeightOptions.indexOf(
                                      editor.getAttributes("textStyle")
                                          ?.lineHeight,
                                  )
                                : editor.getAttributes("textStyle")?.lineHeight
                        }
                        label={""}
                        inline={true}
                        themeObjectKey={"lineHeight"}
                        handleChange={(_, value) => {
                            optionallySelectAll(editor);
                            editor
                                .chain()
                                .focus()
                                .setLineHeight(lineHeightOptions[value])
                                .run();
                        }}
                        optionArray={lineHeightOptions}
                    />
                </div>
                <div className=" flex flex-row pr-2" title={"Font Size"}>
                    <FormSelectEnumType
                        value={editor.getAttributes("textStyle")?.fontSize}
                        label={""}
                        inline={true}
                        themeObjectKey={"fontSize"}
                        handleChange={(_, value) => {
                            optionallySelectAll(editor);
                            editor.chain().focus().setFontSize(value).run();
                            // if (currentSelectionIsEmpty)
                            //     editor.chain().setNodeSelection().run();
                        }}
                        optionObject={{
                            8: 8,
                            9: 9,
                            10: 10,
                            11: 11,
                            12: 12,
                            14: 14,
                            16: 16,
                            18: 18,
                            20: 20,
                            22: 22,
                            24: 24,
                            30: 30,
                            36: 36,
                            48: 48,
                            56: 56,
                            60: 60,
                            64: 64,
                            72: 72,
                            96: 96,
                        }}
                    />
                </div>
                {/* <div className="">
                <span className="px-2 text-sm">{"Color"}</span>
                <div className="-mt-4">
                    <FormInputColorPicker
                        value={color}
                        label=""
                        themeObjectKey="color"
                        handleChange={(_, value) => {
                            optionallySelectAll(editor);
                            editor.chain().focus().setColor(value).run();
                        }}
                        tooltipMode={false}
                        closeOnSelect={true}
                    />
                </div>
            </div> */}
            </div>
        </div>
    );
};
