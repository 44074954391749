import { LayoutType } from "./LayoutType";
import { ContentBlockLayouts as layouts } from "./content-block-layouts";
import { LayoutConfig } from "./LayoutConfigInterface";
import { defaultLinkTheme } from "@/styles";
import { SapienInertia } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

export const LayoutConfigObject: {
    [index in LayoutType]: LayoutConfig;
} = {
    [LayoutType.Feature]: {
        typeKey: "Feature",
        type: LayoutType.Feature,
        layoutContentBlock: layouts.FeatureLayout,
    },
    [LayoutType["Card Deck"]]: {
        typeKey: "Card Deck" as keyof typeof LayoutType,
        type: LayoutType["Card Deck"],
        layoutContentBlock: layouts.CardDeckLayout,
        saveOverride(contentBlock, simulationSlug) {
            SapienInertia.post(
                "admin.design.store.card-deck",
                {
                    ...contentBlock,
                    theme: JSON.stringify({
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                    }),
                    position: "before",
                } as typeof LaravelRequestBodyShapes["admin.design.store.card-deck"],
                {
                    roundId: contentBlock.round_id,
                    simulationSlug,
                }
            );
        },
    },
    [LayoutType.Hero]: {
        typeKey: "Hero" as keyof typeof LayoutType,
        type: LayoutType.Hero,
        layoutContentBlock: layouts.HeroLayout,
    },
    [LayoutType["Hero 2"]]: {
        typeKey: "Hero 2" as keyof typeof LayoutType,
        type: LayoutType["Hero 2"],
        layoutContentBlock: layouts.Hero2Layout,
    },
    [LayoutType["Hero 3"]]: {
        typeKey: "Hero 3" as keyof typeof LayoutType,
        type: LayoutType["Hero 3"],
        layoutContentBlock: layouts.Hero3Layout,
    },
    [LayoutType.Accordion]: {
        typeKey: "Accordion" as keyof typeof LayoutType,
        type: LayoutType.Accordion,
        layoutContentBlock: layouts.AccordionLayout,
    },
    [LayoutType.Card]: {
        typeKey: "Card" as keyof typeof LayoutType,
        type: LayoutType.Card,
        layoutContentBlock: layouts.Card,
    },
    [LayoutType.Table]: {
        typeKey: "Table",
        type: LayoutType.Table,
        layoutContentBlock: layouts.TableLayout,
    },
    [LayoutType["Bottom Drawer"]]: {
        typeKey: "Bottom Drawer" as keyof typeof LayoutType,
        type: LayoutType["Bottom Drawer"],
        layoutContentBlock: layouts.BottomDrawerLayout,
    },
    [LayoutType.Modal]: {
        typeKey: "Modal" as keyof typeof LayoutType,
        type: LayoutType.Modal,
        layoutContentBlock: layouts.ModalLayout,
        saveOverride(modalBlock, simulationSlug, secondaryBlockData) {
            const secondary_content_block_id =
                secondaryBlockData?.blockTypeRequiringSecondary &&
                modalBlock.parent_content_block_id;
            const childContentBlockType =
                secondaryBlockData?.secondaryBlockType;

            SapienInertia.post(
                "admin.design.store.modal",
                {
                    ...modalBlock,
                    linkTheme: JSON.stringify(defaultLinkTheme),
                    position: "before",
                    secondary_content_block_id,
                    childContentBlockType,
                } as typeof LaravelRequestBodyShapes["admin.design.store.modal"],
                {
                    roundId: modalBlock.round_id,
                    simulationSlug,
                },
                {
                    only:['modalContentBlockId','modalComponent','savedBlock']
                }
            );
        },
        canHaveSecondaryBlock: true,
    },
    [LayoutType["Data Display Card"]]: {
        typeKey: "Data Display Card" as keyof typeof LayoutType,
        type: LayoutType["Data Display Card"],
        layoutContentBlock: layouts.DataDisplayCardLayout,
    },
    [LayoutType.Tabs]: {
        typeKey: "Tabs",
        type: LayoutType.Tabs,
        layoutContentBlock: layouts.TabsLayout,
    },
    [LayoutType.Carousel]: {
        typeKey: "Carousel",
        type: LayoutType.Carousel,
        layoutContentBlock: layouts.CarouselLayout,
    },
};
