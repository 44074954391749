import React, { useMemo } from "react";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { PromptShape, PromptType, SelectionShape } from "@/models";
import {
    defaultAccordionItemTheme,
    defaultAccordionsTheme,
    StyledAccordionHeader,
    StyledAccordionItem,
    StyledAccordions,
    StyledAccordionPanel,
} from "../Accordions";
import { StyledUniversalSection } from "../Universal";

type DecisionLogProps = ContentBlockEvents & {
    contentBlockId: string;
    selections: (SelectionShape & { prompt: PromptShape })[];
    theme: typeof defaultAccordionsTheme;
    userId: string;
    teamId: string;
};

const allowedPromptTypes = [
    PromptType["Numerical Input"],
    PromptType["Numerical Slider"],
    PromptType["Rank Order"],
    PromptType["Multiple Choice"],
    PromptType["Multiple Select"],
    PromptType["Long Text"],
    PromptType["Short Text"],
    PromptType["Drag and Drop"],
];

function TextDisplay({ selections }: { selections: SelectionShape[] }) {
    return (
        <div className="py-4 px-5 max-w-6xl">
            {selections.map((selection) => (
                <div
                    className="flex flex-col space-y-2 items-start p-1"
                    key={selection.id}
                >
                    <div className="font-bold pr-3">{selection.user_name}</div>
                    <div>{selection.string_value}</div>
                </div>
            ))}
        </div>
    );
}

function MultiSelectDisplay({ selections }: { selections: SelectionShape[] }) {
    const selectionsByUser = useMemo(() => {
        return selections.reduce(
            (carry: { [index: string]: SelectionShape[] }, selection) => {
                if (!carry[selection.user_name])
                    carry[selection.user_name] = [];
                return {
                    ...carry,
                    [selection.user_name]: [
                        ...carry[selection.user_name],
                        selection,
                    ].sort((a, b) => a.numerical_value - b.numerical_value),
                };
            },
            {}
        );
    }, [selections]);

    return (
        <div className="py-4 px-5 max-w-6xl">
            {Object.keys(selectionsByUser).map((userName) => (
                <div
                    className="flex flex-col py-2 w-full border-b last:border-b-0"
                    key={userName}
                >
                    <h3 className="font-bold border-b py-2">{userName}</h3>
                    {selectionsByUser[userName].map((selection) => (
                        <div
                            className="flex flex-row justify-between items-center p-1 w-full"
                            key={selection.id}
                        >
                            <div className="font-bold">
                                {selection.option_text}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

function MultipleChoiceDisplay({
    selections,
}: {
    selections: SelectionShape[];
}) {
    return (
        <div className="py-4 px-5 max-w-6xl">
            {selections.map((selection) => (
                <div
                    className="flex flex-row justify-between items-center p-1"
                    key={selection.id}
                >
                    <div className="font-bold pr-3">{selection.user_name}</div>
                    <div>{selection.option_text}</div>
                </div>
            ))}
        </div>
    );
}

function NumericDisplay({ selections }: { selections: SelectionShape[] }) {
    return (
        <div className="py-4 px-5 max-w-6xl">
            {selections.map((selection) => (
                <div
                    className="flex flex-row justify-between items-center p-1"
                    key={selection.id}
                >
                    <div className="font-bold">{selection.user_name}</div>
                    <div>{selection.numerical_value}</div>
                </div>
            ))}
        </div>
    );
}

function RankOrderDisplay({ selections }: { selections: SelectionShape[] }) {
    const selectionsByUser = useMemo(() => {
        return selections.reduce(
            (carry: { [index: string]: SelectionShape[] }, selection) => {
                if (!carry[selection.user_name])
                    carry[selection.user_name] = [];
                return {
                    ...carry,
                    [selection.user_name]: [
                        ...carry[selection.user_name],
                        selection,
                    ].sort((a, b) => a.numerical_value - b.numerical_value),
                };
            },
            {}
        );
    }, [selections]);

    return (
        <div className="py-4 px-5 max-w-6xl">
            {Object.keys(selectionsByUser).map((userName) => (
                <div
                    className="flex flex-col py-2 w-full border-b last:border-b-0"
                    key={userName}
                >
                    <h3 className="font-bold border-b py-2">{userName}</h3>
                    {selectionsByUser[userName].map((selection) => (
                        <div
                            className="flex flex-row justify-between items-center p-1 w-full"
                            key={selection.id}
                        >
                            <div className="font-bold">
                                {selection.option_text}
                            </div>
                            <div>{selection.numerical_value}</div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export const DecisionLogTheme = ({
    contentBlockId,
    selections,
    theme,
    onClick,
    onHover,
    isInEditorContext,
}: DecisionLogProps) => {
    //slider,rank,multiple

    const selectionsByPromptId: {
        [index: string]: (SelectionShape & { prompt: PromptShape })[];
    } = useMemo(() => {
        //mock selections for admin
        if (isInEditorContext) {
            return ["1"].reduce((carry, participantName) => {
                return {
                    ...carry,
                    [participantName]: [
                        {
                            id: participantName,
                            numerical_value: Math.random() * 100,
                            string_value: "",
                            is_selected: true,
                            prompt_text: "Lorem ispum prompt text...",
                            option_text: "Option 1",
                            option_id: "1",
                            prompt_id: "1",
                            simulation_id: "1",
                            team_id: participantName,
                            user_id: participantName,

                            //joined columns
                            team_name: participantName,
                            user_name: participantName,
                            content_block_id: "1",
                            prompt_type: PromptType["Multiple Choice"],
                            scope: "Participant",
                            round_title: "Round 1",
                            round_id: "round_1",
                            prompt: {
                                content: `Lorem ipsum prompt text...${participantName}`,
                            },
                        },
                    ],
                };
            }, {});
        }

        return selections.reduce((carry, selection) => {
            if (!carry[selection.prompt_id]) {
                carry[selection.prompt_id] = [];
            }

            return {
                ...carry,
                [selection.prompt_id]: [
                    ...carry[selection.prompt_id],
                    selection,
                ],
            };
        }, {});
    }, [selections, contentBlockId]);

    const filteredPromptsKeys = useMemo(() => {
        return Object.keys(selectionsByPromptId)
            .filter((key) =>
                allowedPromptTypes.includes(
                    selectionsByPromptId[key][0].prompt_type
                )
            )
            .sort(
                (a, b) =>
                    selectionsByPromptId[a][0].prompt_weight -
                    selectionsByPromptId[b][0].prompt_weight
            );
    }, [selectionsByPromptId]);

    const filteredPromptIdsGroupedByRoundId = useMemo(() => {
        return filteredPromptsKeys.reduce((carry, promptId) => {
            const roundId = selectionsByPromptId[promptId][0].round_id;

            if (!carry[roundId]) {
                carry[roundId] = [];
            }
            return {
                ...carry,
                [roundId]: [...carry[roundId], promptId],
            };
        }, {});
    }, [filteredPromptsKeys]);

    return (
        <StyledUniversalSection
            data-content-block-id={contentBlockId}
            position="relative"
            className="w-full space-y-2 flex flex-col"
            {...theme}
        >
            {Object.keys(filteredPromptIdsGroupedByRoundId)
                .sort((a, b) => {
                    return (
                        selectionsByPromptId[
                            filteredPromptIdsGroupedByRoundId[a][0]
                        ][0].round_weight -
                        selectionsByPromptId[
                            filteredPromptIdsGroupedByRoundId[b][0]
                        ][0].round_weight
                    );
                })
                .map((roundId, idx) => (
                    <div className="p-4 flex flex-col" key={roundId}>
                        <h2
                            className={`p-4 font-bold text-lg lg:text-xl capitalize ${
                                theme.type === "ghost" ? "border-b" : "border-0"
                            }`}
                        >
                            {selectionsByPromptId[
                                filteredPromptIdsGroupedByRoundId[roundId][0]
                            ][0]?.round_title || `Round ${idx + 1}`}
                        </h2>
                        <StyledAccordions
                            theme={theme}
                            activeAccordionKeys={[]}
                            contentBlockId={contentBlockId}
                            isInDesignContext={false}
                            isSelected={false}
                        >
                            {filteredPromptIdsGroupedByRoundId[roundId].map(
                                (promptId) => {
                                    const promptType =
                                        selectionsByPromptId[promptId][0]
                                            .prompt_type;

                                    return (
                                        <StyledAccordionItem
                                            theme={defaultAccordionItemTheme}
                                            isDisabled={false}
                                            contentBlockId={promptId}
                                            key={promptId}
                                        >
                                            <StyledAccordionHeader
                                                parentContentBlockId={promptId}
                                                theme={undefined}
                                                contentBlockId={promptId}
                                                dontScroll={true}
                                            >
                                                <h4>
                                                    {
                                                        selectionsByPromptId[
                                                            promptId
                                                        ][0]?.prompt?.content
                                                    }
                                                </h4>
                                            </StyledAccordionHeader>
                                            <StyledAccordionPanel
                                                theme={
                                                    defaultAccordionItemTheme
                                                }
                                                contentBlockId={promptId}
                                                parentContentBlockId={promptId}
                                            >
                                                {promptType
                                                    .toLowerCase()
                                                    .includes("numerical") && (
                                                    <NumericDisplay
                                                        selections={
                                                            selectionsByPromptId[
                                                                promptId
                                                            ]
                                                        }
                                                    />
                                                )}
                                                {promptType ===
                                                    PromptType[
                                                        "Rank Order"
                                                    ] && (
                                                    <RankOrderDisplay
                                                        selections={
                                                            selectionsByPromptId[
                                                                promptId
                                                            ]
                                                        }
                                                    />
                                                )}
                                                {promptType ===
                                                    PromptType[
                                                        "Multiple Choice"
                                                    ] && (
                                                    <MultipleChoiceDisplay
                                                        selections={
                                                            selectionsByPromptId[
                                                                promptId
                                                            ]
                                                        }
                                                    />
                                                )}
                                                {(promptType ===
                                                    PromptType[
                                                        "Multiple Select"
                                                    ] ||
                                                    promptType ===
                                                        PromptType[
                                                            "Drag and Drop"
                                                        ]) && (
                                                    <MultiSelectDisplay
                                                        selections={
                                                            selectionsByPromptId[
                                                                promptId
                                                            ]
                                                        }
                                                    />
                                                )}

                                                {(promptType ===
                                                    PromptType["Short Text"] ||
                                                    promptType ===
                                                        PromptType[
                                                            "Long Text"
                                                        ]) && (
                                                    <TextDisplay
                                                        selections={
                                                            selectionsByPromptId[
                                                                promptId
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </StyledAccordionPanel>
                                        </StyledAccordionItem>
                                    );
                                }
                            )}
                        </StyledAccordions>
                    </div>
                ))}
        </StyledUniversalSection>
    );
};
// <pre>
//     {JSON.stringify(
//         selectionsByPromptId[promptText],
//         null,
//         4
//     )}
// </pre>
