import React, { useCallback } from "react";
import { ContentBlockShape, RichText } from "@/models";
import { cn } from "@/util";
import LessonRichText from "../../LessonRichText";
import {
    TextType,
    LineHeight,
    TextStyleExtended,
    Indent,
} from "@/modules/text-editor";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { FlipHorizontal, RefreshCcw } from "lucide-react";
import { useDebouncedEditor } from "@/modules/text-editor/useDebouncedEditor";

type Props = {
    children: React.ReactNode | React.ReactNode[];
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
};

export default function FlipCardSide({ contentBlock }: Props) {
    const variant: "text" | "image-full" | "image-top" =
        contentBlock.theme?.variant || "text";

    const styles =
        variant === "image-full"
            ? {
                  backgroundImage: `url(${contentBlock.image_url})`,
              }
            : {};

    //trigger the rich text to update when the block is saved
    const dummySave = useCallback(
        (json: RichText) => {},
        [contentBlock.updated_at],
    );
    return (
        <div
            className={`absolute flex h-full w-full flex-col items-center justify-center backface-hidden
            ${cn(contentBlock.theme.tailwindClasses)} group overflow-hidden rounded-lg
            bg-cover bg-center bg-no-repeat`}
            style={styles}
        >
            {variant === "image-top" ? (
                <div className="flex h-full w-full flex-col items-center justify-start gap-2">
                    <img
                        src={contentBlock.image_url}
                        alt={contentBlock.title}
                        className="flex w-full self-start justify-self-start object-cover"
                    />
                    <div className=" px-4 py-2">
                        <LessonRichText
                            contentBlock={contentBlock}
                            editable={false}
                            content={contentBlock.rich_text}
                            saveOverride={dummySave}
                        />
                    </div>
                </div>
            ) : (
                <div className=" px-4 py-2">
                    <LessonRichText
                        contentBlock={contentBlock}
                        editable={false}
                        content={contentBlock.rich_text}
                        saveOverride={dummySave}
                    />
                </div>
            )}
            <div
                className="absolute bottom-0 right-0 p-2 text-slate-500 transition-all duration-500
                    group-hover:scale-125"
            >
                <RefreshCcw className="h-6 w-6" />
            </div>
        </div>
    );
}
