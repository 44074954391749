import { SapienInertia } from "@/inertia-utils/hooks";
import { ContentBlockType } from "@/models";
import { defaultLinkTheme, defaultInboxMessageTheme } from "@/styles";
import { ConfigComponentType } from "@/styles/ThemeObjects";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { PageConfig } from "./PageConfig";
import { saveModal } from "@/Pages/Admin/editor/content-block-buttons/add-modal-button/saveModal";
import { buildCreateModalRequestBody } from "@/Pages/Admin/editor/content-block-buttons/add-modal-button/useSaveModal";

export const InboxMessageConfig: ContentBlockConfig = {
    typeKey: "Inbox Message",
    type: ContentBlockType["Inbox Message"],
    defaultTheme: defaultInboxMessageTheme,
    isCollectionType: true,
    disallowAdd: true,
    addableChildBlocks: PageConfig.addableChildBlocks,
    saveOverride: async (modalBlock, simulationSlug) => {
        const body = buildCreateModalRequestBody(
            modalBlock,
            ContentBlockType["Inbox Notification"],
        );
        body.parent_content_block_id = modalBlock.parent_content_block_id;
        console.log("modalblock", body,modalBlock)

        const block = await saveModal({
            selectedSimulation: { slug: simulationSlug },
            body,
        });
        return block
    },
    variantOptions: [
        {
            label: "Size",
            themeObjectKey: "modalSize",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                sm: "sm",
                md: "md",
                lg: "lg",
                xl: "xl",
                full: "full",
            },
        },
    ],
};
