import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubmissionCount } from "@/inertia-utils/types";

type SubmissionCountSliceState = {
    submissionCount: { [index: string]: SubmissionCount };
};

const initialState: SubmissionCountSliceState = {
    submissionCount: {},
};

const submissionCountSlice = createSlice({
    name: "SubmissionCountSliceState",
    initialState,
    reducers: {
        gotSubmissionCounts(
            state,
            { payload }: PayloadAction<{ [index: string]: SubmissionCount }>
        ) {
            state.submissionCount = payload;
        },
    },
});

export const submissionCountReducer = submissionCountSlice.reducer;

// export type RootState = ReturnType<typeof submissionCountReducer>;

export const { gotSubmissionCounts } = submissionCountSlice.actions;
