import React, { FC, ReactNode } from "react";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";

type StyledPageProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId: string;
};

export const StyledPage: FC<StyledPageProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    isInEditorContext,
    title,
}) => {
    let navBarHeight = 0;
    const participantTopNav = document.querySelector(".participant-top-nav");
    const navBar = document.querySelector(".navbar");
    const editorBar = document.querySelector(".EDITOR-NAV-MENU");
    if (navBar || participantTopNav || editorBar) {
        navBarHeight = [navBar, participantTopNav, editorBar].reduce(
            (carry, element) => {
                if (element) {
                    return carry + element.getBoundingClientRect().height;
                }
                return carry;
            },
            0
        );
    }

    return (
        <StyledUniversalDiv
            width={"100%"}
            //see editorSizes in EditorContainer.tsx
            color={theme.color}
            minHeight={`calc(100vh - ${navBarHeight}px)`}
            backgroundColor={theme.backgroundColor}
            pt={"2em"}
            pb={"2em"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Page"}
        >
            <StyledUniversalDiv {...theme}>{children}</StyledUniversalDiv>
        </StyledUniversalDiv>
    );
};

export const defaultPageTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff",
    pt: 0,
    pb: 0,
    px: 0,
    my: 0,
    mx: 0,
};
