import { SubmissionCount } from "@/inertia-utils/types";
import React, { useMemo } from "react";
import { ThemeObject } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";

type SubmissionCountProps = ContentBlockEvents & {
    theme: typeof defaultSubmissionCountTheme;
    submissionCount: SubmissionCount;
    contentBlockId: string;
    children: JSX.Element;
};

export const SubmissionCountTheme = ({
    onClick,
    onHover,
    theme,
    submissionCount,
    children,
    contentBlockId,
}: SubmissionCountProps) => {
    const color = useMemo(() => {
        if (
            submissionCount?.selection_count ===
            submissionCount.participant_count
        ) {
            if (!theme.colorWhenComplete) {
                return theme.colorWhenLessThanHalfComplete || "#000000ff";
            }
            return theme.colorWhenComplete;
        }

        if (
            submissionCount?.selection_count /
                submissionCount.selection_count >=
            0.5
        ) {
            if (!theme.colorWhenHalfComplete) {
                return theme.colorWhenLessThanHalfComplete || "#000000ff";
            }
            return theme.colorWhenHalfComplete;
        }

        return theme.colorWhenLessThanHalfComplete || "#000000ff";
    }, [theme]);

    return (
        <StyledUniversalDiv
            {...theme}
            data-content-block-id={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            <span style={{ color }}>
                {submissionCount?.selection_count} of{" "}
                {submissionCount?.participant_count}
            </span>{" "}
            <span
                style={{
                    color: theme.colorWhenLessThanHalfComplete || "#000000ff",
                }}
            >
                {children}{" "}
            </span>
        </StyledUniversalDiv>
    );
};

export const defaultSubmissionCountTheme: Partial<ThemeObject> = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    colorWhenComplete: "##009A44ff",
    colorWhenHalfComplete: "#ED8B00ff",
    colorWhenLessThanHalfComplete: "#000000ff",
    fontSize: 3,
    fontWeight: 4,
};
