import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColorGroup, Color } from "../models";
import { mapById } from "./util";

type ColorSliceState = {
    colorGroups: { [index: string]: ColorGroup };
    colors: { [index: string]: Color };
    color: Color | undefined;
    colorGroup: ColorGroup | undefined;
};

const initialState: ColorSliceState = {
    colorGroups: {},
    colors: {},
    color: undefined,
    colorGroup: undefined,
};

const ColorSlice = createSlice({
    name: "ColorStore",
    initialState,
    reducers: {
        ColorsQueried(state, { payload }: PayloadAction<ColorGroup[]>) {
            state.colors = payload.reduce((carry, colorGroup) => {
                return { ...carry, ...mapById(colorGroup.colors) };
            }, {});
            state.colorGroups = mapById(payload);
        },
        SetColorGroup(state, { payload }: PayloadAction<ColorGroup>) {
            state.colorGroup = payload;
        },
        SetColor(state, { payload }: PayloadAction<Color>) {
            state.color = payload;
        },
    },
});

export const colorStoreReducer = ColorSlice.reducer;

const { ColorsQueried, SetColorGroup, SetColor } = ColorSlice.actions;

export { SetColorGroup, SetColor, ColorsQueried };

// export const saveColor = (Color: Color) => {
//     return async (dispatch: AppDispatch) => {
//         try {
//             const savedColor = await SaveColor(Color);
//             if ((savedColor as Color)?.id) {
//                 dispatch(gotSavedColor(savedColor as Color));
//             }
//         } catch (error) {}
//     };
// };
