// import React, { FC, ReactNode } from "react";
// import { StyledUniversalDiv } from "./themes";
import {
    //TextTransform,
    ThemeObject,
} from "./ThemeObjects";
import { SimColors } from "../models";
import { CSSProperties } from "react";

export const TypographyType = {
    "Heading 1": "HEADING_1" as const,
    "Heading 2": "HEADING_2" as const,
    "Heading 3": "HEADING_3" as const,
    "Heading 3 Alt": "HEADING_3_ALT" as const,
    "Heading 4": "HEADING_4" as const,
    "Heading 4 Alt": "HEADING_4_ALT" as const,
    "Heading 5": "HEADING_5" as const,
    "Heading 5 Alt": "HEADING_5_ALT" as const,
    "Heading 6": "HEADING_6" as const,
    "Heading 6 Alt": "HEADING_6_ALT" as const,
    Subtitle: "SUBTITLE" as const,
    "Subtitle Alt": "SUBTITLE_ALT" as const,
    Paragraph: "PARAGRAPH" as const,
    "Paragraph Alt": "PARAGRAPH_ALT" as const,
    "Paragraph Small": "PARAGRAPH_SMALL" as const,
    Small: "SMALL" as const,
    "Button Span": "BUTTON_SPAN" as const,
    "Button Span Alt": "BUTTON_SPAN_ALT" as const,
    Caption: "CAPTION" as const,
    "Caption Alt": "CAPTION_ALT" as const,
    Overline: "OVERLINE" as const,
    "Overline Alt": "OVERLINE_ALT" as const,
    "Figma Heading 1": "FIGMA_HEADING_1" as const,
    "Figma Heading 2": "FIGMA_HEADING_2" as const,
    "Figma Heading 3": "FIGMA_HEADING_3" as const,
    "Figma Subtitle": "FIGMA_SUBTITLE" as const,
    "Figma Paragraph": "FIGMA_PARAGRAPH" as const,
    "Figma Paragraph Alt": "FIGMA_PARAGRAPH_ALT" as const,
    "Text Stacked Card": "TEXT_STACKED_CARD" as const,
} as const;

export type TypographyTypes =
    (typeof TypographyType)[keyof typeof TypographyType];
export type TypographyTypeValues =
    (typeof TypographyType)[keyof typeof TypographyType];

// interface ColorPalette {
//     color: string;
//     backgroundColor: string;
//     borderColor: string;
//     hoverColor?: string;
//     hoverBackgroundColor?: string;
//     hoverBorderColor?: string;
// }

// shape of simulation theme property
interface SimulationTheme {
    colors: { [index: string]: string };
    typography: { [index in TypographyTypes]: Partial<ThemeObject> };
    palettes: SimColors;
}

// default typography styles for simulation theme
const defaultTypographyStyles: {
    [index in TypographyTypes]: Partial<ThemeObject>;
} = {
    [TypographyType["Heading 1"]]: {
        fontSize: [68, 84, 92, 96],
        fontWeight: "lighter",
        letterSpacing: "-1.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 2"]]: {
        fontSize: [60, 60, 60, 60],
        fontWeight: "lighter",
        letterSpacing: "-0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 3"]]: {
        fontSize: [48, 48, 48, 48],
        fontWeight: "normal",
        letterSpacing: "0px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 3 Alt"]]: {
        fontSize: [48, 48, 48, 48],
        fontWeight: "lighter",
        letterSpacing: "-0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 4"]]: {
        fontSize: [34, 34, 34, 34],
        fontWeight: "normal",
        letterSpacing: "0.25px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 4 Alt"]]: {
        fontSize: [34, 34, 34, 34],
        fontWeight: "lighter",
        letterSpacing: "-0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 5"]]: {
        fontSize: [24, 24, 24, 24],
        fontWeight: "normal",
        letterSpacing: "0px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 5 Alt"]]: {
        fontSize: [24, 24, 24, 24],
        fontWeight: "lighter",
        letterSpacing: "-0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 6"]]: {
        fontSize: [20, 20, 20, 20],
        fontWeight: 600,
        letterSpacing: "0.15px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Heading 6 Alt"]]: {
        fontSize: [20, 20, 20, 20],
        fontWeight: 250,
        letterSpacing: "-0.25px",
        // textTransform: TextTransform.none
    },
    [TypographyType.Subtitle]: {
        fontSize: [16, 16, 16, 16],
        fontWeight: "normal",
        letterSpacing: "0.15px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Subtitle Alt"]]: {
        fontSize: [14, 14, 14, 14],
        fontWeight: 500,
        letterSpacing: "0.1px",
        // textTransform: TextTransform.none
    },
    [TypographyType.Paragraph]: {
        fontSize: [16, 16, 16, 16],
        fontWeight: "normal",
        letterSpacing: "0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Paragraph Alt"]]: {
        fontSize: [14, 14, 14, 14],
        fontWeight: "normal",
        letterSpacing: "0.25px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Paragraph Small"]]: {
        fontSize: [12, 12, 12, 12],
        fontWeight: "normal",
        letterSpacing: "0.25px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Button Span"]]: {
        fontSize: [14, 14, 14, 14],
        fontWeight: 500,
        letterSpacing: "1.25px",
        // textTransform: TextTransform.uppercase
    },
    [TypographyType["Button Span Alt"]]: {
        fontSize: [14, 14, 14, 14],
        fontWeight: "lighter",
        letterSpacing: "0.5px",
        // textTransform: TextTransform.uppercase
    },
    [TypographyType.Caption]: {
        fontSize: [12, 12, 12, 12],
        fontWeight: "normal",
        letterSpacing: "0.4px",
        // textTransform: TextTransform.none
    },
    [TypographyType["Caption Alt"]]: {
        fontSize: [12, 12, 12, 12],
        fontWeight: 500,
        letterSpacing: "0.5px",
        // textTransform: TextTransform.none
    },
    [TypographyType.Overline]: {
        fontSize: [10, 10, 10, 10],
        fontWeight: "normal",
        letterSpacing: "1.5px",
        // textTransform: TextTransform.uppercase
    },
    [TypographyType["Overline Alt"]]: {
        fontSize: [9, 9, 9, 9],
        fontWeight: 700,
        letterSpacing: "0.5px",
        // textTransform: TextTransform.uppercase
    },
    //STYLES FROM UI KIT FIGMA (https://www.figma.com/file/D8QXTwkMMrQd6uONi6zmNo/NanoSim-UI-Kit?node-id=100%3A5753)
    [TypographyType["Figma Heading 1"]]: {
        //what other sizes?
        fontSize: [64, 68, 76, 80],
        fontWeight: 700,
        lineHeight: "112%",
    },
    [TypographyType["Figma Heading 2"]]: {
        //what other sizes?
        fontSize: [56],
        fontWeight: 700,
        lineHeight: "112%",
    },
    [TypographyType["Figma Heading 3"]]: {
        //what other sizes?
        fontSize: [48],
        fontWeight: 700,
        lineHeight: "110%",
    },
    [TypographyType["Figma Paragraph"]]: {
        //what other sizes?
        fontSize: [16],
        fontWeight: 600,
        lineHeight: "150%",
    },
    [TypographyType["Figma Paragraph Alt"]]: {
        //what other sizes?
        fontSize: [20],
        fontWeight: 400,
        lineHeight: "150%",
    },
    [TypographyType["Figma Subtitle"]]: {
        //what other sizes?
        fontSize: [24],
        fontWeight: 400,
        lineHeight: "150%",
    },
    [TypographyType["Figma Subtitle Alt"]]: {},
    [TypographyType["Text Stacked Card"]]: {
        //what other sizes?
        fontSize: [14],
        fontWeight: 400,
        lineHeight: "150%",
    },
    [TypographyType.Small]: {
        fontSize: [12],
        fontWeight: 400,
    },
};

enum TypographyTag {
    "h1" = "h1",
    "h2" = "h2",
    "h3" = "h3",
    "h4" = "h4",
    "h5" = "h5",
    "h6" = "h6",
    "p" = "p",
}

export const typographyProps: {
    [index in TypographyTypes]: {
        displayName: string;
        asTag: TypographyTag;
    };
} = {
    [TypographyType["Heading 1"]]: {
        displayName: "H1",
        asTag: TypographyTag.h1,
    },
    [TypographyType["Heading 2"]]: {
        displayName: "H2",
        asTag: TypographyTag.h2,
    },
    [TypographyType["Heading 3"]]: {
        displayName: "H3",
        asTag: TypographyTag.h3,
    },
    [TypographyType["Heading 3 Alt"]]: {
        displayName: "H3Alt",
        asTag: TypographyTag.h3,
    },
    [TypographyType["Heading 4"]]: {
        displayName: "H4",
        asTag: TypographyTag.h4,
    },
    [TypographyType["Heading 4 Alt"]]: {
        displayName: "H4Alt",
        asTag: TypographyTag.h4,
    },
    [TypographyType["Heading 5"]]: {
        displayName: "H5",
        asTag: TypographyTag.h5,
    },
    [TypographyType["Heading 5 Alt"]]: {
        displayName: "H5Alt",
        asTag: TypographyTag.h5,
    },
    [TypographyType["Heading 6"]]: {
        displayName: "H6",
        asTag: TypographyTag.h6,
    },
    [TypographyType["Heading 6 Alt"]]: {
        displayName: "H6Alt",
        asTag: TypographyTag.h6,
    },
    [TypographyType.Subtitle]: {
        displayName: "Subtitle",
        asTag: TypographyTag.p,
    },
    [TypographyType["Subtitle Alt"]]: {
        displayName: "SubtitleAlt",
        asTag: TypographyTag.p,
    },
    [TypographyType.Paragraph]: {
        displayName: "Paragraph",
        asTag: TypographyTag.p,
    },
    [TypographyType["Paragraph Small"]]: {
        displayName: "ParagraphSmall",
        asTag: TypographyTag.p,
    },
    [TypographyType["Paragraph Alt"]]: {
        displayName: "ParagraphAlt",
        asTag: TypographyTag.p,
    },
    [TypographyType["Button Span"]]: {
        displayName: "ButtonSpan",
        asTag: TypographyTag.p,
    },
    [TypographyType["Button Span Alt"]]: {
        displayName: "ButtonSpanAlt",
        asTag: TypographyTag.p,
    },
    [TypographyType.Caption]: {
        displayName: "Caption",
        asTag: TypographyTag.p,
    },
    [TypographyType["Caption Alt"]]: {
        displayName: "CaptionAlt",
        asTag: TypographyTag.p,
    },
    [TypographyType.Overline]: {
        displayName: "Overline",
        asTag: TypographyTag.p,
    },
    [TypographyType["Overline Alt"]]: {
        displayName: "OverlineAlt",
        asTag: TypographyTag.p,
    },
    //STYLES FROM UI KIT FIGMA (https://www.figma.com/file/D8QXTwkMMrQd6uONi6zmNo/NanoSim-UI-Kit?node-id=100%3A5753)
    [TypographyType["Figma Heading 1"]]: {
        displayName: "UI Kit Heading 1",
        asTag: TypographyTag.h1,
    },
    [TypographyType["Figma Heading 2"]]: {
        displayName: "UI Kit Heading 2",
        asTag: TypographyTag.h2,
    },
    [TypographyType["Figma Heading 3"]]: {
        displayName: "UI Kit Heading 3",
        asTag: TypographyTag.h3,
    },
    [TypographyType["Figma Paragraph"]]: {
        displayName: "UI Kit Paragraph",
        asTag: TypographyTag.p,
    },
    [TypographyType["Figma Paragraph Alt"]]: {
        displayName: "UI Kit Paragraph Alt",
        asTag: TypographyTag.p,
    },
    [TypographyType["Figma Subtitle"]]: {
        displayName: "UI Kit Subtitle",
        asTag: TypographyTag.h4,
    },
    [TypographyType["Figma Subtitle Alt"]]: {
        displayName: "UI Kit Subtitle Alt",
        asTag: TypographyTag.h4,
    },
    [TypographyType["Text Stacked Card"]]: {
        //what other sizes?
        displayName: "Card Paragraph",
        asTag: TypographyTag.p,
    },
    [TypographyType["Small"]]: {
        //what other sizes?
        displayName: "Small Paragraph",
        asTag: TypographyTag.p,
    },
};

// interface GeneratedComponentProps {
//     theme: Partial<ThemeObject>;
//     children?: ReactNode;
// }

// function to generate styled functional components
// with theme, display name, and as tag
// const createComponent: (
//     displayName: string,
//     asTag: keyof typeof TypographyTag
// ) => FC<GeneratedComponentProps> = (displayName, asTag) => {
//     const component: FC<GeneratedComponentProps> = ({ theme, children }) => (
//         <StyledUniversalDiv {...theme} as={asTag}>
//             {children}
//         </StyledUniversalDiv>
//     );
//     component.displayName = displayName;
//     return component;
// };

// example generation of typography component
// const TypographyComponents: {
//     [index in TypographyType]?: FC<GeneratedComponentProps>;
// } = Object.values(TypographyType).reduce((map, type) => {
//     return {
//         ...map,
//         [type]: createComponent(
//             typographyProps[type].displayName,
//             typographyProps[type].asTag
//         )
//     };
// }, {});

// export const getTypographyComponent = (type: TypographyType) =>
//     TypographyComponents[type];

export const getTypographyStyle = (
    type: TypographyTypes,
    styles: CSSProperties = {}
) => ({ ...defaultTypographyStyles[type], ...styles });

const defaultColors = {
    white: "rgba(255, 255, 255, 1)",
    black: "rgba(0, 0, 0, 1)",
    transparent: "rgba(255, 255, 255, 0)",
    primary: "rgba(36, 27, 91, 1)",
    secondary: "rgba(79, 28, 31, 1)",
    tertiary: "rgba(96, 96, 41, 1)",
    accentDark: "rgba(15, 74, 29, 1)",
    accentLight: "rgba(197, 203, 227, 1)",
};

const defaultSimColors: SimColors = {
    dark: {
        backgroundColor: "#1f2a37",
        textColor: "#9ca3af",
        borderColor: "#6b7280",
        highlightBackgroundColor: "#374151",
        highlightColor: "#d1d5db",
    },
    light: {
        backgroundColor: "#fff",
        textColor: "#4b5563",
        borderColor: "#e5e7eb",
        highlightBackgroundColor: "#f3f4f6",
        highlightColor: "#1f2a37",
    },
};

export const defaultSimulationTheme: SimulationTheme = {
    colors: defaultColors,
    typography: defaultTypographyStyles,
    palettes: defaultSimColors,
};
