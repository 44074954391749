import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React, { FormEvent } from "react";
import LessonAccordion from "./accordion/LessonAccordion";

type Props = {
    contentBlock: ContentBlockShape;
    children: React.ReactNode | React.ReactNode[];
    isInAdminContext: boolean;
};

export default function LessonSection({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {
    const tailwindClasses = contentBlock.theme?.tailwindClasses || [];
    return (
        <section
            className={`flex w-full items-center justify-center transition-all
                has-[.cursor-wait]:animate-pulse`}
            data-content-block-id={contentBlock.id}
            style={{
                backgroundColor: contentBlock.theme?.backgroundColor,
            }}
            data-test-weight-and-type={`${contentBlock.weight}_${contentBlock.content_block_type}`}
        >
            <div
                style={{ backgroundImage: contentBlock.theme?.backgroundImage }}
                className={`transition-all ${cn([
                    "w-full",
                    "max-w-3xl",
                    "lg:max-w-4xl",
                    ...tailwindClasses,
                ])}`}
            >
                {children}
            </div>
        </section>
    );
}
