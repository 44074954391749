import React from "react";
import { ContentBlockShape } from "@/models";
import { SaveBehaviorBlockRequestBody } from "./saveBehaviorBlock";
import { useAddContentBlockState } from "../useAddContentBlockState";
import { generateContentBlockToSave } from "@/hooks/useConfigSelector/util";
import { useCreateBehaviorContentBlock } from "./useCreateBehaviorContentBlock";

type Props = {
    contentBlock: ContentBlockShape;
    depth: number;
};

export default function AddBehaviorContentBlockButton({
    contentBlock,
    depth,
}: Props) {
    const { addContentBlockState } = useAddContentBlockState();

    const { mutate } = useCreateBehaviorContentBlock();

    return (
        <button
            className="absolute add-content-block-button inset-0 flex h-full w-full items-center justify-center text-2xl text-white"
            aria-label={`Add Content Block With Behaviors to ${contentBlock.content_block_type} ${contentBlock.id}`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const behaviorBlock = {
                    ...generateContentBlockToSave(
                        contentBlock,
                        "",
                        contentBlock.contentBlocks?.length || 0,
                        addContentBlockState.addableContentBlockMetaData,
                    ),
                    id: "", // Add the required "id" property
                    position: "after",
                    parent_content_block_id: contentBlock.id,
                } as SaveBehaviorBlockRequestBody;
                mutate(behaviorBlock);
            }}
            style={{ zIndex: depth * 10 }}
        >
            <span className="sr-only">
                Add Content Block With Behaviors to {contentBlock.content_block_type} {contentBlock.id}
            </span>
        </button>
    );
}
