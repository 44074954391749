import { createContext } from "react";
import { AccordionVariant } from "./AccordionsTheme";

export const accordionContext = createContext<{
    activeAccordionKeys?: string[];
    hoverColor?: string;
    hoverBackgroundColor?: string;
    variant?: AccordionVariant;
    setActiveItemIndices?: (item: number | string) => void;
}>({});
