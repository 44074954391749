import { groupBy } from "lodash";
import {
    ContentBlock,
    ContentBlockType,
    ModelVariableUnit,
    TableCell,
    TableData,
    TableRow,
    VariableValue,
} from "../models";

// TODO: write formatting helper functions (see VariableHelpers.ts)
const formatNumericalModelVariableValue = (
    unitType: ModelVariableUnit,
    // consider is_integer variable property too
    value: number
): string => {
    let formattedEntry = `${value.toString()}`; // "";
    switch (unitType) {
        case ModelVariableUnit.Dollars:
            // formattedEntry = currencyFormat(value);
            break;
        case ModelVariableUnit.Percent:
            // formattedEntry = percentFormat(value);
            break;
        case ModelVariableUnit.Custom:
            // formattedEntry = customFormat(value);
            break;
        case ModelVariableUnit.None:
            // formattedEntry = noneFormat(value);
            break;
        default:
            formattedEntry = "";
    }
    return formattedEntry;
};

// TODO: handle cases where value has no TH
export type VariableValueMap = {
    [modelVariableId: string]: {
        [timeHorizonId: string]: VariableValue;
    };
};

export const createVariableValueMapFromArray = (
    variableValueArray: VariableValue[]
) => {
    let valuesGroupedByModelVariableId = groupBy(
        variableValueArray,
        "model_variable_id"
    );
    return Object.keys(valuesGroupedByModelVariableId).reduce(
        (mapByModelVariableId, variableId) => ({
            ...mapByModelVariableId,
            [variableId]: valuesGroupedByModelVariableId[variableId].reduce(
                (mapByTimeHorizonId, variableValue) => ({
                    ...mapByTimeHorizonId,
                    [variableValue.time_horizon_id]: variableValue,
                }),
                {}
            ),
        }),
        {}
    );
};

export const getTableDataFromContentBlock = (
    contentBlock: ContentBlock,
    variableValueMap: VariableValueMap
): TableData => {
    let tableBody: TableRow[] = [];
    let tableHead: TableRow[] = [];

    if (
        contentBlock &&
        contentBlock.content_block_type === ContentBlockType.Table &&
        contentBlock.contentBlockModelDataSources &&
        contentBlock.contentBlockModelDataSources.length &&
        variableValueMap &&
        Object.keys(variableValueMap).length
    ) {
        tableBody = contentBlock.contentBlockModelDataSources.map(
            (contentBlockModelDataSource, index) => {
                let headerCell: TableCell = {
                    content:
                        contentBlockModelDataSource.modelVariable?.label ||
                        contentBlockModelDataSource.id,
                    isHeaderCell: true,
                };
                let dataCells: TableCell[] =
                    contentBlockModelDataSource.timeHorizons?.map(
                        (timeHorizon) => {
                            let variableValue =
                                variableValueMap[
                                    contentBlockModelDataSource
                                        .model_variable_id
                                ][timeHorizon.id];
                            return {
                                content: formatNumericalModelVariableValue(
                                    contentBlockModelDataSource.modelVariable
                                        ?.unit,
                                    variableValue.numerical_value
                                ),
                            };
                        }
                    );
                return { tableCells: [headerCell, ...dataCells] };
            }
        );

        // TODO: formatting, conditional display (e.g., table props for headers, TH style...)
        tableHead = [
            {
                tableCells: [
                    {
                        content: "Time Horizon",
                        isHeaderCell: true,
                    },
                    ...contentBlock.contentBlockModelDataSources[0].timeHorizons.map(
                        (timeHorizon) => {
                            return {
                                content: timeHorizon.time_index.toString(),
                            };
                        }
                    ),
                ],
            },
        ];
    }

    return { tableHead, tableBody };
};
