import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeHorizon } from "../models";
import { mapById } from "./util";

type TimeHorizonSliceState = {
    timeHorizons: { [index: string]: TimeHorizon };
};

const initialState: TimeHorizonSliceState = {
    timeHorizons: {},
};

const timehorizonDataSlice = createSlice({
    name: "TimeHorizonStore",
    initialState,
    reducers: {
        timeHorizonsQueried(state, { payload }: PayloadAction<TimeHorizon[]>) {
            state.timeHorizons = mapById(payload);
        },
    },
});

const timehorizonStoreReducer = timehorizonDataSlice.reducer;

const { timeHorizonsQueried } = timehorizonDataSlice.actions;

export { timeHorizonsQueried as gotTimeHorizons };

export { timehorizonStoreReducer };
