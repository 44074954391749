import { ContentBlockType } from "../../models";
import {
    ConfigComponentType,
    defaultBoxTheme,
    defaultTabsTheme,
} from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { tabBox } from "../content-block-layouts/TabsLayout";

export const TabsConfig: ContentBlockConfig = {
    typeKey: "Tabs",
    type: ContentBlockType.Tabs,
    requiredChildBlocks: [ContentBlockType.Box],
    addableChildBlocks: [ContentBlockType.Box],
    defaultTheme: defaultTabsTheme,
    isCollectionType: true,
    variantOptions: [
        {
            label: "Tab Style",
            themeObjectKey: "tabStyle",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                underline: "underline",
                pill: "pill",
                button: "button",
            },
        },
    ],
    onClickAdd: (parentContentBlock) => {
        const tab = tabBox(parentContentBlock.contentBlocks.length + 1);
        tab.parent_content_block_id = parentContentBlock.id;
        tab.round_id = parentContentBlock.round_id;
        return tab;

    },
};
