import React, { FC, ReactNode } from "react";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";

interface StyledTextProps {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
}

export const StyledText: FC<StyledTextProps> = ({
    theme,
    children,
    contentBlockId,
}) => {
    return (
        <StyledUniversalDiv
            width={1}
            textAlign={theme.textAlign}
            data-content-block-id={contentBlockId}
        >
            <StyledUniversalDiv {...theme}>{children}</StyledUniversalDiv>
        </StyledUniversalDiv>
    );
};

export const defaultTextTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff00",
    opacity: 1,
    pt: 5,
    pr: 3,
    pb: 5,
    pl: 3,
    mt: 4,
    mr: 4,
    mb: 4,
    ml: 4,
};
