import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SimulationShape } from "../models";

type SimVariantSliceState = {
    simVariants: { [index: string]: SimulationShape[] };
    variantSimulation: SimulationShape | null;
    selectedVariantIndex: number;
};

const initialState: SimVariantSliceState = {
    simVariants: {},
    variantSimulation: null,
    selectedVariantIndex: -1,
};

const simVariantSlice = createSlice({
    name: "SimVariantState",
    initialState,
    reducers: {
        addSimVariant(state, { payload }: PayloadAction<SimulationShape>) {
            if (!state.simVariants[payload.id]) {
                state.simVariants[payload.id] = [payload];
            } else {
                state.simVariants[payload.id] = [
                    ...state.simVariants[payload.id],
                    payload,
                ];
            }
        },
        setVariantSimulation(
            state,
            { payload }: PayloadAction<SimulationShape>
        ) {
            state.variantSimulation = payload;
        },
        setSelectedVariantIndex(state, { payload }: PayloadAction<number>) {
            state.selectedVariantIndex = payload;
        },
        updateSimVariant(state, { payload }: PayloadAction<SimulationShape>) {
            state.simVariants[state.variantSimulation.id][
                state.selectedVariantIndex
            ] = payload;
        },
    },
});

export const simVariantStoreReducer = simVariantSlice.reducer;

export const {
    addSimVariant,
    setVariantSimulation,
    setSelectedVariantIndex,
    updateSimVariant,
} = simVariantSlice.actions;
