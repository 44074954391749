import { ContentBlockShape } from "@/models";
import { useAtom, atom } from "jotai";
import { useSaveMovedContentBlock } from "./useSaveMovedContentBlock";

export const movedContentBlockAtom = atom<ContentBlockShape | false>(false);
movedContentBlockAtom.debugLabel = "movedContentBlockAtom";

export function useMovedContentBlock() {
    const [movedContentBlock, setMovedContentBlock] = useAtom(
        movedContentBlockAtom
    );

    //shape to match existing API expected shape
    return {
        movedContentBlock,
        setMovedContentBlock,
        handleMovedContentBlock: useSaveMovedContentBlock()
    }
}
