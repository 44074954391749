import { AppDispatch } from "./AppStore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cohort, Team, User } from "../models";
import { mapById } from "./util";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";
import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";


type CohortSliceState = {
    cohorts: { [index: string]: Cohort };
    cohort: Cohort | undefined;
    teams: { [index: string]: Team | TeamWithProgressData };
    addableParticipants: { [index: string]: User };
};

const initialState: CohortSliceState = {
    cohorts: {},
    cohort: undefined,
    teams: {},
    addableParticipants: {},
};

const cohortSlice = createSlice({
    name: "CohortStore",
    initialState,
    reducers: {
        cohortsQueried(state, { payload }: PayloadAction<Cohort[]>) {
            state.cohorts = mapById(payload);
        },
        gotCohortById(state, { payload }: PayloadAction<Cohort | undefined>) {
            state.cohort = payload;
        },
        gotTeamsByCohort(
            state,
            { payload }: PayloadAction<Team[] | TeamWithProgressData[]>
        ) {
            state.teams = mapById(payload);
        },
        gotAddableParticipants(state, { payload }: PayloadAction<User[]>) {
            state.addableParticipants = mapById(payload);
        },
    },
});

export const cohortStoreReducer = cohortSlice.reducer;

const {
    cohortsQueried,
    gotCohortById,
    gotTeamsByCohort,
    gotAddableParticipants,
} = cohortSlice.actions;

export const gotCohort = (cohort: Cohort) => {
    return async (dispatch: AppDispatch) => {
        if (!!cohort && !!cohort.id) {
            dispatch(gotCohortById(cohort));
            dispatch(gotTeamsByCohort(cohort.teams || []));
        } else {
            dispatch(gotCohortById(undefined));
            dispatch(gotTeamsByCohort([]));
            dispatch(gotAddableParticipants([]));
        }
    };
};

export const gotCohorts = (cohorts: Cohort[]) => {
    return async (dispatch: AppDispatch) => {
        if (Array.isArray(cohorts)) {
            dispatch(cohortsQueried(cohorts));
        }
    };
};

export const getParticipantsToAdd = (cohortId: string) => {
    return async (dispatch: AppDispatch) => {
        if (cohortId !== undefined) {
            const participants = await axios
                .get<User[]>(
                    sapienRoute("admin.facilitator.participants.addable", {
                        cohortId,
                    })
                )
                .then((response) => response.data);
            console.log("participants", participants);
            if (Array.isArray(participants)) {
                dispatch(gotAddableParticipants(participants));
            }
        } else {
            dispatch(gotAddableParticipants([]));
        }
    };
};
