import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentBlock, ModelVariable } from "@/models";
import { VariableValueMap, createVariableValueMapFromArray } from "@/util";
import { VariableValue } from "@/models/VariableValue";
//import { defaultSimulationTheme, StyledQuestionProps } from "../styles";

type ContentBlockSliceState = {
    contentBlocks: { [index: string]: ContentBlock };
    variableValues: { [index: string]: VariableValue };
    modelVariables: { [index: string]: ModelVariable };
    variableValueMap: VariableValueMap;
    unreadMessageIds: string[];
};

const initialState: ContentBlockSliceState = {
    contentBlocks: {},
    variableValues: {},
    modelVariables: {},
    variableValueMap: {},
    unreadMessageIds: [],
};

const contentBlockDataSlice = createSlice({
    name: "ContentBlockStore",
    initialState,
    reducers: {
        gotContentBlocks(state, { payload }: PayloadAction<ContentBlock[]>) {
            state.contentBlocks = payload.reduce(
                (
                    carry: { [index: string]: ContentBlock },
                    contentBlock: ContentBlock
                ) => {
                    return { ...carry, [contentBlock.id]: contentBlock };
                },
                {}
            );
        },
        gotVariableValues(state, { payload }: PayloadAction<VariableValue[]>) {
            state.variableValues = payload.reduce(
                (
                    carry: { [index: string]: VariableValue },
                    variableValue: VariableValue
                ) => {
                    return { ...carry, [variableValue.id]: variableValue };
                },
                {}
            );
            state.variableValueMap = createVariableValueMapFromArray(payload);
        },
        setUnreadMessageIds(state, { payload }) {
            state.unreadMessageIds = payload;
        },
    },
    extraReducers: {},
});

// export type RootState = ReturnType<typeof contentBlockStoreReducer>;

export const contentBlockStoreReducer = contentBlockDataSlice.reducer;

// export const selectContentBlockById = (state: RootState, id: string) => {
//     return state.contentBlocks[id];
// };

// export const selectContentBlockChildren = (state: RootState, id: string) => {
//     return Object.values(state.contentBlocks).filter(
//         (block) => block.parent_content_block_id === id
//     );
// };

// export const selectContentBlocks = (state: RootState) => state.contentBlocks;

// export const testSelector = useSelector((state: RootState) => {
//     return state.s
// });

export const {
    // content blocks
    gotContentBlocks,
    // gotVariableValues,
    setUnreadMessageIds,
} = contentBlockDataSlice.actions;
