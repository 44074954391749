import { ContentBlockType } from "@/models";
import { ContentBlockCreator } from "../ContentBlockCreator";
import {
    defaultRichText,
    defaultHeadingRichText,
    defaultTableRichText,
} from "../defaultRichtext";
import { defaultRichTextTheme } from "@/styles/themes/RichTextTheme";
import {
    defaultButtonTheme,
    defaultFormTheme,
    defaultImageTheme,
    defaultSectionTheme,
} from "@/styles";
import { createFlipCard } from "./createFlipCard";
import { cnToArray } from "@/util";

export const contentBlockFunctionMap: { [index: string]: ContentBlockCreator } =
    {
        createTextBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: {
                    flexDirection: "row",
                    tailwindClasses: [
                        "flex",
                        "flex-row",
                        "justify-start",
                        "items-center",
                        "gap-4",
                        "p-4",
                        "w-full",
                    ],
                    variant: "paragraph with heading",
                },
                weight,
                contentBlocks: [
                    {
                        round_id,
                        content_block_type: ContentBlockType.Box,
                        rich_text: defaultHeadingRichText,
                        weight: 0,
                        theme: {
                            tailwindClasses: [
                                "flex",
                                "flex-col",
                                "justify-start",
                                "items-center",
                                "gap-4",
                                "p-4",
                                "w-full",
                            ],
                        },
                        contentBlocks: [
                            {
                                round_id,
                                content_block_type:
                                    ContentBlockType["Rich Text"],
                                rich_text: defaultRichText,
                                weight: 0,
                                theme: {
                                    ...defaultRichTextTheme,
                                    width: "100%",
                                },
                            },
                        ],
                    },
                    {
                        round_id,
                        content_block_type: ContentBlockType.Box,
                        rich_text: defaultHeadingRichText,
                        weight: 0,
                        theme: {
                            tailwindClasses: [
                                "flex",
                                "flex-col",
                                "justify-center",
                                "items-start",
                                "gap-4",
                                "p-4",
                                "hidden",
                                "w-full",
                            ],
                        },
                        contentBlocks: [
                            {
                                round_id,
                                content_block_type: ContentBlockType.Image,
                                rich_text: defaultRichText,
                                weight: 0,
                                theme: { ...defaultImageTheme, width: "100%" },
                                image_url:
                                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
                            },
                        ],
                    },
                ],
            };
        },
        createImageBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: defaultSectionTheme,
                weight,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Box,
                        theme: {
                            ...defaultSectionTheme,
                            backgroundImage: null,
                            tailwindClasses: cnToArray([
                                "flex",
                                "flex-col",
                                "justify-center",
                                "items-center",
                                "gap-4",
                            ]),
                        },
                        contentBlocks: [
                            {
                                round_id,
                                content_block_type: ContentBlockType.Image,
                                rich_text: defaultRichText,
                                weight: 0,
                                theme: { ...defaultImageTheme, width: "100%" },
                                image_url:
                                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
                            },
                        ],
                    },
                ],
            };
        },
        createVideoBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: defaultSectionTheme,
                weight,
                contentBlocks: [
                    {
                        round_id,
                        content_block_type: ContentBlockType.Video,
                        rich_text: defaultRichText,
                        weight: 0,
                        theme: { ...defaultImageTheme, width: "100%" },
                        video_urls: [
                            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
                        ],
                    },
                ],
            };
        },
        createFormBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: {
                    tailwindClasses: [
                        "flex",
                        "flex-wrap",
                        "flex-row",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "p-4",
                    ],
                },
                weight,
                contentBlocks: [
                    {
                        round_id,
                        content_block_type: ContentBlockType.Form,
                        weight: 0,
                        theme: {
                            ...defaultFormTheme,
                            tailwindClasses: [
                                "rounded-md",
                                "shadow-lg",
                                "border-gray-300",
                                "w-full",
                                "flex",
                                "flex-col",
                                "px-4",
                                "py-6",
                            ],
                        },
                        contentBlocks: [
                            {
                                round_id,
                                content_block_type: ContentBlockType.Button,
                                weight: 9999,
                                theme: {
                                    ...defaultButtonTheme,
                                    width: "100%",
                                    alignment: "center",
                                },
                                content: "Submit",
                            },
                        ],
                    },
                ],
            };
        },
        createFlipCardBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: {
                    ...defaultSectionTheme,
                    tailwindClasses: [
                        "flex",
                        "flex-wrap",
                        "flex-row",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "p-4",
                    ],
                },
                weight,
                contentBlocks: [
                    createFlipCard(round_id, 0),
                    createFlipCard(round_id, 1),
                    createFlipCard(round_id, 2),
                ],
            };
        },
        createDividerBlock: (round_id, weight) => {
            return {
                round_id,
                content_block_type: ContentBlockType["Divider"],
                weight,
                theme: {
                    variant: "numbered",
                    tailwindClasses: [
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-center",
                        "py-12",
                        "text-sapien-blue",
                    ],
                },
            };
        },
        createTableBlock: (round_id, weight) => {
            return {
                content_block_type: ContentBlockType.Section,
                round_id,
                theme: {
                    ...defaultSectionTheme,
                    tailwindClasses: [
                        "flex",
                        "flex-wrap",
                        "flex-row",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "p-4",
                    ],
                },
                weight,
                contentBlocks: [
                    {
                        round_id,
                        content_block_type: ContentBlockType.Table,
                        weight,
                        theme: {
                            tailwindClasses: [
                                "min-w-full",
                                "border",
                                "table-auto",
                            ],
                        },
                        contentBlocks: [
                            {
                                round_id,
                                weight: 0,
                                content_block_type:
                                    ContentBlockType["Table Body"],
                                theme: {
                                    tailwindClasses: ["divide-gray-300"],
                                },
                                contentBlocks: [
                                    {
                                        round_id,
                                        content_block_type:
                                            ContentBlockType["Table Row"],
                                        weight: 0,
                                        theme: {
                                            tailwindClasses: [],
                                        },
                                        contentBlocks: Array.from({
                                            length: 5,
                                        }).map((_, i) => ({
                                            content_block_type:
                                                ContentBlockType[
                                                    "Table Data Cell"
                                                ],
                                            round_id,
                                            weight: i,
                                            theme: {
                                                tailwindClasses: [
                                                    "px-3",
                                                    "py-4",
                                                    "text-sm",
                                                    "border",
                                                    "first:border-l-0",
                                                    "last:border-r-0",
                                                ],
                                            },
                                            content: `Cell ${i + 1}`,
                                            contentBlocks: [
                                                {
                                                    round_id,
                                                    content_block_type:
                                                        ContentBlockType[
                                                            "Rich Text"
                                                        ],
                                                    rich_text:
                                                        defaultTableRichText(
                                                            "Cell",
                                                            i + 1,
                                                        ),
                                                    weight: 0,
                                                    theme: {
                                                        tailwindClasses: [
                                                            "flex",
                                                            "flex-col",
                                                            "justify-start",
                                                            "items-center",
                                                            "gap-4",
                                                            "p-4",
                                                            "w-full",
                                                        ],
                                                    },
                                                },
                                            ],
                                        })),
                                    },
                                    {
                                        round_id,
                                        content_block_type:
                                            ContentBlockType["Table Row"],
                                        weight: 0,
                                        theme: {
                                            tailwindClasses: [],
                                        },
                                        contentBlocks: Array.from({
                                            length: 5,
                                        }).map((_, i) => ({
                                            content_block_type:
                                                ContentBlockType[
                                                    "Table Data Cell"
                                                ],
                                            round_id,
                                            weight: i,
                                            theme: {
                                                tailwindClasses: [
                                                    "px-3",
                                                    "py-4",
                                                    "text-sm",
                                                    "border",
                                                    "first:border-l-0",
                                                    "last:border-r-0",
                                                ],
                                            },
                                            content: `Cell ${i + 1}`,
                                            contentBlocks: [
                                                {
                                                    round_id,
                                                    content_block_type:
                                                        ContentBlockType[
                                                            "Rich Text"
                                                        ],
                                                    rich_text:
                                                        defaultTableRichText(
                                                            "Cell",
                                                            i + 1,
                                                        ),
                                                    weight: 0,
                                                    theme: {
                                                        tailwindClasses: [
                                                            "flex",
                                                            "flex-col",
                                                            "justify-start",
                                                            "items-center",
                                                            "gap-4",
                                                            "p-4",
                                                            "w-full",
                                                        ],
                                                    },
                                                },
                                            ],
                                        })),
                                    },
                                    {
                                        round_id,
                                        content_block_type:
                                            ContentBlockType["Table Row"],
                                        weight: 0,
                                        theme: {
                                            tailwindClasses: [],
                                        },
                                        contentBlocks: Array.from({
                                            length: 5,
                                        }).map((_, i) => ({
                                            content_block_type:
                                                ContentBlockType[
                                                    "Table Data Cell"
                                                ],
                                            round_id,
                                            weight: i,
                                            theme: {
                                                tailwindClasses: [
                                                    "px-3",
                                                    "py-4",
                                                    "text-sm",
                                                    "border",
                                                    "first:border-l-0",
                                                    "last:border-r-0",
                                                ],
                                            },
                                            content: `Cell ${i + 1}`,
                                            contentBlocks: [
                                                {
                                                    round_id,
                                                    content_block_type:
                                                        ContentBlockType[
                                                            "Rich Text"
                                                        ],
                                                    rich_text:
                                                        defaultTableRichText(
                                                            "Cell",
                                                            i + 1,
                                                        ),
                                                    weight: 0,
                                                    theme: {
                                                        tailwindClasses: [
                                                            "flex",
                                                            "flex-col",
                                                            "justify-start",
                                                            "items-center",
                                                            "gap-4",
                                                            "p-4",
                                                            "w-full",
                                                        ],
                                                    },
                                                },
                                            ],
                                        })),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
        },
    } as const;
