import { ContentBlockType } from "../../../models";
import { ConfigComponentType, defaultHero2Theme } from "../../../styles";
import { ContentBlockConfig } from "../../ContentBlockConfigInterface";

export const Hero3Config: ContentBlockConfig = {
    typeKey: "Hero",
    type: ContentBlockType.Hero3,
    requiredChildBlocks: [],
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType.Text,
        ContentBlockType["Rich Text"]
    ],
    defaultTheme: defaultHero2Theme,
    variantOptions: [
        {
            label: "Text placement",
            themeObjectKey: "textPlacement",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                left: "left",
                right: "right",
                center: "center"
            },
        }
    ],
};
