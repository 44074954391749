import { useMutation } from "@tanstack/react-query";
import { useAddContentBlockState } from "../useAddContentBlockState";
import {
    ModalRequestBodyForExistingBlock,
    ModalRequestBodyForNewBlock,
    saveModal,
} from "./saveModal";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useGotContentBlock, useSelectedSimulation } from "@/hooks";
import { buildModal } from "@/model-configs/content-block-layouts/ModalLayout";
import { defaultLinkTheme, defaultModalTheme } from "@/styles";
import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";

export function buildCreateModalRequestBody(
    contentBlock: ContentBlockShape,
    childContentBlockType: ContentBlockType,
): ModalRequestBodyForNewBlock {
    const modal = buildModal();
    return {
        ...modal,
        parent_content_block_id: contentBlock.id,
        linkTheme: JSON.stringify(defaultLinkTheme),
        childContentBlockType,
        round_id: contentBlock.round_id,
        theme: JSON.stringify(defaultModalTheme),
        weight: 0,
    };
}

export function buildCreateModalRequestBodyForExistingBlock(
    contentBlock: ContentBlockShape,
): ModalRequestBodyForExistingBlock {
    const modal = buildModal();
    return {
        ...modal,
        parent_content_block_id: contentBlock.id,
        linkTheme: JSON.stringify(defaultLinkTheme),
        round_id: contentBlock.round_id,
        secondary_content_block_id: contentBlock.id,
    };
}

export function useSaveModal() {
    const { selectedSimulation } = useSelectedSimulation();
    const { reset, addContentBlockState } = useAddContentBlockState();
    const { gotContentBlock } = useGotContentBlock();
    const { setActiveContentBlock } = useSelectActiveContentBlock();

    return useMutation({
        mutationFn: async (contentBlock: ContentBlockShape) => {
            const body =
                addContentBlockState?.isAddingModalToExistingContentBlock
                    ? buildCreateModalRequestBodyForExistingBlock(contentBlock)
                    : buildCreateModalRequestBody(
                          contentBlock,
                          addContentBlockState.newModalTriggerType,
                      );

            return saveModal({ body, selectedSimulation });
        },
        onSuccess: (contentBlocks) => {
            reset();
            contentBlocks?.forEach((contentBlock) => {
                gotContentBlock(contentBlock);
            });
            const modal = contentBlocks?.find(
                (contentBlock) =>
                    contentBlock.content_block_type === ContentBlockType.Modal,
            );
            if (modal) {
                setActiveContentBlock(modal.id);
            }
            return false;
        },
        onError: (error) => {
            console.log("error saving modal", error);
        },
    });
}
