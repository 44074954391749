import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import React, { useCallback } from "react";
import { useUpdatePrompt } from "./content-block-management/useCreatePrompt";
import { createPortal } from "react-dom";
import { debounce } from "@/hooks";
import { ContentBlockShape, PromptShape } from "@/models";
import { useContentBlockState } from "./useContentBlockState";

type Props = { contentBlock: ContentBlockShape };

export function SliderMinAndMax({ contentBlock }: Props) {
    const { setContentBlock } = useContentBlockState();

    const { prompt } = contentBlock;
    const minElement = useGetElementAsync(
        `[data-min-input-wrapper="${prompt.id}"]`,
    );
    const maxElement = useGetElementAsync(
        `[data-max-input-wrapper="${prompt.id}"]`,
    );

    const { mutate } = useUpdatePrompt();

    const save = debounce(mutate, 500);
    const handleChange = useCallback(
        (prompt: PromptShape) => {
            setContentBlock({ ...contentBlock, prompt });
            save({ ...prompt, scope: "Participant" });
        },
        [prompt],
    );

    if (!minElement || !maxElement) return null;
    return (
        <>
            {createPortal(
                <div
                    className="rounded-md bg-white px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300
                        focus-within:ring-2 focus-within:ring-indigo-600"
                >
                    <label
                        htmlFor={`min-${prompt.id}`}
                        className="block text-xs font-medium text-gray-900"
                    >
                        Min
                    </label>
                    <input
                        defaultValue={prompt.min || 0}
                        type="text"
                        name={`min-${prompt.id}`}
                        id={`min-${prompt.id}`}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0
                            sm:text-sm sm:leading-6"
                        placeholder="Min val"
                        onChange={(e) => {
                            handleChange({
                                ...prompt,
                                min: parseInt(e.target.value),
                            });
                        }}
                    />
                </div>,
                minElement,
            )}
            {createPortal(
                <div
                    className="rounded-md bg-white px-2 pb-1.5 pt-1.5 shadow-sm ring-1 ring-inset ring-gray-300
                        focus-within:ring-2 focus-within:ring-indigo-600"
                >
                    <label
                        htmlFor={`max-${prompt.id}`}
                        className="block text-xs font-medium text-gray-900"
                    >
                        Max
                    </label>
                    <input
                        defaultValue={prompt.max || 100}
                        type="text"
                        name={`max-${prompt.id}`}
                        id={`max-${prompt.id}`}
                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0
                            sm:text-sm sm:leading-6"
                        placeholder="Max val"
                        onChange={(e) => {
                            handleChange({
                                ...prompt,
                                max: parseInt(e.target.value),
                            });
                        }}
                    />
                </div>,
                maxElement,
            )}
        </>
    );
}
