import { useCallback } from "react";
import { gotContentBlocks, setUnreadMessageIds } from "../contentBlockSlice";
import { gotVariableValues } from "@/redux-state/variableValueSlice";
import { gotTheme } from "../simulationThemeSlice";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { ContentBlock, ContentBlockType } from "@/models";
import { VariableValue } from "@/models/VariableValue";
import {
    findNestedItem,
    getTableDataFromContentBlock,
    unflatten,
} from "@/util";
import { SubmissionCount } from "@/inertia-utils/types";
import { gotSubmissionCounts } from "../submissionCountSlice";
// import { useAppDispatch } from "@/redux-state"";

export function useParticipantStore() {
    const dispatch = useAppDispatch();

    const contentBlocks = useAppSelector(
        (state) => state.participantContentBlockStore.contentBlocks
    );

    const _selectContentBlockChildren = (contentBlockId: string) =>
        useAppSelector((state) => {
            return Object.values(
                state.participantContentBlockStore.contentBlocks
            ).filter(
                (block) => block.parent_content_block_id === contentBlockId
            );
        });

    const _selectTableRowsFromTable = (contentBlockId: string) =>
        useAppSelector((state) => {

            const blocks = Object.values(
                state.participantContentBlockStore.contentBlocks
            );
            const tableChildren = blocks.filter(
                (block) => block.parent_content_block_id === contentBlockId
            );

            if (tableChildren?.length) {
                return tableChildren.map((tableChild) => {
                    return {
                        ...tableChild,
                        contentBlocks: blocks.filter(
                            (block) =>
                                block.parent_content_block_id === tableChild.id
                        ),
                    };
                });
            }

            return [];
        });

    const _selectNestedContentBlock = (contentBlockId: string) =>
        useAppSelector((state) => {
            const blockTree = unflatten(
                "parent_content_block_id",
                "contentBlocks",
                Object.values(state.participantContentBlockStore.contentBlocks)
            );

            const contentBlock = findNestedItem(
                blockTree,
                "contentBlocks",
                "id",
                contentBlockId
            );

            return contentBlock;
        });

    const _gotContentBlocks = useCallback(
        (contentBlocks: ContentBlock[]) => {
            return dispatch(gotContentBlocks(contentBlocks));
        },
        [dispatch]
    );

    const _gotVariableValues = useCallback(
        (variableValues: VariableValue[]) => {
            // console.log("VARIABLE VALUES", variableValues);
            return dispatch(gotVariableValues(variableValues));
        },
        [dispatch]
    );

    const _gotTheme = useCallback(
        (simulationTheme) => {
            return dispatch(gotTheme(simulationTheme));
        },
        [dispatch]
    );

    const simulationTheme = useAppSelector(
        (state) => state.simulationThemeStore.simulationTheme
    );

    const selectVariableValuesByContentBlockId = (contentBlockId: string) =>
        useAppSelector((state) => {
            return Object.values(
                state.participantContentBlockStore.variableValues
            ).filter((variableValue) =>
                state.participantContentBlockStore.contentBlocks[
                    contentBlockId
                ].contentBlockModelDataSources.some(
                    (contentBlockModelDataSource) =>
                        contentBlockModelDataSource.model_variable_id ===
                        variableValue.model_variable_id
                )
            );
        });

    const selectTableDataByContentBlockId = (contentBlockId: string) =>
        useAppSelector((state) => {
            return getTableDataFromContentBlock(
                state.participantContentBlockStore.contentBlocks[
                    contentBlockId
                ],
                state.participantContentBlockStore.variableValueMap
            );
        });

    const rootContentBlocks = useAppSelector((state) =>
        Object.values(state.participantContentBlockStore.contentBlocks).filter(
            (contentBlock) => !contentBlock.parent_content_block_id
        )
    );

    const submissionCount = useAppSelector(
        (state) => state.submissionCountStore.submissionCount
    );
    const gotSumbmissionCount = useCallback(
        (submissionCount: { [index: string]: SubmissionCount }) => {
            dispatch(gotSubmissionCounts(submissionCount));
        },
        [dispatch]
    );

    const unreadMessageIds = useAppSelector(
        (state) => state.participantContentBlockStore.unreadMessageIds
    );
    const _setUnreadMessageIds = useCallback(
        (unreadMessageIds: string[]) => {
            dispatch(setUnreadMessageIds(unreadMessageIds));
        },
        [dispatch]
    );

    return {
        contentBlocks,
        gotContentBlocks: _gotContentBlocks,
        selectContentBlockChildren: _selectContentBlockChildren,
        gotVariableValues: _gotVariableValues,
        rootContentBlocks,
        selectVariableValuesByContentBlockId,
        selectTableDataByContentBlockId,
        simulationTheme,
        gotTheme: _gotTheme,
        submissionCount,
        gotSumbmissionCount,
        setUnreadMessageIds: _setUnreadMessageIds,
        unreadMessageIds,
        selectNestedContentBlock: _selectNestedContentBlock,
        selectTableRowsFromTable: _selectTableRowsFromTable,

        // contentBlockTree,
    };
}
