import { AreaSeriesTheme, LineSeriesTheme } from "@/models";

export const defaultLineSeriesTheme: Partial<LineSeriesTheme> = {
    interpolation: "linear",
    stroke: "red",
    strokeWidth: 3,
    // labelProps can be used to styled the VictoryLabel
    labelProps: {
        x: 0,
        y: 0,
    },
};

export const defaultAreaChartTheme: Partial<AreaSeriesTheme> = {
    interpolation: "natural",
    stroke: "black",
    fill: "#ffffff88",
    strokeWidth: 3,
    // labelProps can be used to styled the VictoryLabel
    labelProps: {
        x: 0,
        y: 0,
    },
};
