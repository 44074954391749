import React, { Suspense } from "react";
import { useScorm } from "./useScormApi";
import { useAuth } from "./useAuth";
import LessonDisplay from "./LessonDisplay";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { TanStackRouterDevelopmentTools } from "./components/utils/development-tools/TanStackRouterDevelopmentTools";

const App = () => {
    const { scorm, scormUser:{student_id, student_name} } = useScorm();
    const { isPending, data } = useAuth({
        student_id,
        student_name,
    });

    console.log("app asking for location",scorm.dataGet('cmi.core.lesson_location'));


    return (
        <div className="e-learning flex min-h-screen w-full items-center justify-center">
            {!!isPending && <h1>Loading...</h1>}
            {/* <LessonDisplay/> */}
            {!!data?.lesson && (
                <LessonDisplay
                    auth={data?.auth}
                    teamUserRound={data?.teamUserRound}
                    course={data?.course}
                    lesson={data?.lesson}
                    selections={data.selections}
                />
            )}
        </div>
    );
};

export default App;
