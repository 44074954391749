import styled from "styled-components";

export const BodyOuterWrapper = styled.div`
    width: 100%;
    padding-top: ${({ theme }) => theme.layout.bodyTopPadding};
`;
BodyOuterWrapper.displayName = "BodyOuterWrapper";

export const BodyInnerWrapperWide = styled.div`
    max-width: ${({ theme }) => "1066px"};
    width: 100%;
    margin: 0 auto;
`;
BodyInnerWrapperWide.displayName = "BodyInnerWrapperWide";

export const BodyContentContainer = styled.div`
    padding: 1.5rem;
`;
BodyContentContainer.displayName = "BodyContentContainer";

// export const ThinHR = styled.hr`
//     border-width: 1px 0 0;
//     margin: 1.5rem 0;
//     border-color: #9a9a9a;
// `;
// ThinHR.displayName = "ThinHR";

// export const ThinHRSlim = styled.hr`
//     border-width: 1px 0 0;
//     margin: 0.5rem 0;
// `;
// ThinHRSlim.displayName = "ThinHRSlim";
