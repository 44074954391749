import React, { memo } from "react";
import { ContentBlockDisplay } from "@/Pages/Admin/editor/blocks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useGetContentBlockWithChildren } from "../LessonDesign/useContentBlockState";
import LessonRichText from "./LessonRichText";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";
import LessonQuestion from "./content-block-components/LessonQuestion";
import LessonFormContentBlock from "./content-block-components/LessonFormContentBlock";
import FlipCard from "./content-block-components/flip-cards/FlipCard";
import FlipCardSide from "./content-block-components/flip-cards/FlipCardSide";
import LessonSection from "./content-block-components/LessionSection";
import Divider from "./content-block-components/Divider";
import LessonAccordion from "./content-block-components/accordion/LessonAccordion";
import LessonAccordionItem from "./content-block-components/accordion/LessonAccordionItem";
import LessonTabs from "./content-block-components/tabs/LessonTabs";
import LessonButton from "./LessonButton";
import { LessonImage } from "./LessonImage";
import { StyledBox } from "@/styles";
import {
    LessonTable,
    // LessonTableRow,
    // LessonTableCell,
    // LessonTableHeader,
    // LessonTableHead,
    // LessonTableBody,
} from "./content-block-components/tables/LessonTable";

type Props = {
    contentBlock: ContentBlockShape;
    scrollCallback?: (e) => void;
    isInAdminContext: boolean;
    isParentBlock?: boolean;
};

function ContentBlockRender({
    contentBlock,
    scrollCallback,
    isInAdminContext,
}: Props) {
    const children =
        useGetContentBlockWithChildren(contentBlock.id)?.contentBlocks || [];

    switch (contentBlock.content_block_type) {
        case ContentBlockType.Accordion:
            return (
                <LessonAccordion contentBlock={contentBlock}>
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </LessonAccordion>
            );
        case ContentBlockType.AccordionItem:
            return (
                <LessonAccordionItem
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </LessonAccordionItem>
            );
        case ContentBlockType.Box:
            return (
                <StyledBox
                    contentBlockId={contentBlock.id}
                    theme={contentBlock.theme}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </StyledBox>
            );
        case ContentBlockType.Button:
            return (
                <LessonButton
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                />
            );
        case ContentBlockType.Divider:
            return <Divider contentBlock={contentBlock} />;
        case ContentBlockType["Flip Card"]:
            return (
                <FlipCard
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </FlipCard>
            );
        case ContentBlockType["Flip Card Side"]:
            return (
                <FlipCardSide
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </FlipCardSide>
            );

        case ContentBlockType.Form:
            return (
                <LessonFormContentBlock
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                >
                    {children
                        //filter so that forms can render and pass props to their own buttons
                        .filter(
                            (child) =>
                                child.content_block_type !==
                                ContentBlockType.Button,
                        )
                        ?.map((child) => (
                            <NestedLessonContentBlockDisplay
                                key={child.id}
                                contentBlock={child}
                                isInAdminContext={isInAdminContext}
                            />
                        ))}
                </LessonFormContentBlock>
            );
        case ContentBlockType.Image:
            return (
                <LessonImage
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                />
            );
        case ContentBlockType["Rich Text"]:
            return (
                <LessonRichText
                    contentBlock={contentBlock}
                    editable={isInAdminContext}
                    content={contentBlock.rich_text}
                />
            );

        case ContentBlockType.Question:
            return (
                <LessonQuestion
                    contentBlock={contentBlock}
                    editable={isInAdminContext}
                    content={contentBlock.rich_text}
                />
            );
        case ContentBlockType.Section:
            return (
                <LessonSection
                    isInAdminContext={isInAdminContext}
                    contentBlock={contentBlock}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </LessonSection>
            );
        case ContentBlockType.Table:
            return (
                <LessonTable
                    contentBlock={contentBlock}
                    isInAdminContext={isInAdminContext}
                    children={children}
                />
            );

        case ContentBlockType.Tabs:
            return (
                <LessonTabs
                    contentBlock={contentBlock}
                    contentBlocks={children}
                    isInAdminContext={isInAdminContext}
                />
            );

        default:
            return (
                <ContentBlockDisplay
                    key={contentBlock.id}
                    isSelected={false}
                    contentBlock={contentBlock}
                    isEditable={false}
                    simulationTheme={undefined}
                    activeChildIndex={0}
                    homeRoute={""}
                    renderMap={{}}
                    reflectionJournalEntries={[]}
                    formProps={{
                        questionFormObject: {},
                        isSubmitting: false,
                    }}
                >
                    {children?.map((child) => (
                        <NestedLessonContentBlockDisplay
                            key={child.id}
                            contentBlock={child}
                            isInAdminContext={isInAdminContext}
                        />
                    ))}
                </ContentBlockDisplay>
            );
    }
}

const NestedLessonContentBlockDisplay = memo(
    function NestedLessonContentBlockDisplay({
        contentBlock,
        scrollCallback,
        isInAdminContext = false,
        isParentBlock = false,
    }: Props) {
        const contentBlockEl = useGetElementAsync(
            `[data-content-block-id="${contentBlock.id}"]`,
        );

        // console.log("nested lesson content block display", contentBlock.content_block_type, contentBlock.id);

        return (
            <>
                {isInAdminContext && !!contentBlockEl && !!isParentBlock && (
                    <>
                        <div
                            className="relative w-full p-0 transition-all"
                            data-e-learning-add-block-id={contentBlock.id}
                        ></div>
                        {createPortal(
                            <div
                                data-e-learning-block-id={contentBlock.id}
                                className={`pointer-events-none absolute inset-0 w-full transition-all delay-200
                                    has-[.content-block-buttons]:border has-[.content-block-buttons]:border-l-0
                                    has-[.content-block-buttons]:border-r-0
                                    has-[.content-block-buttons]:border-gray-300/0
                                    has-[.content-block-buttons]:first:border-t-0
                                    has-[.content-block-buttons]:last:border-b-0
                                    has-[.content-block-buttons]:hover:border-gray-300/100`}
                            ></div>,
                            contentBlockEl,
                        )}
                    </>
                )}

                <ContentBlockRender
                    {...{ contentBlock, scrollCallback, isInAdminContext }}
                />
            </>
        );
    }
);
export default NestedLessonContentBlockDisplay;
