import React, { useCallback } from "react";
import { defaultTableRichText } from "@/Pages/ELearning/LessonDesign/content-block-management/defaultRichtext";
import { useSaveContentBlock } from "@/Pages/ELearning/LessonDesign/content-block-management/useSaveContentBlock";
import { useGetContentBlockWithChildren } from "@/Pages/ELearning/LessonDesign/useContentBlockState";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { ArrowDown, ArrowUp } from "lucide-react";

export function AddRowButtons({
    tr,
    row,
}: {
    tr: ContentBlockShape;
    row: number;
}) {
    const { mutateAsync, isPending } = useSaveContentBlock();

    const parentBlock = useGetContentBlockWithChildren(
        tr?.parent_content_block_id,
    );

    const handleAddRow = useCallback(
        (position: "before" | "after") => {
            const newRow = {
                content_block_type: ContentBlockType["Table Row"],
                parent_content_block_id: parentBlock.id,
                weight: position === "after" ? row + 1 : row,
                contentBlocks: tr.contentBlocks.map((td, i) => {
                    return {
                        ...td,
                        content_block_type:
                            row === 0 && position === "before"
                                ? ContentBlockType["Table Header Cell"]
                                : ContentBlockType["Table Data Cell"],
                        id: null,
                        weight: i,
                        contentBlocks: [
                            {
                                round_id: td.round_id,
                                content_block_type:
                                    ContentBlockType["Rich Text"],
                                rich_text: defaultTableRichText("", i),
                                weight: 0,
                                theme: {
                                    tailwindClasses: [
                                        "flex",
                                        "flex-col",
                                        "justify-start",
                                        "items-center",
                                        "gap-4",
                                        "p-4",
                                        "w-full",
                                    ],
                                },
                            },
                        ],
                    };
                }),
            };

            const newPlacement = position === "after" ? row + 1 : row;
            mutateAsync({
                ...parentBlock,
                contentBlocks: [
                    ...parentBlock.contentBlocks
                        .slice(0, newPlacement)
                        .map((row, weight) => ({
                            ...row,
                            weight,
                        })),
                    newRow,
                    ...parentBlock.contentBlocks
                        .slice(newPlacement)
                        .map((row, i) => ({
                            ...row,
                            weight: row.weight + i + 1,
                            contentBlocks: row.contentBlocks.map((td) => ({
                                ...td,
                                content_block_type:
                                    ContentBlockType["Table Data Cell"],
                            })),
                        })),
                ],
            });
        },
        [parentBlock, row, tr],
    );

    if (!tr) return null;
    return (
        <>
            <button
                type="button"
                className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
                hover:bg-slate-100 ${isPending ? "cursor-wait" : "cursor-pointer"}`}
                onClick={() => {
                    handleAddRow("before");
                }}
            >
                <ArrowUp className="h-4 w-4" /> <span>Insert Row Above</span>
            </button>
            <button
                type="button"
                className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
                hover:bg-slate-100 ${isPending ? "cursor-wait" : "cursor-pointer"}`}
                onClick={() => {
                    handleAddRow("after");
                }}
            >
                <ArrowDown className="h-4 w-4" />
                <span>Insert Row Below</span>
            </button>
        </>
    );
}
