import { createGlobalStyle } from "styled-components";

export const GloablStyledComponent = createGlobalStyle`
select{
    color: black;
}
ul,ol{
    padding-left: 2em;
    list-style-position: inside;
    li{
        p{
            display: inline
        }
    }
}
ul li{
    list-style-type: disc;
}
ol li{
    list-style-type: decimal;
}
`;
