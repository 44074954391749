import React, { useState } from "react";
// import useDimensions from "react-use-dimensions";
import { X } from "lucide-react";
import { ThemeObject, Display, FlexDirection } from "@/styles";
import {
    FlexWrap,
    AlignItems,
    AlignContent,
    JustifyItems,
    JustifyContent,
} from "@/styles/ThemeObjects";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
type Props = {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
};

export function LessonImage({ contentBlock, isInAdminContext }: Props) {
    const [isEnlarged, setIsEnlarged] = useState(false);

    return (
        <>
            <div
                style={{ display: contentBlock.theme?.display || "flex" }}
                data-content-block-id={contentBlock.id}
                className={cn(
                    `w-full cursor-zoom-in ${contentBlock.theme?.tailwindClasses || []}`,
                )}
                onClick={() => setIsEnlarged(true)}
            >
                <img
                    src={contentBlock.image_url}
                    alt={contentBlock.title}
                    className="w-full"
                />
            </div>
            <div
                role="button"
                data-is-visible={isEnlarged}
                className={`fixed inset-0 z-[999999] flex h-screen w-screen items-center justify-center
                bg-black bg-opacity-70 transition-all
                data-[is-visible="false"]:pointer-events-none
                data-[is-visible="false"]:bg-opacity-0
                data-[is-visible="false"]:-translate-y-[100vh]`}
                onClick={(e) => {
                    e.preventDefault();
                    setIsEnlarged(false);
                }}
            >
                {!!isEnlarged && (
                    <div
                        className="zoom-image relative flex h-full max-h-[90%] w-full max-w-[90%] flex-col
                            items-center justify-center bg-contain bg-center bg-no-repeat"
                        style={{
                            backgroundImage: `url(${contentBlock.image_url})`,
                        }}
                    >
                        <div
                            className="absolute right-0 top-0 cursor-pointer rounded-full bg-white p-2 text-sapien-blue
                                transition-all hover:scale-110"
                        >
                            <X className="h-6 w-6" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export const defaultImageTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff00",
    borderColor: "#00000000",
    borderWidth: 1,
    borderRadius: 0,
    borderStyle: "solid",
    pt: 0,
    pr: 0,
    pb: 0,
    pl: 0,
    mt: 0,
    mr: 0,
    mb: 0,
    ml: 0,
    width: ["auto", "auto", "auto", "auto"],
    display: Display.flex,
    flexDirection: FlexDirection.row,
    flexWrap: FlexWrap.wrap,
    alignItems: AlignItems.center,
    alignContent: AlignContent.center,
    justifyItems: JustifyItems.center,
    justifyContent: JustifyContent.center,
};
