import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SimulationSliceState = {
    isOpen: boolean;
    isWrappingElementIsPresent: boolean;
    portalNeedsRefresh: boolean;
};

const initialState: SimulationSliceState = {
    isOpen: false,
    isWrappingElementIsPresent: false,
    portalNeedsRefresh: false,
};

const sidebarStateSlice = createSlice({
    name: "SimulationStore",
    initialState,
    reducers: {
        setSidebarOpen(state, { payload }: PayloadAction<boolean>) {
            state.isOpen = payload;
        },
        setWrappingElementIsPresent(
            state,
            { payload }: PayloadAction<boolean>
        ) {
            state.isWrappingElementIsPresent = payload;
        },
        setChildrenOfPortalChanged(state, { payload }) {
            state.portalNeedsRefresh = payload;
        },
    },
});

export const sidebarStateReducer = sidebarStateSlice.reducer;

export const { setSidebarOpen, setWrappingElementIsPresent } =
    sidebarStateSlice.actions;
