import { useAtom, atom } from "jotai";
import {
    contentBlockAtom,
} from "../useContentBlockState";
import { ContentBlockShape } from "@/models";

const editedContentBlockIdAtom = atom<string | false>(false);
const editedContentBlockAtom = atom((get) => {
    const editedIdAtom = get(editedContentBlockIdAtom);
    const contentBlocks = get(contentBlockAtom);

    return editedIdAtom && !!contentBlocks[editedIdAtom]
        ? contentBlocks[editedIdAtom]
        : undefined;
});

const createdContentBlockAtom = atom<ContentBlockShape | false>(false)

export function useEditedContentBlock() {

    const [contentBlockToCreate, setContentBlockToCreate] = useAtom(createdContentBlockAtom)

    const [editedContentBlockId, setEditedContentBlockId] = useAtom(
        editedContentBlockIdAtom,
    );

    const [editedContentBlock] = useAtom(editedContentBlockAtom);

    return {
        editedContentBlockId,
        setEditedContentBlockId,
        editedContentBlock,
        setContentBlockToCreate,
        contentBlockToCreate
    };
}
