import React, { ReactElement, useCallback, useState } from "react";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { ThemeObject } from "@/styles/ThemeObjects";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { StyledUniversalDiv } from "../Universal";

type CarouselTheme = ThemeObject & {};
type StyledCarouselProps = ContentBlockEvents & {
    theme: Partial<CarouselTheme>;
    children?: ReactElement;
    contentBlockId: string;
};

const CarouselItemWrapper = styled.div`
    &:has(.activeBlock) {
        padding-top: 5rem;
        padding-bottom: 5rem;
        > * {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
`;

export const defaultCarouselTheme = {
    pl: 1,
    pr: 1,
    pt: 1,
    pb: 1,
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
    backgroundColor: "#ffffffff",
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 0,
};

export function StyledCarousel({
    theme,
    onClick,
    isInEditorContext,
    contentBlockId,
    children,
    isSelected,
}: StyledCarouselProps) {
    //get the number of child elements. That's our number of slides.
    const childArray = React.Children.toArray(children);
    const numberOfSlides = childArray?.length || 0;
    const [step, setStep] = useState(0);

    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const scrollToSlide = useCallback(
        (step: number) => {
            if (wrapperRef.current) {
                const child = wrapperRef.current?.children[step];
                if (child) {
                    child.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                    setStep(step);
                }
            }
        },
        [wrapperRef]
    );

    const advance = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            scrollToSlide(step < numberOfSlides - 1 ? step + 1 : 0);
        },
        [step, numberOfSlides]
    );

    const retreat = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            scrollToSlide(step > 0 ? step - 1 : numberOfSlides - 1);
        },
        [step, numberOfSlides]
    );

    return (
        <StyledUniversalDiv
            {...{ ...defaultCarouselTheme, ...theme }}
            onClick={onClick}
            isInEditorContext={isInEditorContext}
            data-content-block-id={contentBlockId}
            className="carousel relative flex items-center justify-center w-full group/carousel"
        >
            <div
                className="absolute control left-0 top-0 bottom-0 px-2 flex items-center justify-center opacity-10 group-hover/carousel:opacity-100 z-[100] transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                role="button"
            >
                <button
                    onClick={retreat}
                    type="button"
                    className="rounded-full p-2 bg-slate-500/75 shadow hover:bg-slate-500 hover:scale-110 transition-all duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                >
                    <ChevronLeftIcon className="h-6 w-6 text-white" />
                    <span className="sr-only">Previous</span>
                </button>
            </div>
            <div className="absolute control right-0 top-0 bottom-0 px-2 flex items-center justify-center  opacity-10 group-hover/carousel:opacity-100 z-[100] transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none">
                <button
                    onClick={advance}
                    type="button"
                    className="rounded-full p-2 bg-slate-500/75 shadow hover:bg-slate-500 hover:scale-110 transition-all duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                >
                    <ChevronRightIcon className="h-6 w-6 text-white" />
                    <span className="sr-only">Next</span>
                </button>
            </div>
            <CarouselItemWrapper
                ref={wrapperRef}
                className="relative overflow-auto snap-both snap-mandatory flex items-stretch justify-stretch w-1 flex-1 flex-grow scrollbar-thin scrollbar-thumb-slate-500/50 scrollbar-track-slate-500/10 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-shadow-lg scrollbar-track-shadow-lg"
            >
                {childArray.map((child, index) => (
                    <div key={index} className="w-full min-w-[100%] flex items-center justify-center snap-center">
                        {child}
                    </div>
                ))}
            </CarouselItemWrapper>

        </StyledUniversalDiv>
    );
}
