import { ContentBlockShape, ContentBlockType } from "@/models";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { Lesson } from "../types";
import { isEqual } from "lodash";

export const contentBlockAtom = atom<{ [index: string]: ContentBlockShape }>({});

export function useSetupContentBlockState(lesson: Lesson) {
    const [contentBlocks, setContentBlocks] = useAtom(contentBlockAtom);

    const mappedContentBlocks = useMemo(() => {
        const initialBlocks = lesson.contentBlocks;
        if (!initialBlocks || !initialBlocks.length) return {};

        return initialBlocks.reduce((acc, block) => {
            acc[block.id] = block;
            return acc;
        }, {});
    }, [lesson]);

    useEffect(() => {
        setContentBlocks(mappedContentBlocks);
    }, [mappedContentBlocks]);

    return contentBlocks;
}

export function useContentBlockState() {
    const [contentBlocks, setContentBlocks] = useAtom(contentBlockAtom);

    // useEffect(() => {
    //     console.log("this?")
    //     const blockArray = Object.values(contentBlocks);
    //     const nestedBlocks = blockArray.reduce((acc, block) => {
    //         return {
    //             ...acc,
    //             [block.id]: {
    //                 ...block,
    //                 contentBlocks: blockArray.filter(
    //                     (child) => child.parent_content_block_id === block.id,
    //                 ),
    //             },
    //         };
    //     }, {});
    //     setNestedContentBlocks(nestedBlocks);
    // }, [contentBlocks]);



    const parentContentBlocks = useMemo(() => {
        return Object.values(contentBlocks)
            .filter((block) => !block.parent_content_block_id)
            .sort((a, b) => a.weight - b.weight);
    }, [contentBlocks]);

    const setContentBlock = useCallback(
        (block: ContentBlockShape) => {
            if (!block) return;
            if (!isEqual(contentBlocks[block.id], block)) {
                setContentBlocks((prev) => {
                    return {
                        ...prev,
                        [block.id]: block,
                    };
                });
            }
            block?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
        },
        [contentBlocks, parentContentBlocks],
    );

    const deleteContentBlock = useCallback(
        (blockId: string) => {
            setContentBlocks((prev) => {
                const newBlocks = { ...prev };
                delete newBlocks[blockId];
                return newBlocks;
            });
        },
        [contentBlocks],
    );

    const getParentByType = useCallback(
        (type: ContentBlockType, contentBlock: ContentBlockShape) => {
            if (!contentBlock) return null;
            if (contentBlock.content_block_type === type) return contentBlock;
            if (contentBlock.parent_content_block_id) {
                return getParentByType(
                    type,
                    contentBlocks[contentBlock.parent_content_block_id],
                );
            }
            return null;
        },
        [contentBlocks],
    );

    return {
        contentBlocks,
        setContentBlock,
        setContentBlocks,
        deleteContentBlock,
        parentContentBlocks,
        getParentByType,
    };
}

export function useGetContentBlockWithChildren(id: string) {
    const [contentBlocks] = useAtom(contentBlockAtom);

    const getContentBlockWithChildren = useCallback(
        (
            contentBlock: ContentBlockShape,
            contentBlocks: ContentBlockShape[],
        ): ContentBlockShape | null => {
            if (!contentBlock) return null;
            const childBlocks = contentBlocks
                .filter(
                    (block) =>
                        block.parent_content_block_id === contentBlock.id,
                )
                .sort((a, b) => a.weight - b.weight);

            if (!childBlocks?.length) {
                if (contentBlock?.contentBlocks?.length) {
                    return {
                        ...contentBlock,
                        contentBlocks: contentBlock.contentBlocks.sort(
                            (a, b) => a.weight - b.weight,
                        ),
                    };
                }

                return {
                    ...contentBlock,
                    contentBlocks: [],
                };
            }

            return {
                ...contentBlock,
                contentBlocks: childBlocks?.map((child) => {
                    return getContentBlockWithChildren(child, contentBlocks);
                }),
            };
        },
        [contentBlocks],
    );

    const contentBlockWithChildren = useMemo(() => {
        const contentBlock = contentBlocks[id];

        if (!contentBlock) {
            return null;
        }
        return getContentBlockWithChildren(
            contentBlock,
            Object.values(contentBlocks),
        );
    }, [contentBlocks]);

    return contentBlockWithChildren;
}


export function useGetContentBlockWithDirectChildren(id: string) {
    const [contentBlocks] = useAtom(contentBlockAtom);

    const getContentBlockWithChildren = useCallback(
        (
            contentBlock: ContentBlockShape,
            contentBlocks: ContentBlockShape[],
        ): ContentBlockShape | null => {
            if (!contentBlock) return null;
            const childBlocks = contentBlocks
                .filter(
                    (block) =>
                        block.parent_content_block_id === contentBlock.id,
                )
                .sort((a, b) => a.weight - b.weight);

            if (!childBlocks?.length) {
                if (contentBlock?.contentBlocks?.length) {
                    return {
                        ...contentBlock,
                        contentBlocks: contentBlock.contentBlocks.sort(
                            (a, b) => a.weight - b.weight,
                        ),
                    };
                }

                return {
                    ...contentBlock,
                    contentBlocks: [],
                };
            }

            return {
                ...contentBlock,
                contentBlocks: childBlocks
            };
        },
        [contentBlocks],
    );

    const contentBlockWithChildren = useMemo(() => {
        const contentBlock = contentBlocks[id];

        if (!contentBlock) {
            return null;
        }
        return getContentBlockWithChildren(
            contentBlock,
            Object.values(contentBlocks),
        );
    }, [contentBlocks]);

    return contentBlockWithChildren;
}
