import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModelVariable, VariableRelationship } from "@/models";

type BuilderWorkspaceSliceState = {
    targetVariable: ModelVariable;
    sourceVariables: { [variableId: string]: ModelVariable };
    sourceVariableRelationships: VariableRelationship[];
    sourceVariableRelationshipMap: {
        [index: string]: VariableRelationship;
    };
    availableVariablesMap: {
        [index: string]: ModelVariable;
    };
};

const initialState: BuilderWorkspaceSliceState = {
    targetVariable: undefined,
    sourceVariables: {},
    sourceVariableRelationships: [],
    sourceVariableRelationshipMap: {},
    availableVariablesMap: {},
};

const builderWorkspaceSlice = createSlice({
    name: "BuilderWorkspaceStore",
    initialState,
    reducers: {
        setTargetVariable(state, { payload }: PayloadAction<ModelVariable>) {
            state.targetVariable = payload;
        },
        setSourceVariables(
            state,
            { payload }: PayloadAction<{ [variableId: string]: ModelVariable }>
        ) {
            state.sourceVariables = payload;
        },
        setSourceVariableRelationships(
            state,
            { payload }: PayloadAction<VariableRelationship[]>
        ) {
            state.sourceVariableRelationships = payload;

            if (!!payload && !!payload.length) {
                state.sourceVariableRelationshipMap = payload.reduce(
                    (map, variableRelationship, index) => ({
                        ...map,
                        [variableRelationship.source_variable_id || index]:
                            variableRelationship,
                    }),
                    {}
                );
            } else {
                state.sourceVariableRelationshipMap = {};
            }
        },
        setAvailableVariablesMap(
            state,
            { payload }: PayloadAction<{ [variableId: string]: ModelVariable }>
        ) {
            state.availableVariablesMap = payload;
        },
    },
});

export const builderWorkspaceStoreReducer = builderWorkspaceSlice.reducer;

export const {
    setTargetVariable,
    setSourceVariables,
    setSourceVariableRelationships,
    setAvailableVariablesMap,
} = builderWorkspaceSlice.actions;
