import React, { ReactNode, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { ThemeObject } from "../../ThemeObjects";
import { accordionContext } from "./accordionContext";
import { StyledUniversalDiv } from "../Universal";

const StyledAccordionH2 = styled.h2<{
    hoverColor?: string;
    hoverBackgroundColor?: string;
}>`
    padding: 0;
    &:hover,
    &.expanded {
        color: ${(props) => (props.hoverColor ? props.hoverColor : "")};
        background-color: ${(props) =>
            props.hoverBackgroundColor ? props.hoverBackgroundColor : ""};
    }
`;

export function StyledAccordionHeader({
    children,
    parentContentBlockId,
    theme,
    contentBlockId,
    dontScroll,
    title,
}: {
    children: ReactNode;
    parentContentBlockId: string;
    theme: Partial<ThemeObject>;
    contentBlockId: string;
    dontScroll?: boolean;
    title?: string;
}) {
    const accordionContextInternal = useContext(accordionContext);
    const isExpanded =
        accordionContextInternal?.activeAccordionKeys?.indexOf(
            parentContentBlockId,
        ) !== -1;
    const accordionRef = useRef<HTMLHeadingElement | null>(null);
    const { scrollTo, easeOutQuart } = useScroll();
    useEffect(() => {
        if (isExpanded && !!accordionRef && !dontScroll) {
            setTimeout(() => {
                const navBar = document.querySelector(".navbar") as HTMLElement;
                const offset =
                    navBar && getComputedStyle(navBar).position === "fixed"
                        ? navBar.getBoundingClientRect().bottom + 5
                        : 20;
                const finalTarget = accordionRef.current.offsetTop - offset;

                scrollTo(finalTarget, 1000, easeOutQuart);
            }, 200);
        }
    }, [isExpanded]);

    return (
        <StyledAccordionH2
            ref={accordionRef}
            hoverColor={accordionContextInternal.hoverColor}
            hoverBackgroundColor={accordionContextInternal.hoverBackgroundColor}
            className={`transition-all ${isExpanded ? "expanded" : ""}`}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Accordion Header"}
        >
            <StyledUniversalDiv
                role="button"
                className={`relative
                flex
                w-full
                rounded-none
                border-0
                text-left
                text-base
                transition-all
                focus:outline-none ${
                    accordionContextInternal.variant === "small"
                        ? "items-start p-2"
                        : "items-center px-5 py-4"
                }`}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    accordionContextInternal.setActiveItemIndices(
                        parentContentBlockId,
                    );
                }}
            >
                <StyledUniversalDiv flex="1" textAlign="left">
                    {children}
                </StyledUniversalDiv>
                <svg
                    className={`z-[999999999] -mr-0.5 ml-2 h-5 w-5 transition-all ${
                        isExpanded ? "rotate-180" : "rotate-0"
                    } ${
                        accordionContextInternal.variant === "small"
                            ? "mt-2"
                            : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </StyledUniversalDiv>
        </StyledAccordionH2>
    );
}

export const defaultAccordionHeaderTheme: Partial<ThemeObject> = {
    my: 0,
    mx: 0,
    background: "rgba(0,0,0,0)",
};

/*--------------------------------------------
 Functions to make scroll with speed control
---------------------------------------------*/
function useScroll() {
    function scrollTo(
        Y: number,
        duration: number,
        easingFunction: (currentTime: number) => number,
        callback?: () => void,
    ) {
        const start = Date.now(),
            elem = !isNaN(document.documentElement.scrollTop)
                ? document.documentElement
                : document.body,
            from = elem.scrollTop;

        if (from === Y) {
            callback();
            return; /* Prevent scrolling to the Y point if already there */
        }

        function min(a: number, b: number) {
            return a < b ? a : b;
        }

        function scroll(timestamp: number) {
            var currentTime = Date.now(),
                time = min(1, (currentTime - start) / duration),
                easedT = easingFunction(time);

            elem.scrollTop = easedT * (Y - from) + from;

            if (time < 1) requestAnimationFrame(scroll);
            else if (callback) callback();
        }

        requestAnimationFrame(scroll);
    }

    /* bits and bytes of the scrollTo function inspired by the works of Benjamin DeCock */

    /*
     * Easing Functions - inspired from http://gizma.com/easing/
     * only considering the t value for the range [0, 1] => [0, 1]
     */
    return {
        scrollTo,
        // no easing, no acceleration
        linear: function (t: number) {
            return t;
        },
        // accelerating from zero velocity
        easeInQuad: function (t: number) {
            return t * t;
        },
        // decelerating to zero velocity
        easeOutQuad: function (t: number) {
            return t * (2 - t);
        },
        // acceleration until halfway, then deceleration
        easeInOutQuad: function (t: number) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        // accelerating from zero velocity
        easeInCubic: function (t: number) {
            return t * t * t;
        },
        // decelerating to zero velocity
        easeOutCubic: function (t: number) {
            return --t * t * t + 1;
        },
        // acceleration until halfway, then deceleration
        easeInOutCubic: function (t: number) {
            return t < 0.5
                ? 4 * t * t * t
                : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        // accelerating from zero velocity
        easeInQuart: function (t: number) {
            return t * t * t * t;
        },
        // decelerating to zero velocity
        easeOutQuart: function (t: number) {
            return 1 - --t * t * t * t;
        },
        // acceleration until halfway, then deceleration
        easeInOutQuart: function (t: number) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
        },
        // accelerating from zero velocity
        easeInQuint: function (t: number) {
            return t * t * t * t * t;
        },
        // decelerating to zero velocity
        easeOutQuint: function (t: number) {
            return 1 + --t * t * t * t * t;
        },
        // acceleration until halfway, then deceleration
        easeInOutQuint: function (t: number) {
            return t < 0.5
                ? 16 * t * t * t * t * t
                : 1 + 16 * --t * t * t * t * t;
        },
    };
}
