import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { EllipsisVertical, GripVertical } from "lucide-react";
import { createPortal } from "react-dom";
import RowEditor from "./RowEditor";

export function SortableTR({
    contentBlock,
    isInAdminContext,
    children,
    rowIndex,
}: {
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
    children: JSX.Element[];
    rowIndex: number;
}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: contentBlock.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: contentBlock.theme?.backgroundColor,
    };

    if (!isInAdminContext)
        return (
            <tr
                style={style}
                className={cn(
                    "group",
                    "relative",
                    contentBlock.theme.tailwindClasses,
                )}
                data-tr-id={contentBlock.id}
            >
                {children}
            </tr>
        );

    const trEl = useGetElementAsync(`tr[data-tr-id="${contentBlock.id}"]`);
    const popoverWrapper =
        trEl?.querySelector("td") || trEl?.querySelector("th");

    return (
        <>
            {!!popoverWrapper &&
                createPortal(
                    <RowEditor
                        tr={contentBlock}
                        row={rowIndex}
                        listeners={listeners}
                        isDragging={isDragging}
                    />,

                    popoverWrapper,
                )}
            <tr
                ref={setNodeRef}
                style={style}
                {...attributes}
                className={cn(
                    "group",
                    "relative",
                    contentBlock.theme.tailwindClasses,
                )}
                data-tr-id={contentBlock.id}
            >
                {children}
            </tr>
        </>
    );
}
