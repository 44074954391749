import { BaseModelType } from "./BaseModel";
import { ModelVariable } from "./ModelVariable";
import { TimeHorizon } from "./TimeHorizon";

export enum VariableRelationshipOperation {
    // arithmetic
    Add = "ADD",
    Subtract = "SUBTRACT",
    Multiply = "MULTIPLY",
    Divide = "DIVIDE",
    Exponentiate = "EXPONENTIATE",
    // logical
    "Logical And" = "LOGICAL_AND",
    "Logical Or" = "LOGICAL_OR",
    "Logical Not" = "LOGICAL_NOT",
    "Logical And Not" = "LOGICAL_AND_NOT",
    "Logical Or Not" = "LOGICAL_OR_NOT",
    // comparison
    "Equal To" = "EQUAL_TO",
    "Not Equal To" = "NOT_EQUAL_TO",
    "Less Than" = "LESS_THAN",
    "Less Than Or Equal To" = "LESS_THAN_OR_EQUAL_TO",
    "Greater Than" = "GREATER_THAN",
    "Greater Than Or Equal To" = "GREATER_THAN_OR_EQUAL_TO",
    // time shift
    "Shift Index" = "SHIFT_INDEX",
    "Shift Input" = "SHIFT_INPUT",
    // // question-related types
    // "Effect Value" = "EFFECT_VALUE",
    // "Test Value" = "TEST_VALUE",
    // "Reference Value" = "REFERENCE_VALUE",
    // "Ideal Position" = "IDEAL_POSITION",
    // "Range Boundary" = "RANGE_BOUNDARY",
}

export type VariableRelationship = BaseModelType & {
    source_variable_id: string;
    target_variable_id: string;
    source_time_horizon_id?: string;
    target_time_horizon_id?: string;
    operation_type?: VariableRelationshipOperation;
    weight: number;

    sourceVariable?: ModelVariable;
    targetVariable?: ModelVariable;
    sourceTimeHorizon?: TimeHorizon;
    targetTimeHorizon?: TimeHorizon;
};
