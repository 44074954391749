import React, { FC, ReactNode, useMemo } from "react";
import { ThemeObject } from "../../ThemeObjects";
import { StyledUniversalDiv } from "../Universal";
import { ContentBlockEvents } from "../ContentBlockEvents";
import {
    DesignLayerFormContentBlockProvider,
    FormContentBlockDefaultValuesObject,
    FormContentBlockProvider,
    FormValidationSchema,
} from "./FormContentBlockProvider";
import styled from "styled-components";
import { DynamicValidationRule } from "@/models";

type StyledFormContentBlockProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId: string;
    questionFormObject?: FormContentBlockDefaultValuesObject;
    onFormSubmit?: (
        formObject: FormContentBlockDefaultValuesObject
    ) => Promise<void>;
    validationRules: DynamicValidationRule[];
    isSubmitting: boolean;
};

const StyledForm = styled(StyledUniversalDiv)`
    &:has(.drag-and-drop-container) {
        width: 100% !important;
        max-width: 100%;
    }
`;

export const StyledFormContentBlock: FC<StyledFormContentBlockProps> = ({
    theme,
    children,
    contentBlockId,
    questionFormObject,
    onFormSubmit,
    validationRules,
    onClick,
    onHover,
    isSubmitting,
    isInEditorContext,
    title
}) => {



    if (!isInEditorContext) {
        return (
            <StyledForm
                width={theme.width || "auto"}
                {...theme}
                data-content-block-id={contentBlockId}
                onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                onMouseMove={
                    "function" === typeof onHover ? onHover : (evt) => {}
                }
                className="min-w-[70%]"
            >
                <FormContentBlockProvider
                    defaultValues={questionFormObject}
                    onSubmit={onFormSubmit}
                    validationRules={validationRules}
                    isSubmitting={isSubmitting}
                >
                    {children}
                </FormContentBlockProvider>
            </StyledForm>
        );
    }

    return (
        <StyledForm
            {...theme}
            width={theme.width || "auto"}
            className="min-w-[70%]"
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Form"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            <DesignLayerFormContentBlockProvider
                defaultValues={questionFormObject}
                onSubmit={onFormSubmit}
                validationRules={validationRules}
                isSubmitting={isSubmitting}
            >
                {children}
            </DesignLayerFormContentBlockProvider>
        </StyledForm>
    );
};

export const defaultFormTheme: Partial<ThemeObject> = {
    pt: 5,
    pb: 5,
    pl: 5,
    pr: 5,
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    backgroundColor: "#ffffff",
    width: "100%",
};
