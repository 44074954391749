import {
    ModalQueryStrings,
    modalSchema,
} from "@/Pages/Admin/editor/modals/types";
import { SapienInertia } from "@/inertia-utils/hooks";
import { addSearchParamsToUrl } from "@/util/urlHelper";

export function openModal(newQuery: ModalQueryStrings, callBack?: () => void) {
    const validatedQuery = modalSchema.safeParse(newQuery);
    if (validatedQuery.success) {
        try {
            if ("function" === typeof callBack) {
                callBack();
            }
            const url = addSearchParamsToUrl(
                location.href,
                validatedQuery.data as { [index: string]: string },
            )?.href;

            SapienInertia.visit(url, {
                only: ["locationInfo"],
                preserveState: true,
                preserveScroll: true,
            });
        } catch (error) {
            console.error("error", error);
        }
    } else {
        console.error(
            "Invalid query params passed to openModal",
            validatedQuery,
            (validatedQuery as any).error,
        );
    }
}
