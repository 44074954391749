import React, { FC, ReactNode } from "react";
import {
    ThemeObject,
    Display,
    AlignItems,
    AlignContent,
    JustifyItems,
    JustifyContent,
    FlexWrap,
    FlexDirection,
    BackgroundSize,
} from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";

interface StyledHeroImageProps {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    imageUrl: string;
    contentBlockId?: string;
    title?:string;
}

export const StyledHero: FC<StyledHeroImageProps> = ({
    theme,
    children,
    imageUrl,
    title,
    contentBlockId,
}) => {
    //TODO: figure out a cleaner way to handle background images
    const themeWithBackgroundImage = {
        ...theme,
        ...{ backgroundImage: `url(${imageUrl})` },
        ...{ backgroundSize: "cover" },
    };

    return (
        <StyledUniversalDiv
            {...themeWithBackgroundImage}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Hero"}
        >
            {children}
        </StyledUniversalDiv>
    );
};

export const defaultHeroTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff",
    borderColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: "solid",
    pt: 3,
    pb: 3,
    px: 0,
    mt: 0,
    mb: 0,
    mx: 0,
    width: ["100%"],
    height: ["75vh"],
    display: Display.flex,
    flexDirection: FlexDirection.column,
    flexWrap: FlexWrap.nowrap,
    alignItems: AlignItems.normal,
    alignContent: AlignContent.normal,
    justifyItems: JustifyItems.normal,
    justifyContent: JustifyContent.normal,
    backgroundSize: BackgroundSize.cover,
    backgroundPosition: "center center",
};
