import React from "react";
import { ContentBlockShape } from "@/models";
import { TrashIcon } from "lucide-react";
import { deleteContentBlock } from "@/Pages/ELearning/LessonDesign/content-block-management/useDeleteContentBlock";
import { useContentBlockState } from "@/Pages/ELearning/LessonDesign/useContentBlockState";

export function DeleteRowButton({
    tr,
}: {
    tr: ContentBlockShape;
}) {
    const {deleteContentBlock:removeContentBlockFromLocalState} = useContentBlockState();

    return (
        <button
            className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
            hover:bg-slate-100`}
            onClick={() => {
                removeContentBlockFromLocalState(tr.id);
                deleteContentBlock(tr.id);
            }}
        >
            <TrashIcon className="h-4 w-4" />
            <span>Delete Row</span>
        </button>
    );
}
