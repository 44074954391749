import { defaultBottomDrawerTheme } from "@/styles/themes/BottomDrawerTheme";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const BottomDrawerConfig: ContentBlockConfig = {
    typeKey: "Bottom Drawer",
    type: ContentBlockType["Bottom Drawer"],
    defaultTheme: defaultBottomDrawerTheme,
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType["Rich Text"],
        ContentBlockType.Button,
        ContentBlockType["Submission Count"]
    ],
    availableBehaviors: [
        "DisplayIfRoundIsComplete",
        "DisplayIfFacilitatorAllows",
        "DisplayIfRoundIsCompleteAndFacilitatorAllows",
        "DisplayIfParticipantHasSubmittedOnCurrentPage",
        "DisplayIfTeammateHasSubmittedOnCurrentPage",
        "DisplayIfRoundHasSubmissionFromTeam",
    ],
};
