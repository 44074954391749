import { useSaveContentBlock } from "@/Pages/ELearning/LessonDesign/content-block-management/useSaveContentBlock";
import { ContentBlockShape } from "@/models";
import { ArrowLeft, ArrowRight, TableColumnsSplit } from "lucide-react";
import React, { useCallback } from "react";
import { addColumn } from "./addColumn";
import { useContentBlockState } from "@/Pages/ELearning/LessonDesign/useContentBlockState";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";

export function AddColumnButton({
    column,
    table,
}: {
    table: ContentBlockShape;
    column: number;
}) {
    const { mutateAsync, isPending } = useSaveContentBlock();
    const tableEl = useGetElementAsync(`[data-content-block-id="${table.id}"]`);

    const handleAddColumn = useCallback(
        async (index: number) => {
            const updatedTable = addColumn(table, index);
            tableEl?.classList.add("animate-pulse");
            await mutateAsync(updatedTable);
            tableEl?.classList.remove("animate-pulse");
        },
        [column, table],
    );


    return (
        <>
            <button
                className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
                hover:bg-slate-100 ${isPending ? "cursor-wait" : "cursor-pointer"}`}
                onClick={() => handleAddColumn(column)}
            >
                <ArrowLeft className="h-4 w-4" />
                <span>Insert Column Left</span>
            </button>
            <button
                className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
                hover:bg-slate-100 ${isPending ? "cursor-wait" : "cursor-pointer"}`}
                onClick={() => handleAddColumn(column + 1)}
            >
                <ArrowRight className="h-4 w-4" />
                <span>Insert Column Right</span>
            </button>
        </>
    );
}
