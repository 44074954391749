import { ConfigComponentType, defaultQuestionTheme } from "../../styles";
import { ContentBlockType, PromptType } from "../../models";
import {
    ContentBlockConfig,
    VariantOption,
} from "../ContentBlockConfigInterface";

const borderStyle: VariantOption = {
    label: "Border Style",
    themeObjectKey: "bordered",
    configComponentType: ConfigComponentType.FormSelectEnumType,
    optionObject: {
        bordered: "bordered",
        "none": "not bordered",
    },
};

const highLightStyle = {
    label: "Highlight Style",
    themeObjectKey: "highLightStyle",
    configComponentType: ConfigComponentType.FormSelectEnumType,
    optionObject: {
        none: "none",
        grayscale: "grayscale",
        color: "color",
    },
};

const highLightColor = {
    label: "Highlight Color",
    themeObjectKey: "highlightColor",
    configComponentType: ConfigComponentType.FormInputColorPicker,
};

const sliderColor = {
    label: "Slider Color",
    themeObjectKey: "highlightColor",
    configComponentType: ConfigComponentType.FormInputColorPicker,
};

const checkMarkColor = {
    label: "Checkmark Color",
    themeObjectKey: "checkMarkColor",
    configComponentType: ConfigComponentType.FormInputColorPicker,
};

const spaced = {
    label: "Spacing",
    themeObjectKey: "spaced",
    configComponentType: ConfigComponentType.FormSelectEnumType,
    optionObject: {
        spaced: "spaced",
        "none": "not spaced",
    },
};

const sliderValueType = {
    label: "Display Slider Value",
    themeObjectKey: "sliderValueType",
    configComponentType: ConfigComponentType.FormSelectEnumType,
    optionObject: {
        none: "none",
        tooltip: "tooltip",
        "left": "label_on_left",
        "right": "label_on_right",
    },
};

export const variantsByPromptType: { [index: string]: VariantOption[] } = {
    [PromptType["Multiple Choice"]]: [
        borderStyle,
        highLightStyle,
        spaced,
        highLightColor,
    ],
    [PromptType["Multiple Select"]]: [
        borderStyle,
        highLightStyle,
        spaced,
        highLightColor,
        checkMarkColor
    ],
    [PromptType["Numerical Slider"]]: [
        borderStyle,
        highLightStyle,
        sliderValueType,
        sliderColor
    ],
};

export const QuestionConfig: ContentBlockConfig = {
    typeKey: "Question",
    type: ContentBlockType.Question,
    defaultTheme: defaultQuestionTheme,
    variantOptions: [],
    availableBehaviors: ["ShowOthersResponses"],
    forceTypographyStyles: true,
};
