import React, { useCallback } from "react";
import { useForm } from "@inertiajs/react";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ReflectionJournalEntry } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";
import styled from "styled-components";
import { ButtonGroup, TypographyType } from "@/styles";
import { Button } from "@/components";
import { TextType } from "@/modules/text-editor";
import { TypographyTypeValues } from "@/styles/SimulationTheme";

const TipTapStyles = styled.div`
    .ProseMirror-focused {
        border: none;
        outline: none;
    }
    /* Basic editor styles */
    .ProseMirror {
        > * + * {
            margin-top: 0.75em;
        }

        ul,
        ol {
            padding: 0 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
        }

        pre {
            background: #0d0d0d;
            color: #fff;
            font-family: "JetBrainsMono", monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;

            code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0d0d0d, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0d0d0d, 0.1);
            margin: 2rem 0;
        }
    }
`;

const typographyChoices: { [index: string]: TypographyTypeValues | "none" } = {
    Select: "none",
    "Heading 1": "FIGMA_HEADING_1",
    "Heading 2": "FIGMA_HEADING_2",
    "Heading 3": "FIGMA_HEADING_3",
    "Heading 4": "HEADING_6",
    Paragraph: "FIGMA_PARAGRAPH",
    "Paragraph Alt": "FIGMA_PARAGRAPH_ALT",
    Subtitle: "FIGMA_SUBTITLE",
};

const MenuBar = ({ editor }: { editor: Editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="flex flex-row flex-wrap @lg:gap-4 space-y-0 gap-1 p-2 bg-white rounded-t-md border border-b-0">
            <ButtonGroup background={"white"}>
                <select
                    style={{ border: "1px solid black", borderRight: 0 }}
                    className="border py-0"
                    value={
                        editor.getAttributes("typography")?.typographyType
                            ? editor.getAttributes("typography")?.typographyType
                            : "PARAGRAPH"
                    }
                    onChange={(e) => {
                        const typographyTypeKey = Object.keys(
                            TypographyType
                        ).find((key) => TypographyType[key] === e.target.value);

                        if (typographyTypeKey) {
                            editor
                                .chain()
                                .focus()
                                .setTypographyType(
                                    TypographyType[typographyTypeKey]
                                )
                                .run();
                        }
                    }}
                >
                    {Object.keys(typographyChoices).map((key) => (
                        <option value={typographyChoices[key]} key={key}>
                            {key}
                        </option>
                    ))}{" "}
                </select>

                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().toggleBold().run();
                    }}
                    className={editor.isActive("bold") ? "is-active" : ""}
                >
                    <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M17.061 11.22A4.46 4.46 0 0018 8.5C18 6.019 15.981 4 13.5 4H6v15h8c2.481 0 4.5-2.019 4.5-4.5a4.48 4.48 0 00-1.439-3.28zM13.5 7c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5H9V7h4.5zm.5 9H9v-3h5c.827 0 1.5.673 1.5 1.5S14.827 16 14 16z" />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().toggleItalic().run();
                    }}
                    className={editor.isActive("italic") ? "is-active" : ""}
                >
                    <svg
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M7.991 11.674L9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                    </svg>
                </Button>
            </ButtonGroup>
            <ButtonGroup background={"white"}>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().toggleBulletList().run();
                    }}
                    className={editor.isActive("bulletList") ? "is-active" : ""}
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z" />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().toggleOrderedList().run();
                    }}
                    className={
                        editor.isActive("orderedList") ? "is-active" : ""
                    }
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M920 760H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-568H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM216 712H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h72.4v20.5h-35.7c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h35.7V838H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4V716c0-2.2-1.8-4-4-4zM100 188h38v120c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4V152c0-4.4-3.6-8-8-8h-78c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4zm116 240H100c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h68.4l-70.3 77.7a8.3 8.3 0 00-2.1 5.4V592c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4v-36c0-2.2-1.8-4-4-4h-68.4l70.3-77.7a8.3 8.3 0 002.1-5.4V432c0-2.2-1.8-4-4-4z" />
                    </svg>
                </Button>
            </ButtonGroup>
            <ButtonGroup background={"white"}>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().setTextAlign("left").run();
                    }}
                    className={
                        editor.isActive({ textAlign: "left" })
                            ? "is-active"
                            : ""
                    }
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M120 230h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm0 424h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm784 140H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-424H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z" />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().setTextAlign("center").run();
                    }}
                    className={
                        editor.isActive({ textAlign: "center" })
                            ? "is-active"
                            : ""
                    }
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M264 230h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H264c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm496 424c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H264c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496zm144 140H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-424H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z" />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().setTextAlign("right").run();
                    }}
                    className={
                        editor.isActive({ textAlign: "right" })
                            ? "is-active"
                            : ""
                    }
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M904 158H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 424H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 212H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-424H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z" />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().setTextAlign("justify").run();
                    }}
                    className={
                        editor.isActive({ textAlign: "justify" })
                            ? "is-active"
                            : ""
                    }
                >
                    <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path d="M3 3h18v2H3V3m0 4h18v2H3V7m0 4h18v2H3v-2m0 4h18v2H3v-2m0 4h18v2H3v-2z" />
                    </svg>
                </Button>
            </ButtonGroup>
            <ButtonGroup background={"white"}>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().undo().run();
                    }}
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path
                            fill="currentColor"
                            d="M5.34 4.468h2v2.557a7 7 0 11-1.037 10.011l1.619-1.185a5 5 0 10.826-7.384h2.591v2h-6v-6z"
                        />
                    </svg>
                </Button>
                <Button
                    padding={".25rem"}
                    color={"white"}
                    onClick={(e) => {
                        e.preventDefault();
                        editor.chain().focus().redo().run();
                    }}
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        height="1.2rem"
                        width="1.2rem"
                    >
                        <path
                            fill="currentColor"
                            d="M13.146 11.05l-.174-1.992 2.374-.208a5 5 0 10.82 6.173l2.002.5a7 7 0 11-1.315-7.996l-.245-2.803L18.6 4.55l.523 5.977-5.977.523z"
                        />
                    </svg>
                </Button>
            </ButtonGroup>
        </div>
    );
};
type Props = {
    reflectionJournalEntry: ReflectionJournalEntry;
    cancel: () => void;
};

export default function ReflectionJournalForm({
    reflectionJournalEntry,
    cancel,
}: Props) {
    const { data, setData } = useForm<ReflectionJournalEntry>(
        reflectionJournalEntry
    );

    const editor = useEditor(
        {
            content: data.content,
            extensions: [StarterKit, TextType],
            onUpdate({ editor }) {
                const editTimeOut = setTimeout(() => {
                    clearTimeout(editTimeOut);
                    setData("content", editor.getJSON());
                }, 100);
            },
        },
        [data?.title]
    );

    const save = useCallback(() => {
        if (data.id) {
            SapienInertia.put(
                "reflection-journal.update",
                data,
                {
                    reflection_journal: data.id,
                },
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: [
                        "modalComponent",
                        "modalPageProps",
                        "reflectionJournalEntries",
                    ],
                }
            );
        } else {
            SapienInertia.post(
                "reflection-journal.store",
                data,
                {},
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: [
                        "modalComponent",
                        "modalPageProps",
                        "reflectionJournalEntries",
                    ],
                }
            );
        }
    }, [data]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                save();
            }}
        >
            <div className="lg:space-y-6 space-y-4 bg-blue-50 rounded-md p-4 shadow-xl">
                <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                        <label
                            htmlFor="reflection-journal-entry-title"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Title
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                                type="text"
                                name="reflection-journal-entry-title"
                                id="reflection-journal-entry-title"
                                className="block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Title"
                                value={data.title}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setData("title", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <label
                        htmlFor="reflection-journal-entry"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Text
                    </label>
                    <div className="mt-1 @container">
                        <MenuBar editor={editor} />
                        <TipTapStyles>
                            <EditorContent
                                editor={editor}
                                className="border rounded-b-md p-4 min-h-[8rem] bg-white"
                            />
                        </TipTapStyles>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            save();
                        }}
                        className="inline-flex items-center rounded-md border border-gray-300 bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
                    >
                        Save
                    </button>
                    <button
                        onClick={cancel}
                        className="inline-flex items-center rounded-md border border-red-500 bg-white px-4 py-2 text-sm font-medium text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
}
