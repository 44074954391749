import { Round, RoundShape } from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state/AppStore";
import { useCallback } from "react";
import { setEditedRound, roundsQueried } from "@/redux-state/roundDataSlice";

export function useRoundStore() {
    const dispatch = useAppDispatch();
    const roundMap = useAppSelector((state) => state.roundStore.rounds);
    const rounds = useAppSelector((state) =>
        Object.values(state.roundStore.rounds)
    );

    const editedRound = useAppSelector(
        (state) => state.roundStore.appState.editedRound
    );

    const _gotRounds = useCallback(
        (rounds: RoundShape[]) => dispatch(roundsQueried(rounds)),
        [dispatch]
    );

    const _setEditedRound = useCallback(
        (round: Round) => dispatch(setEditedRound(round)),
        [dispatch]
    );

    return {
        rounds,
        roundMap,
        editedRound,
        gotRounds: _gotRounds,
        setEditedRound: _setEditedRound,
    };
}
