import { BaseModel, IBaseModel } from "./BaseModel";
import { PromptType } from "./Prompt";

export type SelectionShape = {
    id?: string;
    numerical_value: number;
    string_value: string;
    is_selected: boolean;
    prompt_text: string;
    option_text: string;
    option_id: string;
    prompt_id: string;
    simulation_id: string;
    team_id: string;
    user_id: string;

    //joined columns
    team_name?: string;
    user_name?: string;
    content_block_id?: string;
    prompt_type?: PromptType;
    scope?: "Participant" | "Team";
    round_title?: string;
    round_id?: string;
    round_weight?: number;
    prompt_weight?: number;
    drag_and_drop_prompt_container_id?: string;
};

export class Selection extends BaseModel implements IBaseModel, SelectionShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "Selection";
    public static END_POINT: string = "/api/selections/";

    // content: string = "";
    numerical_value: number = 0;
    string_value: string = "";
    is_selected: boolean = false;
    // rank_position: number = 0; // TODO: ADD TO DATABASE?
    prompt_text: string = "";
    option_text: string = "";
    option_id: string = "";
    prompt_id: string = "";
    simulation_id: string = "";
    team_id: string;
    user_id: string = "";

    //joined columns
    team_name?: string;
    user_name?: string;
    content_block_id?: string;
    prompt_type?: PromptType;
    scope?: "Participant" | "Team";
    round_title?: string;
    round_id?: string;
    round_weight?: number;
    drag_and_drop_prompt_container_id?: string;
}
