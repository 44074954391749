import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    setSidebarOpen,
    setWrappingElementIsPresent,
} from "@/redux-state/sidebarStateSlice";
import { useCallback } from "react";

export const useSidebarState = () => {
    const dispatch = useAppDispatch();

    const isSidebarOpen = useAppSelector((state) => state?.sidebarState.isOpen);

    const _setIsSidebarOpen = useCallback(
        (newOpenState: boolean) => {
            dispatch(setSidebarOpen(newOpenState));
        },
        [dispatch, isSidebarOpen]
    );

    const isWrappingElementPresent = useAppSelector(
        (state) => state.sidebarState.isWrappingElementIsPresent
    );

    const _setIsWrappingElementIsPresent = useCallback(
        (isPresent: boolean) => {
            dispatch(setWrappingElementIsPresent(isPresent));
        },
        [dispatch, isSidebarOpen]
    );

    const childrenOfPortalChanged = useAppSelector(
        (state) => state.sidebarState.portalNeedsRefresh
    );
    const _setChildrenOfPortalChanged = useCallback(() => {}, [dispatch]);

    return {
        isSidebarOpen,
        setIsSidebarOpen: _setIsSidebarOpen,
        isWrappingElementPresent,
        setIsWrappingElementIsPresent: _setIsWrappingElementIsPresent,
        childrenOfPortalChanged,
        setChildrenOfPortalChanged: _setChildrenOfPortalChanged,
    };
};
1;
