import React from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useAddContentBlockState } from "./useAddContentBlockState";
import { LayoutType, getContentBlockConfigByType } from "@/model-configs";
import AddContentBlockButtonSlot from "./AddContentBlockButtonSlot";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";

type Props = {
    contentBlock: ContentBlockShape;
    depth: number;
};

function canBeClicked(
    contentBlockType: ContentBlockType,
    addedContentBlockType: ContentBlockType | LayoutType,
) {
    const config = getContentBlockConfigByType(contentBlockType);

    return (
        config?.addableChildBlocks?.includes(
            addedContentBlockType as ContentBlockType,
        ) ||
        config?.addableLayouts?.includes(addedContentBlockType as LayoutType)
    );
}

export default function AddContentBlockButton({ contentBlock, depth }: Props) {
    const { addContentBlockState, isAddingBehaviorBlock, isAddingQuestion } =
        useAddContentBlockState();

    const { data: activeContentBlock } = useGetActiveContentBlock();

    if (
        canBeClicked(
            contentBlock.content_block_type,
            addContentBlockState.newModalTriggerType ||
                (addContentBlockState.addableContentBlockMetaData
                    ?.blockOrLayoutType as ContentBlockType),
        ) ||
        (!addContentBlockState.addableContentBlockMetaData &&
            contentBlock.id !== activeContentBlock?.id)
    ) {
        return (
            <AddContentBlockButtonSlot
                contentBlock={contentBlock}
                isAddingBehaviorBlock={isAddingBehaviorBlock}
                isAddingModalToExistingContentBlock={
                    addContentBlockState?.isAddingModalToExistingContentBlock
                }
                isAddingQuestion={isAddingQuestion}
                addableContentBlockMetaData={
                    addContentBlockState?.addableContentBlockMetaData
                }
                newModalTriggerType={addContentBlockState?.newModalTriggerType}
                depth={depth}
            />
        );
    }

    return <></>;
}
