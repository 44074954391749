import React from "react";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import "./flip-cards.css";
type Props = {
    children: React.ReactNode | React.ReactNode[];
    contentBlock: ContentBlockShape;
    isInAdminContext: boolean;
};

export default function FlipCard({
    children,
    contentBlock,
    isInAdminContext,
}: Props) {
    const [isFlipped, setIsFlipped] = React.useState(false);

    return (
        <>
            <div
                className="perspective-750 h-96 w-64"
                data-content-block-id={contentBlock.id}
            >
                <div
                    className={`data-[side='back']:-rotate-y-180 transform-style-3d relative h-full w-full
                    origin-right rounded-lg bg-white shadow-[0_5px_10px_rgba(0,0,0,0.15)]
                    transition-all duration-500 ease-in-out data-[side='back']:-translate-x-full
                    hover:shadow-[0_5px_15px_rgba(0,0,0,0.25)]
                    ${cn(contentBlock?.theme.tailwindClasses)}`}
                    role="button"
                    onClick={() => {
                        setIsFlipped(!isFlipped);
                    }}
                    data-side={isFlipped ? "back" : "front"}
                >
                    {children}

                </div>
            </div>
        </>
    );
}
