import React, { useCallback, useMemo } from "react";
import { Prompt, SelectionShape } from "@/models";
import { QuestionContentBlockFormFieldDisplayProps } from "../types";
import { DragAndDropItem } from "./types";
import { DragAndDropContextWrapper } from "./DragAndDropContextWrapper";

const mapSelectionShapesByOptionId = (selectionShapes: SelectionShape[]) => {
    return selectionShapes?.length
        ? selectionShapes.reduce((carry, selectionShape) => {
              return {
                  ...carry,
                  [selectionShape.option_id]: selectionShape,
              };
          }, {})
        : {};
};

export const DragAndDropItemChild: React.FC<{ id: string; text: string }> = ({
    id,
    text,
}) => {
    return (
        <div
            key={id}
            style={{
                backgroundColor: "#E9D8FD",
                borderRadius: "6px",
                padding: "8px",
            }}
        >
            {text || "option text"}
        </div>
    );
};

export const QuestionContentBlockDragDropComponent = (
    props: QuestionContentBlockFormFieldDisplayProps & {
        prompt: Prompt;
        optionRenderMap: { [index: string]: JSX.Element | JSX.Element[] };
        isInDesignContext: boolean;
    }
) => {
    const {
        controlledFormFieldData,
        updateControlledFormFieldData,
        prompt,
        optionRenderMap,
        isInDesignContext,
    } = props;

    const { options, selectionContainer, optionContainers } = prompt;

    const handleAction = useCallback(
        (dragIdsGroupedByDropId: { [index: string]: string[] }) => {
            if (
                !updateControlledFormFieldData ||
                !controlledFormFieldData?.length
            ) {
                return;
            }

            const mappedSelectionShapes = mapSelectionShapesByOptionId(
                controlledFormFieldData
            );

            let newData: SelectionShape[] = [];

            Object.keys(dragIdsGroupedByDropId).forEach((dropId) => {
                dragIdsGroupedByDropId[dropId].forEach((dragId, index) => {
                    let newSelectionShape: SelectionShape = {
                        ...mappedSelectionShapes[dragId],
                        is_selected: dropId === selectionContainer.id,
                        numerical_value: index, // TODO: handle different selection container types
                    };

                    newData = [...newData, newSelectionShape];
                });
            });

            updateControlledFormFieldData(newData);
        },
        [
            controlledFormFieldData,
            updateControlledFormFieldData,
            selectionContainer,
        ]
    );

    const dndItems: DragAndDropItem[] = useMemo(() => {
        const mappedSelectionShapes = mapSelectionShapesByOptionId(
            controlledFormFieldData
        );

        return options?.map((option) => {

            const originalContainer = [
                ...optionContainers,
            ].find(
                (container) =>
                    container.id === option.drag_and_drop_prompt_container_id
            );

            return {
                option_id: option.id,
                is_selected: mappedSelectionShapes[option.id]?.is_selected,
                drag_and_drop_prompt_container_id: mappedSelectionShapes[
                    option.id
                ]?.is_selected
                    ? selectionContainer.id
                    : option.drag_and_drop_prompt_container_id,
                option_size: option.size,
                selection_numerical_value: !!mappedSelectionShapes[option.id]
                    ? mappedSelectionShapes[option.id].numerical_value
                    : option.weight,
                option_numerical_value: option.numerical_value,
                option_text: option.content,
                color: originalContainer?.theme?.backgroundColor || "#ffffff",
                child: optionRenderMap[option.id] ? (
                    optionRenderMap[option.id]
                ) : (
                    <DragAndDropItemChild
                        id={option.id}
                        text={option.content}
                    />
                ),
            };
        });
    }, [prompt, controlledFormFieldData, selectionContainer, optionRenderMap]);

    if (!optionContainers?.length || !selectionContainer) return <></>;

    return (
        <DragAndDropContextWrapper
            optionContainers={optionContainers}
            selectionContainer={selectionContainer}
            dragItems={dndItems}
            onDragEnd={handleAction}
            isInDesignContext={isInDesignContext}
        />
    );
};
