import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, PromptShape } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { useContentBlockState } from "../useContentBlockState";

export function useSaveOption() {
    const { setContentBlock } = useContentBlockState();
    return useMutation({
        mutationFn: async (prompt: PromptShape) => {
            const { data } = await sapienAxios.post<
                { contentBlock: ContentBlockShape },
                "e-learning.options.store"
            >("e-learning.options.store", {
                weight: prompt?.options?.length || 0,
                prompt_id: prompt?.id,
            });

            return data?.contentBlock;
        },
        onSuccess(contentBlock) {
            setContentBlock(contentBlock);
        },
    });
}
