import { ModelBlock } from "../../models";
import { normalize } from "normalizr";
import { AppDispatch, extraThunkArguments } from "../AppStore";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    gotEntities,
    ModelBuilderSliceState,
    resetEntities,
} from "./sharedActions";

type modelBlockSliceState = Pick<
    ModelBuilderSliceState,
    "modelBlocks" | "selectedModelBlock"
>;

const initialState: modelBlockSliceState = {
    modelBlocks: {},
    selectedModelBlock: undefined,
};

const modelBlockSlice = createSlice({
    name: "ModelBlockStore",
    initialState,
    reducers: {},
    extraReducers: {
        [gotEntities.toString()]: (
            state,
            { payload }: PayloadAction<Partial<ModelBuilderSliceState>>
        ) => {
            state.modelBlocks = {
                // ...state.modelBlocks,
                ...payload.modelBlocks,
            };
        },
        [resetEntities.toString()]: (
            state,
            { payload }: PayloadAction<Partial<ModelBuilderSliceState>>
        ) => {
            state.modelBlocks = {};
        },
    },
});

export const gotModelBlocks = (modelBlocks: ModelBlock[]) => {
    return async (
        dispatch: AppDispatch,
        _,
        { api, schema }: extraThunkArguments
    ) => {
        if (Array.isArray(modelBlocks)) {
            const entities = normalize<any, ModelBuilderSliceState>(
                modelBlocks,
                schema.modelBlocksSchema
            ).entities;
            dispatch(gotEntities(entities));
        }
    };
};

export const modelBlockStoreReducer = modelBlockSlice.reducer;
