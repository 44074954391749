import { useState, useMemo, useEffect, useCallback } from "react";
import {
    useSensors,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    DragStartEvent,
    DragOverEvent,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove as dndKitArrayMove,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { DragAndDropItem, OptionContainer, SelectionContainer } from "./types";

const arrayMove = (array: string[], oldIndex: number, newIndex: number) => {
    return dndKitArrayMove(array, oldIndex, newIndex);
};
const removeAtIndex = (array: string[], index: number) => {
    return [...array.slice(0, index), ...array.slice(index + 1)];
};
const insertAtIndex = (array: string[], index: number, item: string) => {
    return [...array.slice(0, index), item, ...array.slice(index)];
};

export const useDragAndDropBoard = (
    optionContainers: OptionContainer[],
    selectionContainers: SelectionContainer[],
    dragItems: DragAndDropItem[],
    isInDesignContext: boolean,
    onDragEnd: (dragIdsGroupedByDropId: { [index: string]: string[] }) => void
) => {
    const moveBetweenContainers = (
        dragIdsGroupedByDropId: {
            [index: string]: string[];
        },
        activeContainerId: string,
        activeIndex: number,
        overContainerId: string,
        overIndex: number,
        dragItemId: string
    ) => {
        return {
            ...dragIdsGroupedByDropId,
            [activeContainerId]: removeAtIndex(
                dragIdsGroupedByDropId[activeContainerId],
                activeIndex
            ),
            [overContainerId]: insertAtIndex(
                dragIdsGroupedByDropId[overContainerId],
                overIndex,
                dragItemId
            ),
        };
    };

    const [activeId, setActiveId] = useState<string | null>(null);
    const [
        dragItemIdsGroupedByDropContainer,
        setDragItemIdsGroupedByDropContainer,
    ] = useState<{
        [index: string]: string[];
    }>({});

    const dropContainers = useMemo(() => {
        console.log("useMemo called");
        return [...optionContainers, ...selectionContainers];
    }, [optionContainers]);

    useEffect(() => {
        if (!!dropContainers?.length && !!dragItems?.length) {
            let newItemGroups: { [index: string]: string[] } =
                dropContainers.reduce((map, dropContainer) => {
                    return {
                        ...map,
                        [dropContainer.id]: dragItems
                            .filter((dragItem) => {
                                if (dragItem.is_selected) {
                                    return dropContainer.is_selection_container;
                                } else {
                                    return (
                                        dragItem.drag_and_drop_prompt_container_id ===
                                        dropContainer.id
                                    );
                                }
                            })
                            .sort(
                                (a, b) =>
                                    a.selection_numerical_value -
                                    b.selection_numerical_value
                            )
                            .map((dragItem) => dragItem.option_id),
                    };
                }, {});

            setDragItemIdsGroupedByDropContainer(newItemGroups);
        }
    }, [dropContainers, dragItems]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            /**
             * DND Kit events interfere with click events used by designers, so
             * Require the mouse to move by 1000 pixels before activating if we're in design context
             */
            activationConstraint: {
                distance: isInDesignContext ? 1000 : 10,
            },
        }),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = ({ active }: DragStartEvent) =>
        setActiveId(active?.id?.toString());

    const handleDragCancel = () => setActiveId(null);

    const [overId, setOverId] = useState<string | null>(null);
    const handleDragOver = useCallback(
        (event: DragOverEvent) => {
            const { active, over } = event;

            if (!over?.id || over.id === overId || overId === active?.id) {
                return;
            }
            setOverId(over.id as string);

            const originalItem = dragItems.find(
                (dragItem) => dragItem.option_id === active.id
            );

            const activeContainerId =
                active?.data?.current?.sortable?.containerId;
            const overContainerId =
                over?.data?.current?.sortable?.containerId || over.id;

            if (!!originalItem && activeContainerId !== overContainerId) {
                setDragItemIdsGroupedByDropContainer(
                    (dragItemIdsGroupedByDropContainer) => {
                        const activeIndex =
                            active?.data?.current?.sortable?.index;
                        const overIndex =
                            over.id in dragItemIdsGroupedByDropContainer
                                ? dragItemIdsGroupedByDropContainer[
                                      overContainerId
                                  ].length + 1
                                : over?.data?.current?.sortable?.index;

                        return moveBetweenContainers(
                            dragItemIdsGroupedByDropContainer,
                            activeContainerId,
                            activeIndex,
                            overContainerId,
                            overIndex,
                            active.id as string
                        );
                    }
                );
            }
        },
        [overId, dragItems]
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            // if (isInDesignContext) return false;
            console.log("called handleDragEnd");
            const { active, over } = event;

            if (!over) {
                setActiveId(() => null);
                return;
            }

            const activeContainerId =
                active?.data?.current?.sortable?.containerId;
            const overContainerId =
                over?.data?.current?.sortable?.containerId || over.id;

            const activeIndex = active.data.current?.sortable.index;
            const overIndex =
                over.id in dragItemIdsGroupedByDropContainer
                    ? dragItemIdsGroupedByDropContainer[overContainerId]
                          .length + 1
                    : over.data.current?.sortable.index;

            let newDragItemIdsGroupedByDropContainer: {
                [index: string]: string[];
            };

            if (activeContainerId === overContainerId) {
                newDragItemIdsGroupedByDropContainer = {
                    ...dragItemIdsGroupedByDropContainer,
                    [overContainerId]: arrayMove(
                        dragItemIdsGroupedByDropContainer[overContainerId],
                        activeIndex,
                        overIndex
                    ),
                };
            } else {
                newDragItemIdsGroupedByDropContainer = moveBetweenContainers(
                    dragItemIdsGroupedByDropContainer,
                    activeContainerId,
                    activeIndex,
                    overContainerId,
                    overIndex,
                    active.id as string
                );
            }

            setDragItemIdsGroupedByDropContainer(
                newDragItemIdsGroupedByDropContainer
            );
            onDragEnd(newDragItemIdsGroupedByDropContainer);
            setActiveId(() => null);
        },
        [dragItemIdsGroupedByDropContainer, onDragEnd]
    );

    return {
        sensors,
        dragItemIdsGroupedByDropContainer,
        setDragItemIdsGroupedByDropContainer,
        handleDragCancel,
        handleDragEnd,
        handleDragOver,
        handleDragStart,
        activeId,
        setActiveId,
    };
};

export const useTimelineBoard = (
    optionContainers: OptionContainer[],
    selectionContainers: SelectionContainer[],
    dragItems: DragAndDropItem[],
    isInDesignContext: boolean,
    onDragEnd: (dragAndDropItems: DragAndDropItem[]) => void
) => {
    const [statefulDragItems, setStatefulDragItems] =
        useState<DragAndDropItem[]>(dragItems);

    useEffect(() => {
        setStatefulDragItems(dragItems);
    }, [dragItems?.length]);

    const [overId, setOverId] = useState<string | null>(null);
    const [overIndex, setOverIndex] = useState<number | null>(null);
    const [isAllowedToDrop, setIsAllowedToDrop] = useState<boolean>(true);
    const [overDroppedId, setOverDroppedId] = useState<string>("");

    const {
        sensors,
        handleDragCancel,
        handleDragStart,
        dragItemIdsGroupedByDropContainer,
        setDragItemIdsGroupedByDropContainer,
        activeId,
        setActiveId,
    } = useDragAndDropBoard(
        optionContainers,
        selectionContainers,
        statefulDragItems,
        isInDesignContext,
        () => {}
    );

    const getOverIndices = (
        overId: string | null,
        overIndex: number | undefined,
        activeId: string,
        statefulDragItems: DragAndDropItem[]
    ) => {
        if (!overId || overIndex === undefined) return [];

        const activeItem = statefulDragItems.find(
            (item) => item.option_id === activeId
        );

        if (!activeItem) return [];

        return [...Array(activeItem.option_size)].map(
            (_, idx) => idx + overIndex
        );
    };

    const [overIndices, setOverIndices] = useState([]);

    const getIsAllowedToDrop = (
        overId: string,
        overIndex: number,
        activeId: string,
        overIndices: number[]
    ) => {
        const overContainer = [
            ...selectionContainers,
            ...optionContainers,
        ].find((container) => {
            return container.id === overId;
        });

        const item = statefulDragItems.find(
            (dragItem) => dragItem.option_id === activeId
        );

        //target row
        const targetRow =
            Math.ceil(overIndex / overContainer?.column_count) || 1;

        const itemFits =
            !!overContainer &&
            !!item &&
            (item?.option_size + overIndex <=
                overContainer.column_count * targetRow ||
                !overContainer.is_selection_container);

        const itemAlreadyPresent = statefulDragItems.find((stateFulItem) => {
            if (
                //item can move within the same container, even if it overlaps itself
                stateFulItem.drag_and_drop_prompt_container_id ===
                    item?.option_id ||
                stateFulItem.drag_and_drop_prompt_container_id !== overId
            ) {
                return false;
            }

            return !!overIndices.find((idx) => {
                return (
                    idx >= Number(stateFulItem.selection_numerical_value) &&
                    idx <=
                        stateFulItem.option_size +
                            Number(stateFulItem.selection_numerical_value) -
                            1
                );
            });
        });

        return itemFits && !itemAlreadyPresent;
    };

    const handleTimelineDragOver = useCallback(
        (event: DragOverEvent) => {
            setOverIndices(() => []);

            const { over } = event;
            if (!over?.id) {
                return;
            }
            const splitOverId = (over.id as string).split("_");
            const overContainerId =
                over?.data?.current?.containerId || splitOverId[0];

            let overIndex = -1;
            if (over?.data?.current?.parentContainerId) {
                setOverIndex(() => -1);
                setOverDroppedId(() => over.id as string);
            } else {
                setOverDroppedId(() => "");

                overIndex =
                    over?.data?.current?.index || Number(splitOverId[1]);

                if (!isNaN(overIndex)) {
                    setOverIndex(() => overIndex);
                } else {
                    setOverIndex(() => -1);
                }
            }

            const overIndices = getOverIndices(
                overContainerId,
                overIndex,
                activeId as string,
                statefulDragItems
            );

            const isAllowedToDrop = getIsAllowedToDrop(
                overContainerId,
                overIndex,
                activeId as string,
                overIndices
            );

            setOverIndices(() => overIndices);
            setIsAllowedToDrop(() => isAllowedToDrop);
            setOverId(() => overContainerId);
        },
        [getIsAllowedToDrop, overIndices, statefulDragItems]
    );

    const handleTimelineDragEnd = useCallback(
        (event: DragEndEvent) => {
            let { active, over } = event;
            if (!over) {
                setActiveId(() => null);
                setIsAllowedToDrop(() => false);
                return;
            }
            const isOptionContainer = over?.data?.current?.isOptionContainer;
            const splitOverId = (over.id as string).split("_")[0];

            if (isOptionContainer) {
                const item = statefulDragItems.find((item) => {
                    return item.option_id === active.id;
                });
                const optionContainerTargetId =
                    item?.option_drag_and_drop_prompt_container_id;
                if (splitOverId !== optionContainerTargetId) {
                    setActiveId(() => null);
                    setIsAllowedToDrop(() => false);
                    return;
                }
            }

            if (!isAllowedToDrop && !isOptionContainer) {
                setActiveId(() => null);
                setIsAllowedToDrop(() => false);
                return;
            }

            const newOptionContainers = optionContainers.reduce(
                (carry, optionContainer) => {
                    return {
                        ...carry,
                        [optionContainer.id]: statefulDragItems.filter(
                            (item) => {
                                return (
                                    (item.drag_and_drop_prompt_container_id ===
                                        optionContainer.id &&
                                        item.option_id !== active.id) ||
                                    (item.option_id === active.id &&
                                        optionContainer.id === splitOverId)
                                );
                            }
                        ),
                    };
                },
                {}
            );

            const newSelectionContainers = selectionContainers.reduce(
                (carry, selectionContainer) => {
                    const splitContainerId =
                        selectionContainer.id.split("_")[0];
                    const splitOverContainerIndex = Number(
                        selectionContainer.id.split("_")[1]
                    );
                    const option = dragItems.find(
                        (dragItem) => dragItem.option_id === activeId
                    );

                    if (
                        !option ||
                        splitContainerId !== splitOverId ||
                        overIndex < splitOverContainerIndex ||
                        splitOverContainerIndex - overIndex > option.option_size
                    )
                        return carry;

                    return {
                        ...carry,
                        [selectionContainer.id]: [
                            ...dragItemIdsGroupedByDropContainer[
                                selectionContainer.id
                            ],
                            activeId,
                        ],
                    };
                },
                {}
            );

            setStatefulDragItems((previousStatefulDragItems) =>
                previousStatefulDragItems.map((dragItem) => {
                    return dragItem.option_id !== active?.id
                        ? dragItem
                        : {
                              ...dragItem,
                              selection_numerical_value: Number(overIndex),
                              drag_and_drop_prompt_container_id: splitOverId,
                              is_selected: !!selectionContainers.find(
                                  (selectionContainer) =>
                                      selectionContainer.id === splitOverId
                              ),
                          };
                })
            );

            const newContainers = {
                ...newOptionContainers,
                ...newSelectionContainers,
            };

            const finalDragAndDropItemsByContainer = { ...newContainers };
            setDragItemIdsGroupedByDropContainer(
                finalDragAndDropItemsByContainer
            );
            onDragEnd(
                statefulDragItems.map((dragItem) => {
                    return dragItem.option_id !== active?.id
                        ? dragItem
                        : {
                              ...dragItem,
                              selection_numerical_value: Number(overIndex),
                              drag_and_drop_prompt_container_id: splitOverId,
                              is_selected: !!selectionContainers.find(
                                  (selectionContainer) =>
                                      selectionContainer.id === splitOverId
                              ),
                          };
                })
            );
            setActiveId(() => null);
        },
        [
            dragItemIdsGroupedByDropContainer,
            onDragEnd,
            selectionContainers,
            dragItems,
            activeId,
            statefulDragItems,
            overIndex,
            isAllowedToDrop,
        ]
    );

    return {
        sensors,
        dragItemIdsGroupedByDropContainer,
        handleDragCancel,
        handleTimelineDragEnd,
        handleDragOver: handleTimelineDragOver,
        handleDragStart,
        activeId,
        dragItems: statefulDragItems,
        overId,
        overIndex,
        isAllowedToDrop,
        overDroppedId,
        overIndices,
    };
};
