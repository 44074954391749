import React, { FC, ReactNode, useEffect, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import styled from "styled-components";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";

type TabsTheme = ThemeObject & {
    tabStyle: "pill" | "underline" | "button";
    activeBackgroundColor?: string;
    activeColor?: string;
    activeBorderColor?: string;
    hoverBackgroundColor?: string;
    hoverColor?: string;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    disabledBorderColor?: string;
    disabledBackgroundColor?: string;
    disabledColor?: string;
};

type StyledTabsProps = ContentBlockEvents & {
    theme: Partial<TabsTheme>;
    tabObject: { [index: string]: string };
    activeId?: string;
    children?: ReactNode[];
    contentBlockId: string;
    overrideComponent?: JSX.Element;
    title?: string;
};

type StyledComponentTabProps = TabsTheme & {
    active: boolean;
    disabled: boolean;
};

const StyledTab = styled.div<StyledComponentTabProps>`
    border-radius: ${(props) => {
        switch (props.tabStyle) {
            case "pill":
                return "100px";
            case "button":
                return "8px";
            default:
                return "0px";
        }
    }};
    padding: ${(props) => {
        switch (props.tabStyle) {
            case "pill":
                return "6px 48px";
            case "underline":
                return "12px";
            case "button":
                return "6px 48px";
        }
    }};
    margin: ${(props) => {
        switch (props.tabStyle) {
            case "underline":
                return "0";
            default:
                return "10px 20px 10px 0px";
        }
    }};
    color: ${(props) => {
        if (props.active) {
            return props.activeColor;
        } else {
            return props.color;
        }
    }};
    background-color: ${(props) => {
        if (props.tabStyle === "underline") return "transparent";
        if (props.active) {
            return props.activeBackgroundColor;
        }
        if (props.disabled) {
            return props.disabledBackgroundColor;
        } else {
            return props.backgroundColor;
        }
    }};
    border-bottom: ${(props) =>
        props.active && props.tabStyle === "underline"
            ? `1px solid ${props.activeBorderColor}`
            : ""};

    border: ${(props) => {
        if (props.tabStyle === "underline") {
            return;
        }
        if (props.active) {
            return `1px solid ${props.activeBorderColor}`;
        }
        if (props.disabled) {
            return `1px solid ${props.disabledBorderColor}`;
        } else {
            return `1px solid ${props.borderColor}`;
        }
    }};

    &:hover {
        background-color: ${(props) => props.hoverBackgroundColor};
        color: ${(props) => props.hoverColor};
        border: ${(props) =>
            props.tabStyle !== "underline"
                ? `1px solid ${props.hoverBorderColor}`
                : ""};
    }

    &:disabled {
        background: ${(props) => props.disabledBackgroundColor};
        color: ${(props) => props.disabledColor};
        border: ${(props) => ` 1px solid ${props.disabledBorderColor}`};
    }
`;

export const StyledTabs: FC<StyledTabsProps> = ({
    theme,
    tabObject,
    children,
    contentBlockId,
    activeId,
    title,
}) => {
    const filledTheme = { ...defaultTabsTheme, ...theme };

    if (!tabObject || !Object.keys(tabObject).length) return null;

    const [activeTab, setActiveTab] = useState(
        activeId in tabObject ? activeId : Object.keys(tabObject)[0],
    );

    useEffect(() => {
        if (activeId in tabObject) {
            setActiveTab(activeId);
        }
    }, [activeId]);

    return (
        <StyledUniversalDiv
            {...theme}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Tabs"}
            width={1}
            backgroundColor={"transparent"}
        >
            <Tabs.Root
                onValueChange={setActiveTab}
                value={activeTab}
                // defaultValue={activeTab} //{Object.keys(tabObject)[0]}
            >
                <Tabs.List>
                    {tabObject !== undefined &&
                        Object.keys(tabObject).map((tabKey, i) => (
                            <Tabs.Trigger
                                key={tabKey}
                                value={tabKey}
                                style={{
                                    backgroundColor: "transparent",
                                    color: theme.color,
                                    border: "none",
                                    padding: 0,
                                    margin: 0,
                                    boxShadow: "none",
                                }}
                                data-content-block-id={tabKey}
                                // onClick={(e) => {
                                //     e.stopPropagation();
                                //     e.preventDefault();
                                //     onClick(e);
                                // }}
                                className="relative z-[1000]"
                            >
                                <StyledTab
                                    active={activeTab === tabKey}
                                    tabStyle={theme.tabStyle}
                                    disabled={false}
                                    {...filledTheme}
                                >
                                    {tabObject[tabKey]}
                                </StyledTab>
                            </Tabs.Trigger>
                        ))}
                </Tabs.List>
                {children !== undefined &&
                    tabObject !== undefined &&
                    children.length === Object.keys(tabObject).length &&
                    Object.keys(tabObject).map((tabKey, i) => (
                        <Tabs.Content
                            key={tabKey}
                            value={tabKey}
                            data-content-block-id={tabKey}
                            style={{}}
                        >
                            {children[i]}
                        </Tabs.Content>
                    ))}
            </Tabs.Root>
        </StyledUniversalDiv>
    );
};

export const defaultTabsTheme: Partial<TabsTheme> = {
    py: 0,
    px: 0,
    my: 0,
    mx: 0,
    color: "#374151",
    backgroundColor: "#ffffff",
    tabStyle: "underline",
    activeBackgroundColor: "#ffffff",
    activeColor: "#1A56DB",
    activeBorderColor: "#1A56DB",
    hoverBackgroundColor: "#ffffff",
    hoverColor: "#1A56DB",
    hoverBorderColor: "#1A56DB",
    textColor: "#374151",
    borderColor: "#1C64F2",
    disabledBorderColor: "rgba(0, 0, 0, 0.16)",
    disabledBackgroundColor: "rgba(0, 0, 0, 0.06)",
    disabledColor: "rgba(0, 0, 0, 0.16)",
};
