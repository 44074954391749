import React from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormOption,
    EditorFormSelectSmall,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";

export const FormSelectArrayIndex = (props: {
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    optionArray: Array<string | number>;
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    displayProperty?: (property: string | number) => string;
    width?: string;
    inline?: boolean;
}) => {
    const {
        value,
        label,
        themeObjectKey,
        optionArray,
        handleChange,
        displayProperty,
        inline,
    } = props;

    return (
        <EditorFormGroup style={!!inline ? { margin: 0 } : {}}>
            <EditorFormLabel>{label}</EditorFormLabel>
            <EditorFormSelectSmall
                width={props.width}
                value={value}
                onChange={(e) =>
                    handleChange(themeObjectKey, Number(e.target.value))
                }
                style={
                    !!inline ? { padding: "0.25rem 2rem 0.25rem 0.5rem", fontSize: "12px" } : {}
                }
            >
                {optionArray !== undefined &&
                    optionArray.map(
                        (option: string | number, index: number) => (
                            <EditorFormOption
                                key={option}
                                value={index}
                                className="bg-[#29303e]"
                            >
                                {displayProperty !== undefined
                                    ? displayProperty(option)
                                    : option}
                            </EditorFormOption>
                        ),
                    )}
            </EditorFormSelectSmall>
        </EditorFormGroup>
    );
};
