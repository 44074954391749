import React, { useEffect, useState } from "react";
import { ReflectionJournalEntry } from "@/models";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { ThemeObject } from "../../ThemeObjects";
import { defaultSectionTheme } from "../SectionTheme";
import { StyledUniversalDiv } from "../Universal";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import ReflectionJournalForm from "./ReflectionJournalForm";
import ReflectionJournalEntryDisplay from "./ReflectionJournalEntryDisplay";

type ReflectionJournalProps = ContentBlockEvents & {
    contentBlockId: string;
    reflectionJournalEntries: ReflectionJournalEntry[];
    theme: typeof defaultReflectionJournalTheme;
};

export const ReflectionJournalTheme = ({
    contentBlockId,
    onClick,
    onHover,
    reflectionJournalEntries,
    theme,
}: ReflectionJournalProps) => {
    const [reflectionJournalEntryToEdit, setReflectionJournalEntryToEdit] =
        useState<ReflectionJournalEntry | undefined>();

    useEffect(() => {
        if (reflectionJournalEntries?.length === 0) {
            setReflectionJournalEntryToEdit({
                content: {
                    type: "doc",
                    content: [],
                },
                title: "",
                team_user_round_id: "",
                weight: 0,
            });
        } else {
            setReflectionJournalEntryToEdit(undefined);
        }
    }, [reflectionJournalEntries]);

    return (
        <StyledUniversalDiv
            {...theme}
            data-content-block-id={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
            className="p-4 space-y-4 w-full"
        >
            {reflectionJournalEntries.map((reflectionJournalEntry) => (
                <div
                    className={`flex flex-col rounded-md ${
                        reflectionJournalEntryToEdit?.id !==
                            reflectionJournalEntry.id && "border"
                    }`}
                    key={reflectionJournalEntry.id}
                >
                    <div>
                        {reflectionJournalEntryToEdit?.id ===
                        reflectionJournalEntry.id ? (
                            <ReflectionJournalForm
                                reflectionJournalEntry={
                                    reflectionJournalEntryToEdit
                                }
                                cancel={() =>
                                    setReflectionJournalEntryToEdit(
                                        () => undefined
                                    )
                                }
                            />
                        ) : (
                            <div className="flex space-x-2 p-4 relative group @container">
                                <span className="absolute inset-0 shadow hidden group-hover:flex items-center justify-center z-90 pointer-events-none">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReflectionJournalEntryToEdit(
                                                reflectionJournalEntry
                                            );
                                        }}
                                        className="pointer-events-auto rounded-md border border-blue-600 text-blue-600 bg-white px-4 py-2 text-sm font-medium  hover:text-blue-900 hover:border-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all"
                                    >
                                        <PencilIcon className="w-6 h-6 " />
                                    </button>
                                </span>
                                <div className="@lg:p-4 p-2 rounded-md w-full flex flex-col items-start justify-center">
                                    <h3 className="mb-2 font-bold">
                                        {reflectionJournalEntry.title}
                                    </h3>
                                    <ReflectionJournalEntryDisplay
                                        content={reflectionJournalEntry.content}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            {!reflectionJournalEntryToEdit && (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setReflectionJournalEntryToEdit({
                            content: {
                                type: "doc",
                                content: [],
                            },
                            title: "",
                            team_user_round_id: "",
                            weight: reflectionJournalEntries.length,
                        });
                    }}
                    className="inline-flex items-center rounded-md border border-blue-600 bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all"
                >
                    Add <PlusIcon className="w-6 h-6" />
                </button>
            )}

            {reflectionJournalEntryToEdit &&
                !reflectionJournalEntryToEdit.id && (
                    <ReflectionJournalForm
                        cancel={() =>
                            setReflectionJournalEntryToEdit(() => undefined)
                        }
                        reflectionJournalEntry={reflectionJournalEntryToEdit}
                    />
                )}
        </StyledUniversalDiv>
    );
};

export const defaultReflectionJournalTheme: Partial<ThemeObject> =
    defaultSectionTheme;
