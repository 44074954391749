import React, { memo } from "react";
import NestedLessonContentBlockDisplay from "./NestedLessonContentBlockDisplay";
import { useContentBlockState } from "../LessonDesign/useContentBlockState";
import { Lesson } from "../types";
import { useTrackParentContentBlocks } from "./viewed-content-block-tracking/useTrackParentContentBlocks";

type Props = {
    scrollCallback?: (contentBlockId:string) => void;
    lesson: Lesson;
    lessonCount: number;
    isInAdminContext: boolean;
    // children?: JSX.Element
};

const LessonDisplay = memo(function LessonDisplay({
    lesson,
    scrollCallback,
    lessonCount,
    isInAdminContext,
    // children
}: Props) {
    const { parentContentBlocks } = useContentBlockState();

    const {} = useTrackParentContentBlocks(200, scrollCallback);

    return (
        <div className="flex w-full flex-col items-center justify-center">
            {parentContentBlocks?.map((block) => (
                <NestedLessonContentBlockDisplay
                    key={block.id}
                    contentBlock={block}
                    scrollCallback={scrollCallback}
                    isInAdminContext={isInAdminContext}
                    isParentBlock={true}

                />
            ))}
            {/* {children} */}
        </div>
    );
})

export default LessonDisplay;
