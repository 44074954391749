export const fontWeightObject = {
    thin: 100,
    "ultra light": 200,
    "extra light": 250,
    light: 300,
    normal: 400,
    medium: 500,
    "semi bold": 600,
    bold: 700,
    "extra bold": 800,
    heavy: 900,
    // lighter: "lighter",
    // bolder: "bolder"
};

export const letterSpacingObject = {
    // 0.1, 0.15, 0.4
    tightest: "-1.5px",
    tighter: "-1px",
    tight: "-0.5px",
    "semi tight": "-0.25px",
    normal: "normal",
    "semi wide": "0.25px",
    wide: "0.5px",
    wider: "1px",
    widest: "1.5px",
};

export const lineHeightObject = {
    //1, 1.1, 1.2, 1.4, 1.6, 1.8, 2.0
    tightest: 0.6,
    tighter: 0.7,
    tight: 0.8,
    "semi tight": 0.9,
    normal: 1,
    "semi wide": 1.1,
    wide: 1.2,
    wider: 1.4,
    widerer: 1.5,
    widererer: 1.6,
    widerererer: 1.7,
    widererererer: 1.8,
    widest: 2,

};

export const borderWidthObject = {
    none: 0,
    thin: "thin",
    medium: "medium",
    thick: "thick",
};

export const GlobalTheme = {
    breakpoints: ["40em", "52em", "64em", "80em"],
    space: [0, 1, 2, 4, 8, 16, 32, 64, 128],
    fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64], // 9, 34, 60, 72, 96
    fontWeights: Object.values(fontWeightObject),
    // lineHeights: {},
    letterSpacings: Object.values(letterSpacingObject),
    borderWidths: Object.values(borderWidthObject),
    radii: ["0", "5px", "10px", "25px", "50px", "100px", "10%", "25%", "50%"],
    colors: {
        steelDark: "#566581",
        steelDusk: "#e9ebf3",
        steelAccent: "#c9d3e6",
        steelDawn: "#f4f5f9",
        steelLight: "#29296112",
        indigoDark: "#241B5B",
        indigoDusk: "#4056A1",
        indigoAccent: "#1890ff",
        indigoDawn: "#7cb5ec",
        indigoLight: "#C5CBE3",
        bluegreenDark: "#0F4A1D",
        bluegreenDusk: "#8D8B00",
        bluegreenAccent: "#D9E505",
        bluegreenDawn: "#129495",
        bluegreenLight: "#00C2AE",
        redDark: "#560000",
        redDusk: "#81130e",
        redAccent: "#E70033",
        redDawn: "#d66c68",
        redLight: "#ffaba8",
        greyscaleDark: "#212121",
        greyscaleDusk: "#484848",
        greyscaleMedium: "#5a5a5a",
        greyscaleAccent: "#a2a2a2",
        greyscaleDawn: "#6d6d6d",
        greyscaleLight: "#F4F4F4",
        primary: "#007ce0",
        success: "#52c41a",
        warning: "#faad14",
        danger: "#ff0000",
        info: "#129495",
        error: "#e84118",
        discovery: "#671168",
        secondary: "#4f1c1f",
        tertiary: "#606029",
        link: "#4e19df",
    },
    fonts: {
        "0": "sans-serif",
        "1": `"Gill Sans", sans-serif`,
        "2": "Arial, Helvetica, sans-serif",
        "3": `"Arial Black", Gadget, sans-serif`,
        "4": `Impact, Charcoal, sans-serif`,
        "5": `"Lucida Sans Unicode", "Lucida Grande", sans-serif`,
        "6": `Tahoma, Geneva, sans-serif`,
        "7": `"Trebuchet MS", Helvetica, sans-serif`,
        "8": `Verdana, Geneva, sans-serif`,
        "9": `Georgia, serif`,
        "10": `Montserrat, serif`,
        "11": `"Palatino Linotype", "Book Antiqua", Palatino, serif`,
        "12": `"Times New Roman", Times, serif`,
        "13": `"Courier New", Courier, monospace`,
        "14": `"Lucida Console", Monaco, monospace`,
    },
    zIndices: {
        topNavZ: 1000,
        sideNavZ: 600,
    },
    layout: {
        topNavHeight: "3rem",
        bodyTopPadding: "3rem",
        sideNavWidthOpen: "16em",
        sideNavWidthClosed: "4em",
        bodySidePaddingOpen: "16em",
        bodySidePaddingClosed: "4em",
        sideNavBreakpoint: 1200,
        maxContentWidth: "990px",
    },
    palettes: {
        success: {
            buttonColor: "#155724",
            buttonBackgroundColor: "#d4edda80",
            buttonHoverColor: "#11451d",
            buttonHoverBackgroundColor: "#d4edda",
            buttonDisabledColor: "#155724",
            buttonDisabledBackgroundColor: "#d4edda80",
        },
        danger: {
            buttonColor: "#721c24",
            buttonBackgroundColor: "#f8d7da80",
            buttonHoverColor: "#57161c",
            buttonHoverBackgroundColor: "#f8d7da",
            buttonDisabledColor: "#721c24",
            buttonDisabledBackgroundColor: "#f8d7da80",
        },
        grayscale: {
            buttonColor: "#6d6d6d",
            buttonBackgroundColor: "#0000000a",
            buttonHoverColor: "#484848",
            buttonHoverBackgroundColor: "#00000014",
            buttonDisabledColor: "#6d6d6d",
            buttonDisabledBackgroundColor: "#0000000a",
        },
        grayscaleOpaque: {
            buttonColor: "#3c3c3cbf",
            buttonBackgroundColor: "#ccccccbf",
            buttonHoverColor: "#3c3c3c",
            buttonHoverBackgroundColor: "#cccccc",
            buttonDisabledColor: "#3c3c3cbf",
            buttonDisabledBackgroundColor: "#ccccccbf",
        },
    },
};

export type PaletteType = keyof typeof GlobalTheme.palettes;
