import React, { useEffect, useMemo, useState } from "react";
import {
    Behaviors,
    ClickBehaviors,
    ParticipantStateBehaviors,
    QuestionBehaviors,
    ScrollBehaviors,
} from "@/models";
import { useRoundStore } from "@/hooks/store";
import { usePages } from "./usePages";
import { isEqual } from "lodash";
import { getContentBlockConfigByType } from "@/model-configs";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    useGetActiveContentBlock,
    useUpdateActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { useSelectedRoundId } from "@/hooks";
import { H6Span } from "@/components";

type ClickBehaviorList = { [index in keyof ClickBehaviors]: string };
export const clickBehaviorsMap: Required<ClickBehaviorList> = {
    // OpenModal: "Open Modal",
    MoveToRound: "Move To Round",
    MoveToNextRound: "Move To Next Round",
    NavigateToPage: "Navigate To Page",
    NavigateToPreviousPage: "Navigate to Previous Page",
    NavigateToNextPage: "Navigate to Next Page",
    ShowModalContentBlock: "Open a modal",
    ExternalLink: "Link to another site",
};

type QuestionBehaviorList = { [index in keyof QuestionBehaviors]: string };
const questionBehaviorsMap: Required<QuestionBehaviorList> = {
    ShowOthersResponses: "Show others' responses",
};

type ParticipantStateBehaviorList = {
    [index in keyof ParticipantStateBehaviors]: string;
};
const participantStateBehaviorsMap: Required<ParticipantStateBehaviorList> = {
    DisplayIfRoundIsComplete: "Display if round is complete",
    DisplayIfFacilitatorAllows: "Display if facilitator allows advance",
    DisplayIfRoundIsCompleteAndFacilitatorAllows:
        "Display if round is complete and facilitator allows advance",
    DisplayIfParticipantHasSubmittedOnCurrentPage:
        "Display if participant has submitted on this page",
    DisplayIfTeammateHasSubmittedOnCurrentPage:
        "Display if anyone on the participant's team has submitted on this page",
    DisplayIfRoundHasSubmissionFromTeam:
        "Display if anyone on the participant's team has submitted in a specified round",
    ShowCountOfSubmissionByTeamForRound:
        "Show team submission count in a round",
};

type ScrollBehaviorList = {
    [index in keyof ScrollBehaviors]: string;
};
const scrollBehaviorsMap: Required<ScrollBehaviorList> = {
    DisplayOnScroll: "Hide until scrolled into view",
};

export const BehaviorEditor = () => {
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const { rounds } = useRoundStore();
    const { selectedRoundId } = useSelectedRoundId();
    const { data: pages } = usePages(selectedRoundId);

    const [data, setData] = useState<Behaviors>(activeContentBlock?.behaviors);

    useEffect(() => {
        setData(
            typeof activeContentBlock.behaviors === "string"
                ? JSON.parse(activeContentBlock.behaviors)
                : activeContentBlock.behaviors,
        );
    }, [activeContentBlock?.id]);

    useEffect(() => {
        if (!isEqual(data, activeContentBlock.behaviors)) {
            updateActiveContentBlock("behaviors", data);
        }
    }, [data]);

    const config = useMemo(() => {
        return getContentBlockConfigByType(
            activeContentBlock.content_block_type,
        );
    }, [activeContentBlock]);

    function handleChangeBehavior(
        behaviorType:
            | keyof ClickBehaviorList
            | keyof QuestionBehaviorList
            | keyof ParticipantStateBehaviorList,
    ) {
        // if (behaviorType === "OpenModal") {
        //     setData((oldData) => ({
        //         ...oldData,
        //         click: {
        //             OpenModal: {
        //                 modalId: "",
        //             },
        //         },
        //     }));
        // } else
        if (behaviorType === "MoveToRound") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    MoveToRound: {
                        roundId: "",
                    },
                },
            }));
        } else if (behaviorType === "MoveToNextRound") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    MoveToNextRound: true,
                },
            }));
        } else if (behaviorType === "NavigateToPage") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    NavigateToPage: {
                        pageId: "",
                    },
                },
            }));
        } else if (behaviorType === "NavigateToPreviousPage") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    NavigateToPreviousPage: true,
                },
            }));
        } else if (behaviorType === "NavigateToNextPage") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    NavigateToNextPage: true,
                },
            }));
        } else if (behaviorType === "ExternalLink") {
            setData((oldData) => ({
                ...oldData,
                click: {
                    ExternalLink: "",
                },
            }));
        } else if (behaviorType === "ShowOthersResponses") {
            setData((oldData) => ({
                ...oldData,
                question: {
                    ShowOthersResponses: true,
                },
            }));
        } else if (behaviorType === "DisplayIfFacilitatorAllows") {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfFacilitatorAllows: true,
                },
            }));
        } else if (behaviorType === "DisplayIfRoundIsComplete") {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfRoundIsComplete: true,
                },
            }));
        } else if (
            behaviorType === "DisplayIfRoundIsCompleteAndFacilitatorAllows"
        ) {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfRoundIsCompleteAndFacilitatorAllows: true,
                },
            }));
        } else if (behaviorType === "DisplayIfRoundHasSubmissionFromTeam") {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfRoundHasSubmissionFromTeam: { roundId: "" },
                },
            }));
        } else if (behaviorType === "ShowCountOfSubmissionByTeamForRound") {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    ShowCountOfSubmissionByTeamForRound: { roundId: "" },
                },
            }));
        } else if (
            behaviorType === "DisplayIfParticipantHasSubmittedOnCurrentPage"
        ) {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfParticipantHasSubmittedOnCurrentPage: true,
                },
            }));
        } else if (
            behaviorType === "DisplayIfTeammateHasSubmittedOnCurrentPage"
        ) {
            setData((oldData) => ({
                ...oldData,
                participantState: {
                    DisplayIfTeammateHasSubmittedOnCurrentPage: true,
                },
            }));
        }
        zodResolver;
    }

    if (!activeContentBlock)
        return (
            <>
                <div className="px-4 py-2">
                    <H6Span>{`Behaviors`}</H6Span>
                </div>
                <div className="px-4">
                    {"Select a component to edit behaviors."}
                </div>
            </>
        );

    return !!config &&
        !!config.availableBehaviors &&
        !!config.availableBehaviors.length ? (
        <>
            <div className="px-4 py-2">
                <H6Span>{`Behaviors`}</H6Span>
            </div>
            <form className="flex flex-col space-y-4 px-4">
                {config.availableBehaviors.some(
                    (behavior) => !!participantStateBehaviorsMap[behavior],
                ) && (
                    <>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Display Behavior
                            </label>
                            <select
                                onChange={(e) => {
                                    const participantStateBehavior = e.target
                                        .value as keyof ParticipantStateBehaviors;
                                    handleChangeBehavior(
                                        participantStateBehavior,
                                    );
                                }}
                                value={
                                    !!data?.participantState &&
                                    !!Object.keys(data?.participantState)
                                        .length &&
                                    Object.keys(data?.participantState)[0]
                                }
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                <option>Choose Behavior</option>
                                {Object.keys(participantStateBehaviorsMap)
                                    .filter((key) => {
                                        return !!config?.availableBehaviors?.includes(
                                            key as keyof ParticipantStateBehaviorList,
                                        );
                                    })
                                    .map((key) => (
                                        <option value={key} key={key}>
                                            {participantStateBehaviorsMap[key]}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        {(data?.participantState
                            ?.DisplayIfRoundHasSubmissionFromTeam ||
                            data?.participantState
                                ?.ShowCountOfSubmissionByTeamForRound) && (
                            <div>
                                <label
                                    htmlFor="time_horizon"
                                    className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                                >
                                    Choose Round
                                </label>
                                <div>
                                    <select
                                        onChange={(e) => {
                                            const prop = data?.participantState
                                                ?.DisplayIfRoundHasSubmissionFromTeam
                                                ? {
                                                      participantState: {
                                                          DisplayIfRoundHasSubmissionFromTeam:
                                                              {
                                                                  roundId:
                                                                      e.target
                                                                          .value,
                                                              },
                                                      },
                                                  }
                                                : {
                                                      participantState: {
                                                          ShowCountOfSubmissionByTeamForRound:
                                                              {
                                                                  roundId:
                                                                      e.target
                                                                          .value,
                                                              },
                                                      },
                                                  };

                                            setData({
                                                ...data,
                                                ...prop,
                                            });
                                        }}
                                        value={
                                            data?.participantState
                                                ?.DisplayIfRoundHasSubmissionFromTeam
                                                ? data?.participantState
                                                      ?.DisplayIfRoundHasSubmissionFromTeam
                                                      .roundId
                                                : data?.participantState
                                                      ?.ShowCountOfSubmissionByTeamForRound
                                                      .roundId
                                        }
                                        id="time_horizon"
                                        name="time_horizon"
                                        autoComplete="time_horizon"
                                        className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option>Choose Round</option>
                                        {rounds.map((round) => (
                                            <option
                                                value={round.id}
                                                key={round.id}
                                            >
                                                {round.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {config.availableBehaviors.some(
                    (behavior) => !!clickBehaviorsMap[behavior],
                ) && (
                    <>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Click Behavior
                            </label>
                            <select
                                onChange={(e) => {
                                    const clickBehavior = e.target
                                        .value as keyof ClickBehaviors;
                                    handleChangeBehavior(clickBehavior);
                                }}
                                value={
                                    !!data?.click &&
                                    !!Object.keys(data?.click).length &&
                                    Object.keys(data?.click)[0]
                                }
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                <option>Choose Click Behavior</option>
                                {Object.keys(clickBehaviorsMap)
                                    .filter((key) => {
                                        return !!config?.availableBehaviors?.includes(
                                            key as keyof ClickBehaviors,
                                        );
                                    })
                                    .map((key) => (
                                        <option value={key} key={key}>
                                            {clickBehaviorsMap[key]}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="pl-4">
                            {data?.click?.ExternalLink !== undefined && (
                                <div>
                                    <label
                                        htmlFor="time_horizon"
                                        className="flex flex-col space-y-2 text-sm font-medium text-gray-100"
                                    >
                                        Link URL
                                        <input
                                            onChange={(e) => {
                                                setData((oldData) => ({
                                                    ...oldData,
                                                    click: {
                                                        ExternalLink:
                                                            e.target.value,
                                                    },
                                                }));
                                            }}
                                            value={
                                                data?.click?.ExternalLink || ""
                                            }
                                            type="text"
                                            className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </label>
                                </div>
                            )}
                            {data?.click?.MoveToRound !== undefined && (
                                <div>
                                    <label
                                        htmlFor="time_horizon"
                                        className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                                    >
                                        Choose Round
                                    </label>
                                    <select
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                click: {
                                                    MoveToRound: {
                                                        roundId: e.target.value,
                                                    },
                                                },
                                            })
                                        }
                                        value={
                                            data?.click?.MoveToRound?.roundId
                                        }
                                        id="time_horizon"
                                        name="time_horizon"
                                        autoComplete="time_horizon"
                                        className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option>Choose Round</option>
                                        {rounds.map((round) => (
                                            <option
                                                value={round.id}
                                                key={round.id}
                                            >
                                                {round.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {data?.click?.NavigateToPage !== undefined && (
                                <div>
                                    <label
                                        htmlFor="time_horizon"
                                        className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                                    >
                                        Choose Page
                                    </label>
                                    <select
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                click: {
                                                    NavigateToPage: {
                                                        pageId: e.target.value,
                                                    },
                                                },
                                            })
                                        }
                                        value={
                                            data?.click?.NavigateToPage?.pageId
                                        }
                                        id="time_horizon"
                                        name="time_horizon"
                                        autoComplete="time_horizon"
                                        className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option>Choose Page</option>
                                        {pages.map((page, pageNumber) => (
                                            <option
                                                value={page.id}
                                                key={page.id}
                                            >
                                                {page.title ||
                                                    `Page ${pageNumber + 1}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {config.availableBehaviors.some(
                    (behavior) => !!questionBehaviorsMap[behavior],
                ) && (
                    <>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="mb-1 flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Question Behavior
                            </label>
                            <select
                                onChange={(e) => {
                                    const questionBehavior = e.target
                                        .value as keyof QuestionBehaviorList;
                                    handleChangeBehavior(questionBehavior);
                                }}
                                value={
                                    !!data?.question &&
                                    !!Object.keys(data.question).length &&
                                    Object.keys(data.question)[0]
                                }
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                <option>Choose Question Behavior</option>
                                {Object.keys(questionBehaviorsMap)
                                    .filter((key) => {
                                        return !!config?.availableBehaviors?.includes(
                                            key as keyof QuestionBehaviors,
                                        );
                                    })
                                    .map((key) => (
                                        <option value={key} key={key}>
                                            {questionBehaviorsMap[key]}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </>
                )}
                {config.availableBehaviors.some(
                    (behavior) => !!scrollBehaviorsMap[behavior],
                ) && (
                    <>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Scroll Behavior
                            </label>
                        </div>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Delay in seconds
                            </label>
                            <input
                                type={"number"}
                                onChange={(e) => {
                                    setData((data) => ({
                                        ...data,
                                        scroll: {
                                            DisplayOnScroll: {
                                                delay: Number(e.target.value),
                                                animation: "none",
                                                elementOnDelay:
                                                    data?.scroll
                                                        ?.DisplayOnScroll
                                                        ?.elementOnDelay,
                                            },
                                        },
                                    }));
                                }}
                                value={data?.scroll?.DisplayOnScroll?.delay}
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="time_horizon"
                                className="flex flex-col space-y-2 text-sm font-medium text-gray-100"
                            >
                                Text to show while loading content
                            </label>
                            <input
                                type={"text"}
                                onChange={(e) => {
                                    setData((data) => ({
                                        ...data,
                                        scroll: {
                                            DisplayOnScroll: {
                                                elementOnDelay: e.target.value,
                                                animation: "none",
                                                delay:
                                                    data?.scroll
                                                        ?.DisplayOnScroll
                                                        ?.delay || 0,
                                            },
                                        },
                                    }));
                                }}
                                value={
                                    data?.scroll?.DisplayOnScroll
                                        ?.elementOnDelay
                                }
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </>
                )}
            </form>
        </>
    ) : (
        <>
            <div className="px-4 py-2">
                <H6Span>{`Behaviors`}</H6Span>
            </div>
            <div className="px-4">{"No available behaviors."}</div>
        </>
    );
};
