import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { AppDispatch, extraThunkArguments } from "./AppStore";

type UploadSliceStateShape = {
    uploading: boolean;
    uploadPercentage: number;
};

const initialState: UploadSliceStateShape = {
    uploading: false,
    uploadPercentage: 0,
};

export const uploadComplete = createAction<string>("uploadComplete");

const uploadSlice = createSlice({
    name: "uploadSlice",
    initialState,
    reducers: {
        setUploading(state, { payload }: PayloadAction<boolean>) {
            state.uploading = payload;
        },
        setUploadingPercentage(state, { payload }: PayloadAction<number>) {
            state.uploadPercentage = payload;
        },
        setFinishedUploading(state, { payload }: PayloadAction<boolean>) {
            state.uploading = false;
            state.uploadPercentage = 0;
        },
    },
    extraReducers: {
        [uploadComplete.toString()](
            state,
            { payload }: PayloadAction<string>
        ) {},
    },
});

export const uploadFile =
    (files: File[], callback) =>
    async (dispatch: AppDispatch, _, { api }: extraThunkArguments) => {
        const fileUrl = (await api.fileUploads.upload(
            files,
            (event: ProgressEvent) => {
                const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                );
                dispatch(setUploadingPercentage(percentCompleted));
                if (percentCompleted >= 100) {
                    dispatch(setUploading(false));
                } else {
                    dispatch(setUploading(true));
                }
            }
        )) as string;
        dispatch(uploadComplete(fileUrl));
    };

export const uploadVideo =
    (files: File[], callback, contentBlockId: string) =>
    async (dispatch: AppDispatch, _, { api }: extraThunkArguments) => {
        const fileUrl = (await api.fileUploads.uploadVideo(
            contentBlockId,
            files,
            (event: ProgressEvent) => {
                console.log(event);
                const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                );
                dispatch(setUploadingPercentage(percentCompleted));
                if (percentCompleted >= 100) {
                    dispatch(setUploading(false));
                } else {
                    dispatch(setUploading(true));
                }
            }
        )) as string;
        dispatch(uploadComplete(fileUrl));
    };

export const { setUploading, setUploadingPercentage } = uploadSlice.actions;

export const uploadFileReducer = uploadSlice.reducer;
