import { ContentBlockShape } from "@/models";
import React from "react";
import { useCreateContentBlock } from "./useCreateContentBlock";
import { ContentBlockMetadata } from "@/model-configs";
import { useAddContentBlockState } from "../useAddContentBlockState";
import { getIdOfChildBlockNearestClick } from "../getIdOfChildBlockNearestClick";
import { useFindContentBlockMethods } from "@/hooks";

type Props = {
    contentBlock: ContentBlockShape;
    children?: JSX.Element;
    position?: "before" | "after";
    addableContentBlockMetaData: ContentBlockMetadata;
    depth: number;
    weight?: number;
};

export default function CreateContentBlockButton({
    contentBlock,
    children,
    position = "after",
    addableContentBlockMetaData,
    depth,
    weight,
}: Props) {
    const mutation = useCreateContentBlock(addableContentBlockMetaData);
    const { reset } = useAddContentBlockState();
    const { getContentBlock } = useFindContentBlockMethods();
    return (
        <button
            data-add-to={contentBlock.id}
            className="add-content-block-button absolute inset-0 flex h-full w-full items-center justify-center bg-transparent text-2xl text-white"
            aria-label={`Create Content Block in ${contentBlock.content_block_type} ${contentBlock.id}`}
            onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                let newWeight = 0;
                if ("undefined" === typeof weight) {
                    const id = getIdOfChildBlockNearestClick(e);

                    if (id) {
                        const contentBlock = getContentBlock(id);
                        if (contentBlock) {
                            newWeight = contentBlock.weight + 1;
                        }
                    }
                } else {
                    newWeight = weight;
                }

                if (
                    "function" ===
                    typeof addableContentBlockMetaData.addOverride
                ) {
                    addableContentBlockMetaData.addOverride(contentBlock.id);
                    reset();
                    return;
                }
                mutation.mutateAsync({
                    contentBlock,
                    position,
                    weight: newWeight,
                });
            }}
            style={{
                zIndex: depth * 10,
            }}
        >
            {!!children ? (
                children
            ) : (
                <span className="sr-only">
                    Create Content Block in {contentBlock.id}
                </span>
            )}
        </button>
    );
}
