import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, Simulation } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

export type SaveBehaviorBlockRequestBody =
    (typeof LaravelRequestBodyShapes)["creator.design.store.with-behaviors"];

export async function saveBehaviorBlock({
    contentBlock,
    selectedSimulation,
}: {
    contentBlock: SaveBehaviorBlockRequestBody;
    selectedSimulation: Partial<Simulation>;
}) {
    const { data } = await sapienAxios.post<{
        contentBlock: ContentBlockShape;
    }>("creator.design.store.with-behaviors", contentBlock, {
        simulationSlug: selectedSimulation?.slug,
        roundId: contentBlock.round_id,
    });

    return data?.contentBlock;
}
