import {
    ContentBlockMetadata,
    LayoutConfig,
    ContentBlockConfig,
    getChildBlockConfigs,
    LayoutType,
    getContentBlockConfigByType,
    getLayoutConfigByType,
} from "@/model-configs";
import { ContentBlockConfigObject } from "@/model-configs/ContentBlockConfigObject";
import { LayoutConfigObject } from "@/model-configs/LayoutConfigObject";
import { ContentBlockShape, ContentBlock, ContentBlockType } from "@/models";

export const getContentBlockOrLayoutConfigByType = (
    contentBlockOrLayoutType: ContentBlockType | LayoutType
): ContentBlockConfig | LayoutConfig => {
    let contentBlockOrLayoutConfig: ContentBlockConfig | LayoutConfig =
        getLayoutConfigByType(contentBlockOrLayoutType as LayoutType) ||
        getContentBlockConfigByType(
            contentBlockOrLayoutType as ContentBlockType
        );

    return contentBlockOrLayoutConfig;
};
export const generateContentBlockToSave = (
    parentContentBlock: ContentBlockShape,
    childContent: string = "Lorem ipsum... ",
    weight: number = 0,
    contentBlockMeta: ContentBlockMetadata,
    activeBlockToMove?: ContentBlockShape
): ContentBlockShape & { content: string, theme: any } => {
    if (!parentContentBlock) return;

    if (contentBlockMeta?.contentBlock) {
        return {
            ...contentBlockMeta.contentBlock,
            round_id: parentContentBlock.round_id,
            parent_content_block_id: parentContentBlock.id,
            weight,
            content: childContent || "",
        };
    }

    let contentBlockToSave;

    const configToUse = getContentBlockOrLayoutConfigByType(
        contentBlockMeta?.blockOrLayoutType
    );

    if (!!activeBlockToMove) {
        contentBlockToSave = {
            ...activeBlockToMove,
            parent_content_block_id: parentContentBlock.id,
            round_id: parentContentBlock.round_id,
            weight,
        } as ContentBlockShape;
    } else if (!!configToUse) {
        if (configToUse.type in LayoutConfigObject) {
            const layoutConfig = configToUse as LayoutConfig;
            contentBlockToSave = new ContentBlock({
                ...layoutConfig.layoutContentBlock,
                content: childContent,
                parent_content_block_id: parentContentBlock.id,
                weight,
                round_id: parentContentBlock.round_id,
            });
        } else if (configToUse.type in ContentBlockConfigObject) {
            const contentBlockConfig = configToUse as ContentBlockConfig;

            contentBlockToSave = new ContentBlock({
                content: childContent,
                content_block_type: contentBlockMeta.blockOrLayoutType,
                theme: {
                    ...ContentBlockConfigObject[configToUse.type].defaultTheme,
                    ...contentBlockConfig.defaultCreationData,
                },
                parent_content_block_id: parentContentBlock.id,
                weight,
                round_id: parentContentBlock.round_id,
            });

            let requiredChildConfigs = getChildBlockConfigs(
                contentBlockToSave.content_block_type,
                "requiredChildBlocks"
            );

            contentBlockToSave.contentBlocks = requiredChildConfigs.map(
                (requiredChildConfig, i) =>
                    new ContentBlock({
                        content:
                            requiredChildConfig.defaultContent ||
                            requiredChildConfig.typeKey,
                        content_block_type: requiredChildConfig.type,
                        weight: i,
                        round_id: parentContentBlock.round_id,
                        theme: {
                            ...requiredChildConfig.defaultTheme,
                        },
                    })
            );
        }

        if (contentBlockMeta.theme) {
            contentBlockToSave.theme = {
                ...contentBlockToSave.theme,
                ...contentBlockMeta.theme,
            };
        }
    }

    return { ...contentBlockToSave, content: childContent || "",};
};
