import { ColorScheme } from "@/models";
import styled from "styled-components";
import { space, flexbox, variant } from "styled-system";

type OptionalPalette = {
    palette?: ColorScheme;
};

const spacedVariant = variant({
    prop: "spaced",
    variants: {
        true: {
            gap: "12px",
        },
        false: {
            gap: 0,
        },
    },
});
type FormLabelProps = OptionalPalette & {
    isSelected?: boolean;
    highLightStyle?: "color" | "grayscale" | "none";
    highlightColor?: string;
};

export const FormLabel = styled.label<FormLabelProps>`
    display: inline-block;
    position: relative;
    margin: 0 1rem 0.5rem 0;
    background-color: ${({
        highLightStyle,
        palette,
        isSelected,
        highlightColor,
    }) => {
        if (!palette || !highLightStyle) return "transparent";

        if (highLightStyle === "color" && isSelected) {
            return highlightColor || palette?.selectedColor || "#9AE6B4";
        } else if (highLightStyle === "grayscale" && isSelected) {
            //TODO: name this color better
            return palette?.unSelectedColor || "#D1D5DB";
        } else if (highLightStyle === "none") {
            return "transparent";
        }

        return "#E5E7EB";
    }};
`;
FormLabel.displayName = "FormLabel";

export const FormInput = styled.input<OptionalPalette>`
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: initial;
`;
FormInput.displayName = "FormInput";

type FormInputRadioCheckboxProps = OptionalPalette & {
    checkMarkColor?: string;
};
export const FormInputRadioCheckbox = styled.input<FormInputRadioCheckboxProps>`
    margin: 0.25rem 0.75rem 0 0.25rem;
    color: ${({ checkMarkColor }) => checkMarkColor || "#2563eb"};
    &:focus {
        --tw-ring-color: ${({ checkMarkColor }) => checkMarkColor || "#2563eb"};
    }
`;
FormInputRadioCheckbox.displayName = "FormInputRadioCheckbox";

export const FormRadioOrCheckboxGroup = styled.div<{
    spaced: "spaced" | "not spaced";
    bordered: "bordered" | "not bordered";
    collapsed?: boolean;
    palette?: ColorScheme;
    isInDesignContext?: boolean;
}>`
    ${space}
    ${flexbox}
    border-radius: 8px;
    overflow: ${({ isInDesignContext }) =>
        isInDesignContext ? "visible" : "hidden"};
    border: ${({ palette, spaced, bordered }) => {
        if (spaced == "not spaced" && bordered === "bordered") {
            return `1px solid ${palette?.borderColor || "#e5e7eb"}`;
        }
        return "none";
    }};
    max-width: 960px;
    display: flex;
    flex-direction: column;
    gap: ${({ spaced }) => (spaced === "spaced" ? "12px" : 0)};
    ${spacedVariant}
    > label {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: ${({ collapsed }) => (collapsed ? "4px 8px" : "16px 24px")};
        border-radius: ${({ spaced }) => (spaced === "spaced" ? "8px" : 0)};
        /* background-color: ${({ palette }) => palette?.backgroundColor}; */
        border: ${({ spaced, palette, bordered }) =>
            spaced == "spaced" && bordered === "bordered"
                ? `1px solid ${palette?.borderColor || "#e5e7eb"}`
                : `none`};
        border-bottom: ${({ palette, bordered, spaced }) =>
            bordered === "bordered"
                ? `1px solid  ${palette?.borderColor || "#e5e7eb"}`
                : "none"};
        &:last-child {
            border-bottom: ${({ spaced, palette, bordered }) =>
                spaced == "spaced" && bordered === "bordered"
                    ? `1px solid ${palette?.borderColor || "#e5e7eb"}`
                    : `none`};
        }

        input[type="radio"] {
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            /* For iOS < 15 */
            background-color: transparent;
            /* Not removed via appearance */
            margin: 0;
            margin-right: ${({ collapsed }) => (collapsed ? "4px" : "14px")};
            font: inherit;
            color: currentColor;
            width: 20px;
            height: 20px;
            border: 0.15em solid currentColor;
            border-radius: 50%;
            transform: translateY(0.075em);
            display: grid;
            place-content: center;
        }

        input[type="radio"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em #374151;
            /* Windows High Contrast Mode */
            background-color: #374151;
        }

        input[type="radio"]:checked::before {
            transform: scale(1);
        }

        input[type="radio"]:focus {
            outline: max(2px, 0.15em) solid currentColor;
            outline-offset: max(2px, 0.15em);
        }
    }
`;
FormRadioOrCheckboxGroup.displayName = "FormRadioOrCheckboxGroup";

type WithMargin = OptionalPalette & { hasMargin?: boolean; color?: string };
export const FormInputRange = styled.input<WithMargin>`
    &::-webkit-slider-thumb {
        height: 15px;
        width: 105px;
        background-color: ${({ color }) => color};
        border-radius: 50%;
        border: none;
    }
    &::-moz-range-thumb {
        height: 15px;
        width: 15px;
        background-color: ${({ color }) => color};
        border-radius: 50%;
        border: none;

        /* box-shadow: -407px 0 0 400px #f50; emove this line */
        transition: 0.2s ease-in-out;
    }
    ${({ hasMargin }) => (!hasMargin ? "margin: 0;" : "")};
    width: 100%;
`;
FormInputRange.displayName = "FormInputRange";

export const FormToggleLabel = styled.label<OptionalPalette>`
    position: absolute;
    top: 0;
    left: 0;
    width: 52px;
    height: 26px;
    border-radius: 15px;
    background: #bebebe;
    cursor: pointer;
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin: 3.5px;
        background: #ffffff;
        /* box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2); */
        transition: 0.2s;
    }
`;
FormToggleLabel.displayName = "FormToggleLabel";

export const FormInputToggle = styled.input<OptionalPalette>`
    margin: 0;
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 52px;
    height: 26px;
    &:checked + ${FormToggleLabel} {
        background: #0374fe;
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 30px;
            transition: 0.2s;
        }
    }
`;
FormInputToggle.displayName = "FormInputToggle";

export const FormToggleDiv = styled.div<OptionalPalette>`
    position: relative;
`;
FormToggleDiv.displayName = "FormToggleDiv";

export const FormToggleLeftLabel = styled.label<OptionalPalette>`
    display: inline-block;
    margin: 0 0.75rem 0.25rem 0;
`;
FormToggleLeftLabel.displayName = "FormToggleLeftLabel";

export const FormToggleRightLabel = styled.label<OptionalPalette>`
    display: inline-block;
    margin: 0 0 0.25rem 0.75rem;
`;
FormToggleRightLabel.displayName = "FormToggleRightLabel";

export const FormSelect = styled.select<OptionalPalette>`
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: initial;
`;
FormSelect.displayName = "FormSelect";

export const FormOption = styled.option<OptionalPalette>`
    line-height: 1.5;
`;
FormOption.displayName = "FormOption";

export const FormTextArea = styled.textarea<OptionalPalette>`
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-family: inherit;
    resize: vertical;
`;
FormTextArea.displayName = "FormTextArea";
