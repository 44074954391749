import { ContentBlock, ContentBlockType } from "../../models";
import { defaultGridTheme, ThemeObject } from "../../styles";

export const FeatureLayout: ContentBlock = new ContentBlock({
    title: "Feature",
    content: "Grid",
    content_block_type: ContentBlockType.Grid,
    theme: {
        ...defaultGridTheme,
        columnCount: 2,
        childCount: 2,
        consistentChildProps: true,
        widthBreakpoint1: "100%",
        widthBreakpoint2: "100%",
        widthBreakpoint3: 0.5,
        widthBreakpoint4: 0.5
    } as ThemeObject,
    contentBlocks: [
        new ContentBlock({
            content: "Tile",
            content_block_type: ContentBlockType.Tile,
            contentBlocks: [
                new ContentBlock({
                    content: "Image",
                    content_block_type: ContentBlockType.Image
                })
            ]
        }),
        new ContentBlock({
            content: "Tile",
            content_block_type: ContentBlockType.Tile,
            contentBlocks: [
                new ContentBlock({
                    content: "Box",
                    content_block_type: ContentBlockType.Box
                })
            ]
        })
    ]
});
