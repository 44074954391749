import { ContentBlockType } from "../../models";
import {
    defaultTypographyComponentProps,
    defaultTypographyTheme,
} from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const TypographyConfig: ContentBlockConfig = {
    typeKey: "Typography",
    type: ContentBlockType.Typography,
    defaultTheme: defaultTypographyTheme,
    needsDataToCreate: true,
    defaultCreationData: defaultTypographyComponentProps,
    availableBehaviors: ["ShowCountOfSubmissionByTeamForRound"],
};
