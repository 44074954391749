import { ContentBlock, ContentBlockType } from "../../models";
import { ThemeObject, TypographyType } from "../../styles";
// const theme = {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     background: "rgba(0,0,0,0)",
// };
//TODO: Figure out how to work with background images
//

export function buildHero(
    maintext = "Large text",
    subtext = "Smaller text",
    image_url: string = "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png"
) {
    return new ContentBlock({
        title: "Hero",
        content: "Hero",
        content_block_type: ContentBlockType.Hero,
        image_url,
        theme: {
            consistentChildProps: true,
            widthBreakpoint1: "100%",
            widthBreakpoint2: "100%",
            widthBreakpoint3: 0.5,
            widthBreakpoint4: 0.5,
            minHeight: "75vh",
            width: "100%",
            position: "relative",
            backgroundColor: "rgba(0,0,0,0)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
            px: [2, 4, 8],
            image_url,
            backgroundSize: "cover",
            backgroundPosition: "center",
        } as ThemeObject,
        contentBlocks: [
            new ContentBlock({
                content_block_type: ContentBlockType.Box,
                weight: 0,
                theme: {
                    background: "rgba(0,0,0,0)",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: [1],
                },
                contentBlocks: [
                    new ContentBlock({
                        content: maintext,
                        content_block_type: ContentBlockType.Typography,
                        weight: 0,
                        theme: {
                            mx: 0,
                            my: 1,
                            px: 0,
                            py: 0,
                            color: "rgba(2,2,2,1)",
                            textAlign: "left",
                            typographyType: "HEADING_2",
                            backgroundColor: "rgba(0,0,0,0)",
                        },
                    }),
                    new ContentBlock({
                        content: subtext,
                        content_block_type: ContentBlockType.Typography,
                        weight: 1,
                        theme: {
                            mx: 0,
                            my: 1,
                            px: 0,
                            py: 0,
                            color: "rgba(2,2,2,1)",
                            textAlign: "left",
                            typographyType: "HEADING_5",
                            backgroundColor: "rgba(0,0,0,0)",
                        },
                    }),
                ],
            }),
        ],
    });
}

export const HeroLayout: ContentBlock = buildHero();
