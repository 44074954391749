import { ContentBlockType } from "../../models";
import { defaultGridComponentProps, defaultGridTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const CardDeckConfig: ContentBlockConfig = {
    typeKey: "Card Deck",
    type: ContentBlockType.Grid,
    addableChildBlocks: [ContentBlockType.Tile, ContentBlockType.Card],
    defaultTheme: defaultGridTheme,
    isCollectionType: true,
    needsDataToCreate: true,
    defaultCreationData: defaultGridComponentProps,


    // onClickAdd(parent) {
    //     const blockToCreate = buildCard();
    //     blockToCreate.parent_content_block_id = parent.id;
    //     blockToCreate.round_id = parent.round_id;

    //     const lastChild =
    //         parent.contentBlocks &&
    //         parent.contentBlocks[parent.contentBlocks.length - 1];
    //     const weight = !!lastChild ? lastChild.weight + 1 : 0;

    //     blockToCreate.weight = weight;

    //     return blockToCreate;
    // },
};
// console.log("CardDeckConfig", CardDeckConfig);
