import React, { FC, ReactNode } from "react";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";

type StyledCardDeckProps = ContentBlockEvents & {
    theme: typeof defaultCardDeckTheme;
    children?: ReactNode[] | ReactNode;
    contentBlockId: string;
};

export const StyledCardDeck: FC<StyledCardDeckProps> = (props) => {
    const { theme, children, contentBlockId, onClick, onHover, title } = props;

    return (
        <StyledUniversalDiv
            display={"flex"}
            flexWrap={theme.flexWrap as "wrap" | "nowrap"}
            py={theme.py}
            px={theme.px}
            justifyContent={theme.justifyContent || "flex-start"}
            //I think this is an acceptable constraint. Grids should fill their available space
            width={"100%"}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "CardDeck"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            {children}
        </StyledUniversalDiv>
    );
};

export const defaultCardDeckTheme = {
    display: "flex",
    px: 0,
    py: 0,
    flexWrap: "wrap",
    justifyContent: "center"
};
