import { ContentBlockType, RichText } from "@/models";

const makeRichText = (cardNumber: number, side: "Back" | "Front"): RichText => {
    return {
        type: "doc",
        content: [
            {
                type: "typography",
                attrs: {
                    lineHeight: "100%",
                    indent: 0,
                    textAlign: "left",
                    typographyType: "PARAGRAPH",
                    default: null,
                },
                content: [
                    {
                        type: "text",
                        marks: [
                            {
                                type: "bold",
                            },
                            {
                                type: "textStyle",
                            },
                        ],
                        text: `${side} of card ${cardNumber}`,
                    },
                ],
            },
        ],
    };
};

export function createFlipCard(round_id: string, weight: number) {
    return {
        content_block_type: ContentBlockType["Flip Card"],
        round_id,
        weight,
        theme: {
            tailwindClasses: [],
        },
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Flip Card Side"],
                round_id,
                weight: 0,
                theme: { tailwindClasses: ["rotate-y-0"], variant: "text" },
                image_url:
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
                rich_text: makeRichText(weight + 1, "Front"),
            },
            {
                content_block_type: ContentBlockType["Flip Card Side"],
                round_id,
                weight: 1,
                theme: { tailwindClasses: ["rotate-y-180"], variant: "text" },
                image_url:
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
                rich_text: makeRichText(weight + 1, "Back"),
            },
        ],
    };
}
