import { isEqual } from "lodash";
import { useFindContentBlockMethods } from "../useContentBlockStore";
import { useGetActiveContentBlock } from "./useGetActiveContentBlock";
import { ContentBlockShape } from "@/models";

const keysForComparison = [
    "id",
    "content",
    "theme",
    // "title",
    "rich_text",
    "behaviors",
    "table_data",
    "content_block_type",
];

const filterContentBlockPropsForComparison = (block: ContentBlockShape) => {
    const filteredBlock = {};
    keysForComparison.forEach((key) => {
        if (!!block[key]) {
            filteredBlock[key] = block[key];
        }
    });
    return filteredBlock;
};

export function useHasUnsavedChanges() {
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();

    const original = getContentBlockWithChildren(activeContentBlock?.id);

    // console.log(original, activeContentBlock)
    if (!original || !activeContentBlock) return false;

    const filteredActive =
        filterContentBlockPropsForComparison(activeContentBlock);
    const filteredOriginal = filterContentBlockPropsForComparison(original);

    return !isEqual(filteredActive, filteredOriginal);
}
