import {
    ContentBlockConfig,
    ContentBlockMetadata,
    getContentBlockConfigByType,
} from "@/model-configs";
import { ContentBlockType } from "@/models";
import { useAtom } from "jotai";
import { atomWithReducer } from "jotai/utils";
import { useMemo } from "react";
import { clickBehaviorsMap } from "../behavior-editor/BehaviorEditor";

type AddContentBlockState = {
    isAddingModalToExistingContentBlock: boolean;
    newModalTriggerType?: ContentBlockType.Button | ContentBlockType.Link;
    addableContentBlockMetaData?: ContentBlockMetadata;
};

type ActionType =
    | {
          type: "isAddingModalToExistingContentBlock";
          payload: boolean;
      }
    | {
          type: "newModalTriggerType";
          payload: ContentBlockType.Button | ContentBlockType.Link | undefined;
      }
    | {
          type: "addableContentBlockMetaData";
          payload: ContentBlockMetadata;
      }
    | {
          type: "reset";
      };

const initialState: AddContentBlockState = {
    isAddingModalToExistingContentBlock: false,
    newModalTriggerType: undefined,
    addableContentBlockMetaData: undefined,
};
const addContentBlockAtom = atomWithReducer(
    initialState,
    (state: AddContentBlockState, action: ActionType) => {
        switch (action.type) {
            case "isAddingModalToExistingContentBlock":
                return {
                    ...state,
                    isAddingModalToExistingContentBlock: action.payload,
                };
            case "newModalTriggerType":
                return {
                    ...state,
                    newModalTriggerType: action.payload,
                };

            case "addableContentBlockMetaData":
                return {
                    ...state,
                    addableContentBlockMetaData: action.payload,
                };
            case "reset":
                return initialState;
        }

        return state;
    },
);

addContentBlockAtom.debugLabel = "addContentBlockAtom";

export function useAddContentBlockState() {
    const [addContentBlockState, dispatch] = useAtom(addContentBlockAtom);

    const setIsAddingModalToExistingContentBlock = (
        isAddingModalToExistingContentBlock: boolean,
    ) =>
        dispatch({
            type: "isAddingModalToExistingContentBlock",
            payload: isAddingModalToExistingContentBlock,
        });

    const setAddableContentBlockMetaData = (
        contentBlockMetadata: ContentBlockMetadata,
    ) => {
        dispatch({
            type: "addableContentBlockMetaData",
            payload: contentBlockMetadata,
        });
    };

    const setNewModalTriggerType = (
        newModalTriggerType:
            | ContentBlockType.Button
            | ContentBlockType.Link
            | undefined,
    ) =>
        dispatch({ type: "newModalTriggerType", payload: newModalTriggerType });

    const reset = () => dispatch({ type: "reset" });

    const selectedConfig = useMemo(() => {
        return getContentBlockConfigByType(
            addContentBlockState.addableContentBlockMetaData
                ?.blockOrLayoutType as ContentBlockType,
        );
    }, [addContentBlockState.addableContentBlockMetaData]);

    const isAddingModal = useMemo(() => {
        return (
            addContentBlockState.addableContentBlockMetaData
                ?.blockOrLayoutType === ContentBlockType.Modal
        );
    }, [addContentBlockState.addableContentBlockMetaData]);

    const isAddingQuestion = useMemo(() => {
        return (
            addContentBlockState.addableContentBlockMetaData
                ?.blockOrLayoutType === ContentBlockType.Question
        );
    }, [addContentBlockState.addableContentBlockMetaData]);

    const isBehaviorType = (selectedConfig: ContentBlockConfig) => {
        return (
            selectedConfig?.availableBehaviors?.filter(
                /**
                 * make an exception for blocks that open modals,
                 * since modals are connected to blocks when the modals themselves are created
                 */
                (behavior) => {
                    return (
                        behavior !== "ShowModalContentBlock" &&
                        Object.keys(clickBehaviorsMap).includes(behavior)
                    );
                },
            )?.length > 0
        );
    };

    const isAddingBehaviorBlock = useMemo(() => {
        return isBehaviorType(selectedConfig);
    }, [selectedConfig]);

    return {
        addContentBlockState,
        setIsAddingModalToExistingContentBlock,
        setAddableContentBlockMetaData,
        setNewModalTriggerType,
        isAddingModal,
        isAddingQuestion,
        isAddingBehaviorBlock,
        isBehaviorType,
        selectedConfig,
        reset,
    };
}
