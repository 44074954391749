import styled from "styled-components";
import {
    space,
    layout,
    flexbox,
    grid,
    background,
    color,
    position,
    typography,
    border,
    width,
} from "styled-system";
import { ThemeObject } from "../ThemeObjects";

function handleWrapBackgroundImage(imagePath: string | null) {
    if (imagePath) {
        imagePath =
            imagePath.indexOf("url(") === 0 ||
            imagePath.indexOf("gradient") !== -1
                ? imagePath
                : `url(${imagePath})`;

        return imagePath;
    }
    return "";
}

export const StyledUniversalDiv = styled.div.attrs(
    (props: ThemeObject & { dataContentBlockId?: string }) => {
        const style = {
            // ...props,
            color: props.color,
            backgroundColor: props.backgroundColor,
            opacity: props.opacity,
            backgroundImage: handleWrapBackgroundImage(
                props.backgroundImage as unknown as string
            ),
            // width: props.width,
        };

        return {
            style,
        };
    }
)<ThemeObject>`
    ${width}
    ${space}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    /* color */
    ${position}
    ${typography}
    ${border}
    // additional
    text-transform: ${(props) => props.textTransform || "none"};
    cursor: ${(props) => props.cursor || "auto"};
`;
StyledUniversalDiv.displayName = "StyledUniversalDiv";

//use role={"button"}
export const StyledUniversalButton = styled.button<ThemeObject>`
    ${space}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${color}
    ${position}
    ${typography}
    ${border}
    // additional
    text-transform: ${(props) => props.textTransform || "none"};
    cursor: ${(props) => props.cursor || "auto"};

`;
StyledUniversalButton.displayName = "StyledUniversalButton";

export const StyledUniversalLinkDiv = styled.div<ThemeObject>`
    ${space}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    /* color */
    ${position}
    ${typography}
    ${border}
    // additional
    text-transform: ${(props) => props.textTransform || "none"};
    cursor: ${(props) => props.cursor || "auto"};
    background-color: none;
    // child link/span styling
    > button,
    > div,
    > a,
    span {
        color: ${(props) => props.color};
        cursor: ${(props) => props.cursor || "auto"};
        opacity: ${(props) => props.opacity};
        transition: 0.3s;
        text-decoration: none;
        &:hover,
        &.active {
            opacity: 1;
            text-decoration: none;
            color: ${(props) =>
                props.activeColor ? props.activeColor : props.color};
        }
        display: ${(props) => props.display};
        align-items: ${(props) => props.alignItems};
    }
`;
StyledUniversalLinkDiv.displayName = "StyledUniversalLinkDiv";

export const SytledUniversalImage = styled.img<ThemeObject>`
    ${border}
    ${layout}
`;

SytledUniversalImage.displayName = "SytledUniversalImage";

export const StyledUniversalSection = styled.section.attrs(
    (props: ThemeObject) => {
        // console.log("PROPS", props);
        return {
            style: {
                color: props.color,
                backgroundColor: props.backgroundColor,
                opacity: props.opacity,
                backgroundImage: props.backgroundImage,
            },
        };
    }
)<ThemeObject>`
    ${space}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    /* color */
    ${position}
    ${typography}
    ${border};
    // additional
    text-transform: ${(props) => props.textTransform || "none"};
    cursor: ${(props) => props.cursor || "auto"};
    background-image: ${({ backgroundImage }) =>
        handleWrapBackgroundImage(backgroundImage as string)};
    background-size: cover;
    background-position ${({ backgroundPosition }) =>
        backgroundPosition || "center"};
`;
StyledUniversalSection.displayName = "StyledUniversalSection";
