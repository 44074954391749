import { useCallback } from "react";
import { useAtom } from "jotai";
import { useHasUnsavedChanges } from "./useHasUnsavedChanges";
import { activeContentBlockAtom } from "./useGetActiveContentBlock";

export function useDiscardActiveContentBlock() {
    const hasUnsavedChanges = useHasUnsavedChanges();
    const [_, setActiveContentBlock] = useAtom(activeContentBlockAtom);

    const discardActiveContentBlock = useCallback(
        (ignoreHasUnsavedChanges = false) => {
            if (
                (!ignoreHasUnsavedChanges && !hasUnsavedChanges) ||
                confirm("Are you sure you want to discard your changes?")
            ) {
                setActiveContentBlock(false);
                return true;
            }
            return false;
        },
        [hasUnsavedChanges]
    );

    return {
        discardActiveContentBlock,
    };
}
