import React, { useCallback, useMemo } from "react";
import { Prompt, SelectionShape } from "@/models";
import { QuestionContentBlockFormFieldDisplayProps } from "../types";
import { DragAndDropItem } from "./types";
import {
    // DragAndDropContextWrapper,
    TimelineContextContainer,
} from "./DragAndDropContextWrapper";

const mapSelectionShapesByOptionId = (selectionShapes: SelectionShape[]) => {
    return selectionShapes?.length
        ? selectionShapes.reduce((carry, selectionShape) => {
              return {
                  ...carry,
                  [selectionShape.option_id]: selectionShape,
              };
          }, {})
        : {};
};

const DragAndDropItemChild: React.FC<{
    id: string;
    text: string;
    width: number;
}> = ({ id, text, width }) => {
    return (
        <div
            key={id}
            style={{
                backgroundColor: "#E9D8FD",
                borderRadius: "6px",
                padding: "8px",
                gridColumn: `span ${width}`,
            }}
        >
            {text || "option text"}
        </div>
    );
};

export const QuestionContentBlockTimeline = (
    props: QuestionContentBlockFormFieldDisplayProps & {
        prompt: Prompt;
        optionRenderMap: { [index: string]: JSX.Element | JSX.Element[] };
        isInDesignContext: boolean;
    }
) => {
    const {
        controlledFormFieldData,
        updateControlledFormFieldData,
        prompt,
        optionRenderMap,
        isInDesignContext,
    } = props;

    const { selectionContainers, optionContainers } = prompt;

    const dndItems: DragAndDropItem[] = useMemo(() => {
        const mappedSelectionShapes = mapSelectionShapesByOptionId(
            controlledFormFieldData
        );

        return prompt?.options?.map((option, i) => {
            const originalContainer = [
                ...selectionContainers,
                ...optionContainers,
            ].find(
                (container) =>
                    container.id === option.drag_and_drop_prompt_container_id
            );

            return {
                option_id: option.id,
                is_selected: mappedSelectionShapes[option.id]?.is_selected,
                drag_and_drop_prompt_container_id: !!mappedSelectionShapes[
                    option.id
                ]?.drag_and_drop_prompt_container_id
                    ? mappedSelectionShapes[option.id]
                          .drag_and_drop_prompt_container_id
                    : option.drag_and_drop_prompt_container_id,
                option_drag_and_drop_prompt_container_id:
                    option.drag_and_drop_prompt_container_id,
                option_size: option.size,
                selection_numerical_value: !!mappedSelectionShapes[option.id]
                    ? mappedSelectionShapes[option.id].numerical_value
                    : option.weight,
                option_numerical_value: option.numerical_value,
                option_text: option.content,
                color: originalContainer?.theme?.backgroundColor || "#E9D8FD",
                child: !!optionRenderMap[option.id] ? (
                    <>{optionRenderMap[option.id]}</>
                ) : (
                    <DragAndDropItemChild
                        id={option.id}
                        text={option.content}
                        width={i + 1}
                    />
                ),
            };
        });
    }, [
        prompt,
        controlledFormFieldData,
        selectionContainers,
        optionContainers,
        optionRenderMap,
        prompt.options,
        prompt?.options?.length,
    ]);

    const handleAction = useCallback(
        (dragAndDropItems: DragAndDropItem[]) => {
            if (!updateControlledFormFieldData) {
                return;
            }

            const mappedSelectionShapes = mapSelectionShapesByOptionId(
                controlledFormFieldData
            );

            const newData = dragAndDropItems.map((item) => ({
                ...mappedSelectionShapes[item.option_id],
                is_selected: item.is_selected,
                option_id: item.option_id,
                drag_and_drop_prompt_container_id:
                    item.drag_and_drop_prompt_container_id,
                option_drag_and_drop_prompt_container_id:
                    item.option_drag_and_drop_prompt_container_id,
                option_size: item.option_size,
                selection_numerical_value: item.selection_numerical_value || 0,
                option_numerical_value: item.option_numerical_value || 0,
                numerical_value:
                    item.selection_numerical_value ||
                    item.option_numerical_value, // TODO: handle different selection container types
            }));

            updateControlledFormFieldData(newData);
        },
        [
            controlledFormFieldData,
            updateControlledFormFieldData,
            selectionContainers,
            dndItems,
        ]
    );

    if (!optionContainers?.length || !selectionContainers) return <></>;
    return (
        <>
            <TimelineContextContainer
                optionContainers={optionContainers}
                selectionContainers={selectionContainers}
                dragItems={dndItems}
                onDragEnd={handleAction}
                isInDesignContext={isInDesignContext}
            />
        </>
    );
};
