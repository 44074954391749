import styled from "styled-components";

// export const H1Span = styled.span`
//     font-size: 96px;
//     font-weight: lighter;
//     letter-spacing: -1.5px;
// `;
// H1Span.displayName = "H1Span";

// export const H2Span = styled.span`
//     font-size: 60px;
//     font-weight: lighter;
//     letter-spacing: -0.5px;
// `;
// H2Span.displayName = "H2Span";

export const H3Span = styled.span`
    font-size: 48px;
    font-weight: normal;
    letter-spacing: 0px;
`;
H3Span.displayName = "H3Span";

// export const H3ThinSpan = styled.span`
//     font-size: 48px;
//     font-weight: lighter;
//     letter-spacing: -0.5px;
// `;
// H3ThinSpan.displayName = "H3ThinSpan";

export const H4Span = styled.span`
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 0.25px;
`;
H4Span.displayName = "H4Span";

export const H4ThinSpan = styled.span`
    font-size: 34px;
    font-weight: lighter;
    letter-spacing: -0.5px;
`;
H4ThinSpan.displayName = "H4ThinSpan";

export const H5Span = styled.span`
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;
`;
H5Span.displayName = "H5Span";

export const H6Span = styled.span`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
`;
H6Span.displayName = "H6Span";

// export const H6ThinSpan = styled.span`
//     font-size: 20px;
//     font-weight: 250;
//     letter-spacing: -0.25px;
// `;
// H6ThinSpan.displayName = "H6ThinSpan";

export const Subtitle1Span = styled.span`
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.15px;
`;
Subtitle1Span.displayName = "Subtitle1Span";

export const Subtitle2Span = styled.span`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
`;
Subtitle2Span.displayName = "Subtitle2Span";

// export const Body1Span = styled.span`
//     font-size: 16px;
//     font-weight: normal;
//     letter-spacing: 0.5px;
// `;
// Body1Span.displayName = "Body1Span";

export const Body2Span = styled.span`
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
`;
Body2Span.displayName = "Body2Span";

export const ButtonSpan = styled.span`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-transform: uppercase;
`;
ButtonSpan.displayName = "ButtonSpan";

export const CaptionSpan = styled.span`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
`;
CaptionSpan.displayName = "CaptionSpan";

export const OverlineSpan = styled.span`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
`;
OverlineSpan.displayName = "OverlineSpan";

// export const OverlineBoldSpan = styled.span`
//     font-size: 9px;
//     font-weight: 700;
//     letter-spacing: 0.5px;
//     text-transform: uppercase;
// `;
// OverlineBoldSpan.displayName = "OverlineBoldSpan";
