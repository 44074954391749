import { sapienRoute } from "@/inertia-utils/hooks";
import axios from "axios";
import { z } from "zod";

const userMessageSchema = z.object({
    content: z.string(),
    role: z.literal("user"),
});
const assistantMessageSchema = z.object({
    content: z.string(),
    role: z.literal("assistant"),
    isPending: z.boolean().default(false),
});
export type UserMessage = z.infer<typeof userMessageSchema>;
export type AssistantMessage = z.infer<typeof assistantMessageSchema>;

const messageSchema = z.discriminatedUnion("role", [
    userMessageSchema,
    assistantMessageSchema,
]);
export type GPTMessage = z.infer<typeof messageSchema>;
const gptResponseSchema = z.array(messageSchema);
export type GptResponse = z.infer<typeof gptResponseSchema>;

export async function getOpenAiPromptResponse(messages: GptResponse) {
    const route = sapienRoute("creator.design.chat-gpt-suggestion");
    const { data } = await axios.post<{ gpt_responses: GptResponse }>(route, {
        messages,
    });

    try {
        // const fixedData = Array.isArray(data.gpt_responses)
        //     ? data
        //     : { ...data, gpt_responses: Object.values(data.gpt_responses) };
        // console.log("fixedData", fixedData);
        const parsed = gptResponseSchema.parse(data.gpt_responses);
        return parsed;
    } catch (e) {
        console.error(e);
    }
}
