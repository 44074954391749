import { ContentBlock, ContentBlockShape, DisplayRule } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DisplayRuleSliceState = {
    displayRulesByContentBlockId: { [index: string]: DisplayRule };
    selectedDisplayRuleModelVariableIds: string[];
    hiddenContentBlockIds: string[];
};

const initialState: DisplayRuleSliceState = {
    displayRulesByContentBlockId: {},
    selectedDisplayRuleModelVariableIds: [],
    hiddenContentBlockIds: [],
};

const displayRuleSlice = createSlice({
    name: "displayRuleSlice",
    initialState,
    reducers: {
        setDisplayRules(
            state,
            { payload }: PayloadAction<(ContentBlock | ContentBlockShape)[]>
        ) {
            state.displayRulesByContentBlockId = payload.reduce(
                (carry, contentBlock) => {
                    if (contentBlock.displayRule) {
                        return {
                            ...carry,
                            [contentBlock.id]: contentBlock.displayRule,
                        };
                    }
                    return carry;
                },
                {}
            );
        },
        setSelectedDisplayRuleModelVariableIds(
            state,
            { payload }: PayloadAction<string[]>
        ) {
            state.selectedDisplayRuleModelVariableIds = payload;
        },
    },
});

export const displayRuleSliceReducer = displayRuleSlice.reducer;
export const { setDisplayRules, setSelectedDisplayRuleModelVariableIds } =
    displayRuleSlice.actions;
