import React, { useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { PromptType, SelectionShape } from "@/models";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Dictionary, groupBy } from "lodash";

type OthersSelectionsPopoverProps = {
    othersSelections: SelectionShape[];
    userId: string;
    teamId: string;
};

const numericTypes = [
    PromptType["Numerical Slider"],
    PromptType["Numerical Input"],
];

const freeformTypes = [PromptType["Long Text"], PromptType["Short Text"]];

export function OthersSelectionsPopover({
    othersSelections,
    userId,
    teamId,
}: OthersSelectionsPopoverProps) {
    const [groupedSelections, setGroupedSelections] = useState<
        Dictionary<SelectionShape[]>
    >({});

    useEffect(() => {
        if (!!othersSelections?.length && !!userId && !!teamId) {
            if (othersSelections[0].scope === "Participant") {
                if (
                    othersSelections.some(
                        (selection) => selection.user_id !== userId
                    )
                ) {
                    setGroupedSelections(
                        groupBy(othersSelections, "user_name")
                    );
                } else {
                    setGroupedSelections({});
                }
            } else {
                if (
                    othersSelections.some(
                        (selection) => selection.team_id !== teamId
                    )
                ) {
                    setGroupedSelections(
                        groupBy(othersSelections, "team_name")
                    );
                } else {
                    setGroupedSelections({});
                }
            }
        }
    }, [othersSelections, userId, teamId]);

    // TO DO: handle ranking text/number display and ordering of ms selections

    return (
        !!othersSelections &&
        !!groupedSelections &&
        !!Object.keys(groupedSelections).length && (
            <>
                <Popover className={"absolute right-0 -top-[1rem]"}>
                    {({ open }) => (
                        <>
                            <Popover.Button
                                type="button"
                                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {othersSelections[0].scope === "Participant"
                                    ? "View Team Selections"
                                    : "View Cohort Selections"}
                                <ChevronDownIcon
                                    className="ml-2 -mr-0.5 h-4 w-4"
                                    aria-hidden="true"
                                />
                            </Popover.Button>

                            {/* Use the `Transition` component. */}
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100 z-50"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                {/* Mark this component as `static` */}
                                <Popover.Panel static className="z-50">
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute right-0 top-1 min-w-[300px] w-max">
                                        <ul className="relative bg-white p-4 flex flex-col list-none">
                                            {[...Object.keys(groupedSelections)]
                                                .sort()
                                                .map((key) => (
                                                    <li
                                                        key={key}
                                                        className="list-none flex flex-row justify-between border-b border-gray-200 last:border-0 py-2 gap-6"
                                                    >
                                                        <span className="text-stone-800 font-bold">
                                                            {key}
                                                        </span>
                                                        <span>
                                                            {numericTypes.includes(
                                                                groupedSelections[
                                                                    key
                                                                ][0].prompt_type
                                                            )
                                                                ? groupedSelections[
                                                                      key
                                                                  ][0]
                                                                      .numerical_value
                                                                : freeformTypes.includes(
                                                                      groupedSelections[
                                                                          key
                                                                      ][0]
                                                                          .prompt_type
                                                                  )
                                                                ? groupedSelections[
                                                                      key
                                                                  ][0]
                                                                      .string_value
                                                                : groupedSelections[
                                                                      key
                                                                  ].reduce(
                                                                      (
                                                                          displayString,
                                                                          selection,
                                                                          index
                                                                      ) => {
                                                                          if (
                                                                              groupedSelections[
                                                                                  key
                                                                              ]
                                                                                  .length ===
                                                                                  1 ||
                                                                              index ===
                                                                                  0
                                                                          ) {
                                                                              return selection.option_text;
                                                                          } else {
                                                                              return `${displayString}, ${selection.option_text}`;
                                                                          }
                                                                      },
                                                                      ""
                                                                  )}
                                                        </span>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </>
        )
    );
}
