import { ContentBlockShape, PromptType } from "@/models";
import React from "react";
import { useCreateQuestion } from "./useCreateQuestion";
import { useAddContentBlockState } from "../useAddContentBlockState";

type Props = {
    contentBlock: ContentBlockShape;
    promptType: PromptType;
    depth: number
};

export default function AddQuestionButton({ contentBlock, depth }: Props) {
    const mutation = useCreateQuestion();
    const { addContentBlockState } = useAddContentBlockState();
    return (
        <button
            className="add-question-to-content-block-button add-content-block-button absolute w-full h-full inset-0 bg-transparent flex items-center justify-center text-white text-2xl"
            aria-label={`Add Question Content Block to ${contentBlock.content_block_type} ${contentBlock.id}`}
            onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                mutation.mutateAsync({
                    parentContentBlockId: contentBlock.id,
                    promptType:
                        addContentBlockState?.addableContentBlockMetaData
                            ?.promptType,
                });
            }}
            style={{ zIndex: depth * 10 }}
        ></button>
    );
}
