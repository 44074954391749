import React from "react";

export type HoveredIndexes = {
    x: number;
    y: number;
}
export function useHoveredIndexes() {
    const [hoveredIndexes, setHoveredIndexes] = React.useState<HoveredIndexes>({
        x: -1,
        y: -1,
    });

    const handleMouseEnter = ({ x, y }: HoveredIndexes) => {
        setHoveredIndexes({ x, y });
    };

    const handleMouseLeave = () => {
        setHoveredIndexes({ x: -1, y: -1 });
    };

    return { hoveredIndexes, handleMouseEnter, handleMouseLeave };
}
