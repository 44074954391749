import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
// import useDimensions from "react-use-dimensions";
import {
    ThemeObject,
    Display,
    AlignItems,
    AlignContent,
    JustifyItems,
    JustifyContent,
    FlexWrap,
    FlexDirection,
} from "../ThemeObjects";
import { StyledUniversalDiv, SytledUniversalImage } from "./Universal";
import { ContentBlockEvents } from "@/styles/themes/ContentBlockEvents";
import { useContentBlockBehaviors } from "@/hooks/useContentBlockBehaviors";
import { WindowIcon } from "@heroicons/react/24/solid";

type StyledImageProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    imageUrl: string;
    contentBlockId: string;
};

export const StyledImage: FC<StyledImageProps> = ({
    theme,
    imageUrl,
    contentBlockId,
    title,
    isInEditorContext,
    children,
}) => {
    const [numberOfRetriesRemaining, setNumberOfRetriesRemaining] = useState(5);
    const [src, setSrc] = useState(imageUrl);

    useEffect(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => imageUrl);
        } else {
            setSrc(
                () =>
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
            );
        }
    }, [numberOfRetriesRemaining]);

    useEffect(() => {
        setNumberOfRetriesRemaining(5);
        setSrc(() => imageUrl);
    }, [imageUrl]);

    const retry = useCallback(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => "");
            setNumberOfRetriesRemaining((previousNumber) => {
                return previousNumber - 1;
            });
        }
    }, [numberOfRetriesRemaining, imageUrl]);

    const { clickFunction, behaviors } =
        useContentBlockBehaviors(contentBlockId);
    const opensModal =
        !!behaviors?.click?.ShowModalContentBlock?.modalContentBlockId;

    return (
        <StyledUniversalDiv
            className="group/image relative"
            {...theme}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Image"}
        >
            {!!opensModal && (
                <>
                    <div
                        onClick={isInEditorContext ? undefined : clickFunction}
                        role="button"
                        className={`${isInEditorContext ? "pointer-events-none" : ""} ${contentBlockId} absolute inset-0 z-[1000] flex cursor-pointer items-center justify-center opacity-0 transition-all group-hover/image:opacity-50`}
                    >
                        <div className="rounded-full bg-white/50 p-1 group-hover/image:bg-white/100">
                            <WindowIcon className="h-12 w-12" />
                        </div>
                    </div>
                    <div className="absolute right-2 top-2 rounded-full bg-white/50 p-1 group-hover:bg-white/100">
                        <WindowIcon className="h-5 w-5" />
                    </div>
                </>
            )}
            {children}
            {numberOfRetriesRemaining >= 0 && (
                <SytledUniversalImage
                    width="100%"
                    borderRadius={theme.borderRadius}
                    src={src}
                    onError={retry}
                    className={`${
                        "function" === typeof clickFunction && isInEditorContext
                            ? "cursor-pointer group-hover:cursor-pointer"
                            : "cursor-auto"
                    }`}
                />
            )}
        </StyledUniversalDiv>
    );
};

export const defaultImageTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff00",
    borderColor: "#00000000",
    borderWidth: 1,
    borderRadius: 0,
    borderStyle: "solid",
    pt: 0,
    pr: 0,
    pb: 0,
    pl: 0,
    mt: 0,
    mr: 0,
    mb: 0,
    ml: 0,
    width: ["auto", "auto", "auto", "auto"],
    display: Display.flex,
    flexDirection: FlexDirection.row,
    flexWrap: FlexWrap.wrap,
    alignItems: AlignItems.center,
    alignContent: AlignContent.center,
    justifyItems: JustifyItems.center,
    justifyContent: JustifyContent.center,
};
