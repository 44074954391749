import { Tabs, TabsList, TabsTrigger } from "@/components/Tabs";
import { ContentBlockShape } from "@/models";
import React from "react";
import { cn } from "@/util";
import LessonTab from "./LessonTab";

type Props = {
    contentBlock: ContentBlockShape;
    contentBlocks: ContentBlockShape[];
    isInAdminContext: boolean;
};

export default function LessonTabs({
    contentBlock,
    contentBlocks,
    isInAdminContext,
}: Props) {
    return (
        <Tabs
            className={`${cn(contentBlock.theme?.tailwindClasses)} overflow-hidden border`}
            defaultValue={contentBlocks?.[0]?.id}
            style={{
                borderColor: contentBlock.theme?.borderColor,
                backgroundColor: contentBlock.theme?.backgroundColor,
            }}
        >
            <TabsList className="flex h-auto justify-stretch p-0">
                {contentBlocks?.map((contentBlock) => (
                    <TabsTrigger
                        key={contentBlock.id}
                        value={contentBlock.id}
                        className={`grow border-l border-r first:border-none last:border-none
                        data-[state='active']:bg-[${
                            contentBlock?.theme?.backgroundColorId
                                ? "color:var(--" + contentBlock?.theme?.backgroundColorId + ")"
                                : "white"
                        }]
                        data-[state='inactive']:bg-gray-100 data-[state='active']:shadow-none
                        ${cn(contentBlock.theme?.tailwindClasses)}`}
                        style={{
                            borderColor: contentBlock.theme?.borderColor,
                            backgroundColor:
                                contentBlock.theme?.backgroundColor,
                        }}
                    >
                        {contentBlock.content}
                    </TabsTrigger>
                ))}
            </TabsList>
            {contentBlocks?.map((contentBlock) => (
                <LessonTab
                    isInAdminContext={isInAdminContext}
                    key={contentBlock.id}
                    contentBlock={contentBlock}
                />
            ))}
        </Tabs>
    );
}
