import React, { useState } from "react";
import {
    Popover,
    PopoverContent,
    PopoverPortal,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { ContentBlockShape } from "@/models";
import { Ellipsis } from "lucide-react";
import { AddColumnButton } from "./AddColumnButton";
import { DeleteColumnButton } from "./DeleteColumnButton";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";

type Props = {
    column: number;
    table: ContentBlockShape;
    td: ContentBlockShape;
    isShown: boolean;
};

export default function ColumnEditor({ table, column, isShown, td }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const tableEl = useGetElementAsync(
        `[data-content-block-id="${table.id}"]`,
    ) as HTMLTableElement;
    const tr = tableEl?.querySelector("tr");
    const tdEl = Object.values(tr?.children || {})?.find(
        (tdEl) => tdEl.getAttribute("data-content-block-id") === td.id,
    ) as HTMLTableCellElement;

    if (!tdEl) return null;
    return (
        <>
            {!!isOpen && (
                <div
                    className="borde right pointer-events-none absolute left-0 right-0 top-0 z-1 border
                        border-sky-300"
                    style={{ height: tableEl.clientHeight }}
                />
            )}
            <div
                className="absolute left-1/2 top-0 z-2 flex items-center opacity-0 transition-all
                    -translate-x-1/2 -translate-y-1/2 transform data-[is-shown='true']:opacity-100"
                data-is-shown={isShown}
            >
                <Popover onOpenChange={setIsOpen}>
                    <PopoverTrigger
                        className="z-[99999] flex h-5 w-8 items-center justify-center rounded-md bg-white
                            text-slate-600 shadow transition-all hover:bg-sapien-blue hover:text-white
                            hover:shadow-sm"
                    >
                        <Ellipsis className="w-6" />
                    </PopoverTrigger>
                    <PopoverPortal container={tdEl}>
                        <PopoverContent
                            className="bg-popover text-popover-foreground data-[state=open]:animate-in
                                data-[state=closed]:animate-out data-[state=closed]:fade-out-0
                                data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
                                data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2
                                data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2
                                data-[side=top]:slide-in-from-bottom-2 z-[9999999] mt-2 rounded-md border
                                bg-white shadow-md outline-none"
                            align="start"
                        >
                            <div className="flex w-64 flex-col justify-center p-2 text-sm">
                                <AddColumnButton
                                    table={table}
                                    column={column}
                                />
                                <DeleteColumnButton
                                    table={table}
                                    column={column}
                                />
                            </div>
                        </PopoverContent>
                    </PopoverPortal>
                </Popover>
            </div>
        </>
    );
}
