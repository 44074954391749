import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, PromptShape, PromptType } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { useEditedContentBlock } from "../content-block-editing/useEditedContentBlock";
import { contentBlockFunctionMap } from "./content-block-functions/contentBlockFunctionMap";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useContentBlockState } from "../useContentBlockState";

export function useCreatePrompt() {
    const { contentBlockToCreate } = useEditedContentBlock();
    const { setContentBlock } = useContentBlockState();

    return useMutation({
        mutationFn: async (prompt_type: PromptType) => {
            if (!contentBlockToCreate) return;
            const { round_id, weight } = contentBlockToCreate;
            const formBlock = contentBlockFunctionMap.createFormBlock(
                round_id,
                weight,
            );
            const { data } = await sapienAxios.post<
                { contentBlocks: ContentBlockShape[] },
                "e-learning.prompts.store"
            >("e-learning.prompts.store", {
                ...formBlock,
                prompt_type,
                position: "before",
            } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.prompts.store"]);

            return data?.contentBlocks;
        },
        onSuccess(contentBlocks) {
            contentBlocks?.forEach((contentBlock) =>
                setContentBlock(contentBlock),
            );
        },
    });
}

export function useUpdatePrompt() {
    const { setContentBlock } = useContentBlockState();

    return useMutation({
        mutationFn: async (prompt: PromptShape) => {
            console.log("calling?", prompt);
            const { data } = await sapienAxios.put<
                { contentBlocks: ContentBlockShape[] },
                "e-learning.prompts.update"
            >(
                `e-learning.prompts.update`,
                {
                    ...prompt,
                } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.prompts.update"],
                { prompt: prompt.id },
            );

            console.log("hey", data);
            return data?.contentBlocks;
        },
        onSuccess(contentBlocks) {
            contentBlocks?.forEach((contentBlock) =>
                setContentBlock(contentBlock),
            );
        },
    });
}
