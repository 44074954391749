import { useState, useEffect } from "react";

export const useGetElementAsync = (query: string) => {
    const [element, setElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        (async () => {
            let element: HTMLElement = await new Promise((resolve) => {
                let timeOut = 100;
                let timesRun = 0;
                function getElement() {
                    const element = document.querySelector(
                        query,
                    ) as HTMLElement;
                    if (element) {
                        resolve(element);
                    } else {
                        timesRun += 1;
                        timeOut = Math.min(timesRun * 100, 2000);

                        // Set timeout isn't a must but it
                        // decreases number of recursions
                        setTimeout(() => {
                            requestAnimationFrame(getElement);
                        }, timeOut);
                    }
                }

                getElement();
            });

            setElement(element);
        })();
    }, [query]);

    return element;
};
