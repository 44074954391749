import { useSelectedSimulation } from "@/hooks";
import { sapienAxios } from "@/inertia-utils/hooks";
import { Color } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

async function fetchColors(courseId: string) {
    const { data } = await sapienAxios.get<
        { colors: Color[] },
        "creator.colors.index"
    >("creator.colors.index", {
        courseId,
    });

    return data;
}

export function useColors() {
    const { selectedSimulation } = useSelectedSimulation();

    return useQuery({
        queryKey: ["colors", selectedSimulation?.id],
        queryFn: async () => {
            const {colors} = await fetchColors(selectedSimulation?.id);
            // colors.forEach((color) => {
            //     document.documentElement.style.setProperty(`--${color.id}`, color.css_color);
            // });
            return colors;
        },
        select: (data) => data,
        initialData: [],
    });
}

export function useSetColors(colors: { [index: string]: string }) {
    useEffect(() => {
        Object.entries(colors).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
    },[colors])
}
