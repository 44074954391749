import React, { Ref, useMemo, useRef, useState } from "react";
import {
    ContentBlock,
    ContentBlockShape,
    ContentBlockType,
    DisplayRule,
} from "@/models";
import { getIsCollectionType, createChildObject } from "@/model-configs";
import { ContentBlockDisplay } from "./ContentBlockDisplay";
import { EyeIcon } from "@heroicons/react/24/solid";
import {
    useSimulationTheme,
    useFindContentBlockMethods,
    useSelectedSimulation,
    useSelectedRoundId,
} from "@/hooks";
import { contentBlockHasChildren } from "@/util";
import { NestedContentBlockEvents } from "@/styles/themes/ContentBlockEvents";
import { sapienRoute } from "@/inertia-utils/hooks";
import styled from "styled-components";
import { useReflectionJournalEntryStore } from "@/hooks/store/useReflectionJournalEntryStore";
import { createPortal } from "react-dom";
import {
    useGetActiveContentBlock,
    useGetActiveContentBlockId,
} from "@/hooks/activeContentBlock";
import AddContentBlockButton from "../content-block-buttons/AddContentBlockButton";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { defaultSimulationTheme } from "@/styles";

export const StyledPopperWrapper = styled.div`
    + *:not(.modal-backdrop):not(.fixed) {
        position: relative;
        overflow: visible;
        &:has(.cb-outline){
            z-index: 9000;
        }
    }
    visibility: hidden;
    display: none;
`;

const hiddenTypes = [ContentBlockType.Modal, ContentBlockType["Inbox Message"]];

type NestedContentBlockProps = {
    contentBlockId: string;

    contentBlockNested?: ContentBlock | ContentBlockShape;
    parentType?: ContentBlockType;
    siblingCount: number;
    siblingIndex: number;
    // handleSetActiveChildIndex: (index: number, parentId: string) => void;
    displayRules?: { [index: string]: DisplayRule };
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    hiddenContentBlockIds: string[];
    depth: number;
} & NestedContentBlockEvents;

export const NestedContentBlockDisplay = (
    props: NestedContentBlockProps,
    ref: Ref<any>,
) => {
    const {
        contentBlockId,
        contentBlockNested,
        // handleSetActiveChildIndex,
        displayRules,
        editorSizes,
        hiddenContentBlockIds,
        depth = 0,
    } = props;

    const { data: activeContentBlock } = useGetActiveContentBlock();
    const activeId = useGetActiveContentBlockId();
    const isSelected = activeId === contentBlockId;

    const { getContentBlockWithChildren } = useFindContentBlockMethods();
    const { selectedSimulation } = useSelectedSimulation();
    const simulationTheme = selectedSimulation?.theme || defaultSimulationTheme;

    const { selectedRoundId } = useSelectedRoundId();

    const homeRoute = useMemo(() => {
        return sapienRoute("admin.design.show", {
            roundId: selectedRoundId,
        });
    }, [selectedSimulation, selectedRoundId]);

    const [activeChildIndex] = useState<number>(0);

    const contentBlockNestedWithFilteredPrompt:
        | ContentBlock
        | ContentBlockShape = useMemo(() => {
        if (contentBlockNested?.prompt?.options?.length > 0) {
            const filteredOptions = contentBlockNested.prompt.options.filter(
                (option) => {
                    return !hiddenContentBlockIds.includes(
                        option.content_block_id,
                    );
                },
            );

            return {
                ...contentBlockNested,
                prompt: {
                    ...contentBlockNested.prompt,
                    options: filteredOptions,
                },
            } as ContentBlock;
        }
        return contentBlockNested;
    }, [contentBlockNested, hiddenContentBlockIds]);

    //Build out content block components to display for Drag and Drop options
    const optionRenderMap = useMemo(() => {
        return contentBlockNestedWithFilteredPrompt.prompt
            ? contentBlockNestedWithFilteredPrompt.prompt.options
                  .filter(
                      (option) =>
                          !!option.content_block_id &&
                          !!getContentBlockWithChildren(
                              option.content_block_id,
                          ),
                  )
                  //todo: sort by weight
                  //   .sort(
                  //       (a, b) =>
                  //           a.contentBlock.weight - b.contentBlock.weight
                  //   )
                  .reduce((map, option) => {
                      return {
                          ...map,
                          [option.id]: (
                              <NestedContentBlockDisplay
                                  contentBlockId={option.content_block_id}
                                  contentBlockNested={getContentBlockWithChildren(
                                      option.content_block_id,
                                  )}
                                  siblingCount={0}
                                  //   handleSetActiveChildIndex={
                                  //       handleSetActiveChildIndex
                                  //   }
                                  hoverCallback={(e) => {}}
                                  displayRules={displayRules}
                                  editorSizes={editorSizes}
                                  hiddenContentBlockIds={hiddenContentBlockIds}
                                  siblingIndex={0}
                                  depth={depth + 1}
                              />
                          ),
                      };
                  }, {})
            : {};
    }, [
        contentBlockNestedWithFilteredPrompt,
        getContentBlockWithChildren,
        activeId,
    ]);

    const { reflectionJournalEntries } = useReflectionJournalEntryStore();

    if (!simulationTheme) return null;

    const element = useGetElementAsync(
        `[data-content-block-id="${contentBlockId}"]`,
    );

    return (
        <>
            {!!simulationTheme?.palettes &&
                contentBlockNestedWithFilteredPrompt !== undefined && (
                    <>
                        {!!element && (
                            <>
                                {createPortal(
                                    <>
                                        <AddContentBlockButton
                                            contentBlock={
                                                contentBlockNestedWithFilteredPrompt
                                            }
                                            depth={depth}
                                        />
                                    </>,
                                    element,
                                )}
                            </>
                        )}
                        {!hiddenTypes.includes(
                            contentBlockNested.content_block_type,
                        ) && (
                            <StyledPopperWrapper
                                className="absoute"
                                data-type-for={
                                    contentBlockNested.content_block_type
                                }
                            ></StyledPopperWrapper>
                        )}
                        <ContentBlockDisplay
                            contentBlock={
                                isSelected &&
                                activeContentBlock &&
                                activeId === contentBlockId
                                    ? activeContentBlock
                                    : contentBlockNestedWithFilteredPrompt
                            }
                            isSelected={isSelected && !!activeContentBlock}
                            isEditable={true}
                            childObject={
                                getIsCollectionType(
                                    contentBlockNested.content_block_type,
                                ) && contentBlockHasChildren(contentBlockNested)
                                    ? createChildObject(contentBlockNested)
                                    : {}
                            }
                            childBlocks={contentBlockNested?.contentBlocks?.map(
                                (block) =>
                                    !activeContentBlock ||
                                    block.id !== activeContentBlock?.id
                                        ? block
                                        : getContentBlockWithChildren(activeId),
                            )}
                            activeId={
                                getIsCollectionType(
                                    contentBlockNested.content_block_type,
                                ) && activeId
                            }
                            isInEditorContext={true}
                            // TODO: tableData for admin editor
                            tableData={{ tableBody: [] }}
                            simulationTheme={simulationTheme}
                            // setActiveTab={(contentBlockId:string) =>
                            //     clickCallback(new MouseEvent("click") as React.MouseEvent, contentBlockId)
                            // }
                            activeChildIndex={activeChildIndex}
                            formProps={{
                                questionFormObject: {},
                                isSubmitting: false,
                            }}
                            homeRoute={homeRoute}
                            renderMap={optionRenderMap}
                            reflectionJournalEntries={reflectionJournalEntries}
                        >
                            {contentBlockHasChildren(
                                contentBlockNestedWithFilteredPrompt,
                            ) &&
                                contentBlockNestedWithFilteredPrompt.contentBlocks
                                    .filter(
                                        (contentBlock) =>
                                            !hiddenContentBlockIds.includes(
                                                contentBlock.id,
                                            ),
                                    )
                                    .map((child, i) => (
                                        <React.Fragment key={child.id}>
                                            {!!displayRules &&
                                                !!displayRules[child.id] && (
                                                    <>
                                                        <EyeIcon className="absolute ml-2 mt-2 h-6 w-6 text-indigo-500" />
                                                    </>
                                                )}
                                            <NestedContentBlockDisplay
                                                key={child.id}
                                                contentBlockId={child.id}
                                                contentBlockNested={child}
                                                parentType={
                                                    contentBlockNested.content_block_type
                                                }
                                                siblingCount={
                                                    contentBlockNestedWithFilteredPrompt
                                                        .contentBlocks.length
                                                }
                                                siblingIndex={i}
                                                displayRules={displayRules}
                                                editorSizes={editorSizes}
                                                hiddenContentBlockIds={
                                                    hiddenContentBlockIds
                                                }
                                                depth={depth + 1}
                                            />
                                        </React.Fragment>
                                    ))}
                        </ContentBlockDisplay>
                    </>
                )}
        </>
    );
};
