import { ContentBlockShape, RichText } from "@/models";
import { EditorContent } from "@tiptap/react";
import React, { useCallback } from "react";
import { useDebouncedEditor } from "@/modules/text-editor/useDebouncedEditor";
import { useSaveContentBlock } from "../LessonDesign/content-block-management/useSaveContentBlock";
import { RichTextTooltipMenu } from "@/components/rich-text";
import { useContentBlockState } from "../LessonDesign/useContentBlockState";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
    saveOverride?: (rich_text: RichText) => void;
    delayMilliseconds?: number;
};

const LessonRichText = React.memo(function LessonRichText({
    editable = false,
    content,
    contentBlock,
    saveOverride,
    delayMilliseconds = 500,
}: Props) {
    const { mutateAsync } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const handleSave = useCallback(
        (rich_text: RichText) => {
            mutateAsync({ ...contentBlock, rich_text });
        },
        [contentBlock?.id, editable],
    );

    const editor = useDebouncedEditor(
        content,
        editable,
        saveOverride || handleSave,
        undefined,
        delayMilliseconds,
        "Add some text...",
        async (rich_text) => {
            setContentBlock({ ...contentBlock, rich_text });
            mutateAsync({ ...contentBlock, rich_text });
        },
    );

    return (
        <>
            {!!editor && editable && (
                <RichTextTooltipMenu editor={editor} showColorPicker={true} />
            )}

            <EditorContent editor={editor} className="w-full" />
        </>
    );
});

export default LessonRichText;
