import { ReflectionJournalEntry } from "@/models";
import { gotReflectionJournalEntries } from "@/Pages/Participant/SimulationDisplay/redux-state/reflectionJournalEntrySlice";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { isEqual } from "lodash";
import { useCallback } from "react";

export function useReflectionJournalEntryStore() {
    const dispatch = useAppDispatch();

    const reflectionJournalEntries = useAppSelector((state) =>
        state?.reflectionJournalEntryStore?.reflectionJournalEntries
            ? Object.values(
                  state.reflectionJournalEntryStore.reflectionJournalEntries
              )
            : []
    );

    const _gotReflectionJournalEntries = useCallback(
        (updatedReflectionJournalEntries: ReflectionJournalEntry[]) => {
            if (
                !isEqual(
                    updatedReflectionJournalEntries,
                    reflectionJournalEntries
                )
            ) {
                dispatch(
                    gotReflectionJournalEntries(updatedReflectionJournalEntries)
                );
            }
        },
        [dispatch, reflectionJournalEntries]
    );

    return {
        reflectionJournalEntries,
        gotReflectionJournalEntries: _gotReflectionJournalEntries,
    };
}
