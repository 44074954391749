import { ContentBlockType } from "@/models";
import {
    defaultSectionTheme,
    defaultInboxMessageTheme,
    defaultInboxTheme,
} from "@/styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const InboxConfig: ContentBlockConfig = {
    typeKey: "Inbox",
    type: ContentBlockType.Inbox,
    defaultTheme: defaultInboxTheme,
    onClickAdd(parentContentBlock) {
        return {
            content_block_type: ContentBlockType["Inbox Message"],
            theme: JSON.stringify(defaultInboxMessageTheme),
            parent_content_block_id: parentContentBlock.id,
            round_id: parentContentBlock.round_id,
            weight: parentContentBlock?.contentBlocks?.length || 0,
            contentBlocks: [
                {
                    content_block_type: ContentBlockType.Section,
                    theme: defaultSectionTheme,
                },
            ],
        };
    },
    // variantOptions: [
    //     {
    //         label: "Color",
    //         themeObjectKey: "colorWhenLessThanHalfComplete",
    //         configComponentType: ConfigComponentType.FormInputColorPicker,
    //     },
    //     {
    //         label: "Color when 50% complete",
    //         themeObjectKey: "colorWhenHalfComplete",
    //         configComponentType: ConfigComponentType.FormInputColorPicker,
    //     },
    //     {
    //         label: "Color when complete",
    //         themeObjectKey: "colorWhenComplete",
    //         configComponentType: ConfigComponentType.FormInputColorPicker,
    //     },
    // ],
    // availableBehaviors: ["ShowCountOfSubmissionByTeamForRound"],
};
