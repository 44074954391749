import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import axios from "axios";

export async function moveContentBlock({
    id,
    parent_content_block_id,
}: (typeof LaravelRequestBodyShapes)["creator.design.move-content-block"]) {
    const route = sapienRoute("creator.design.move-content-block");
    const { data } = await axios.post<{ savedBlock: ContentBlockShape }>(
        route,
        {
            id,
            parent_content_block_id,
        }
    );

    return data.savedBlock;
}
