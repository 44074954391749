import {
    defaultAccordionContentTheme,
    defaultBoxTheme,
    defaultCardTheme,
} from "../../styles";
import {
    ContentBlockShape,
    ContentBlockType,
    CurrencyType,
    ModelVariableUnit,
    RichText,
} from "../../models";
import { defaultRichTextTheme } from "@/styles/themes/RichTextTheme";
import { buildAccordion } from "@/model-configs/content-block-layouts/AccordionLayout";
import { variableValueFormatter } from "@/util";

export function buildCard(
    image_url: string = "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
): ContentBlockShape {
    return {
        title: "Card",
        content: "",
        content_block_type: ContentBlockType.Card,
        theme: {
            ...defaultCardTheme,
            ...{ palette: "light", fillStyle: "elevated" },
        },
        contentBlocks: [
            {
                title: "",
                content: "",
                content_block_type: ContentBlockType.Box,
                theme: {
                    height: ["156px", "166px", "176px", "196px"],
                    backgroundImage: `url(${image_url})`,
                    backgroundRepeat: "no-repeat",
                    // backgroundSize: "cover",
                    backgroundPosition: "center top",
                },
            },
            {
                title: "",
                content: "",
                content_block_type: ContentBlockType.Box,
                theme: {
                    pt: "16px",
                    pb: "16px",
                    pr: "16px",
                    pl: "16px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                },
                contentBlocks: [
                    {
                        title: "",
                        content: "Title",
                        content_block_type: ContentBlockType["Rich Text"],
                        rich_text: {
                            type: "doc",
                            content: [
                                {
                                    type: "typography",
                                    attrs: {
                                        textAlign: "left",
                                        typographyType: "SUBTITLE_ALT",
                                    },
                                    content: [
                                        {
                                            type: "text",
                                            text: "Title",
                                        },
                                    ],
                                },
                            ],
                        },
                        theme: {
                            ...defaultRichTextTheme,
                            typographyType: "SUBTITLE_ALT",
                            //color: cardTheme.palette.textColor,
                        },
                    },
                    {
                        title: "",
                        content: "Subhead",
                        content_block_type: ContentBlockType["Rich Text"],
                        rich_text: {
                            type: "doc",
                            content: [
                                {
                                    type: "typography",
                                    attrs: {
                                        textAlign: "left",
                                        typographyType: "TEXT_STACKED_CARD",
                                    },
                                    content: [
                                        {
                                            type: "text",
                                            text: "Subhead",
                                        },
                                    ],
                                },
                            ],
                        },
                        theme: {
                            ...defaultRichTextTheme,
                            typographyType: "TEXT_STACKED_CARD",
                            mt: "2px",
                        },
                    },
                    {
                        title: "",
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor`,
                        content_block_type: ContentBlockType["Rich Text"],
                        rich_text: {
                            type: "doc",
                            content: [
                                {
                                    type: "typography",
                                    attrs: {
                                        textAlign: "left",
                                        typographyType: "TEXT_STACKED_CARD",
                                    },
                                    content: [
                                        {
                                            type: "text",
                                            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor`,
                                        },
                                    ],
                                },
                            ],
                        },
                        theme: {
                            ...defaultRichTextTheme,
                            typographyType: "TEXT_STACKED_CARD",
                            mt: "16px",
                        },
                    },
                ],
            },
        ],
    };
}

export function buildTimelineCard(
    text: string,
    parent_content_block_id: string,
    weight: number,
    theme = defaultCardTheme,
): ContentBlockShape {
    return {
        title: text,
        content: "",
        content_block_type: ContentBlockType.Card,
        theme: {
            ...theme,
            ...{ palette: "light", fillStyle: "filled", hasNoPadding: true },
        },
        parent_content_block_id,
        weight,
        contentBlocks: [buildAccordion(text, text, "small")],
    };
}

const dragAndDropHeaderBlocks = (
    text: string,
    subtext: string,
    formattedValue: string,
) => [
    {
        content_block_type: ContentBlockType.Box,
        theme: {
            ...defaultBoxTheme,
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            margin: 0,
        },
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Box,
                theme: {
                    ...defaultBoxTheme,
                    p: 2,
                    backgroundColor: "rgba(0,0,0,1)",
                    weight: 0,
                },
                contentBlocks: [
                    {
                        weight: 0,
                        content_block_type: ContentBlockType["Rich Text"],
                        rich_text: {
                            type: "doc",
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            marks: [
                                                {
                                                    type: "textStyle",
                                                    attrs: {
                                                        color: "#ffffffff",
                                                    },
                                                },
                                            ],
                                            text,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                ],
            },
            {
                weight: 0,
                content_block_type: ContentBlockType["Rich Text"],
                rich_text: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            attrs: {
                                textAlign: "left",
                                typographyType: "TEXT_STACKED_CARD",
                            },
                            content: [
                                {
                                    type: "text",
                                    text: `${formattedValue}`,
                                },
                            ],
                        },
                    ],
                } as RichText,
            },
        ],
    },
    {
        content_block_type: ContentBlockType.Box,
        theme: {
            ...defaultBoxTheme,
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
            my: 2,
        },
        contentBlocks: [
            {
                weight: 0,
                content_block_type: ContentBlockType["Rich Text"],
                rich_text: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            attrs: {
                                textAlign: "left",
                                typographyType: "SMALL",
                            },
                            content: [
                                {
                                    marks: [
                                        {
                                            type: "textStyle",
                                            attrs: { color: "#00000066" },
                                        },
                                    ],
                                    type: "text",
                                    text: subtext,
                                },
                            ],
                        },
                    ],
                } as RichText,
            },
        ],
    },
];

export function buildDragAndDropCard(
    text: string,
    parent_content_block_id: string,
    weight: number,
    numerical_value: number,
    subtext: string,
    content: any,
    theme = defaultCardTheme,
): ContentBlockShape {
    //TODO: give designers options for formatting and units
    const formattedValue = variableValueFormatter(
        CurrencyType.USD,
        ModelVariableUnit.Dollars,
        numerical_value,
        true,
    );

    const headerBlocks = dragAndDropHeaderBlocks(text, subtext, formattedValue);
    return {
        title: text,
        content: "",
        content_block_type: ContentBlockType.Card,
        theme: {
            ...theme,
            ...{ palette: "light", fillStyle: "filled" },
        },
        parent_content_block_id,
        weight,
        contentBlocks: [
            buildAccordion(text, text, "small", headerBlocks, {
                content: text,
                content_block_type: ContentBlockType.AccordionContent,
                theme: {
                    ...defaultAccordionContentTheme,
                    px: 4,
                    py: 4,
                },
                weight: 1,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Box,
                        theme: {
                            ...defaultBoxTheme,
                            mx: 0,
                            my: 0,
                            color: "rgba(2,2,2,1)",
                            backgroundColor: "rgba(0,0,0,0)",
                            borderColor: "rgba(72, 72, 72, 0.25)",
                            borderWidth: "1px",
                            borderRadius: "4px",
                        },
                        contentBlocks: [
                            {
                                content: text,
                                weight: 0,
                                content_block_type:
                                    ContentBlockType["Rich Text"],
                                theme: defaultRichTextTheme,
                                rich_text: content,
                            },
                        ],
                    },
                ],
            }),
        ],
    };
}

export const Card = buildCard();
