import { useMutation } from "@tanstack/react-query";
import { moveContentBlock } from "./moveContentBlock";
import {
    movedContentBlockAtom,
    useMovedContentBlock,
} from "./useMovedContentBlock";
import { useGotContentBlock } from "@/hooks";
import { useHandleSavedContentBlock } from "@/hooks/activeContentBlock";
import { useAtom } from "jotai";

export function useSaveMovedContentBlock() {
    const [_, setMovedContentBlock] = useAtom(movedContentBlockAtom);
    const { gotContentBlock } = useGotContentBlock();
    return useMutation({
        mutationFn: async ({
            id,
            parent_content_block_id,
        }: {
            id: string;
            parent_content_block_id: string;
        }) => moveContentBlock({ id, parent_content_block_id }),
        onSuccess: (savedBlock) => {
            setMovedContentBlock(false);
            gotContentBlock(savedBlock);
        },
    });
}
