import React, { ReactNode } from "react";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { defaultInboxMessageTheme } from "./InboxMessage";
import { useContentBlockBehaviors } from "@/hooks/useContentBlockBehaviors";

type Props = ContentBlockEvents & {
    isOpen: boolean;
    theme: typeof defaultInboxMessageTheme;
    title?: string;
    children: ReactNode;
    contentBlockId: string;
    isRead: boolean;
};

export const InboxNotification = ({
    theme,
    title,
    contentBlockId,
    onHover,
    onClick,
    isInEditorContext,
    isRead,
}: Props) => {
    const { clickFunction } = useContentBlockBehaviors(contentBlockId);

    const functionOnClick =
        "function" === typeof onClick && isInEditorContext
            ? onClick
            : clickFunction;

    return (
        <div
            role={isInEditorContext ? "div" : "button"}
            className={`flex items-center justify-between  py-2  hover:bg-slate-400/10 transition-colors w-full px-4 border-b last:border-0`}
            data-content-block-id={contentBlockId}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
            onClick={
                "function" === typeof functionOnClick
                    ? functionOnClick
                    : undefined
            }
        >
            <div className="flex">
                <div className="flex items-center p-2">
                    {!!theme?.fromAvatarUrl ? (
                        <div
                            className="inline-block h-10 w-10 rounded-full"
                            style={{
                                backgroundImage: `url(${theme.fromAvatarUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                            aria-label={`Avatar of ${theme?.from}`}
                        />
                    ) : (
                        <div className="h-10 w-10 bg-gray-300 text-white rounded-full overflow-hidden">
                            <svg fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                    )}
                </div>
                <div className="flex flex-col space-y-1/2 justify-center min-h-[3rem]">
                    <div className="text-bold flex">{theme?.from}</div>
                    <div className="text-xs flex">{title}</div>
                </div>
            </div>
            <div className="flex items-center">
                {!isRead && (
                    <span className="inline-block w-2 h-2 mr-2 bg-blue-600 rounded-full"></span>
                )}
            </div>
        </div>
    );
};
