import { ContentBlockType } from "@/models";
import { defaultModalTheme } from "@/styles";
import { ConfigComponentType } from "@/styles/ThemeObjects";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { PageConfig } from "./PageConfig";

export const ModalConfig: ContentBlockConfig = {
    typeKey: "Modal",
    type: ContentBlockType.Modal,
    defaultTheme: defaultModalTheme,
    isCollectionType: true,
    addableChildBlocks: PageConfig.addableChildBlocks,
    variantOptions: [
        {
            label: "Size",
            themeObjectKey: "modalSize",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                sm: "sm",
                md: "md",
                lg: "lg",
                xl: "xl",
                full: "full",
            },
        },
    ],
};
