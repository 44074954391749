import { useCallback, useMemo } from "react";
import {
    ModalQueryStrings,
    modalSchema,
} from "@/Pages/Admin/editor/modals/types";
import { useLocationOnInertiaNavigation } from "@/hooks/useLocationOnInertiaNavigation";
import {
    removeSearchParamsFromUrl,
    queryStringToObject,
} from "@/util/urlHelper";
import { openModal } from "./openModal";
import { previouslyOpenedModalParamsAtom } from "./previouslyOpenedModalParamsAtom";
import { useAtom } from "jotai";
import { closeModal } from "./closeModal";
import { useDiscardActiveContentBlock } from "../activeContentBlock";

type ValidModalComponent = ModalQueryStrings["modalComponent"];

export type ReturnTypeBasedOnType<
    T extends ModalQueryStrings,
    K extends ValidModalComponent
> = Extract<T, { modalComponent?: K }>;

export const useModalQueryParams = <T extends ValidModalComponent>(
    modalComponent: T | undefined = undefined
) => {
    const [previouslyOpenedModalParams, setPreviouslyOpenedModalParams] =
        useAtom(previouslyOpenedModalParamsAtom);

    const { discardActiveContentBlock } = useDiscardActiveContentBlock();

    const location = useLocationOnInertiaNavigation();

    const _closeModal = useCallback(
        (e?: React.MouseEvent) => {
            e?.preventDefault();
            e?.stopPropagation();
            const search = queryStringToObject(location?.search);
            const parsed = modalSchema.safeParse(search);
            if (!parsed.success) {
                closeModal(location.href);
                return;
            }
            const newURL = removeSearchParamsFromUrl(
                location?.href,
                Object.keys(parsed.data)
            );

            if (
                previouslyOpenedModalParams &&
                previouslyOpenedModalParams.modalComponent !== modalComponent
            ) {
                const old = previouslyOpenedModalParams;
                setPreviouslyOpenedModalParams(false);
                openModal(old);
            } else {
                setPreviouslyOpenedModalParams(false);
                closeModal(newURL.toString());
            }
        },
        [previouslyOpenedModalParams, openModal, location, modalComponent]
    );

    const queryParams = useMemo(() => {
        const search = queryStringToObject(location?.search);
        try {
            const parsed = modalSchema.safeParse(search);
            if (parsed.success) {
                return parsed.data as ReturnTypeBasedOnType<
                    ModalQueryStrings,
                    T
                >;
            }
        } catch (e: any) {
            console.error("error parsing modal query params", e, e.error);
        }
        return false;
    }, [
        modalComponent,
        location,
        previouslyOpenedModalParams,
    ]) as ReturnTypeBasedOnType<ModalQueryStrings, T>;

    const isAtModalUrl = useMemo(() => {
        return queryParams?.modalComponent === modalComponent;
    }, [queryParams, modalComponent]);

    const isAnyModalOpen = useMemo(() => {
        return !!queryParams?.modalComponent;
    },[queryParams])

    const _openModal = useCallback(
        (
            newQuery: ReturnTypeBasedOnType<ModalQueryStrings, T>,
            shouldReopenPreviouslyOpenedModal = false
        ) => {
            if (!!queryParams && shouldReopenPreviouslyOpenedModal) {
                openModal(newQuery, () =>
                    setPreviouslyOpenedModalParams(queryParams)
                );
            } else {
                openModal(newQuery);
            }
        },
        [previouslyOpenedModalParams, queryParams]
    );

    return {
        openModal: _openModal,
        closeModal: _closeModal,
        isAtModalUrl,
        isAnyModalOpen,
        queryParams,
    };
};
