//TODO: merge these functions into the util namespace
import { BaseModel } from "../models";

export const mapById = (models: BaseModel[], initialMap: any = {}) => {
    return models.reduce((map, model) => {
        return { ...map, ...{ [model.id]: model } };
    }, initialMap);
};

// export const filterMapById = (
//     modelMap: { [index: string]: BaseModel },
//     id: string
// ) => {
//     return Object.keys(modelMap).reduce((map, key) => {
//         if (key === id) {
//             return { ...map };
//         } else {
//             return { ...map, ...{ [key]: modelMap[key] } };
//         }
//     }, {});
// };

//export const normalize = (data: BaseModel[], state: any) => {};

// export const normalizeState = <T>(
//     parentObject: BaseModel,
//     parentType: string,
//     state: T
// ) => {
//     const parentObjectCopy = { ...parentObject } as BaseModel;
//     // let newState = {};
//     for (let prop in parentObjectCopy) {
//         if (
//             state.hasOwnProperty(prop) &&
//             Array.isArray(parentObjectCopy[prop]) &&
//             parentObjectCopy[prop].length &&
//             parentObjectCopy[prop][0].hasOwnProperty("id")
//         ) {
//             console.log(prop, parentObjectCopy[prop]);
//             state[prop] = normalizeStateFromArray(
//                 parentObjectCopy[prop],
//                 prop,
//                 state[prop]
//             );
//             /*
//             state[prop] = state[prop]
//                 ? { ...state[prop], ...mapById(parentObjectCopy[prop]) }
//                 : mapById(parentObjectCopy[prop]);

//             console.log(mapById(parentObjectCopy[prop]));
// */
//             parentObjectCopy[prop] = [];
//         }
//     }
//     state[parentType] = state[parentType]
//         ? { ...state[parentType], ...{ [parentObject.id]: parentObjectCopy } }
//         : { [parentObject.id]: parentObjectCopy };

//     return state;
// };

// /**
//  *
//  * Updates a redux state from a nested structure, for now with the assumption that the parent objects passed will have keys corresponding to keys in the store's state
//  * TODO: allow a map to be passed specifying the relationship between keys in the parent objects' type and the target store(s) keys
//  *
//  * @param parentObjects
//  * @param parentType
//  * @param state
//  * @returns T
//  */
// export const normalizeStateFromArray = <T>(
//     parentObjects: BaseModel[],
//     parentType: string,
//     state: Partial<T>
// ): Partial<T> => {
//     return parentObjects.reduce((state, parentObject) => {
//         return { ...state, ...normalizeState(parentObject, parentType, state) };
//     }, state);
// };

// /**
//  * In the context of entities created by normalizr, replace state matching the keys of newState in a reducer
//  * @param state
//  * @param newState
//  */
// export const resetState = <T>(state: T, newState: Partial<T>): void => {
//     for (let prop in newState) {
//         if (state[prop]) state[prop] = newState[prop];
//     }
// };

// /**
//  * In the context of entities created by normalizr, merge updated data into state matching the keys of newState in a reducer
//  * @param state
//  * @param newState
//  */
// export const upsertState = <T>(state: T, newState: Partial<T>): void => {
//     for (let prop in newState) {
//         if (state[prop]) state[prop] = { ...state[prop], ...newState[prop] };
//     }
// };

// export const getChildrenFromState = <StateType, ParentType extends BaseModel>(
//     parent: ParentType,
//     state: StateType,
//     locationOfChildrenInState: keyof StateType,
//     childProp: keyof ParentType,
//     parentProp: keyof ParentType = "id"
// ) => {
//     if (
//         state[locationOfChildrenInState] &&
//         typeof state[locationOfChildrenInState] === "object"
//     ) {
//         //return Object.values(state[locationOfChildrenInState]).filter(child => child[])
//     }

//     return [];
// };

// export const updateMapById = (
//     modelMap: { [index: string]: BaseModel },
//     updatedModels: BaseModel[]
// ) => {
//     const updatedModelMapById = mapById(updatedModels);
//     return Object.keys(modelMap).reduce((map, key) => {
//         if (updatedModelMapById[key] !== undefined) {
//             return { ...map, ...{ [key]: updatedModelMapById[key] } };
//         } else {
//             return { ...map, ...{ [key]: modelMap[key] } };
//         }
//     }, {});
// };
