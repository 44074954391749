import { ButtonConfig } from "./ButtonConfig";
import { BoxConfig } from "./BoxConfig";
import {
    AccordionsConfig,
    AccordionContentConfig,
    AccordionHeaderConfig,
    AccordionItemConfig,
} from "./AccordionsConfig";
import { CardConfig } from "./CardConfig";
import { CardDeckConfig } from "./CardDeckConfig";
import { CarouselConfig } from "./CarouselConfig";
import { ChartConfig, LineChartConfig, AreaChartConfgi } from "./ChartConfig";
import { GridConfig } from "./GridConfig";
import { HeroConfig } from "./HeroConfig";
import { Hero2Config, Hero3Config } from "./Heroes/";
import { IconConfig } from "./IconConfig";
import { ImageConfig } from "./ImageConfig";
import { LinkConfig } from "./LinkConfig";
import { NavbarConfig } from "./NavbarConfig";
import { PageConfig } from "./PageConfig";
import { QuestionConfig } from "./QuestionConfig";
import { SectionConfig } from "./SectionConfig";
import { TableConfig, TableBodyConfig, LeaderboardConfig } from "./TableConfig";
import { TabsConfig } from "./TabsConfig";
import { TextConfig } from "./TextConfig";
import { TileConfig } from "./TileConfig";
import { TypographyConfig } from "./TypographyConfig";
import { VideoConfig } from "./VideoConfig";
import { FormConfig } from "./FormConfig";
import { ModelDataDisplayConfig } from "./ModelDataDisplayConfig";
import { BottomDrawerConfig } from "./BottomDrawerConfig";
import { ModalConfig } from "./ModalConfig";
import { ReflectionJournalConfig } from "./ReflectionJournalConfig";
import { DecisionLogConfig } from "./DecisionLogConfig";
import { SubmissionCountConfig } from "./SubmissionCountConfig";
import { InboxConfig } from "./InboxConfig";
import { InboxMessageConfig } from "./InboxMessageConfig";
import { TopDrawerConfig } from "./TopDrawerConfig";

export const ContentBlockConfigs = {
    ButtonConfig,
    BoxConfig,
    AccordionsConfig,
    AccordionContentConfig,
    AccordionHeaderConfig,
    AccordionItemConfig,
    CardConfig,
    CardDeckConfig,
    CarouselConfig,
    GridConfig,
    HeroConfig,
    Hero2Config,
    Hero3Config,
    IconConfig,
    ImageConfig,
    LinkConfig,
    NavbarConfig,
    PageConfig,
    QuestionConfig,
    SectionConfig,
    TabsConfig,
    TextConfig,
    TileConfig,
    TypographyConfig,
    VideoConfig,
    FormConfig,

    //table configs
    TableConfig,
    TableBodyConfig,
    LeaderboardConfig,

    //chart configs
    ChartConfig,
    LineChartConfig,
    AreaChartConfgi,

    ModelDataDisplayConfig,
    BottomDrawerConfig,
    TopDrawerConfig,

    ModalConfig,
    ReflectionJournalConfig,
    DecisionLogConfig,
    SubmissionCountConfig,

    InboxConfig,
    InboxMessageConfig,
};
