import React from "react";
import { Icon } from "react-icons-kit";
import { chevronUp } from "react-icons-kit/feather/chevronUp";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { Option, SelectionShape } from "@/models";
import { QuestionContentBlockFormFieldDisplayProps } from "./types";

export const QuestionContentBlockRankingComponent = (
    props: QuestionContentBlockFormFieldDisplayProps & {
        options: Option[];
    }
) => {
    const { controlledFormFieldData, updateControlledFormFieldData, options } =
        props;

    const handleAction = (start: number | string, end: number | string) => {
        if (
            !updateControlledFormFieldData ||
            !controlledFormFieldData?.length
        ) {
            return;
        }

        let arr = [...controlledFormFieldData].sort(
            (a, b) => a.numerical_value - b.numerical_value
        );
        [arr[Number(start)], arr[Number(end)]] = [
            arr[Number(end)],
            arr[Number(start)],
        ];
        let newOptions = arr.map((selection, i) => {
            return {
                option_id: selection.option_id,
                numerical_value: i,
                option_text: selection.option_text,
            } as SelectionShape;
        });

        updateControlledFormFieldData(newOptions);
    };

    return !!controlledFormFieldData?.length ? (
        <>
            {[...controlledFormFieldData]
                .sort((a, b) => a.numerical_value - b.numerical_value)
                .map((selection, index) => (
                    <RankingItemDisplay
                        key={selection.option_id}
                        itemId={selection.option_id}
                        itemText={selection.option_text}
                    >
                        <RankingItemArrows
                            itemIndex={index}
                            itemCount={controlledFormFieldData.length}
                            handleArrowClick={handleAction}
                        />
                    </RankingItemDisplay>
                ))}
        </>
    ) : (
        <>
            {!!options &&
                options.map((option, index) => (
                    <RankingItemDisplay
                        key={option.id}
                        itemId={option.id}
                        itemText={option.content}
                    >
                        <RankingItemArrows
                            itemIndex={index}
                            itemCount={options.length}
                            handleArrowClick={handleAction}
                        />
                    </RankingItemDisplay>
                ))}
        </>
    );
};

function RankingItemDisplay({
    itemId,
    itemText,
    children,
}: {
    itemId: string;
    itemText: string;
    children: any;
}) {
    return (
        <div
            className={`form-check grid`}
            key={itemId}
            style={{
                gridTemplateColumns: "50px 1fr",
                gridGap: 0,
                marginTop: 0,
                display: "grid",
                padding: "10px 5px",
            }}
        >
            <label
                style={{
                    marginLeft: 0,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 0,
                }}
            >
                {children}
            </label>
            <label
                style={{
                    marginLeft: 0,
                    display: "flex",
                    alignItems: "center",
                }}
                className="form-check-label"
                htmlFor={itemId}
            >
                {itemText}
            </label>
        </div>
    );
}

function RankingItemArrows({
    itemIndex,
    itemCount,
    handleArrowClick,
}: {
    itemIndex: number;
    itemCount: number;
    handleArrowClick?: (start: number | string, end: number | string) => void;
}) {
    return (
        <>
            {itemIndex !== 0 && (
                <Icon
                    icon={chevronUp}
                    onClick={() => {
                        !!handleArrowClick &&
                            handleArrowClick(itemIndex, itemIndex - 1);
                    }}
                />
            )}
            {itemIndex !== itemCount - 1 && (
                <Icon
                    icon={chevronDown}
                    onClick={() => {
                        !!handleArrowClick &&
                            handleArrowClick(itemIndex, itemIndex + 1);
                    }}
                />
            )}
        </>
    );
}
