import { ContentBlockType } from "../../models";
import { ConfigComponentType, defaultButtonTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const ButtonConfig: ContentBlockConfig = {
    typeKey: "Button",
    type: ContentBlockType.Button,
    defaultTheme: defaultButtonTheme,
    defaultContent: "Submit",
    availableBehaviors: [
        "MoveToRound",
        "MoveToNextRound",
        "NavigateToNextPage",
        "NavigateToPage",
        "NavigateToPreviousPage",
        "DisplayIfRoundIsComplete",
        "DisplayIfParticipantHasSubmittedOnCurrentPage",
        "DisplayIfTeammateHasSubmittedOnCurrentPage",
        "DisplayIfRoundHasSubmissionFromTeam",
    ],
    variantOptions: [
        {
            label: "Icon Placement",
            themeObjectKey: "iconPlacement",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                left: "left",
                right: "right",
                center: "center",
                none: "none",
            },
        },
        {
            label: "Shape",
            themeObjectKey: "buttonShape",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                sharp: "sharp",
                soft: "soft",
                pill: "pill",
                circle: "circle",
            },
        },
        {
            label: "size",
            themeObjectKey: "size",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                xs: "xs",
                sm: "sm",
                md: "md",
                lg: "lg",
                xl: "xl",
            },
        },
        {
            label: "Fill Style",
            themeObjectKey: "fillStyle",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                outline: "outline",
                filled: "filled",
                ghost: "ghost",
            },
        },
        {
            label: "Alignment",
            themeObjectKey: "alignment",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                right: "right",
                left: "left",
                center: "center",
            },
        },

        // : "#1E429F",
        // : "#FFF",
        // : "#FFF",
        // : "#1A56DB",
        // : "#FFF",
        // : "rgba(0, 0, 0, 0.16)",
        // : "rgba(0, 0, 0, 0.06)",
        // : "rgba(0, 0, 0, 0.16)",
        // : "rgba(0, 0, 0, 0.16)",
    ],
};
