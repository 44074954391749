import React from "react";
import { FieldError } from "react-hook-form";

export const QuestionContentBlockErrorDisplay = ({
    error,
}: {
    error: FieldError | undefined;
}) => {
    return !!error && !!error.message ? (
        <div className="mt-1">
            <span className="text-xs font-normal tracking-wide text-red-700">
                {error.message}
            </span>
        </div>
    ) : null;
};
