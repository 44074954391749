import React, { FC, ReactNode, useMemo } from "react";
import styled from "styled-components";
import { space, flexbox, position, border, variant } from "styled-system";
import { ColorScheme } from "@/models";
import { ThemeObject, widthToTailwindFraction } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { useContentBlockBehaviors } from "@/hooks/useContentBlockBehaviors";
import { WindowIcon } from "@heroicons/react/24/solid";

enum FillStyle {
    "outline" = "outline",
    "elevated" = "elevated",
    "filled" = "filled",
    "none" = "none",
}

enum ImagePlacement {
    "none" = "none",
    "left" = "left",
    "right" = "right",
    "center" = "center",
}

type CardTheme = Partial<ThemeObject> & {
    fillStyle: FillStyle;
    imagePlacement: ImagePlacement;
    isOption: boolean;
    hasNoPadding: boolean;
};
type StyledCardProps = ContentBlockEvents & {
    theme: CardTheme;
    children?: ReactNode[];
    imageUrl: string;
    palette: ColorScheme;
    contentBlockId: string;
    childIsSelected: boolean;
};

export const defaultCardTheme: CardTheme = {
    width: "100%",
    borderRadius: "12px",
    margin: "5px",
    height: "fit-content",
    // maxWidth: ["100%", "50%", "360px"],
    fillStyle: FillStyle.elevated,
    imagePlacement: ImagePlacement.center,
    display: "flex",
    isOption: false,
    hasNoPadding: false,
    //TODO: should we do allow some background position/size stuff here?
};

const Card = styled.div<{
    fillStyle: FillStyle;
    imagePlacement: ImagePlacement;
    palette: ColorScheme;
    variant: "imageCentered" | "imageNotCentered";
    hasNoPadding: boolean;
    backgroundColor: string;
}>`
    ${space}
    ${flexbox}
    ${position}
    ${border}
    background-image: none;

    margin: ${({ hasNoPadding }) => (hasNoPadding ? "0" : "5px")};
    min-height: fit-content;
    align-self: stretch;
    display: flex;
    background-color: ${({ fillStyle, backgroundColor }) =>
        fillStyle !== "none" ? backgroundColor : "transparent"};
    box-shadow: ${({ fillStyle }) =>
        fillStyle === "elevated"
            ? "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
            : null};
    flex-direction: ${({ imagePlacement }) =>
        imagePlacement === "left"
            ? "row"
            : imagePlacement === "right"
              ? "row-reverse"
              : "column"};
    /* max-width: ${({ imagePlacement }) =>
        imagePlacement === "left" || imagePlacement === "right"
            ? "100%"
            : "360px"}; */
    /* ${variant({
        variants: {
            imageCentered: {
                maxWidth: ["100%", "100%", "calc(50% - 10px)", "360px"],
                width: "100%",
            },
            imageNotCentered: {
                maxWidth: ["100%", "100%", "calc(50% - 10px)", "768px"],
                width: "100%",
            },
        },
    })} */
    border: ${({ fillStyle, palette }) =>
        fillStyle === FillStyle.outline
            ? `1px solid ${palette.borderColor}`
            : null};
    > div {
        width: ${({ imagePlacement }) =>
            ImagePlacement.center === "center" ? "100%" : "auto"};
        /* min-width: 100%; */
        ${({ imagePlacement }) =>
            imagePlacement !== ImagePlacement.center
                ? "height: auto; align-self: stretch;"
                : ""};
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
    }

    > div:first-child {
    }
    ${({ imagePlacement }) => {
        if (imagePlacement === ImagePlacement.center) {
            return `
            *:has(img){
                display:block;
                margin-left:auto;
                margin-right:auto;
            }
            `;
        }
        return null;
    }}
`;

export const StyledCard: FC<StyledCardProps> = (props) => {
    const {
        theme,
        children,
        palette,
        contentBlockId,
        childIsSelected,
        isInEditorContext,
        title,
    } = props;

    const widthClass = useMemo(() => {
        if (!theme.width) return "";

        return `@6xl:w-${widthToTailwindFraction[theme.width as string]}`;
    }, [theme]);

    const { clickFunction, behaviors } =
        useContentBlockBehaviors(contentBlockId);

    const opensModal =
        !!behaviors?.click?.ShowModalContentBlock?.modalContentBlockId;

    return (
        <div
            data-content-block-id={contentBlockId}
            role={
                "function" === typeof clickFunction && isInEditorContext
                    ? "button"
                    : "div"
            }
            className={`${
                !theme?.hasNoPadding && "p-2"
            } w-full @md:w-1/2 ${widthClass} card group/card relative flex items-center justify-center`}
        >
            {!!opensModal && (
                <>
                    <div
                        onClick={isInEditorContext ? undefined : clickFunction}
                        role="button"
                        className={`${isInEditorContext ? "pointer-events-none" : ""} ${contentBlockId} absolute inset-0 z-[9000] flex cursor-pointer items-center justify-center opacity-0 transition-all group-hover/image:opacity-50`}
                    >
                        <div className="rounded-full bg-white/50 p-1 group-hover/image:bg-white/100">
                            <WindowIcon className="h-12 w-12" />
                        </div>
                    </div>
                    <div className="absolute right-2 top-2 rounded-full bg-white/50 p-1 group-hover:bg-white/100">
                        <WindowIcon className="h-5 w-5" />
                    </div>
                </>
            )}
            <Card
                variant={
                    theme.imagePlacement === "center"
                        ? "imageCentered"
                        : "imageNotCentered"
                }
                hasNoPadding={theme.hasNoPadding}
                fillStyle={theme.fillStyle as FillStyle}
                imagePlacement={theme.imagePlacement as ImagePlacement}
                palette={palette}
                backgroundColor={theme?.backgroundColor || "#FFF"}
                data-content-block-type={title || "Card"}
                onClick={clickFunction}
                className={`w-full rounded-md ${
                    childIsSelected ? "overflow-visible" : "overflow-hidden"
                }`} //figure out whether any children are selected
            >
                {children}
            </Card>
        </div>
    );
};
