import React, { FC, ReactNode } from "react";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import styled from "styled-components";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { Link } from "@inertiajs/react";
import { createPortal } from "react-dom";

type NavbarTheme = Partial<ThemeObject> & {
    bottomBorder: "bordered" | "none" | "drop shadow";
};

type StyledNavbarProps = ContentBlockEvents & {
    theme: Partial<NavbarTheme>;
    children?: ReactNode;
    isEditable?: boolean;
    contentBlockId: string;
    homeLinkText: string;
    homeRoute: string;
    isSidebarOpen: boolean;
};
let dimensions: any;

const StyledComponentNavbar = styled(StyledUniversalDiv)<Partial<NavbarTheme>>`
    width: 100%;
    * {
        color: ${({ color }) => (color ? color : "purple")};
    }
    border-bottom: ${({ bottomBorder }) =>
        bottomBorder === "bordered" ? `1px solid currentcolor` : "none"};
    box-shadow: ${({ bottomBorder }) =>
        bottomBorder === "drop shadow"
            ? `rgb(0 0 0 / 50%) 0px 2px 4px`
            : "none"};
`;

const StyledSideNavWrapper = styled.div`
    > div,
    > button {
        width: 100% !important;
        display: flex !important;
        border-radius: 0.375rem;
        cursor: pointer;
        color: #2563eb !important;
        &:hover {
            background: #2463eb !important;
            color: white !important;
            * {
                color: white !important;
            }
        }
        * {
            font-size: 0.875rem !important;
            border-radius: 0.375rem;
            width: 100% !important;
            padding: 0.1rem 0.1rem;
            opacity: 1 !important;
            cursor: pointer;
            color: #2463eb !important;
            &:hover {
                background: #2463eb !important;
                color: white !important;
            }
        }
    }
`;

export const StyledNavbar: FC<StyledNavbarProps> = ({
    theme,
    children,
    isEditable,
    contentBlockId,
    homeLinkText,
    onClick,
    onHover,
    homeRoute,
    isSidebarOpen,
    title
}) => {
    const element = document.querySelector(".navbar") as HTMLElement;
    if (element) dimensions = element.getBoundingClientRect();

    const navBarTheme = { ...theme, ...{ backgroundImage: null } };

    const sideBarNavWrappingElement = document.querySelector(
        "#participant-sim-nav-links"
    );

    return (
        <>
            {sideBarNavWrappingElement &&
                createPortal(
                    <>
                        <div
                            className="space-y-1 py-6 navbar navigation border-t"
                            id="side-navigation"
                        >
                            <h3
                                className="px-2 text-sm font-medium text-gray-500"
                                id="simulation-navigation-headline"
                            >
                                Simulation
                            </h3>
                            <StyledSideNavWrapper
                                className="space-y-1"
                                role="group"
                                aria-labelledby="simulation-navigation-headline"
                            >
                                {children}
                            </StyledSideNavWrapper>
                        </div>
                    </>,
                    sideBarNavWrappingElement
                )}
            <StyledComponentNavbar
                // ref={ref}
                //hardcode classname for animation and padding purposes in other components
                className="navbar navigation @4xl:block hidden"
                position={isEditable ? "relative" : "fixed"}
                //position={"relative"}
                top={0}
                right={0}
                left={0}
                zIndex={90}
                mb={isEditable ? `-${theme.minHeight}` : "10rem"}
                //mb={`-${theme.minHeight}`}
                color={theme.color}
                backgroundColor={theme.backgroundColor}
                data-content-block-id={contentBlockId}
                data-content-block-type={title || "Navbar"}
                onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                onMouseMove={
                    "function" === typeof onHover
                        ? (event: React.MouseEvent) => {
                              onHover(event);
                          }
                        : (evt) => {}
                }
                {...navBarTheme}
            >
                <StyledUniversalDiv
                    display="flex"
                    minHeight={theme.minHeight}
                    ml={32}
                >
                    <StyledUniversalDiv
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        py={theme.py}
                        px={theme.px}
                    >
                        <Link href={homeRoute}>
                            {dimensions && theme?.backgroundImage ? (
                                <StyledUniversalDiv
                                    as={"img"}
                                    src={theme.backgroundImage as string}
                                    backgroundImage=""
                                    alignSelf="stretch"
                                    maxWidth={["100%", 100, 150]}
                                    width="200px"
                                />
                            ) : (
                                homeLinkText
                            )}
                        </Link>
                        {/* TODO: see if styled-system will ever support flex gap */}
                        <StyledUniversalDiv
                            justifyContent="flex-start"
                            gridGap={[5]}
                            display="flex"
                        >
                            {children}
                        </StyledUniversalDiv>
                    </StyledUniversalDiv>
                </StyledUniversalDiv>
            </StyledComponentNavbar>
        </>
    );
};

export const defaultNavbarTheme: NavbarTheme = {
    color: "#f4f4f4",
    backgroundColor: "#212121",
    pt: 0,
    pb: 0,
    px: 5,
    minHeight: "3rem", // no form element exists yet
    bottomBorder: "none",
};
