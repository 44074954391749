import React from "react";
import { ContentBlockShape } from "@/models";
import { useSaveModal } from "./useSaveModal";

type Props = { contentBlock: ContentBlockShape ; depth: number};

export default function AddModalWithSiblingBlockButton({
    contentBlock,
    depth
}: Props) {
    const mutation = useSaveModal();

    return (
        <button
            className="add-modal-button add-content-block-button absolute w-full h-full inset-0 bg-transparent flex items-center justify-center text-white text-2xl"
            aria-label={`Add Modal Content Block to ${contentBlock.id}`}
            onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                mutation.mutateAsync(contentBlock);
            }}
            style={{ zIndex: depth * 10 }}
        ></button>
    );
}
