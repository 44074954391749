import { ContentBlockType } from "../../models";
import { defaultGridComponentProps, defaultGridTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const GridConfig: ContentBlockConfig = {
    typeKey: "Grid",
    type: ContentBlockType.Grid,
    addableChildBlocks: [ContentBlockType.Tile, ContentBlockType.Card],
    defaultTheme: defaultGridTheme,
    isCollectionType: true,
    needsDataToCreate: true,
    defaultCreationData: defaultGridComponentProps
};
