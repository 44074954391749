export interface IBaseModel {
    setUp?: (data) => any;
    id?: string | null;
    created_at?: string;
    updated_at?: string;
    copied_from_id?: string | null;
    created_by_id?: string;
    deleted_at?: string | null;

}

class BaseModel {
    constructor(data?: any) {}

    public static END_POINT: string = "";
    public static CLASSNAME: string = "";
    public busy?: boolean = false;

    id?: string | null = null;
    copied_from_id?: string | null = null;
    created_by_id?: string = "";
    errorState?: string = "";
    title?: string = "";
    label?: string = "";
    weight?: number = 0;
    created_at?: string = "";
    updated_at?: string = "";
    isDirty?: boolean = false;
    message?: string = "";
}

// abstract class BaseModelWithTable extends BaseModel {
//     abstract tableName: string;
// }

export type BaseModelType = {
    id?: string | null;
    copied_from_id?: string | null;
    created_by_id?: string;
    errorState?: string;
    title?: string;
    label?: string;
    weight?: number;
    created_at?: string;
    updated_at?: string;
    isDirty?: boolean;
    message?: string;
    busy?: boolean;
    deleted_at?: string | null;
};

export { BaseModel };
