import { ContentBlockType } from "../models";
import { ContentBlockConfigs as configs } from "./content-block-configs";
import { ContentBlockConfig } from "./ContentBlockConfigInterface";

export const ContentBlockConfigObject: {
    [index in ContentBlockType]: Partial<ContentBlockConfig>;
} = {
    [ContentBlockType.Text]: configs.TextConfig,
    [ContentBlockType["Rich Text"]]: configs.TextConfig,
    [ContentBlockType.Typography]: configs.TypographyConfig,
    [ContentBlockType.Table]: configs.TableConfig,
    [ContentBlockType.Chart]: configs.ChartConfig,
    [ContentBlockType.Icon]: configs.IconConfig,
    [ContentBlockType.Image]: configs.ImageConfig,
    [ContentBlockType.Video]: configs.VideoConfig,
    [ContentBlockType.Question]: configs.QuestionConfig,
    [ContentBlockType.Button]: configs.ButtonConfig,
    [ContentBlockType.Link]: configs.LinkConfig,
    [ContentBlockType.Hero]: configs.HeroConfig,
    [ContentBlockType.Hero2]: configs.Hero2Config,
    [ContentBlockType.Hero3]: configs.Hero3Config,
    [ContentBlockType.Grid]: configs.GridConfig,
    [ContentBlockType.Tile]: configs.TileConfig,
    [ContentBlockType.Tabs]: configs.TabsConfig,
    [ContentBlockType.Accordion]: configs.AccordionsConfig,
    [ContentBlockType.AccordionItem]: configs.AccordionItemConfig,
    [ContentBlockType.AccordionHeader]: configs.AccordionHeaderConfig,
    [ContentBlockType.AccordionContent]: configs.AccordionContentConfig,
    [ContentBlockType.Carousel]: configs.CarouselConfig,
    [ContentBlockType.Box]: configs.BoxConfig,
    [ContentBlockType.Section]: configs.SectionConfig,
    [ContentBlockType.Page]: configs.PageConfig,
    [ContentBlockType.Navbar]: configs.NavbarConfig,
    [ContentBlockType.Card]: configs.CardConfig,
    [ContentBlockType["Card Deck"]]: configs.CardDeckConfig,
    [ContentBlockType.Form]: configs.FormConfig,

    //chart configs
    [ContentBlockType["Chart Container"]]: configs.ChartConfig,
    [ContentBlockType["Chart Axis"]]: configs.ChartConfig,
    [ContentBlockType["Line Series"]]: configs.LineChartConfig,
    [ContentBlockType["Area Series"]]: configs.AreaChartConfgi,

    //table configs
    [ContentBlockType["Table Head"]]: configs.TableConfig,
    [ContentBlockType["Table Body"]]: configs.TableBodyConfig,
    [ContentBlockType["Table Row"]]: configs.TableConfig,
    [ContentBlockType["Table Header Cell"]]: configs.TableConfig,
    [ContentBlockType["Table Data Cell"]]: configs.TableConfig,
    [ContentBlockType.Leaderboard]: configs.LeaderboardConfig,
    [ContentBlockType["Model Value Display"]]: configs.ModelDataDisplayConfig,

    [ContentBlockType["Bottom Drawer"]]: configs.BottomDrawerConfig,
    [ContentBlockType["Top Drawer"]]: configs.TopDrawerConfig,
    [ContentBlockType.Modal]: configs.ModalConfig,
    [ContentBlockType["Reflection journal"]]: configs.ReflectionJournalConfig,
    [ContentBlockType["Decision log"]]: configs.DecisionLogConfig,
    [ContentBlockType["Submission Count"]]: configs.SubmissionCountConfig,
    [ContentBlockType.Inbox]: configs.InboxConfig,
    [ContentBlockType["Inbox Message"]]: configs.InboxMessageConfig,
    [ContentBlockType["Inbox Notification"]]: configs.InboxMessageConfig,

    [ContentBlockType.option]: configs.BoxConfig,
};
