import { defaultFormTheme } from "@/styles";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const FormConfig: ContentBlockConfig = {
    typeKey: "Form",
    type: ContentBlockType.Form,
    addableChildBlocks: [ContentBlockType.Question],
    requiredChildBlocks: [ContentBlockType.Button],
    addableLayouts: [],
    defaultTheme: defaultFormTheme,
};
