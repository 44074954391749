import React, { useMemo, useState } from "react";
import { DndContext, DragOverlay, pointerWithin } from "@dnd-kit/core";

import { OptionContainer, SelectionContainer, DragAndDropItem } from "./types";
import { DroppableContainer } from "./DroppableContainer";
import { DraggableItem } from "./DraggableItem";
import { useDragAndDropBoard, useTimelineBoard } from "./useDragAndDropBoard";
import { TimelineContainer } from "./TimelineContainer";

type DragAndDropComponentProps = {
    optionContainers: OptionContainer[];
    selectionContainer: SelectionContainer;
    onDragEnd: (dragIdsGroupedByDropId: { [index: string]: string[] }) => void;
    dragItems: DragAndDropItem[];
    orientation?: "rows" | "columns";
    isInDesignContext: boolean;
};

type TimelineComponentProps = {
    optionContainers: OptionContainer[];
    dragItems: DragAndDropItem[];
    orientation?: "rows" | "columns";
    isInDesignContext: boolean;
    selectionContainers: SelectionContainer[];
    onDragEnd: (dragAndDropItems: DragAndDropItem[]) => void;
};

export const DragAndDropContextWrapper: React.FC<DragAndDropComponentProps> = ({
    optionContainers,
    selectionContainer,
    onDragEnd,
    dragItems,
    orientation = "columns",
    isInDesignContext,
}) => {
    const {
        sensors,
        dragItemIdsGroupedByDropContainer,
        handleDragCancel,
        handleDragEnd,
        handleDragOver,
        handleDragStart,
        activeId,
    } = useDragAndDropBoard(
        optionContainers,
        [selectionContainer],
        dragItems,
        isInDesignContext,
        onDragEnd
    );

    const activeItem = useMemo(() => {
        return dragItems?.find((dragItem) => dragItem.option_id === activeId);
    }, [dragItems, activeId]);

    if (
        !dragItemIdsGroupedByDropContainer ||
        !selectionContainer ||
        !optionContainers?.length
    )
        return <></>;

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            collisionDetection={pointerWithin}
        >
            <div
                className="drag-and-drop-container"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: orientation === "rows" ? "column" : "row",
                    padding: "16px 0",
                    overflowX: "auto",
                    maxHeight: "100vh",
                }}
            >
                <div
                    className="option-container-wrapper gap-2 relative flex w-full"
                    style={{
                        flexDirection:
                            orientation === "rows" ? "column" : "row",
                        minWidth: "400px",
                        gridTemplateColumns:
                            orientation === "rows"
                                ? ""
                                : `repeat(${
                                      1 + (optionContainers?.length || 0)
                                  }, minmax(0, 1fr))`,
                    }}
                >
                    {optionContainers.map((optionContainer) => (
                        <DroppableContainer
                            id={optionContainer.id}
                            dragItemIds={
                                dragItemIdsGroupedByDropContainer[
                                    optionContainer.id
                                ]
                            }
                            activeId={activeId}
                            key={optionContainer.id}
                            isSelectionContainer={false}
                            name={optionContainer.label}
                            dragItems={dragItems}
                            orientation={orientation}
                            backgroundColor={
                                optionContainer?.theme?.backgroundColor || ""
                            }
                        />
                    ))}
                    <DroppableContainer
                        id={selectionContainer.id}
                        dragItemIds={
                            dragItemIdsGroupedByDropContainer[
                                selectionContainer.id
                            ]
                        }
                        activeId={activeId}
                        key={selectionContainer.id}
                        isSelectionContainer={true}
                        name={selectionContainer.label}
                        dragItems={dragItems}
                        orientation={orientation}
                        backgroundColor={
                            selectionContainer?.theme?.backgroundColor || ""
                        }
                    />
                </div>
                {/* TODO: switch on drop container type */}

                <DragOverlay
                    dropAnimation={{
                        duration: 500,
                        easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                    }}
                >
                    {!!activeItem && (
                        <DraggableItem
                            option_id={activeId}
                            color={activeItem?.color || ""}
                            child={activeItem.child}
                            selection_numerical_value={
                                activeItem?.selection_numerical_value
                            }
                            isBeingDragged={true}
                        />
                    )}
                </DragOverlay>
            </div>
        </DndContext>
    );
};

export const TimelineContextContainer: React.FC<TimelineComponentProps> = ({
    optionContainers,
    selectionContainers,
    onDragEnd,
    dragItems: initialDragItems,
    orientation = "columns",
    isInDesignContext,
}) => {
    const {
        sensors,
        handleDragCancel,
        handleTimelineDragEnd,
        handleDragOver,
        handleDragStart,
        activeId,
        dragItems,
        overId,
        overIndex,
        isAllowedToDrop,
        overDroppedId,
        overIndices,
    } = useTimelineBoard(
        optionContainers,
        selectionContainers,
        initialDragItems,
        isInDesignContext,
        onDragEnd
    );

    const [width, setWidth] = useState(0);
    const dividedWidth = useMemo(() => {
        if (!selectionContainers || !selectionContainers.length) return 100;
        return width / selectionContainers[0].column_count - 12;
    }, [width, selectionContainers]);

    const dragItemIdsGroupedByDropContainer = useMemo(() => {
        return [...selectionContainers, ...optionContainers].reduce(
            (map, dropContainer) => {
                return {
                    ...map,
                    [dropContainer.id]: dragItems
                        .filter((dragItem) => {
                            return (
                                dragItem.drag_and_drop_prompt_container_id ===
                                dropContainer.id
                            );
                        })
                        .map((dragItem) => dragItem.option_id),
                };
            },
            {}
        );
    }, [dragItems, selectionContainers, optionContainers]);

    if (
        !dragItemIdsGroupedByDropContainer ||
        !selectionContainers ||
        !selectionContainers.length ||
        !optionContainers?.length
    )
        return <></>;

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            onDragEnd={handleTimelineDragEnd}
        >
            <div className="drag-and-drop-container flex flex-col w-full gap-4">
                <div className="w-full option-container-wrapper relative">
                    {optionContainers.map((optionContainer) => (
                        <TimelineContainer
                            columns={selectionContainers[0].column_count}
                            id={optionContainer.id}
                            dragItemIds={
                                dragItemIdsGroupedByDropContainer[
                                    optionContainer.id
                                ]
                            }
                            activeId={activeId}
                            key={optionContainer.id}
                            isSelectionContainer={false}
                            name={optionContainer.label}
                            dragItems={dragItems}
                            orientation={orientation}
                            cssClasses={"rounded-md"}
                            overId={overId}
                            overIndex={overIndex}
                            isAllowedToDrop={isAllowedToDrop}
                            overDroppedId={overDroppedId}
                            overIndices={[]}
                            backgroundColor={
                                optionContainer?.theme?.backgroundColor || ""
                            }
                        />
                    ))}
                </div>
                <div className="w-full selection-container-wrapper relative">
                    <div
                        className="grid px-4"
                        style={{
                            gridTemplateColumns: `repeat(${
                                selectionContainers[0].column_count + 1
                            }, 1fr)`,
                            alignItems: "center",
                        }}
                        ref={(element) => {
                            if (element) {
                                setWidth(
                                    () => element.getBoundingClientRect().width
                                );
                            }
                        }}
                    >
                        {[
                            ...Array(selectionContainers[0].column_count + 1),
                        ].map((_, idx) => {
                            const container = selectionContainers[0];
                            return idx === 0 ? (
                                <div
                                    key={`${container.column_label}_${idx}`}
                                ></div>
                            ) : (
                                <div
                                    key={`${container.column_label}_${idx}`}
                                    className="h-6 text-xs px-4 flex justify-start items-center"
                                >
                                    <span>
                                        {container.column_label} {idx}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {selectionContainers.map((selectionContainer, idx) => (
                        <TimelineContainer
                            columns={selectionContainers[0].column_count}
                            id={selectionContainer.id}
                            dragItemIds={
                                dragItemIdsGroupedByDropContainer[
                                    selectionContainer.id
                                ]
                            }
                            activeId={activeId}
                            key={selectionContainer.id}
                            isSelectionContainer={true}
                            name={selectionContainer.label}
                            dragItems={dragItems}
                            orientation={orientation}
                            cssClasses={
                                idx === 0
                                    ? "rounded-t-md"
                                    : idx === selectionContainers.length - 1
                                    ? "rounded-b-md"
                                    : ""
                            }
                            overId={overId}
                            overIndex={overIndex}
                            isAllowedToDrop={isAllowedToDrop}
                            overDroppedId={overDroppedId}
                            overIndices={
                                overId === selectionContainer.id
                                    ? overIndices
                                    : []
                            }
                            backgroundColor=""
                        />
                    ))}
                </div>

                {/* TODO: switch on drop container type
                <DroppableContainer
                    id={selectionContainer.id}
                    dragItemIds={
                        dragItemIdsGroupedByDropContainer[selectionContainer.id]
                    }
                    activeId={activeId}
                    key={selectionContainer.id}
                    isSelectionContainer={true}
                    name={selectionContainer.label}
                    dragItems={dragItems}
                    orientation={orientation}
                />*/}

                <DragOverlay
                    dropAnimation={{
                        duration: 0,
                        easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                    }}
                    className="cursor-not-allowed"
                >
                    {!!activeId && (
                        <>
                            <div
                                id={activeId}
                                style={{
                                    width: `${
                                        dragItems?.find(
                                            (dragItem) =>
                                                dragItem.option_id === activeId
                                        )?.option_size *
                                        (dividedWidth + 1)
                                    }px`,
                                    border: `3px solid ${
                                        dragItems?.find(
                                            (dragItem) =>
                                                dragItem.option_id === activeId
                                        )?.color
                                    }`,
                                }}
                                className={`cursor-not-allowed rounded-md w-full text-xs flex items-center opacity-70 h-12 ${
                                    dragItems?.find(
                                        (dragItem) =>
                                            dragItem.option_id === activeId
                                    )?.color
                                }`}
                            >
                                {
                                    dragItems?.find(
                                        (dragItem) =>
                                            dragItem.option_id === activeId
                                    )?.child
                                }
                            </div>
                        </>
                    )}
                </DragOverlay>
            </div>
        </DndContext>
    );
};
