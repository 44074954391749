import styled from "styled-components";
import { PaletteType } from "../styles";

export const Button = styled.button<{ processing?: boolean; padding?: string }>`
    cursor: pointer;
    padding: ${({ padding }) => (!!padding ? padding : "0.5rem 1rem")};
    color: ${({ theme }) => theme.palettes.grayscale.buttonColor};
    background-color: ${({ theme }) =>
        theme.palettes.grayscale.buttonBackgroundColor};
    border: 0px;
    :hover {
        color: ${({ theme }) => theme.palettes.grayscale.buttonHoverColor};
        background-color: ${({ theme }) =>
            theme.palettes.grayscale.buttonHoverBackgroundColor};
    }
    :disabled {
        color: ${({ theme }) => theme.palettes.grayscale.buttonDisabledColor};
        background-color: ${({ theme }) =>
            theme.palettes.grayscale.buttonDisabledBackgroundColor};
        cursor: default;
    }
`;
Button.displayName = "Button";

export const ButtonCircle = styled.button<{ palette: PaletteType }>`
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    border: 0px;
    color: ${({ theme, palette }) => theme.palettes[palette].buttonColor};
    background-color: ${({ theme, palette }) =>
        theme.palettes[palette].buttonBackgroundColor};
    > i {
        color: ${({ theme, palette }) => theme.palettes[palette].buttonColor};
    }
    :hover {
        color: ${({ theme, palette }) =>
            theme.palettes[palette].buttonHoverColor};
        background-color: ${({ theme, palette }) =>
            theme.palettes[palette].buttonHoverBackgroundColor};
    }
    :disabled {
        color: ${({ theme, palette }) =>
            theme.palettes[palette].buttonDisabledColor};
        background-color: ${({ theme, palette }) =>
            theme.palettes[palette].buttonDisabledBackgroundColor};
        cursor: default;
        :hover {
            color: ${({ theme, palette }) =>
                theme.palettes[palette].buttonDisabledColor};
            background-color: ${({ theme, palette }) =>
                theme.palettes[palette].buttonDisabledBackgroundColor};
            cursor: default;
        }
    }
    :focus {
        outline: none;
        box-shadow: 0 0 2px 0
            ${({ theme, palette }) => theme.palettes[palette].buttonColor};
    }
`;
ButtonCircle.displayName = "ButtonCircle";
