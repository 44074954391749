export enum ContentBlockType {
    // typography
    "Text" = "TEXT",
    "Typography" = "TYPOGRAPHY",
    // data
    "Leaderboard" = "LEADERBOARD",
    "Table" = "TABLE",
    "Table Head" = "TABLE_HEAD",
    "Table Body" = "TABLE_BODY",
    "Table Row" = "TABLE_ROW",
    "Table Header Cell" = "TABLE_HEADER_CELL",
    "Table Data Cell" = "TABLE_DATA_CELL",
    "Chart" = "CHART",
    "Model Value Display" = "MODEL_VALUE_DISPLAY",
    // media
    "Icon" = "ICON",
    "Image" = "IMAGE",
    "Video" = "VIDEO",
    // action
    "Question" = "QUESTION",
    "Button" = "BUTTON",
    // navigation
    "Link" = "LINK",
    // composite
    "Hero" = "HERO",
    "Hero2" = "HERO2",
    "Hero3" = "HERO3",
    "Card" = "CARD",
    "Card Deck" = "CARD_DECK",
    // layout
    "Grid" = "GRID",
    "Tile" = "TILE",
    // collection
    "Tabs" = "TABS",
    "Tabs Content" = "TABS_CONTENT",
    "Accordion" = "ACCORDION",
    "AccordionItem" = "ACCORDIONS_ITEM",
    "AccordionHeader" = "ACCORDIONS_HEADER",
    "AccordionContent" = "ACCORDIONS_CONTENT",
    "Carousel" = "CAROUSEL",
    // container
    "Box" = "BOX",
    "Section" = "SECTION",
    // frame
    "Page" = "PAGE",
    "Navbar" = "NAVBAR",
    "Rich Text" = "RICH_TEXT",
    "Form" = "FORM",
    //Charts
    "Chart Container" = "CHART_CONTAINER", //Should render VictoryChart component
    "Chart Axis" = "CHART_AXIS", //Should render VictoryAxis component
    "Area Series" = "AREA_SERIES", //Should render VictoryArea
    "Line Series" = "LINE_SERIES", //Should render VictoryLine
    "PIE_SERIES" = "PIE_SERIES", //Should render VivtoryPie
    "DOUGHNUT_SERIES" = "DOUGHNUT_SERIES", //Should render VictoryDonut
    "Bottom Drawer" = "BOTTOM_DRAWER",
    "Top Drawer" = "TOP_DRAWER",
    "Modal" = "MODAL",
    "Reflection journal" = "REFLECTION_JOURNAL",
    "Decision log" = "DECISION_LOG",
    "Submission Count" = "SUBMISSION_COUNT",

    //Inbox components
    "Inbox" = "INBOX",
    "Inbox Message" = "INBOX_MESSAGE",
    "Inbox Notification" = "INBOX_NOTIFICATION",

    //generally hidden. Placeholders for content blocks that belong to options
    "option" = "OPTION",

    //eLearning
    "Flip Card" = "FLIP_CARD",
    "Flip Card Side" = "FLIP_CARD_SIDE",
    "Divider" = "DIVIDER",

}

export function getContentBlockTypeKey(contentBlockType: ContentBlockType) {
    return Object.keys(ContentBlockType).find(
        (key) => ContentBlockType[key] === contentBlockType
    );
}
