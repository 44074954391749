import styled from "styled-components";

export const TopNav = styled.nav`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndices.topNavZ};
    color: ${({ theme }) => theme.colors.greyscaleLight};
    background-color: ${({ theme }) => theme.colors.greyscaleDark};
`;
TopNav.displayName = "TopNav";

export const TopNavBase = styled.div`
    display: inline-flex;
    width: 100%;
    min-height: ${({ theme }) => theme.layout.topNavHeight};
    /* box-shadow: 0 8px 6px -6px #999; */
    justify-content: space-between;
    align-items: center;
`;
TopNavBase.displayName = "TopNavBase";

// export const TopNavHeader = styled.h4`
//     margin: 0;
//     font-weight: bold;
// `;
// TopNavHeader.displayName = "TopNavHeader";

export const TopNavBody = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
`;
TopNavBody.displayName = "TopNavBody";

// export const TopNavImg = styled.img`
//   height: 25px;
//   width: 25px;
//   margin: 0 0.5rem 0 0;
// `;

export const TopNavLinkDiv = styled.div`
    padding: 0 0.75rem;
    display: inline-flex;
    align-items: center;
    > a,
    span {
        color: ${({ theme }) => theme.colors.greyscaleLight};
        cursor: pointer;
        opacity: 0.75;
        transition: 0.3s;
        text-decoration: none;
        &:hover,
        &.active {
            opacity: 1;
            text-decoration: none;
        }
        display: inline-flex;
        align-items: center;
    }
`;
TopNavLinkDiv.displayName = "TopNavLinkDiv";

// export const TopNavDivML = styled.div`
//     margin: 0 0 0 1rem;
// `;
// TopNavDivML.displayName = "TopNavDivML";

export const TopNavDivMR = styled.div`
    margin: 0 0.5rem 0 0;
`;
TopNavDivMR.displayName = "TopNavDivMR";
