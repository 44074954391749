import { ContentBlockShape } from "@/models";
import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";

export async function getActiveContentBlock(id: string) {
    if (!id) return Promise.resolve({} as ContentBlockShape);

    const route = sapienRoute("creator.design.active-content-block.show", {
        contentBlock: id,
    });

    const { data } = await axios.get<{ activeContentBlock: ContentBlockShape }>(
        route
    );

    return data?.activeContentBlock;
}
