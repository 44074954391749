import React, { useState, useMemo, useCallback } from "react";
import parseColor from "parse-css-color";
import {
    EditorFormGroup,
    EditorFormInputRange,
    EditorFormLabel,
} from "./StyledEditorComponents";
// import { ColorResult } from "react-color";
import { ThemeObject } from "../ThemeObjects";
import { useColorStore } from "../../hooks/store";
import { ColorMap } from "../../models";

function RGBAToHexA([r, g, b]: [r: number, g: number, b: number], a: number) {
    let red = r.toString(16);
    let green = g.toString(16);
    let blue = b.toString(16);
    let alpha = Math.round(a * 255).toString(16);

    if (red.length == 1) red = "0" + red;
    if (green.length == 1) green = "0" + green;
    if (blue.length == 1) blue = "0" + blue;
    if (alpha.length == 1) alpha = "0" + alpha;

    return "#" + red + green + blue + alpha;
}

function create8DigitHexWithOpacity(
    colorString: string,
    opacity?: number,
): string | null {
    const parsed = parseColor(colorString);
    if (parsed) {
        const { alpha, values } = parsed;
        const [r, g, b] = values;
        const opacityValue = opacity || alpha;
        return RGBAToHexA([r, g, b], opacityValue);
    }

    return colorString;
}

type FormInputColorPickerProps = {
    value: ColorMap | string;
    label: string;
    themeObjectKey: keyof ThemeObject;
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    tooltipMode?: boolean;
    closeOnSelect?: boolean;
    hideOpacity?: boolean;
};

function areColorsEqual(color1: string, color2: string): boolean {
    const parsedColor1 = parseColor(color1);
    const parsedColor2 = parseColor(color2);

    return parsedColor1?.values.join() === parsedColor2?.values.join();

    return false; // Return false for unsupported or invalid formats
}

export const FormInputColorPicker = ({
    value,
    label,
    themeObjectKey,
    handleChange,
    tooltipMode,
    closeOnSelect,
    hideOpacity,
}: FormInputColorPickerProps) => {
    const { colorGroups } = useColorStore();
    const [customColor, setCustomColor] = useState(value as string);
    const [selectedColor, setSelectedColor] = useState(value as string);
    const [opacity, setOpacity] = useState(
        parseColor(value as string)?.alpha || 1,
    );
    const uniqueColors = useMemo(() => {
        if (colorGroups) {
            let uniqueColors = [];
            colorGroups.forEach((colorGroup) => {
                colorGroup.colors.forEach((color) => {
                    if (
                        !uniqueColors.find(
                            (uniqueColor) =>
                                uniqueColor.css_color == color.css_color,
                        )
                    ) {
                        uniqueColors.push(color);
                    }
                });
            });
            return uniqueColors;
        }
        return [];
    }, [colorGroups]);

    const selectColor = useCallback(
        (color: string) => {
            setSelectedColor(color);
            const fullHexColor = create8DigitHexWithOpacity(color, opacity);
            setCustomColor(fullHexColor);
            handleChange(themeObjectKey, fullHexColor);
        },
        [opacity, uniqueColors],
    );

    const inputColor = useCallback(
        (color: string) => {
            setCustomColor(color);
            const parsedColor = create8DigitHexWithOpacity(color);
            if (parsedColor) {
                const colorToSelect = uniqueColors.find((uniqueColor) =>
                    areColorsEqual(uniqueColor.css_color, color),
                )?.css_color;
                if (colorToSelect) {
                    setSelectedColor(colorToSelect);
                }
                setOpacity(parseColor(color)?.alpha || 1);
                handleChange(themeObjectKey, parsedColor);
            } else {
                console.log("invalid color");
            }
        },
        [uniqueColors],
    );

    const setOpacityValue = useCallback(
        (opacity: number) => {
            setOpacity(opacity);
            const fullHexColor = create8DigitHexWithOpacity(
                customColor,
                opacity,
            );
            setCustomColor(fullHexColor);
            handleChange(themeObjectKey, fullHexColor);
        },
        [customColor, uniqueColors],
    );

    return (
        <EditorFormGroup style={{ margin: 0 }} className="py-4">
            <div className="flex w-full flex-col justify-center">
                {!tooltipMode && <EditorFormLabel>{label}</EditorFormLabel>}

                <div
                    className="z-10 m-1 rounded p-1"
                    style={{
                        position: tooltipMode ? "absolute" : "static",
                        marginTop: tooltipMode ? "280px" : "",
                        maxWidth: tooltipMode ? "550px" : "",
                    }}
                >
                    <div className="flex flex-col justify-center">
                        <div className="relative flex items-center py-2">
                            <svg
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                width={64}
                                height={42}
                                viewBox="0 0 64 42"
                                fill="none"
                            >
                                <rect
                                    x={4}
                                    y={4}
                                    width={56}
                                    height={34}
                                    rx={17}
                                    fill="#2A3040"
                                />
                                <path
                                    d="M21 15.75V26.25"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M15.75 21H26.25"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <g clipPath="url(#clip0_135_1895)">
                                    <path
                                        d="M43 27.75C42.1136 27.75 41.2358 27.5754 40.4169 27.2362C39.5979 26.897 38.8538 26.3998 38.227 25.773C37.6002 25.1462 37.103 24.4021 36.7638 23.5831C36.4246 22.7642 36.25 21.8864 36.25 21C36.25 20.1136 36.4246 19.2358 36.7638 18.4169C37.103 17.5979 37.6002 16.8538 38.227 16.227C38.8538 15.6002 39.5979 15.103 40.4169 14.7638C41.2358 14.4246 42.1136 14.25 43 14.25C44.7902 14.25 46.5071 14.8821 47.773 16.0074C49.0388 17.1326 49.75 18.6587 49.75 20.25C49.75 21.0456 49.3944 21.8087 48.7615 22.3713C48.1285 22.9339 47.2701 23.25 46.375 23.25H44.5C44.1654 23.2446 43.8386 23.3513 43.5716 23.553C43.3046 23.7547 43.1127 24.04 43.0265 24.3633C42.9403 24.6867 42.9646 25.0296 43.0957 25.3375C43.2268 25.6454 43.4571 25.9006 43.75 26.0625C43.8997 26.2007 44.0024 26.3823 44.0437 26.5818C44.0849 26.7814 44.0625 26.9888 43.9798 27.175C43.897 27.3612 43.758 27.5168 43.5823 27.62C43.4065 27.7231 43.2029 27.7686 43 27.75Z"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M39.625 20.25C39.8321 20.25 40 20.0821 40 19.875C40 19.6679 39.8321 19.5 39.625 19.5C39.4179 19.5 39.25 19.6679 39.25 19.875C39.25 20.0821 39.4179 20.25 39.625 20.25Z"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M43 18C43.2071 18 43.375 17.8321 43.375 17.625C43.375 17.4179 43.2071 17.25 43 17.25C42.7929 17.25 42.625 17.4179 42.625 17.625C42.625 17.8321 42.7929 18 43 18Z"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M46.375 20.25C46.5821 20.25 46.75 20.0821 46.75 19.875C46.75 19.6679 46.5821 19.5 46.375 19.5C46.1679 19.5 46 19.6679 46 19.875C46 20.0821 46.1679 20.25 46.375 20.25Z"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_135_1895">
                                        <rect
                                            width={18}
                                            height={18}
                                            fill="white"
                                            transform="translate(34 12)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>

                            <div>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <div
                                            className={`h-5 w-5 rounded-full border border-[#37415166]`}
                                            style={{
                                                backgroundColor: customColor,
                                            }}
                                        ></div>
                                    </div>
                                    <input
                                        type="text"
                                        data-testid={`${themeObjectKey}-custom-color`}
                                        name={`${themeObjectKey}-custom-color`}
                                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Enter a color"
                                        value={customColor}
                                        onChange={(e) => {
                                            inputColor(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="absolute rounded-md p-4 h-48 w-48 bg-white shadow">
                                <div className="w-full h-24"></div>
                                <div
                                    className="w-full h-12"
                                    style={{
                                        background: `linear-gradient(to right, #f00 0%, #ff0 17%, #0f0
                33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%)`,
                                    }}
                                ></div>
                                <div className="w-full h-12"></div>
                            </div> */}
                        </div>
                        <div className="flex flex-row flex-wrap items-center justify-between">
                            {uniqueColors.map((colorOption) => (
                                <div
                                    className={`h-8 w-8 rounded-full p-1 ${
                                        selectedColor === colorOption.css_color
                                            ? "border-2 border-white"
                                            : "none"
                                    }`}
                                    key={colorOption.id}
                                >
                                    <div
                                        data-color={colorOption.css_color}
                                        className={`flex h-full w-full items-center justify-center rounded-full`}
                                        role="button"
                                        onClick={() => {
                                            selectColor(colorOption.css_color);
                                        }}
                                        style={{
                                            backgroundColor:
                                                selectedColor ===
                                                colorOption.css_color
                                                    ? customColor
                                                    : colorOption.css_color,
                                            cursor: "pointer",
                                        }}
                                    >
                                        {selectedColor ===
                                            colorOption.css_color && (
                                            <div className="h-1 w-1 rounded-full bg-white/50"></div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {!hideOpacity && (
                            <div className="mt-px">
                                <div className="flex flex-row items-center justify-between">
                                    <EditorFormLabel
                                        style={{
                                            margin: "0 0.25rem",
                                            width: "70px",
                                            fontSize: "12px",
                                            minWidth: "65px",
                                            color: "white",
                                        }}
                                    >
                                        {`Opacity`}
                                    </EditorFormLabel>
                                    <EditorFormInputRange
                                        type="range"
                                        step={0.01}
                                        min={0}
                                        max={1}
                                        value={opacity}
                                        onChange={(e) => {
                                            setOpacityValue(
                                                Number(e.target.value),
                                            );
                                        }}
                                        style={{
                                            minWidth: "100px",
                                            maxWidth: "250px",
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </EditorFormGroup>
    );
};
