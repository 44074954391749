import React, { FC, ReactNode } from "react";
import {
    ThemeObject,
    Display,
    // AlignItems,
    // AlignContent,
    // JustifyItems,
    // JustifyContent,
    FlexWrap,
    // FlexDirection
} from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import styled from "styled-components";
export interface GridComponentProps {
    columnCount: number;
    childCount: number;
    consistentChildProps?: boolean;
}
import { ContentBlockEvents } from "./ContentBlockEvents";

// row count and column count
// number of children: rows * columns
// child widths at four breakpoints, e.g., [1, 1 / 2, 1 / 3, 1 / 4]
// (last being 1 / column count)

type GridTheme = Partial<ThemeObject> & GridComponentProps;

type StyledGridProps = ContentBlockEvents & {
    theme: GridTheme;
    children?: ReactNode[];
    contentBlockId: string;
};

// function gridTemplateColumns(cols: number) {}

function getColumns(theme: GridTheme) {
    if (theme.gridTemplateColumns) return theme.gridTemplateColumns;
    if (theme.columnCount)
        return [
            `repeat(${Math.round(
                theme.columnCount * theme.widthBreakpoint1
            )}, 1fr)`,
            `repeat(${Math.round(
                theme.columnCount * theme.widthBreakpoint2
            )}, 1fr)`,
            `repeat(${Math.round(
                theme.columnCount * theme.widthBreakpoint3
            )}, 1fr)`,
            `repeat(${Math.round(
                theme.columnCount * theme.widthBreakpoint4
            )}, 1fr)`,
        ];

    return ["repeat(auto-fit, minmax(300px, 1fr))"];
}

const GridChild = styled(StyledUniversalDiv)`
    > * {
        width: 100% !important;
        /* min-width: 100% !important; */
        max-width: 100% !important;
    }
`;

export const StyledGrid: FC<StyledGridProps> = ({
    theme,
    children,
    contentBlockId,
    onHover,
    onClick,
    title
}) => {
    return (
        <StyledUniversalDiv
            display={theme.display}
            flexWrap={theme.flexWrap}
            gridTemplateColumns={getColumns(theme)}
            gridGap={theme.gridGap}
            py={theme.py}
            px={theme.px}
            //I think this is an acceptable constraint. Grids should fill their available space
            width={"100%"}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Grid"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            {children !== undefined &&
                Array.isArray(children) &&
                children.map((child, i) => (
                    <GridChild
                        key={i}
                        display={"flex"}
                        width={
                            theme.display === "grid"
                                ? "100%"
                                : [
                                      theme.widthBreakpoint1,
                                      theme.widthBreakpoint2,
                                      theme.widthBreakpoint3,
                                      theme.widthBreakpoint4,
                                  ]
                        }
                    >
                        {child}
                    </GridChild>
                ))}
        </StyledUniversalDiv>
    );
};

export const defaultGridComponentProps: GridComponentProps = {
    columnCount: 3,
    childCount: 6,
};

export const defaultGridTheme: GridTheme = {
    ...defaultGridComponentProps,
    consistentChildProps: true,
    widthBreakpoint1: "100%",
    widthBreakpoint2: 0.5,
    widthBreakpoint3: 0.33,
    widthBreakpoint4: 0.33,
    py: 0,
    px: 0,
    gridGap: 1,
    // color: "#000000",
    // backgroundColor: "#ffffff",
    // borderColor: "#000000",
    // borderWidth: 0,
    // borderRadius: 0,
    // borderStyle: "solid",
    // py: 2,
    // px: 2,
    // my: 2,
    // mx: 2,
    // width: ["auto", "auto", "auto", "auto"],
    display: Display.flex,
    // flexDirection: FlexDirection.row,
    flexWrap: FlexWrap.wrap,
    // alignItems: AlignItems.normal,
    // alignContent: AlignContent.normal,
    // justifyItems: JustifyItems.normal,
    // justifyContent: JustifyContent.normal
};

