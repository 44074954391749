// import { store as content } from "./ContentBlockStore";
// import { store as modelBuilder } from "./ModelBuilderStore";
// import { store as questions } from "./QuestionStore";
// import { store as rounds } from "./RoundStore";
// import { store as sandBox } from "./SandboxStore";
// import { store as selections } from "./SelectionStore";
// import { store as teamModelData } from "./TeamModelDataStore";
// import { store as tenants } from "./TenantStore";
// import { store as timeHorizons } from "./TimeHorizonStore";
// import { store as users } from "./UserStore";
import * as fileUploads from "./FileUploadStore";

export const stores = {
    // content,
    // facilitation,
    // login,
    // modelBuilder,
    // questions,
    // rounds,
    // sandBox,
    // selections,
    // teamModelData,
    // tenants,
    // timeHorizons,
    // users,
    fileUploads,
};

// export type apiShape = typeof stores;
