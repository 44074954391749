import { ContentBlockShape, ContentBlockType } from "@/models";
import React from "react";
import SelectContentBlockButton from "./SelectContentBlockButton";
import AddBehaviorContentBlockButton from "./create-behavior-content-block/AddBehaviorContentBlockButton";
import AddModalToExistingBlockButton from "./add-modal-button/AddModalToExistingBlockButton";
import AddModalWithSiblingBlockButton from "./add-modal-button/AddModalWithSiblingBlockButton";
import CreateContentBlockButton from "./create-content-block-button/CreateContentBlockButton";
import { ContentBlockMetadata } from "@/model-configs";
import AddQuestionButton from "./add-question-button/AddQuestionButton";

type Props = {
    contentBlock: ContentBlockShape;
    children?: JSX.Element;
    addableContentBlockMetaData?: ContentBlockMetadata;
    isAddingModalToExistingContentBlock: boolean;
    newModalTriggerType:
        | ContentBlockType.Button
        | ContentBlockType.Link
        | undefined;
    isAddingBehaviorBlock: boolean;
    isAddingQuestion: boolean;
    depth: number;
    weight?: number;
};

export default function AddContentBlockButtonSlot({
    contentBlock,
    children,
    addableContentBlockMetaData,
    newModalTriggerType,
    isAddingModalToExistingContentBlock,
    isAddingBehaviorBlock,
    depth,
    weight
}: Props) {
    if (!addableContentBlockMetaData)
        return (
            <SelectContentBlockButton
                contentBlock={contentBlock}
                depth={depth}
            />
        );

    if (isAddingModalToExistingContentBlock)
        return (
            <AddModalToExistingBlockButton
                contentBlock={contentBlock}
                depth={depth}
            />
        );

    if (!!newModalTriggerType)
        return (
            <AddModalWithSiblingBlockButton
                contentBlock={contentBlock}
                depth={depth}
            />
        );

    if (addableContentBlockMetaData?.promptType)
        return (
            <AddQuestionButton
                contentBlock={contentBlock}
                promptType={addableContentBlockMetaData.promptType}
                depth={depth}
            />
        );

    if (isAddingBehaviorBlock)
        return (
            <AddBehaviorContentBlockButton
                contentBlock={contentBlock}
                depth={depth}
            />
        );

    return (
        <CreateContentBlockButton
            contentBlock={contentBlock}
            addableContentBlockMetaData={addableContentBlockMetaData}
            depth={depth}
            weight={weight}
        >
            {children}
        </CreateContentBlockButton>
    );
}
