import { combineReducers } from "@reduxjs/toolkit";
import { modelBlockStoreReducer as modelBlockStore } from "./modelBlockSlice";
import { modelVariableStoreReducer as modelVariableStore } from "./modelVariableSlice";
import { variableRelationshipStoreReducer as variableRelationshipStore } from "./variableRelationshipSlice";

export const modelBuilderReducers = combineReducers({
    modelBlockStore,
    modelVariableStore,
    variableRelationshipStore,
});
