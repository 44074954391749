import { ColorScheme } from "@/models";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import {
    background,
    backgroundPosition,
    flexbox,
    justifyContent,
    ResponsiveValue,
    space,
} from "styled-system";
import { ThemeObject } from "../../ThemeObjects";
import { ContentBlockEvents } from "../ContentBlockEvents";

type Hero3Theme = Partial<ThemeObject> & {
    imagePlacement: "left" | "right";
    textPlacement: "left" | "right" | "center";
};

type StyledHeroImageProps = ContentBlockEvents & {
    theme: Hero3Theme;
    children?: ReactNode;
    imageUrl: string;
    palette: ColorScheme;
    contentBlockId: string;
};

export const defaultHero3Theme: Partial<ThemeObject> = {
    backgroundSize: "cover",
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 42.39%, #000000 100%),
          url(https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/hero_image_video_chat.7ca726d11ef9f3c2e1bc.jpg)`,
    // backgroundPosition: heroTheme.imagePlacement,
};

function handleWrapBackgroundImage(imagePath: string | null) {
    if (imagePath) {
        imagePath =
            imagePath.indexOf("url(") === 0 ||
            imagePath.indexOf("gradient") !== -1
                ? imagePath
                : `url(${imagePath})`;

        return imagePath;
    }
    return "";
}

const Hero3StyledComponent = styled.div<
    {
        hero3Theme: Hero3Theme;
    } & {
        padding: ResponsiveValue<any>;
        justifyContent: ResponsiveValue<any>;
        backgroundPosition: ResponsiveValue<any>;
    }
>`
    ${space}
    ${background}
    ${flexbox}
    ${justifyContent}
    ${backgroundPosition}
    align-items: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: ${({ hero3Theme }) => hero3Theme.imagePlacement};
    background-image: ${({ hero3Theme }) =>
        handleWrapBackgroundImage(hero3Theme.backgroundImage as string)};
    * {
        max-width: 1312px;
    }
`;
// justify-content${({hero3Theme}) => hero3Theme.justifyContent}

export const StyledHero3: FC<StyledHeroImageProps> = ({
    theme,
    children,
    imageUrl,
    palette,
    contentBlockId,
    onClick,
    onHover,
    title,
}) => {
    return (
        <Hero3StyledComponent
            hero3Theme={theme}
            justifyContent={theme.justifyContent}
            padding={theme.padding}
            //should this be a variant?
            backgroundPosition={["center", "center"]}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Hero"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
        >
            {children}
        </Hero3StyledComponent>
    );
};
