export enum LayoutType {
    "Feature" = "FEATURE",
    "Card Deck" = "CARD_DECK",
    "Card" = "CARD",
    "Hero" = "HERO",
    "Hero 2" = "HERO2",
    "Hero 3" = "HERO3",
    "Accordion" = "ACCORDION",
    "Table" = "TABLE",
    "Bottom Drawer" = "BOTTOM_DRAWER",
    "Modal" = "MODAL",
    "Data Display Card" = "DATA_DISPLAY_CARD",
    "Tabs" = "TABS",
    "Carousel" = "CAROUSEL",
}
