import { ContentBlockType } from "../../models";
import { defaultPageTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { LayoutType } from "../LayoutType";

export const PageConfig: ContentBlockConfig = {
    typeKey: "Page",
    type: ContentBlockType.Page,
    addableChildBlocks: [
        ContentBlockType.Section,
        ContentBlockType.Text,
        ContentBlockType.Form,
        ContentBlockType.Image,
        ContentBlockType.Video,
        ContentBlockType.Tabs,
        ContentBlockType.Grid,
        ContentBlockType["Chart Container"],
        ContentBlockType["Reflection journal"],
        ContentBlockType["Decision log"],
        ContentBlockType.Inbox,
        ContentBlockType.Carousel
    ],
    addableLayouts: [
        LayoutType.Feature,
        LayoutType["Card Deck"],
        LayoutType.Hero,
        LayoutType["Hero 2"],
        LayoutType["Hero 3"],
        LayoutType.Accordion,
        LayoutType.Table,
        LayoutType.Modal,
    ],
    defaultTheme: defaultPageTheme,
};
