export function isColorDark(color: string) {
    // console.log("COLRO SI", color);
    const rgb = color
        .match(/\(([^)]+)\)/)[1]
        .split(",")
        .map((num) => parseFloat(num.trim()));

    // If the element has no background color, consider it "not dark"
    if (rgb.length < 3) {
        console.log("NO RGB LENGTH", color);
        return false;
    }
    // console.log("RGB IS", rgb);

    const [r, g, b] = rgb;

    const linearize = (color: number) => {
        const scaledColor = color / 255;
        return scaledColor <= 0.03928
            ? scaledColor / 12.92
            : Math.pow((scaledColor + 0.055) / 1.055, 2.4);
    };

    const L =
        0.2126 * linearize(r) + 0.7152 * linearize(g) + 0.0722 * linearize(b);

    // Using a threshold of 0.5 to determine if the background is dark.
    // This value can be adjusted based on your needs.
    // console.log("L IS", L, r, g, b);
    // console.log("r IS", r);
    // console.log("g IS", g);
    // console.log("b IS", b);
    return L < 0.5;
}

export function isBackgroundDark(el: Element): boolean {
    if (!el) return false;
    const backgroundColor = getComputedStyle(el).backgroundColor;
    if (!backgroundColor) return false;
    return isColorDark(backgroundColor);
}
