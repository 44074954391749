import { PromptType } from "../models";

const PromptTypeObject: { [index: string]: string } = {
    [PromptType["Multiple Choice"]]: "Multiple Choice",
    [PromptType["Multiple Select"]]: "Multiple Select",
    [PromptType["Numerical Input"]]: "Numerical Input",
    [PromptType["Numerical Slider"]]: "Numerical Slider",
    [PromptType["Rank Order"]]: "Rank Order",
    [PromptType["Toggle Switch"]]: "Toggle Switch",
    [PromptType["Long Text"]]: "Long Text",
    [PromptType["Short Text"]]: "Short Text",
    [PromptType["Dropdown List"]]: "Dropdown List",
    [PromptType["Drag and Drop"]]: "Drag and Drop",
    [PromptType["Timeline"]]: "Timeline",
};

export const displayPromptType = (promptType: PromptType): string => {
    return PromptTypeObject[promptType] || "";
};

// const ModelVariableTypeObject: { [index in ModelVariableType]: string } = {
//     [ModelVariableType.Independent]: "Independent",
//     [ModelVariableType["Selection Data"]]: "Selection Data",
//     [ModelVariableType.Iterative]: "Iterative",
//     [ModelVariableType.Aggregate]: "Aggregate",
//     [ModelVariableType.Algebraic]: "Algebraic",
//     [ModelVariableType.Conditional]: "Conditional",
//     [ModelVariableType.Logical]: "Logical",
//     [ModelVariableType.Comparison]: "Comparison",
// };

// export const displayModelVariableType = (
//     modelVariableType: ModelVariableType
// ): string => {
//     return ModelVariableTypeObject[modelVariableType] || "";
// };
