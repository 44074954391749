import { createSlice } from "@reduxjs/toolkit";

type SimulationThemeState = {
    simulationTheme: any;
};

const initialState: SimulationThemeState = {
    simulationTheme: {},
};

const simulationThemeSlice = createSlice({
    name: "SimulationThemeStore",
    initialState,
    reducers: {
        gotTheme(state, { payload }) {
            state.simulationTheme = payload;
        },
    },
});

export const simulationThemeStoreReducer = simulationThemeSlice.reducer;

// export type RootState = ReturnType<typeof simulationThemeStoreReducer>;

const {
    // content blocks
    gotTheme,
} = simulationThemeSlice.actions;

export { gotTheme };
