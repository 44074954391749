import { ContentBlockType } from "../../models";
import { defaultImageTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const ImageConfig: ContentBlockConfig = {
    typeKey: "Image",
    type: ContentBlockType.Image,
    defaultTheme: defaultImageTheme,
    availableBehaviors: [
        "ShowModalContentBlock"
    ],
    addableChildBlocks: [
        ContentBlockType.Modal
    ],
};
