import { ContentBlockShape, ContentBlockType } from "@/models";
import { defaultCarouselTheme, defaultImageTheme } from "@/styles";
const image_url: string =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png";
const defaultBlocks = [
    {
        content_block_type: ContentBlockType.Image,
        theme: defaultImageTheme,
        weight: 0,
        image_url,
    },
    {
        content_block_type: ContentBlockType.Image,
        theme: defaultImageTheme,
        weight: 1,
        image_url,
    },
];
export function buildCarousel(
    contentBlocks: ContentBlockShape[] = defaultBlocks
): ContentBlockShape {
    return {
        content_block_type: ContentBlockType.Carousel,
        theme: defaultCarouselTheme,
        contentBlocks,
    };
}

export const CarouselLayout = buildCarousel();
