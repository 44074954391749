import React, { FC, ReactNode, useEffect, useState, useCallback } from "react";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalSection, StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { cn } from "@/util";

type SectionTheme = ThemeObject & {
    alignment: "left" | "right" | "center";
    stacking: "row" | "column";
};

type StyledSectionProps = ContentBlockEvents & {
    theme: Partial<SectionTheme>;
    children?: ReactNode;
    contentBlockId: string;
};

function getJustifyContent({ alignment, stacking }: Partial<SectionTheme>) {
    if (!stacking || stacking === "column") return "center";
    return alignment;
}

function getAlignItems({ alignment, stacking }: Partial<SectionTheme>) {
    if (!stacking || stacking === "row" || alignment === "center")
        return "center";

    if (alignment === "left") return "flex-start";
    return "flex-end";
}

export const StyledSection: FC<StyledSectionProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    title,
}) => {
    const [numberOfRetriesRemaining, setNumberOfRetriesRemaining] = useState(5);
    const [src, setSrc] = useState(theme.backgroundImage);

    useEffect(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => theme.backgroundImage);
        } else {
            setSrc(
                () =>
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/fYVikfRxPBgvFoJjOLNqRIRjrHt4SOeEYLZHBQ7C.png"
            );
        }
    }, [numberOfRetriesRemaining]);

    useEffect(() => {
        setNumberOfRetriesRemaining(5);
        setSrc(() => theme?.backgroundImage);
    }, [theme?.backgroundImage]);

    const retry = useCallback(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => "");
            setNumberOfRetriesRemaining((previousNumber) => {
                return previousNumber - 1;
            });
        }
    }, [numberOfRetriesRemaining, theme?.backgroundImage]);

    const fixedTheme = { ...theme, backgroundImage: src };

    return (
        <StyledUniversalSection
            {...fixedTheme}
            as={"section"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            px={!theme.fullBleed && [1, 2, 3, 4, 5]}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Section"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            <StyledUniversalDiv
                maxWidth={
                    !theme.fullBleed
                        ? [
                              "48rem" /* 896px */,
                              "56rem" /* 896px */,
                              "64rem" /* 1024px */,
                              "72rem" /* 1152px */,
                              /* 1280px */
                          ]
                        : "unset"
                }
                width={"100%"}
                display={"flex"}
                alignItems={getAlignItems(theme)}
                justifyContent={getJustifyContent(theme)}
                flexWrap="wrap"
                flexDirection={theme?.stacking || "row"}
                pr={theme.pr}
                pl={theme.pl}
                data-alignment={theme.alignment}
                className={cn(theme.tailwindClasses)}
                // onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                // onMouseEnter={"function" === typeof onHover ? onHover : (evt) => {}}
            >
                {children}
            </StyledUniversalDiv>
            {!!theme.backgroundImage && (
                <img
                    className="h-0 w-0 p-0 m-0 opacity-0"
                    src={src as string}
                    onError={retry}
                />
            )}
        </StyledUniversalSection>
    );
};

export const defaultSectionTheme: Partial<SectionTheme> = {
    color: "#000000ff",
    backgroundColor: "#ffffff00",
    pt: 6,
    pb: 6,
    mb: 0,
    mt: 0,
    pl: 0,
    pr: 0,
    alignment: "center",
    stacking: "row",
    fullBleed: false,
};
