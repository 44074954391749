import React, { FC, ReactNode } from "react";
import {
    TypographyTypeValues,
    getTypographyStyle,
    typographyProps,
} from "../SimulationTheme";
import { ThemeObject, TextAlign } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";
export interface TypographyComponentProps {
    typographyType: TypographyTypeValues;
}
import {
    NodeViewContent,
    NodeViewWrapper,
    NodeViewWrapperProps,
} from "@tiptap/react";

type TypographyTheme = Partial<ThemeObject> & TypographyComponentProps;

type StyledTypographyProps = ContentBlockEvents & {
    theme?: TypographyTheme;
    children?: ReactNode;
    contentBlockId?: string;
};

//TODO: for accessibility and semantics, these should not be divs, and they should probably not be nested.
//Should return only one element
export const StyledTypography: FC<StyledTypographyProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
}) => {
    return (
        <StyledUniversalDiv
            data-content-block-id={contentBlockId}
            as={typographyProps[theme.typographyType]?.asTag}
            {...theme}
            {...getTypographyStyle(theme.typographyType)}
            textAlign={theme.textAlign}
            onClick={"function" === typeof onClick ? onClick : (_) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (_) => {}}
            className="relative z-[1000]"
        >
            {children}
        </StyledUniversalDiv>
    );
};

export const StyledTipTapTextTheme = (stuff: NodeViewWrapperProps) => {
    // const theme = node.
    const theme = stuff.node.attrs;
    // const content =
    return (
        <NodeViewWrapper className="react-component-with-content" as={"span"}>
            <StyledUniversalDiv
                className="text-wrapper"
                {...theme}
                {...getTypographyStyle(theme.typographyType, stuff.node.attrs)}
            >
                <NodeViewContent
                    className="content ingore-inner-div"
                    as={typographyProps[theme.typographyType].asTag}
                />
            </StyledUniversalDiv>
        </NodeViewWrapper>
    );
};

export const defaultTypographyComponentProps: TypographyComponentProps = {
    typographyType: "HEADING_2",
};

export const defaultTypographyTheme: Partial<TypographyTheme> = {
    ...defaultTypographyComponentProps,
    textAlign: TextAlign.left,
    color: "#000000ff",
    backgroundColor: "#ffffff00",
    py: 0,
    px: 0,
    my: 0,
    mx: 0,
};
