import { ContentBlockType } from "../../models";
import { defaultHeroTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const HeroConfig: ContentBlockConfig = {
    typeKey: "Hero",
    type: ContentBlockType.Hero,
    requiredChildBlocks: [ContentBlockType.Text],
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType.Text,
    ],
    defaultTheme: defaultHeroTheme
};
