import { VariableRelationship } from "../../models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModelBuilderSliceState, resetEntities } from "./sharedActions";
import { mapById } from "@/redux-state";
import { groupBy } from "lodash";

type variableRelationshipSliceState = Pick<
    ModelBuilderSliceState,
    | "variableRelationships"
    | "relationshipsBySourceId"
    | "relationshipsByTargetId"
>;

const initialState: variableRelationshipSliceState = {
    variableRelationships: {},
    relationshipsBySourceId: {},
    relationshipsByTargetId: {},
};

const variableRelationshipSlice = createSlice({
    name: "VariableRelationshipStore",
    initialState,
    reducers: {
        gotVariableRelationships(
            state,
            { payload }: PayloadAction<VariableRelationship[]>,
        ) {
            state.variableRelationships = mapById(payload);
            state.relationshipsBySourceId = groupBy(
                payload,
                "source_variable_id",
            );
            state.relationshipsByTargetId = groupBy(
                payload,
                "target_variable_id",
            );
        },
    },
    extraReducers: {
        // [gotEntities.toString()]: (
        //     state,
        //     { payload }: PayloadAction<Partial<ModelBuilderSliceState>>
        // ) => {
        //     state.variableRelationships = {
        //         ...state.variableRelationships,
        //         ...payload.variableRelationships,
        //     };
        //     state.relationshipsBySourceId = {
        //         ...state.relationshipsBySourceId,
        //         ...groupBy(
        //             Object.values({
        //                 ...state.variableRelationships,
        //                 ...payload.variableRelationships,
        //             }),
        //             "source_variable_id"
        //         ),
        //     };
        //     state.relationshipsByTargetId = {
        //         ...state.relationshipsByTargetId,
        //         ...groupBy(
        //             Object.values({
        //                 ...state.variableRelationships,
        //                 ...payload.variableRelationships,
        //             }),
        //             "target_variable_id"
        //         ),
        //     };
        // },
        [resetEntities.toString()]: (
            state,
            { payload }: PayloadAction<Partial<ModelBuilderSliceState>>,
        ) => {
            state.variableRelationships = {};
            state.relationshipsBySourceId = {};
            state.relationshipsByTargetId = {};
        },
    },
});

export const variableRelationshipStoreReducer =
    variableRelationshipSlice.reducer;

export const { gotVariableRelationships } = variableRelationshipSlice.actions;

// export const gotVariableRelationships = (
//     variableRelationships: VariableRelationship[]
// ) => {
//     return async (
//         dispatch: AppDispatch,
//         _,
//         { api, schema }: extraThunkArguments
//     ) => {
//         if (Array.isArray(variableRelationships)) {
//             const entities = normalize<any, ModelBuilderSliceState>(
//                 variableRelationships,
//                 [schema.variableRelationshipSchema]
//             ).entities;
//             dispatch(gotEntities(entities));
//         }
//     };
// };
