import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";
import { ContentBlockShape } from "@/models";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    depth: number;
};

export default function SelectContentBlockButton({ contentBlock, depth }: Props) {
    const { setActiveContentBlock } = useSelectActiveContentBlock();

    return (
        <button
            className="absolute w-full h-full inset-0 bg-transparent cursor-pointer add-content-block-button"
            aria-label={`Select Content Block ${contentBlock.id}`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveContentBlock(contentBlock.id);
            }}
            style={{
                zIndex: depth * 10,
            }}
            data-testid={contentBlock.content_block_type + "_" + contentBlock.weight}
        >
            <span className="sr-only">
                Select Content Block {contentBlock.id}
            </span>
        </button>
    );
}
