import React from "react";
import { ColorScheme } from "@/models";
import styled from "styled-components";

const QuestionContentBlockFormGroup = styled.div<{
    palette?: ColorScheme;
}>`
    margin: 0 0 1rem;
    /* background-color: ${({ palette }) =>
        palette && palette.backgroundColor
            ? palette.backgroundColor
            : "transparent"}; */
`;
QuestionContentBlockFormGroup.displayName = "QuestionContentBlockFormGroup";

const QuestionContentBlockPromptLabel = styled.label<{
    palette?: ColorScheme;
}>`
    display: inline-block;
    margin: 0 0 1rem 0;
`;
QuestionContentBlockPromptLabel.displayName = "QuestionContentBlockPromptLabel";

export const QuestionContentBlockFormFieldWrapper = ({
    promptText,
    palette,
    children,
}: {
    promptText?: string;
    palette?: ColorScheme;
    children?: JSX.Element;
}) => {
    return (
        <QuestionContentBlockFormGroup palette={palette}>
            <QuestionContentBlockPromptLabel>
                {promptText}
            </QuestionContentBlockPromptLabel>
            {children}
        </QuestionContentBlockFormGroup>
    );
};
