import { RichText } from "@/models";

export const defaultRichText: RichText = {
    type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
                typographyType: "FIGMA_HEADING_3",
                default: null,
            },
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "bold",
                        },
                        {
                            type: "textStyle",
                            attrs: {
                                color: "#000000ff",
                                lineHeight: "100%",
                                fontFamily: null,
                                indent: 0,
                                fontSize: 48,
                            },
                        },
                    ],
                    text: "Heading",
                },
            ],
        },
        {
            type: "paragraph",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
            },
        },
        {
            type: "typography",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
                typographyType: "FIGMA_PARAGRAPH",
                default: null,
            },
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "textStyle",
                            attrs: {
                                color: "#000000ff",
                                lineHeight: "100%",
                                fontFamily: "Inter",
                                indent: 0,
                                fontSize: 16,
                            },
                        },
                    ],
                    text: "Update this with your own text.",
                },
            ],
        },
    ],
};

export const defaultHeadingRichText: RichText = {
    type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
                typographyType: "FIGMA_HEADING_3",
                default: null,
            },
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "bold",
                        },
                        {
                            type: "textStyle",
                            attrs: {
                                color: "#000000ff",
                                lineHeight: "100%",
                                fontFamily: null,
                                indent: 0,
                                fontSize: 48,
                            },
                        },
                    ],
                    text: "Heading",
                },
            ],
        },
    ],
};

export const defaultParagraphRichText: RichText = {
    type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
                typographyType: "FIGMA_PARAGRAPH",
                default: null,
            },
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "textStyle",
                            attrs: {
                                color: "#000000ff",
                                lineHeight: "100%",
                                fontFamily: "Inter",
                                indent: 0,
                                fontSize: 16,
                            },
                        },
                    ],
                    text: "Update this with your own text.",
                },
            ],
        },
    ],
};

export const defaultTableRichText: (text:string, i: number) =>  RichText = (text = "Cell", i =1) => {
    return {type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                lineHeight: "100%",
                indent: 0,
                textAlign: "left",
                typographyType: "FIGMA_PARAGRAPH",
                default: null,
            },
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "textStyle",
                            attrs: {
                                color: "#000000ff",
                                lineHeight: "100%",
                                fontFamily: "Inter",
                                indent: 0,
                                fontSize: 16,
                            },
                        },
                    ],
                    text: ``,
                },
            ],
        },
    ],}
}
