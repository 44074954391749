import { ContentBlockType } from "../../models";
import { ConfigComponentType, defaultNavbarTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { LayoutType } from "../LayoutType";

export const NavbarConfig: ContentBlockConfig = {
    typeKey: "Navbar",
    type: ContentBlockType.Navbar,
    defaultTheme: defaultNavbarTheme,
    addableChildBlocks: [ContentBlockType.Link, ContentBlockType.Image, ContentBlockType.Inbox],
    addableLayouts: [LayoutType.Modal],
    variantOptions: [
        {
            label: "Bottom Border",
            themeObjectKey: "bottomBorder",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                border: "bordered",
                none: "none",
                "shadow": "drop shadow",
            },
        },
    ],
};
