import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orientation, device } from "./MobileWrapperTypes";

type MobileViewSliceState = {
    orientation: orientation | null;
    device: device | null;
};

const initialState: MobileViewSliceState = {
    orientation: "vertical",
    device: null,
};

const mobileViewSlice = createSlice({
    name: "MobileViewSliceState",
    initialState,
    reducers: {
        setOrientation(
            state,
            { payload }: PayloadAction<orientation | null>
        ) {
            state.orientation = payload
        },
        setDevice(
            state,
            { payload }: PayloadAction<device | null>
        ) {
            state.device = payload
        },
    },
});

export const mobileViewReducer = mobileViewSlice.reducer;

export const { setOrientation, setDevice } = mobileViewSlice.actions;
