import React, { useCallback } from "react";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { DraggableItem } from "./DraggableItem";
import { DragAndDropItem } from "./types";

export const DroppableContainer: React.FC<{
    id: string;
    activeId: string | null;
    name: string;
    isSelectionContainer: boolean;
    dragItemIds: string[];
    dragItems: DragAndDropItem[];
    orientation: "rows" | "columns";
    backgroundColor: string;
}> = ({ id, dragItemIds, name, dragItems, orientation, backgroundColor }) => {
    const { setNodeRef } = useDroppable({ id: id });

    const getChildWithId = useCallback(
        (dragItemId: string): JSX.Element | JSX.Element[] => {
            return dragItems?.find(
                (dragItem) => dragItem.option_id === dragItemId
            )?.child;
        },
        [dragItems]
    );

    return (
        <div
            className="relative rounded-md w-full"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: backgroundColor || "#e4e3e3",
                padding: "12px",
            }}
            id={id}
        >
            {" "}
            <div
                style={{
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "150%",
                    textAlign: "center",
                    color: "#374151",
                    padding: "12px 8px",
                    marginBottom: "12px",
                }}
                className="bg-white shadow-md rounded-md"
            >
                {name}
            </div>
            <SortableContext
                id={id}
                items={dragItemIds || []}
                strategy={rectSortingStrategy}
            >
                <ul
                    ref={setNodeRef}
                    style={{
                        height: "100%",
                        padding: "8px",
                        borderRadius: "6px",
                        display: "flex",
                        flexDirection:
                            orientation === "rows" ? "row" : "column",
                        gap: "16px",
                        width: "100%",
                        overflow: "auto",
                    }}
                >
                    {dragItemIds?.map((dragItemId, index) => (
                        <DraggableItem
                            key={dragItemId}
                            option_id={dragItemId}
                            child={getChildWithId(dragItemId)}
                            selection_numerical_value={index}
                            color={
                                dragItems?.find(
                                    (item) => item.option_id === dragItemId
                                )?.color || "#E9D8FD"
                            }
                            isBeingDragged={false}
                        />
                    ))}
                </ul>
            </SortableContext>
        </div>
    );
};
