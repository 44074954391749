import { ZodError, z } from "zod";
import { EditorContainerProps } from "../EditorContainer";

const baseContentBlockSchema = z.object({
    copied_from_id: z.string().nullable().optional(),
    title: z.string().nullable().optional(),
    weight: z.number().nullable().optional(),
    round_id: z.string().nullable().optional(),
    content: z.string().nullable().optional(),
    parent_content_block_id: z.string().nullable().optional(),
    theme: z.any().nullable().optional().default({}),
    content_block_type: z.string().nullable().optional(),
    route: z.string().nullable().optional().default(""),
    image_url: z.string().nullable().optional().default(""),
    video_urls: z.array(z.string()).nullable().optional().default([]),
    updated_at: z.string().nullable().optional(),
    created_at: z.string().nullable().optional().default(""),
    id: z.string().nullable().optional().default(""),
    prompt: z.any().nullable().optional(),
    rich_text: z.any().nullable().optional(),
});

export type ContentBlockSchemaType = z.infer<typeof baseContentBlockSchema>;

export const contentBlockSchema: z.ZodType<ContentBlockSchemaType> =
    baseContentBlockSchema
        .extend({
            contentBlocks: baseContentBlockSchema
                .array()
                .nullable()
                .default([]),
            ancestors: baseContentBlockSchema.array().nullable().default([]),
        })
        .nullable()
        .optional()
        .default(null);

const validatorSchema = z.object({
    auth: z
        .object({
            isAdmin: z.boolean(),
            user: z.object({
                email: z.string().email(),
                id: z.string(),
                name: z.string(),
                roles: z.array(
                    z.object({
                        id: z.number(),
                        name: z.string(),
                    })
                ),
            }),
        })
        .optional()
        .nullable()
        .default({
            isAdmin: false,
            user: {
                email: "default@test.com",
                id: "",
                name: "",
                roles: [],
            },
        }),
    breadcrumbs: z.array(z.string()).optional().nullable().default([]),
    locationInfo: z
        .object({
            query: z.record(z.string()).or(z.array(z.string())),
            location: z.string(),
        })
        .optional()
        .nullable()
        .default({
            query: {},
            location: "",
        }),
    savedBlock: contentBlockSchema,
    // simulation: z.any().nullable().optional().default({}),
    // errors: z.object({}),
    toasts: z.array(z.any()).nullable().optional().default([]),
    modalPageErrors: z.any(),
    topLevelContentBlockLayers: z
        .array(baseContentBlockSchema)
        .nullable()
        .optional()
        .default(null),
});

export type ValidatedEditorContainerProps = z.infer<typeof validatorSchema>;

export function validateDesignLayerProps(
    props: Partial<EditorContainerProps>,
): ValidatedEditorContainerProps {
    try {

        const validatedData = validatorSchema.parse(props);
        return validatedData;
    } catch (error) {
        if (error instanceof ZodError) {
            // Handle validation errors here
            console.error("Validation Errors:", error, props.auth);
        } else {
            // Handle other types of errors
            console.error("Unexpected Error:", error);
        }
    }
}
