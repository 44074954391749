import { Auth } from "@/inertia-utils/types";
import { Team, Tenant, User, UserShape } from "@/models";
import { atom, useAtom } from "jotai";

const authAtom = atom<Auth | false>(false);
const teamAtom = atom<Team | false>(false);
const tenantAtom = atom<Tenant | false>(false);
const userAtom = atom<User | UserShape | false>((get) => {
    const auth = get(authAtom);
    return auth ? auth?.user : false;
});

const isNotParticipantAtom = atom((get) => {
    const user = get(userAtom);
    return (
        user &&
        user.roles &&
        user.roles?.some((role) => role.name !== "Participant")
    );
});

export const useAuthStore = () => {
    const [auth, setAuth] = useAtom(authAtom);

    const signedIn = (auth: Auth) => setAuth(auth);
    const signedOut = () => setAuth(false);

    const [user] = useAtom(userAtom);
    const [team, setTeam] = useAtom(teamAtom);

    const [tenant, setTenant] = useAtom(tenantAtom);
    const [isNotParticipant] = useAtom(isNotParticipantAtom);

    return {
        user,
        signedIn,
        signedOut,
        auth,
        setTeam,
        team,
        tenant,
        setTenant,
        isNotParticipant,
    };
};
