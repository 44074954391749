import { ContentBlockShape, ContentBlockType } from "../../models";
import { ConfigComponentType, defaultTableTheme } from "../../styles";
import { buildTableRow } from "../content-block-layouts/TableLayout";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const LeaderboardConfig: ContentBlockConfig = {
    typeKey: "Leaderboard",
    type: ContentBlockType.Leaderboard,
    defaultTheme: defaultTableTheme,
    variantOptions: [
        {
            label: "Show Borders",
            themeObjectKey: "isBordered",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
        {
            label: "Striped",
            themeObjectKey: "isStriped",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
        {
            label: "Spreadsheet display",
            themeObjectKey: "isSpreadsheet",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
    ],
};

export const TableConfig: ContentBlockConfig = {
    typeKey: "Table",
    type: ContentBlockType.Table,
    defaultTheme: defaultTableTheme,
    variantOptions: [
        {
            label: "Show Borders",
            themeObjectKey: "isBordered",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
        {
            label: "Striped",
            themeObjectKey: "isStriped",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
        {
            label: "Spreadsheet display",
            themeObjectKey: "isSpreadsheet",
            configComponentType:
                ConfigComponentType.FormInputToggleArrayElement,
            optionArray: [false, true],
        },
    ],
    onClickAdd(table: ContentBlockShape) {
        const tableBody = table.contentBlocks.find(
            (block) =>
                block.content_block_type === ContentBlockType["Table Body"]
        );
        const row = buildTableRow(tableBody);
        return row;
    },
};

// export const TableHeadConfig: ContentBlockConfig = {
//     typeKey: "Table Head",
//     type: ContentBlockType["Table Head"],
//     defaultTheme: {},
// };

export const TableBodyConfig: ContentBlockConfig = {
    typeKey: "Table Body",
    type: ContentBlockType["Table Body"],
    defaultTheme: {},
    addableChildBlocks: [ContentBlockType["Table Row"]],
    onClickAdd(tableBody) {
        const row = buildTableRow(tableBody);
        return row;
    },
};

// export const TableRowConfig: ContentBlockConfig = {
//     typeKey: "Table Row",
//     type: ContentBlockType["Table Row"],
//     defaultTheme: {},
// };

// export const TableHeaderCellConfig: ContentBlockConfig = {
//     typeKey: "Table Header Cell",
//     type: ContentBlockType["Table Header Cell"],
//     defaultTheme: {},
// };

// export const TableDataCellConfig: ContentBlockConfig = {
//     typeKey: "Table Data Cell",
//     type: ContentBlockType["Table Data Cell"],
//     defaultTheme: {},
// };
