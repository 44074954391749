import { CardDeckLayout } from "./CardDeckLayout";
import { FeatureLayout } from "./FeatureLayout";
import { HeroLayout } from "./HeroLayout";
import { Hero2Layout } from "./HeroLayout2";
import { Hero3Layout } from "./HeroLayout3";
import { AccordionLayout } from "./AccordionLayout";
import { Card } from "./CardLayouts";
import { TableLayout } from "./TableLayout";
import { BottomDrawerLayout } from "./BottomDrawerLayout";
import { ModalLayout } from "./ModalLayout";
import { DataDisplayCardLayout } from "./DataDisplayCardLayout";
import { TabsLayout } from "./TabsLayout";
import { CarouselLayout } from "./CarouselLayout";

export const ContentBlockLayouts = {
    CardDeckLayout,
    FeatureLayout,
    HeroLayout,
    Hero2Layout,
    Hero3Layout,
    AccordionLayout,
    Card,
    TableLayout,
    BottomDrawerLayout,
    ModalLayout,
    DataDisplayCardLayout,
    TabsLayout,
    CarouselLayout,
};
