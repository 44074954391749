import { useMutation } from "@tanstack/react-query";
import { createQuestion } from "./createQuestion";
import { PromptType } from "@/models";
import { useGotContentBlock, useModalQueryParams } from "@/hooks";
import { useAddContentBlockState } from "../useAddContentBlockState";

export function useCreateQuestion() {
    const { gotContentBlock } = useGotContentBlock();
    const { openModal } = useModalQueryParams();
    const { reset } = useAddContentBlockState();

    return useMutation({
        mutationFn: async ({
            parentContentBlockId,
            promptType,
        }: {
            parentContentBlockId: string;
            promptType: PromptType;
        }) => {
            return createQuestion({ parentContentBlockId, promptType });
        },
        onSuccess: ({ contentBlock, promptType }) => {
            gotContentBlock(contentBlock);
            openModal({
                modalComponent: "QuestionModal",
                contentBlockId: contentBlock.id,
                promptType,
            });
            reset();
        },
    });
}
