import { SapienInertia } from "@/inertia-utils/hooks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { Trash } from "lucide-react";
import React, { useCallback, useState } from "react";

export function DeleteColumnButton({
    column,
    table,
}: {
    table: ContentBlockShape;
    column: number;
}) {
    const [isPending, setIsPending] = useState(false);
    const handleDeleteColumn = useCallback(() => {
        setIsPending(true);
        const rows = table.contentBlocks.reduce((carry, block) => {
            return [
                ...carry,
                ...block.contentBlocks.filter(
                    (b) =>
                        b.content_block_type === ContentBlockType["Table Row"],
                ),
            ];
        }, []);
        const blocksToDelete = rows.map((row) => row.contentBlocks[column]);

        SapienInertia.post(
            "e-learning.delete-multiple-content-blocks",
            {
                ids: blocksToDelete.map((b) => b.id),
            } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.delete-multiple-content-blocks"],
            {},
            { onFinish: () => setIsPending(false), preserveScroll: true },
        );
    }, [column, table]);

    return (
        <button
            className={`flex items-center space-x-2 rounded-md px-2 py-1 transition-all
            hover:bg-slate-100 ${isPending ? "cursor-wait" : "cursor-pointer"}`}
            onClick={() => handleDeleteColumn()}
        >
            <Trash className="h-4 w-4 text-red-500" />
            <span>Delete Column</span>
        </button>
    );
}
