import { ContentBlockType } from "../../models";
import { defaultBoxTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const BoxConfig: ContentBlockConfig = {
    typeKey: "Box",
    type: ContentBlockType.Box,
    defaultTheme: defaultBoxTheme,
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType.Grid,
        ContentBlockType.Text,
        ContentBlockType.Image,
        ContentBlockType.Video,
        ContentBlockType.Icon,
        ContentBlockType.Link,
        ContentBlockType["Rich Text"],
        ContentBlockType.Button,
        ContentBlockType["Chart Container"],
        ContentBlockType["Model Value Display"],
        ContentBlockType.Carousel
    ],
};
