import React from "react";

type Props = {
    onClick: () => void;
    children: React.ReactNode | React.ReactNode[];
    isActive: boolean;
};

export default function RichTextMenuButton({
    onClick,
    children,
    isActive,
}: Props) {
    return (
        <button
            onClick={onClick}
            className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
            hover:border-white/20 hover:bg-sapien-blue hover:text-white focus:outline-none
            focus:ring-opacity-50 ${
                isActive
                    ? "border-gray-300/50 bg-sapien-blue text-white"
                    : "border-gray-300/30 bg-slate-100 text-slate-900"
            }`}
        >
            {children}
        </button>
    );
}
