import { ModelVariable, VariableRelationship } from "../../models";
import { StoredModelBlock } from "./schema";
import { createAction } from "@reduxjs/toolkit";

export type ModelBuilderSliceState = {
    modelBlocks: {
        [index: string]: StoredModelBlock;
    };
    selectedModelBlock: StoredModelBlock | undefined;
    modelVariables: { [index: string]: ModelVariable };
    activeModelVariable: ModelVariable | undefined;
    variableRelationships: { [index: string]: VariableRelationship };
    relationshipsBySourceId: { [index: string]: VariableRelationship[] };
    relationshipsByTargetId: { [index: string]: VariableRelationship[] };
};

export const gotEntities =
    createAction<Partial<ModelBuilderSliceState>>("gotEntities");
export const resetEntities =
    createAction<Partial<ModelBuilderSliceState>>("resetEntities");
