import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { useQuery } from "@tanstack/react-query";

async function fetchPages(roundId: string) {
    const { data } = await sapienAxios.get<
        { pages: ContentBlockShape[] },
        "creator.design.pages.index"
    >("creator.design.pages.index", { roundId });

    return data?.pages;
}

export function usePages(roundId: string) {
    return useQuery({
        queryKey: ["pages", roundId],
        queryFn: () => fetchPages(roundId),
        initialData: [],
    });
}
