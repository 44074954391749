import { useQuery } from "@tanstack/react-query";
import { Participant } from "@/models";
import axios from "axios";
import { Auth } from "@/inertia-utils/types";
import { Course, Lesson } from "../../types";
import { sapienAxios } from "@/inertia-utils/hooks";
import { Submission } from "../../shared-state/useSelections";
let accessToken = "";
function setAuthorizationHeader(token: string) {
    console.log("setting aut", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults["withCredentials"] = true;
axios.defaults.baseURL = window?.["baseUrl"] || "";
axios.interceptors.response.use(
    (response) => {
        // console.log("CONFIG", response);
        if (response.data.token) {
            accessToken = response.data.token;
        }
        //   const token = getToken(); // Function to get the token dynamically
        //   if (token) {
        //     config.headers['Authorization'] = `Bearer ${token}`;
        //   }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);
axios.interceptors.request.use((request) => {
    if (accessToken) {
        request.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return request;
});
async function login({
    student_id,
    student_name,
    lesson_id,
}: {
    student_id: string;
    student_name: string;
    lesson_id?: string;
}) {
    const { data } = await sapienAxios.post<
        {
            teamUserRound: {
                id: string;
                viewedContentBlocks: {
                    content_block_id: string;
                }[];
            };
            auth: Auth & { user: Participant };
            token: string;
            course: Course;
            lesson: Lesson;
            selections: Submission[]
        },
        "e-learning.embed.login"
    >(
        "e-learning.embed.login",
        {
            student_id,
            student_name,
            lesson_id,
        },
        {
            withCredentials: true,
            headers: {
                "X-Force-Preflight": "force-preflight",
                "Content-Type": "application/json",
            },
            method: "POST",
        },
    );
    console.log("data", data);
    return data;
}

export function useAuth({
    student_id,
    student_name,
}: {
    student_id: string;
    student_name: string;
}) {
    const lesson_id = window?.["lessonId"];

    console.log("student_id", student_id, student_name, lesson_id);
    return useQuery({
        queryKey: ["auth", student_id, student_name],
        queryFn: () => login({ student_id, student_name, lesson_id }),
        // enabled: !!student_id && !!student_name,
    });
}
