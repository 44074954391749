import { ContentBlockType } from "../../models";
import { defaultTextTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const TextConfig: ContentBlockConfig = {
    typeKey: "Text",
    type: ContentBlockType.Text,
    defaultTheme: defaultTextTheme,
    // availableBehaviors: ["ShowCountOfSubmissionByTeamForRound"],
};
