import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentBlockType } from "../models";
import {
    AddSiblingBlockData,
    ContentBlockMetadata,
    AvailableBehaviors,
} from "../model-configs";
import { DimensionObject } from "@/hooks";

export type SecondaryBlockData = {
    blockTypeRequiringSecondary?: ContentBlockType;
    secondaryBlockType?: ContentBlockType;
    secondaryBlockId?: string;
    secondaryBlockBehavior?: AvailableBehaviors;
};

type ContentSelectorState = {
    selectedBlockMeta: ContentBlockMetadata | null;
    copiedContentBlockId: string | null;
    hoveredContentBlockIds: string[];
    mousePosition: { x: number; y: number };
    hoveredDimensions: DimensionObject | null;

    /**
     * when adding certain content blocks, allow designer to choose
     * extra data about any necessary secondary blocks
     * like a trigger block used to open a modal
     */
    secondaryBlockData: SecondaryBlockData;

    //when add sibling block button is clicked in design layer
    addSiblingBlockData: AddSiblingBlockData | null;
};

const initialState: ContentSelectorState = {
    selectedBlockMeta: null,
    hoveredContentBlockIds: [],
    mousePosition: { x: 0, y: 0 },
    hoveredDimensions: null,
    copiedContentBlockId: null,
    addSiblingBlockData: null,
    secondaryBlockData: {},
};

const contentSelectorSlice = createSlice({
    name: "ContentSelectorState",
    initialState,
    reducers: {
        setSelectedBlockMeta(
            state,
            { payload }: PayloadAction<ContentBlockMetadata>
        ) {
            state.selectedBlockMeta = payload;
        },
        setHoveredContentBlockIds(state, { payload }: PayloadAction<string[]>) {
            state.hoveredContentBlockIds = payload;
        },
        setMousePosition(
            state,
            { payload }: PayloadAction<{ x: number; y: number }>
        ) {
            state.mousePosition = payload;
        },
        setHoveredDimensions(
            state,
            { payload }: PayloadAction<DimensionObject | null>
        ) {
            state.hoveredDimensions = payload;
        },

        // setCopiedContentBlockId not used?
        setCopiedContentBlockId(
            state,
            { payload }: PayloadAction<string | null>
        ) {
            state.copiedContentBlockId = payload;
        },
        setAddSiblingBlockData(
            state,
            { payload }: PayloadAction<AddSiblingBlockData | null>
        ) {
            state.addSiblingBlockData = payload;
        },
        setSecondaryAddBlockData(
            state,
            { payload }: PayloadAction<SecondaryBlockData>
        ) {
            state.secondaryBlockData = payload;
        },
    },
});

export const contentSelectorStoreReducer = contentSelectorSlice.reducer;

export const {
    setSelectedBlockMeta,
    setHoveredContentBlockIds,
    setMousePosition,
    setHoveredDimensions,
    setAddSiblingBlockData,
    setSecondaryAddBlockData,
} = contentSelectorSlice.actions;
