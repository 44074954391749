import { ContentBlockShape, ContentBlockType } from "../../models";

function buildHero2({
    imagePlacement,
    textPlacement,
    palette,
}): ContentBlockShape {
    return {
        title: "Hero",
        content: "Hero",
        content_block_type: ContentBlockType.Hero2,
        theme: {
            imagePlacement,
            textPlacement,
        },
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Box,
                weight: 0,
                theme: {
                    imagePlacement,
                    textPlacement,
                },
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Box,
                        weight: 0,
                        theme: {
                            width: "100%",
                            maxWidth: "640px",
                            display: "flex",
                            flexDirection: "column",
                            margin: [
                                "0px 64px 20px 64px",
                                "0px 64px 20px 64px",
                                "0px 64px 20px 64px",
                                "0px 64px 0px 64px",
                            ],
                            backgoundImage: "",
                        },
                        contentBlocks: [
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 0,
                                theme: {
                                    typographyType: "SUBTITLE",
                                    margin: "0px 0px 0px 0px",
                                    // textAlign: heroTheme.textPlacement,
                                    content: "Season 1",
                                },
                            },
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 1,
                                theme: {
                                    typographyType: "HEADING_4",
                                    margin: "12px 0px 0px 0px",
                                    // textAlign: heroTheme.textPlacement,
                                },
                                content: "You've just been hired.",
                            },
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 2,
                                theme: {
                                    typographyType:
                                        "PARAGRAPH_ALT",
                                    margin: "24px 0px 0px 0px",
                                    // textAlign: heroTheme.textPlacement,
                                    //         color: heroTheme.palette.textColor,
                                },
                                content: `Stiegler International is a large multinational consumer
                                    goods business that began in the United States in 1959.
                                    Today, it sells products ranging from domestic
                                    appliances to over-ear headphones across the globe.`,
                            },
                            {
                                content_block_type: ContentBlockType.Box,
                                weight: 3,
                                theme: {
                                    width: "50px",
                                    height: "50px",
                                    // backgroundImage: `url(${buttonIconDark}) `,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    alignSelf: "center",
                                    marginTop: [
                                        "60px",
                                        "80px",
                                        "120px",
                                        "200px",
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        content_block_type: ContentBlockType.Box,
                        weight: 1,
                        theme: {
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: ["310px", "504px", "604px", "100%"],
                            height: ["65vh", "65vh", "100vh", "100vh"],
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "end",
                            backgroundSize: [
                                "cover",
                                "cover",
                                "cover",
                                "cover",
                            ],
                            backgroundImage:
                                "url(https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/windturbines.png)",
                        },
                        contentBlocks: [
                            {
                                content_block_type: ContentBlockType.Box,
                                weight: 0,
                                theme: {
                                    width: "100%",
                                    maxWidth: "360px",
                                    height: "fit-content",
                                    background: "#1F2A37",
                                    borderRadius: "8px",
                                    padding: "24px",
                                    margin: [
                                        "0px 5px 5px 5px",
                                        "0px 28px 28px 28px",
                                        "0px 28px 28px 28px",
                                        "0px 48px 48px 48px",
                                    ],
                                },
                                contentBlocks: [
                                    {
                                        content_block_type:
                                            ContentBlockType.Box,
                                        weight: 0,
                                        theme: {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        },
                                        contentBlocks: [
                                            {
                                                content_block_type:
                                                    ContentBlockType.Box,
                                                weight: 0,

                                                theme: {
                                                    width: "22px",
                                                    height: "22px",
                                                    // backgroundImage: `url(${info}) `,
                                                    backgroundRepeat:
                                                        "no-repeat",
                                                    backgroundSize: "contain",
                                                    marginRight: "5px",
                                                },
                                            },
                                            {
                                                content_block_type:
                                                    ContentBlockType.Typography,
                                                weight: 1,
                                                theme: {
                                                    typographyType: "PARAGRAPH_SMALL",
                                                    // textAlign:
                                                    //     heroTheme.textPlacement,
                                                    margin: 0,
                                                    width: "100%",
                                                    maxWidth: "320px",
                                                    color: palette.textColor,
                                                },
                                                content: `The number of countries announcing pledges to
                                                achieve net zero emissions over the coming
                                                decades continues to grow. But the pledges to
                                                date fall well short of what is required to
                                                bring global energy-related carbon dioxide
                                                emissions to net zero by 2050.`,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

export const Hero2Layout: ContentBlockShape = buildHero2({
    imagePlacement: "right",
    textPlacement: "center",
    palette: {
        textColor: "#6B7280",
        borderColor: "",
        highlightColor: "",
        highlightBackgroundColor: "",
    },
});
