import { ConfigComponentType, defaultSubmissionCountTheme } from "@/styles";
import { ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const SubmissionCountConfig: ContentBlockConfig = {
    typeKey: "Submission Count",
    type: ContentBlockType["Submission Count"],
    defaultTheme: defaultSubmissionCountTheme,
    variantOptions: [
        {
            label: "Color",
            themeObjectKey: "colorWhenLessThanHalfComplete",
            configComponentType: ConfigComponentType.FormInputColorPicker,
        },
        {
            label: "Color when 50% complete",
            themeObjectKey: "colorWhenHalfComplete",
            configComponentType: ConfigComponentType.FormInputColorPicker,
        },
        {
            label: "Color when complete",
            themeObjectKey: "colorWhenComplete",
            configComponentType: ConfigComponentType.FormInputColorPicker,
        },
    ],
    availableBehaviors: ["ShowCountOfSubmissionByTeamForRound"],
};
