import styled from "styled-components";

export const TableContainer = styled.div`
    max-width: 100vw;
    overflow-x: auto;
`;
TableContainer.displayName = "TableContainer";

// export const TableHeaderCellDiv = styled.div`
//     min-width: 200px;
//     font-weight: bold;
// `;
// TableHeaderCellDiv.displayName = "TableHeaderCellDiv";

export const TableStickyLeftCell = styled.td`
    position: sticky;
    left: 0;
    background: white;
    box-shadow: 3px 0px 5px #dee2e6;
    padding: 0.5rem;
`;
TableStickyLeftCell.displayName = "TableStickyLeftCell";

export const TablePaddedCell = styled.td`
    padding: 0.5rem;
`;
TablePaddedCell.displayName = "TablePaddedCell";

export const Table = styled.table`
    font-size: "0.8rem";
    border-collapse: separate;
    &.table-striped {
        tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05);
        }
        &.h-gray tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05);
        }
        &.h-green tbody tr:nth-of-type(odd) {
            background-color: rgba(20, 134, 5, 0.05);
        }
        &.h-blue tbody tr:nth-of-type(odd) {
            background-color: rgba(58, 171, 218, 0.05);
        }
        &.h-red tbody tr:nth-of-type(odd) {
            background-color: rgba(133, 4, 4, 0.05);
        }
    }
    /* &.vertical-striped {
    tbody td:nth-of-type(odd) {
      background-color: rgba(58, 171, 218, 0.04);
    }
    &.v-gray tbody td:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &.v-green tbody td:nth-of-type(odd) {
      background-color: rgba(20, 134, 5, 0.04);
    }
    &.v-blue tbody td:nth-of-type(odd) {
      background-color: rgba(58, 171, 218, 0.04);
    }
    &.v-red tbody td:nth-of-type(odd) {
      background-color: rgba(133, 4, 4, 0.04);
    }
  } */
    &.vertical-striped {
        tbody td:nth-of-type(even) {
            background-color: rgba(58, 171, 218, 0.04);
        }
        &.v-gray tbody td:nth-of-type(even) {
            background-color: rgba(0, 0, 0, 0.04);
        }
        &.v-green tbody td:nth-of-type(even) {
            background-color: rgba(20, 134, 5, 0.04);
        }
        &.v-blue tbody td:nth-of-type(even) {
            background-color: rgba(58, 171, 218, 0.04);
        }
        &.v-red tbody td:nth-of-type(even) {
            background-color: rgba(133, 4, 4, 0.04);
        }
    }
`;
Table.displayName = "Table";
